import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import CommunityGeneral from '../../CommunityGeneral';
import Fade from '@mui/material/Fade';
import CommunityAddress from '../../CommunityAddress';
import CommunityContact from '../../CommunityContact';

type Route = 'name' | 'address' | 'contact';

export default function TemporaryDrawer() {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    console.log("onClose");
    setAnchorEl(null);
    
  };

const ROUTES  = {
  name:<CommunityGeneral onClose={handleClose}/>,
  address: <CommunityAddress onClose={handleClose}/>,
  contact: <CommunityContact onClose={handleClose}/>
}

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [state, setState] = React.useState<{route: Route, isDrawer: boolean}>({
    route: "name",
    isDrawer: false
  });

  const toggleDrawer =
    (route: Route, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, isDrawer: open, route: route });
        handleClose()
      };


      
      
      React.useEffect(()=>{
        console.log("Anchor element changed: ",anchorEl)
      },[anchorEl])

  const list = (route: Route) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem >
          <Box>Edit community {route.charAt(0).toUpperCase().concat(route.replace(route.charAt(0), ""))}</Box>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem>
          {ROUTES[route]}
        </ListItem>
      </List>
    </Box>
  );

  return (


    <React.Fragment>
      <Tooltip title="Edit Profile">
        <IconButton id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem disabled>Edit</MenuItem>
        <MenuItem onClick={toggleDrawer("name", true)}>Name</MenuItem>
        <MenuItem onClick={toggleDrawer("address", true)}>Address</MenuItem>
        <MenuItem onClick={toggleDrawer("contact", true)}>Contact</MenuItem>
      </Menu>


      {/* Side Bar Menu */}
      <Drawer
        anchor={"right"}
        open={state["isDrawer"]}
        onClose={toggleDrawer("name",false)}
      >
        {list(state['route'])}
      </Drawer>
    </React.Fragment>

  );
}

