import { Invoice } from 'Straclou/interfaces/Invoice'
import React, { useState, createContext, useEffect } from 'react'
import { IAccount } from 'Straclou'


type PaymentOption = "cash" | "debit card" | "credit card" | "online"
interface IProps {
    children: React.ReactNode
}

export interface PaymentMethod {
    id: string
    amount: number
    option: PaymentOption
}
interface ActivePaymentIntf {
    account: IAccount
    setAccount: Function
    paymentMethod: PaymentMethod[]
    setPaymentMethod: Function
    total: number
    invoices: Invoice[]
    setInvoices: Function
    setFinancialId: Function
    financialId: string
    accountHolder: string
    setAccountHolder: Function
    description: string
    setDescription: Function
    property: any
    setProperty: Function
    setActivePayment: Function
}

export const ActivePaymentContext = createContext<ActivePaymentIntf>({
    account: new IAccount(),
    setAccount: (e: IAccount)=>{},
    paymentMethod: [],
    setPaymentMethod: (e: any) => { },
    total: 0,
    invoices: [],
    setInvoices: (e: any) => { },
    setFinancialId: (e: any) => { },
    financialId: "",
    accountHolder: "",
    setAccountHolder: (e: any) => { },
    description: "",
    setDescription: (e: any)=>{ },
    property: {},
    setProperty: (e: any)=>{ },
    setActivePayment: (e: any)=>{}
})

const ActivePaymentProvider = ({ children }: IProps) => {
    const [total, setTotal] = useState<number>(0)
    const [paymentMethod, setPaymentMethod] = useState<Array<PaymentMethod>>([])
    const [invoices, setInvoices] = useState<Array<Invoice>>([])
    const [financialId, setFinancialId] = useState<string>("2323")
    const [accountHolder, setAccountHolder] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [property, setProperty] = useState<any>({})
    const [activePayment, setActivePayment] = useState<boolean>(true)
    const [account, setAccount] = useState<IAccount>(new IAccount())

    useEffect(() => {
        let t = paymentMethod.reduce((acc: number, el: PaymentMethod) => {
            return parseInt(acc.toString()) + parseInt(el.amount.toString())
        }, 0)
        setTotal(t)
    }, [paymentMethod])


    useEffect(()=>{
        // if(!activePayment){
            setTotal(0)
            setPaymentMethod([])
            setInvoices([])
            setFinancialId("")
            setAccountHolder("")
            setDescription("")
            setProperty({})            
        // }
    },[])

    return (
        <ActivePaymentContext.Provider value={{
            account,
            setAccount,
            paymentMethod,
            setPaymentMethod,
            total,
            invoices,
            setInvoices,
            setFinancialId,
            financialId,
            accountHolder,
            setAccountHolder,
            description,
            setDescription,
            property,
            setProperty,
            setActivePayment

        }} >
            {children}
        </ActivePaymentContext.Provider>
    )
}


export default ActivePaymentProvider