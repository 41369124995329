import { Box, styled } from "@mui/material";

export const NavigationLink = styled('span')<{ fontSize?: string }>(({ theme, fontSize }) => ({
    fontSize: fontSize || "9pt",
    margin: "0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
        textDecoration: "underline",
    }
}))