import { styled} from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

const NavLinkStyles = styled(NavLink)(({
    textDecoration: "none",
    color: "#000000bf"
}))

interface IProps{
    children?: React.ReactNode
    to: string
}
const NavLinkExhibit: React.FC<IProps> = (props: any)=>{

    return (
        <NavLinkStyles {...props}>{props.children}</NavLinkStyles>
    )
}

export default NavLinkExhibit