import { useNavigate } from "react-router-dom"
import { ProgressStep } from "./useOnboardingProgress"

export default function useOnboardingProgressRouter(id?: string) {

    const navigate = useNavigate()

    const route = (choice: ProgressStep) => {
        let path = `/client/${id}/`
        let options: any = {
            CommunityName: "community_name",
            CommunityStrataNumber: `community_strata_number`,
            CommunitySize: `community_size`,
            CommunityStructure: `community_structure`,
            CommunityAddress: `community_address`,
            Complete: "setup_complete"
        }
        return `${path}${options[choice]}`
    }

    const goto = (path: ProgressStep) => {
        navigate(route(path))
    }

    return {
        goto: goto
    }
}