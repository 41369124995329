
import { ConnectionStrings } from 'Straclou/ConnectionStrings';
import { axiosExecute, AxiosOptions } from '../axios';

export module PaymentConnectionHandler {



    // TODO Remove all Temp vars 
    // Temp Vars
    const communityIdx = "e004a3cf-7051-4cd3-8ec2-020dfd2e64a6"


    // Temp Vars ends here



    /**
        * List all Invoices for a specific Account.
        * @param filter 
        * @returns 
        */
    export const listAll = async (filter: any) => {
        try {
            const communityId = communityIdx//(await CommunityActivityHandler.GetCommunityProfileFromLocalStorage()).id

            filter['communityId'] = communityId

            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.account,
                data: JSON.stringify({
                    input: filter,
                    method: 'get'
                })

            }


            return []
        } catch (error) {
            return []
        }
    }


    export const makePayment = async (payload: any) => {
        return new Promise(async(resolve, reject) => {

            payload['communityId'] = communityIdx

            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.PAYMENTCONNECTION,
                data: JSON.stringify({
                    input: payload,
                    method: 'makePayment'
                })
            }

            try {                
                let result  = await axiosExecute(option)
    
                console.log(result)
    
                resolve(result)
            } catch (error) {
                reject(error)
            }

        })
    }

}