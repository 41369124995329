import { Box, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import Check from '@mui/icons-material/Check';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import Groups2Icon from '@mui/icons-material/Groups2';
import { theme } from "globals/theme";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";


export default function AdminSideMenuContainer() {

    const navigate = useNavigate()
    const params = useParams()

    const location = useLocation()

    const handleOnClick = (name: string)=>{
        navigate(name)
    }

    const active = (name: string, bg: string)=>{
        let subMenu = location.hash

        if(subMenu === name) {
            return {
                background: bg,
                color: "#fff",
                "& svg":{
                    color: "#fff"
                }
            }
        }
        return {
            background: "inherit",
            color: "#333"
        }
    }

    useEffect(()=>{
        console.log("params: ",params);
        
    },[params])

    useEffect(()=>{
        console.log("location: ",location.hash);
        
    },[params])


    return (
        <Box sx={{ background: "0", height: "100%", color: "#333", padding: "8px" }}>
            <MenuList dense>
                <MenuItem sx={{ ...active("#dashboard",theme.palette.primary.dark)}} onClick={()=>handleOnClick("#dashboard")}>
                    <ListItemIcon >
                        <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText>Main Dashboard</ListItemText>
                </MenuItem>

                <MenuItem sx={{...active("#subscription",theme.palette.primary.dark)}} onClick={()=>handleOnClick("#subscription")} >
                    <ListItemIcon>
                        <SubscriptionsIcon />
                    </ListItemIcon>
                    <ListItemText>Subscriptions</ListItemText>
                </MenuItem>

                <MenuItem sx={{...active("#staff",theme.palette.primary.dark)}} onClick={()=>handleOnClick("#staff")}>
                    <ListItemIcon>
                        <Groups2Icon />
                    </ListItemIcon>
                    <ListItemText>Staff</ListItemText>
                </MenuItem>
                {/* <MenuItem>
                    <ListItemIcon>
                        <Check />
                    </ListItemIcon>
                    Custom: 1.2
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemText>Add space before paragraph</ListItemText>
                </MenuItem>
                <MenuItem>
                    <ListItemText>Add space after paragraph</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem>
                    <ListItemText>Custom spacing...</ListItemText>
                </MenuItem> */}
            </MenuList>
        </Box>
    )
}