import { Box, Button, Popover, Typography } from "@mui/material";
import AppButton from "components/widget/AppButton";
import React, { useEffect } from "react";

export const UnitSizeInfo: React.FC<any> = ({ detail, value }) => {


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [descriptions, setDescriptions] = React.useState<Array<string>>([]);

    useEffect(() => {
        if (detail !== null && Array.isArray(detail)) {
            setDescriptions([...detail])
        } else {
            setDescriptions([])
        }
    }, [detail])

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <Box>
                <AppButton onClick={handleClick} value={value} />
            </Box>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}

            >
                <Box sx={{ width: "500px" }}>
                    {descriptions.map(d => {
                        return <Typography sx={{ p: 2, fontSize: "11pt" }}>{d}</Typography>
                    })}

                </Box>
            </Popover>
        </div>
    )
}