import { IWorkspace } from "Straclou/commons/IWorkspace"
import { create } from "zustand"


interface WorkspaceStore {
    workspace: IWorkspace
    structureList: Array<string>,
    updateStructureList: (item: any) => void,
    clearStore: ()=>void
    setWorkspace: (iw: IWorkspace)=>void
    updateWorkspace: (iw: any)=>void
}

let workspaceStore: any = (set: any) => ({
    workspace: new IWorkspace(),
    structureList: [],
    updateStructureList: (item: string) => set((s: WorkspaceStore) => {
        let myList = s.structureList || []
        let isIndex = myList.findIndex((i: string) => (i === item ))
                
        // We did not fnd a key therefore this object is not part of the list
        if (isIndex === -1) {
            //The item is new 
            myList.push(item)
        } else {
            // The item is old but we are going to update it 
            myList[isIndex] = item
        }

        return {
            structureList: [...myList]
        }
    }),
    clearStore: ()=>set((s:WorkspaceStore)=>({
        structureList: []
    })),
    setWorkspace: (iw: IWorkspace)=>set((s: WorkspaceStore)=>{        
            return {
                workspace: iw
    
            }
        
    }),
    updateWorkspace: (iw: any)=>set((s:WorkspaceStore)=>{
        s.workspace.update(iw)
        return {
            ...s
        }

    })
})

const useWorkspaceStore = create<WorkspaceStore>(workspaceStore)

export default useWorkspaceStore