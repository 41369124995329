import { IAccount, ICollection } from "Straclou"
import { ListAccountResponse } from "Straclou/API"
import { IClass } from "Straclou/IClass"
import { create } from "zustand"


interface AccountStore {
    list: ICollection<IAccount>
    sortList: ICollection<IAccount>
    count: number
    start: number
    end: number
    next: boolean
    page: number
    totalCount: number
    orderBy: (fieldName: string, cb?: Function) => void
    updateAccount: (item: ListAccountResponse<IAccount>) => void
    addToCollection: (item: any) => void
    clearStore: () => void
    newAccountInput: (fn: Function) => void

}

let accountStore: any = (set: any) => ({
    start: 1,
    end: 50,
    totalCount: 50,
    list: new ICollection<IAccount>([
        new IAccount({
            id: "5005255a-762a-4b7e-afbd-0522c6dff327",
            accountName: "Maureen M. Brown",
            daysOutstanding: 45,
            createdAt: "2031-01-13T00:00:00.000Z",
            updatedAt :"2031-01-13T00:00:00.000Z",
            unitNumber: "1200",
            status: "DELINQUENT",
            proprietorId: "3297wyewheihr97r23794t52347t5i23hroq",
            propertyId: "2805y29eyr92y529hr2h49rh23r23h92",
            prePayment: 0,
            months: 1.3,
            fee: 3200,
            dueAmount: 6400,
            communityId: "3AGJMQRT2",
            balance: 10000,
            author: {id: "1", name: "Kelly Price"},
            accountNumber: "768-392-495"
        })
    ]),
    sortList: new ICollection<IAccount>([
        new IAccount({
            id: "5005255a-762a-4b7e-afbd-0522c6dff327",
            accountName: "Maureen M. Brown",
            daysOutstanding: 45,
            createdAt: "2031-01-13T00:00:00.000Z",
            updatedAt :"2031-01-13T00:00:00.000Z",
            unitNumber: "1200",
            status: "DELINQUENT",
            proprietorId: "3297wyewheihr97r23794t52347t5i23hroq",
            propertyId: "2805y29eyr92y529hr2h49rh23r23h92",
            prePayment: 0,
            months: 1.3,
            fee: 3200,
            dueAmount: 6400,
            communityId: "3AGJMQRT2",
            balance: 10000,
            author: {id: "1", name: "Kelly Price"},
            accountNumber: "768392495"
        })
    ]),
    addToCollection: (item: any)=>set((s: AccountStore)=>{
        let al = s.list
        al.update(new IAccount(item))

        let sal = s.sortList
        
        sal.update(new IAccount(item))

        s.totalCount += 1

        if(s.end < 50) s.end += 1

        sal.orderBy("createdAt",(a: string,b: string)=>{
            let aD = new Date(a)
            let bD = new Date(b)
            return  bD.getTime() - aD.getTime()
        })

        return {
            ...s,
            list: al,
            sortList: sal
        }
    }),
    updateAccount: (item: ListAccountResponse) => set((s: AccountStore) => {

        // let collection: ICollection<IAccount> = new ICollection()
        // const list: ICollection<IAccount> = new ICollection([])
        // const sortedList: ICollection<IAccount> = new ICollection([])

        IClass.setList(item.items, (arg) => {
            s.list.update(new IAccount(arg))
            s.sortList.update(new IAccount(arg))
        })

        s.sortList.orderBy("createdAt",(a: string,b: string)=>{
            let aD = new Date(a)
            let bD = new Date(b)
            return  bD.getTime() - aD.getTime()
        })



        return {
            list: s.list,
            sortList: s.sortList,
            count: item.count,
            start: item.start,
            end: item.end,
            totalCount: item.totalCount,
            next: item.next,
            page: item.page
        }
    }),
    clearStore: () => set((s: AccountStore) => ({
        list: new ICollection<IAccount>([]),
        sortList: new ICollection<IAccount>([])
    })),
    newAccountInput: (fn: Function) => set((s: AccountStore) => {
        fn({})
        return s
    }),
    orderBy: (fieldName: string, cb: Function) => set((s: AccountStore) => {
       return {
            sortList: s.sortList.orderBy(fieldName, cb)
        }
    })
})

// userAccess"" = persist(userAccess"", { name: userHandler.userAccessTag })

const useAccountStore = create<AccountStore>(accountStore)

export default useAccountStore