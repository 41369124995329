import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, styled } from "@mui/material";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { useParams } from "react-router-dom";
import { ChangeDetectionManager } from "Straclou";
import { ButtonController, IMessageControl } from "./component/ButtonController";
import { Identity } from "./interface";

const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))
export default function CommunityContact(IProps: any) {

    const { onClose } = IProps

    const { workspace, updateWorkspace } = useWorkspaceStore()
    const { queryWorkspaceById, updateWorkspaceById } = useWorkspace()
    const [identity, setIdentity] = useState<Identity | null>(null)
    const params = useParams()

    const { navigateBack } = useWidgetBuilderNavigation()
    const [isLoading, setIsLoading] = useState<boolean>(false)


    const [message, setMessage] = useState<IMessageControl>({
        open: false,
        message: "",
        type: "info"
    });

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();

    const onSubmit = (e: any) => {
        setIsLoading(true)
        console.log("State data: ", identity)
        ChangeDetectionManager.check(identity, {
            CommunityEmail: e.email,
            CommunityPhone: e.telephone
        }).then(res => {

            console.log("Change Data: ", res);

            if (res.isChange) {
                updateWorkspaceById(workspace.workspaceId || "", res.change).then((updateRes: any) => {

                    console.log("Data from response update: >>>> ", updateRes);

                    updateWorkspace(updateRes?.Attributes)


                    /**
                     * Update the identity here to prevent continous on save of the same data
                     */
                    const newData: any = {}
                    Object.keys(updateRes?.Attributes).forEach(key => {
                        if (key === "CommunityEmail" || key === "CommunityPhone") {
                            newData[key] = updateRes?.Attributes[key]
                        }
                    })
                    setIdentity({ ...identity, ...newData });

                    setIsLoading(false)

                    setMessage({
                        open: true,
                        message: "data saved .... ",
                        type: "success"
                    })

                })
            } else {
                setMessage({
                    open: true,
                    message: "No change detected, nothing to save!",
                    type: "info"
                })

                setIsLoading(false)
            }
        })


    }

    useEffect(() => {
        // let id = workspace?.workspaceId || ""
        // queryWorkspaceById(id, ["CommunityPhone", "CommunityEmail"]).then((res: any) => {
        // })
        setIdentity({
            CommunityEmail: workspace.email,
            CommunityPhone: workspace.phone
        })
        reset({
            telephone: workspace?.phone || "",
            email: workspace?.email || ""
        })
    }, [workspace])


    return (
        <Box sx={{ pr: 3, height: "calc(100vh - 90px)", width: "350px" }}>

            <form onSubmit={handleSubmit(onSubmit)}>
                <p>

                </p>

                <Box sx={{ mb: 3 }}>

                    <InputLabel>Email</InputLabel>
                    <Controller
                        name={"email"}
                        control={control}
                        defaultValue={identity?.CommunityEmail || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("email")}
                                onChange={onChange}
                                value={value}
                                size="small"
                                placeholder="Email address"
                                type={'email'}
                                inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                sx={{ width: "97%" }} />
                        )}
                    />
                </Box>

                <Box sx={{ mb: 3 }}>

                    <InputLabel>Telephone</InputLabel>
                    <Controller
                        name={"telephone"}
                        control={control}
                        defaultValue={identity?.CommunityPhone || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("telephone")}
                                onChange={onChange}
                                value={value}
                                size="small"
                                placeholder="Telephone number"
                                inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                sx={{ width: "97%" }} />
                        )}
                    />
                </Box>





                <Box>
                    <ButtonController onCancel={() => { onClose?.(null) }} isLoading={isLoading} message={message} setMessage={setMessage} />
                </Box>

            </form>
        </Box>
    )
}