import { Box, styled } from "@mui/material";
import PageWrapper from "components/PageWrapper";
import Row from "components/Row";
import StraclouLogo from "components/StraclouLogo";
import { NavigationLink } from "Pages/Onboarding/Widgets/NavigationLink";
import { Outlet, useNavigate } from "react-router-dom";


const Wrapper = styled(Box)({
    width: "70vw",
    height: "90vh",
    padding:"24px"
})

export default function PrivacyMainActivity(){
    const navigate = useNavigate()
    return (
        <PageWrapper>
            <Wrapper>
                <Row>
                    <StraclouLogo />
                    {/* User Info Control */}
                    {/* <UserInfo /> */}
                </Row>
                <Row sx={{
                    padding: "24px 0 0 24px",
                    width: "350px",
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap:"0 10px",
                    
                }}>
                    <NavigationLink fontSize={"12pt"} onClick={()=>navigate("/policy/user_term_of_use")}>User Term of Service</NavigationLink>
                    <NavigationLink fontSize={"12pt"}onClick={()=>navigate("/policy/privacy_policy")} >Privacy Policy</NavigationLink>
                    </Row>
                <Row sx={{overflow: "auto"}}>
                    <Outlet />
                </Row>

            </Wrapper>
        </PageWrapper>
    )
}