

interface ChangeDetectionManagerCheckResponse {
    isChange: boolean
    change: any
}

export module ChangeDetectionManager {
    export const check = async (source: any, comparer: any): Promise<ChangeDetectionManagerCheckResponse> => {
        return new Promise((resolve, reject) => {
            let comparerNodes = Object.keys(comparer)
            let change: any = {}
            let isChange: boolean = false

            comparerNodes.forEach(node => {
                if (Object.prototype.hasOwnProperty.call(source, node)) {
                    if (source[node] !== comparer[node]) {
                        isChange = true;
                        change[node] = comparer[node]
                    }
                } else {
                    isChange = true;
                    change[node] = comparer[node]
                }
            })

            resolve({
                isChange,
                change
            })
        })
    }


    export function merge<T>(source: T[] | undefined, value: T) {
        if(source !== undefined){
            source.push(value)
        }

        return source
    }


    export function unMerge<T>(source: T[] | undefined, value: T) {
        if(source !== undefined){
            let index = source.findIndex((v)=>v===value)
            source.splice(index, 1)
        }

        return source
    }

}