// import StraclouLogo from "../../components/StraclouLogo"
import { Box, styled } from "@mui/material"
import { grey } from "@mui/material/colors"
// import MenuIconComponent from "Pages/HomePage/MenuIconComponent"
import { Outlet, useNavigate } from "react-router-dom"
import useCommunity from "hooks/useCommunity"
import ClouMainHeader from "components/headers/ClouMainHeader/ClouMainHeader"
import ResidentListing from "./ResidentListing"
import { ReactNode, useEffect, useState } from "react"
import useAuth from "hooks/useAuth"


const Page = styled(Box)({
    width: "100vw",
    height: "100vh",
    overflow: "hidden"
})

const PageContent = styled(Box)({
    width: "100vw",
    height: "calc(100vh - 50px)",
    marginTop: "50px",
    overflow: "auto"
})

const Header = styled(Box)({
    // background: red[200],
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    borderBottom: `1px solid ${grey[200]}`,
    background: "#fff"
})

const RMSLandingPage = () => {
    const { activeCommunity } = useCommunity();
    const { user } = useAuth()
    const navigate = useNavigate()

    const [view, setView] = useState<ReactNode | null>(null)

    const canvas = <ResidentListing />
    useEffect(() => {
        // console.log("user object at rms landing>>>>", user)
        if (user === undefined) {
            navigate('/')
        } else {
            setView(canvas)
        }
    }, [])


    return (
        <Page>
            <ClouMainHeader />
            {/* <Header>
                <Box sx={{ height: "50px", width: "250px", padding: "0 0 0 16px", margin: "0 0 0 -16px", borderBottom: `0px solid ${grey[300]}` }}>
                    <Box sx={{ padding: " 0 0 0 8px", display: "flex" }}>
                        <StraclouLogo variant="small" />
                    </Box>
                </Box>

                <Box sx={{zIndex: -999999, position: "absolute", background: "0", width: "100%", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {activeCommunity?.name}
                </Box>

                <Box sx={{ background: "0", position: "fixed", right: "16px", height: "50px" }}>
                    <MenuIconComponent />
                </Box>

            </Header> */}
            <PageContent>
                {view}
            </PageContent>
        </Page>

    )
}

export default RMSLandingPage

/**
 *  
 */