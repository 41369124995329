
import { Box, Divider } from "@mui/material";

import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ChangeDetectionManager } from "Straclou";

import { Identity } from "./interface";
import { CommunityPreferenceDialogStructure } from "./SettingsData";

import ApartmentIcon from '@mui/icons-material/Apartment';
import VillaIcon from '@mui/icons-material/Villa';
import CottageIcon from '@mui/icons-material/Cottage';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import CategoryButton from "../../../components/Button/CategoryButton";
import { SubTitle, Title } from "components/Title";
import { grey } from "@mui/material/colors";
import { IWidgetBuilderNavigate, IWidgetBuilderRoute, WidgetBuilder, WidgetBuilderOutlet } from "@knymbus/react-widget-builder";
import StructureOutlet from "./component/StructureOutlet";
import { useTranslation } from "react-i18next";
import CategoryButtonItemCount from "./component/CategoryButtonItemCount";
import { v4 } from "uuid";
import StructureOutletCategoryDropDown from "./component/StructureOutletCategoryDropDown";



export default function CommunityStructure() {
    const { workspace } = useWorkspaceStore()
    const { updateWorkspaceById } = useWorkspace()
    const [identity, setIdentity] = useState<Identity | null>(null)
    const params = useParams()
    const [onNavigate, setOnNavigate] = useState<IWidgetBuilderNavigate | undefined>(undefined)

    const { t } = useTranslation()

    const onSubmit = (e: any) => {
        // console.log("form data name: ", e.target.name, " and data state", e.target.checked)
        let option: any[] = []
        if (e.target.checked) {
            ChangeDetectionManager.merge<string>(identity?.CommunityStructure, e.target.name)

        }

        if (!e.target.checked) {
            ChangeDetectionManager.unMerge<string>(identity?.CommunityStructure, e.target.name)
        }

        console.log("selection option: ", identity?.CommunityStructure)
        updateWorkspaceById(params.workspaceId || "", identity)
    }

    // Setup the widget builder to navigate to the ui of the item we wish to action

    const structureRoutes: IWidgetBuilderRoute[] = [
        {
            path: 'main',
            window: <StructureOutlet />,
            fragmentHeaderOptions: {
                header: t('CommunitySettings.structure.editor.header.title')
            }
        }
    ]

    const structureBuilder = new WidgetBuilder({
        name: "structure Outlet",
        routes: structureRoutes,
        type: 'fragment'
    })
    const handleClick = (type: CommunityPreferenceDialogStructure, e?: any) => {
        // console.log("Event on button Click: ", e)
        // console.log("Type: ", type)
        setOnNavigate({
            path: 'main',
            params: {
                cid: params.workspaceId,
                type,
                icon: e?.icon,
                check: e?.check
            }
        })
    }


    const CATEGORYBUTTONGROUP = [
        {
            key: v4(),
            icon: < ApartmentIcon htmlColor="#fff" />,
            value: CommunityPreferenceDialogStructure.apartment,
            subValue: < CategoryButtonItemCount type={{
                category: CommunityPreferenceDialogStructure.apartment,
                communityId: workspace.workspaceId
            }} />,
            check: workspace?.structure?.includes(CommunityPreferenceDialogStructure.apartment),
            onClick: (e: any) => { handleClick(CommunityPreferenceDialogStructure.apartment, e) }
        },
        {
            key: v4(),
            icon: <CottageIcon htmlColor="#fff" />,
            value: CommunityPreferenceDialogStructure.singleFamilyHome,
            subValue: <CategoryButtonItemCount type={{
                category: CommunityPreferenceDialogStructure.singleFamilyHome,
                communityId: workspace.workspaceId
            }} />,
            check: workspace?.structure?.includes(CommunityPreferenceDialogStructure.singleFamilyHome),
            onClick: (e: any) => { handleClick(CommunityPreferenceDialogStructure.singleFamilyHome, e) }
        },
        {
            key: v4(),
            icon: < HolidayVillageIcon htmlColor="#fff" />,
            value: CommunityPreferenceDialogStructure.condominiums,
            subValue: < CategoryButtonItemCount type={{
                category: CommunityPreferenceDialogStructure.condominiums,
                communityId: workspace.workspaceId
            }} />,
            check: workspace?.structure?.includes(CommunityPreferenceDialogStructure.condominiums),
            onClick: (e: any) => { handleClick(CommunityPreferenceDialogStructure.condominiums, e) }
        },
        {
            key: v4(),
            icon: <HomeWorkIcon htmlColor="#fff" />,
            value: CommunityPreferenceDialogStructure.townhouses,
            subValue: <CategoryButtonItemCount type={{
                category: CommunityPreferenceDialogStructure.townhouses,
                communityId: workspace.workspaceId
            }} />,
            check: workspace?.structure?.includes(CommunityPreferenceDialogStructure.townhouses),
            onClick: (e: any) => {
                handleClick(CommunityPreferenceDialogStructure.townhouses, e)
            }
        },
        {
            key: v4(),
            icon: <VillaIcon htmlColor="#fff" />,
            value: CommunityPreferenceDialogStructure.villas,
            subValue: <CategoryButtonItemCount type={{
                category: CommunityPreferenceDialogStructure.villas,
                communityId: workspace.workspaceId
            }} />,
            check: workspace?.structure?.includes(CommunityPreferenceDialogStructure.villas),
            onClick: (e: any) => { handleClick(CommunityPreferenceDialogStructure.villas, e) }
        }

    ]

    return (
        <Box sx={{ pl: 3, width: "700px", position: 'relative' }}>

            <Title variant="1">{t('CommunitySettings.structure.title')}</Title>
            <SubTitle sx={{ color: grey[600], mb: 1 }}>{t('CommunitySettings.structure.subtitle')}</SubTitle>

            <Box sx={{position: "absolute", right: 0, top: '10px'}}>
                {/* Adding selection option for the structure categotory */}
                <StructureOutletCategoryDropDown />
            </Box>

            <Divider />

            <Box sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "16px 16px",
                mt: 3

            }}>

                <WidgetBuilderOutlet builder={structureBuilder} onNavigate={onNavigate} />

                {/* Buttons  */}

                {
                    CATEGORYBUTTONGROUP.sort((e: any, b: any) => (b.check - e.check)).map((el: any) => {
                        return <CategoryButton {...el} />
                    })
                }

            </Box >
        </Box >
    )
}