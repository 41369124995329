
import ClouItemTitle from "components/Display/ClouItemTitle";
import Row from "components/Row";
import { styled, Box, Divider } from "@mui/material";
import { grey} from "@mui/material/colors";

import React, { useContext } from "react";
import { ActivePaymentContext } from "./Context/PaymentProvider";
import {useIRoutesParams} from "React-Router-Internal";



const Wrapper = styled(Box, { name: "wrapper-property" })(({
    
}))

const LineItem = styled(Box, { name: "Clou-LineItem" })(({
    height: "30px",
    fontSize: ".9em",
    color: grey[600],
    padding: "5px 0",
    width: "88%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
}))

const AmountEntryCard: React.FC = () => {
    
    const {total} = useContext(ActivePaymentContext)

    const {routeParams} = useIRoutesParams()
    // console.log("Params sent from welcome to Pay Method: ",routeParams)
    
    return (
        <Wrapper>
            <Row sx={{ padding: "15px 30px" }} height={'auto'}>
                <ClouItemTitle sx={{ fontSize: "1em", color: "#000" }}>Record your payment here</ClouItemTitle>
                {routeParams?.name}                

                {/* <Row height={20}/> */}

                {/* <ClouItemTitle sx={{ fontSize: "1em", color: "#000" }}>Total Recieved</ClouItemTitle> */}
                {/* <Row center>You have entered</Row> */}

                 {/* <Row right sx={{fontSize: "1em", width: "88%" }}>
                    Total Payment <ClouMoneyExhibit value={total} />
                </Row> */}
                <Box sx={{ background: '0', width: "88%", padding: "5px 0 0 0" }}>
                    <Divider />
                </Box>
                

            </Row>

        </Wrapper>
    );
}

export default AmountEntryCard