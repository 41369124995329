import React, { useEffect, useState } from 'react'
import { ThemeColor } from '../Table/GlobalTableProps'
import { ClouFromContext } from './ClouFormContext'

interface IProps{
    children: React.ReactNode
    color?: ThemeColor
}
const ClouFormWrapper: React.FC<IProps> = ({children, color})=>{

    const [activeColor, setActiveColor] = useState<ThemeColor>("primary")

    useEffect(()=>{
        if(color) setActiveColor(color)
    },[color])

    return (
        <ClouFromContext.Provider value={{activeColor}}>
            {children}
        </ClouFromContext.Provider>
    )
}

export default ClouFormWrapper