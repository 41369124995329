import DateTimeExhibit from "components/DateTimeExhibit"
import ClouMoneyExhibit from "components/ClouMoneyExhibit"
import Row from "components/Row"

import { IInvoice } from "Straclou/commons/IInvoice"

import { Box, styled,  TableCell, TableRow } from "@mui/material"
import { grey } from "@mui/material/colors"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import TemporaryDrawer from "./InvoiceDrawer"
import LinearProgressWithLabel from "./LinearProgressWithLabel"


const TableCellDetail = styled(Box)(({
    height: "auto",
    padding: '0 0 0 5px',
    fontSize: ".9em",
    color: grey[700]
}))

interface IProps {
    data: IInvoice
}

const TransactionInvoiceCard: React.FC<IProps> = ({ data }) => {
    const navigate = useNavigate()
    const [openDrawer, setDrawer] = useState(false)

    
    const handleClick = (e: any) => {
        setDrawer(true)
    }

    return (
        
        <TableRow hover sx={{cursor: "pointer"}} >
            {/* Date */}
            <TableCell>
                <TableCellDetail >
                    <DateTimeExhibit time={data.createdAt} />
                </TableCellDetail>
            </TableCell>

            {/* Tracking Id */}
            <TableCell>
                <TableCellDetail>
                    <Row>{data.trackingNumber}</Row>
                </TableCellDetail>
            </TableCell>



            {/* Description */}
            <TableCell>
                <TableCellDetail sx={{ height: "auto", padding: '0 0 0 5px' }}>
                    <Row>
                        {data.description}
                    </Row>
                </TableCellDetail>
            </TableCell>


            {/* Amount */}
            <TableCell>
                <TableCellDetail>
                    <Row>
                        <ClouMoneyExhibit value={data.amount} />
                    </Row>
                </TableCellDetail>
            </TableCell>

            {/* Percentage Paid */}
            <TableCell>
                <TableCellDetail sx={{ height: "auto", padding: '0 0 0 5px' }}>
                    <Row>
                        <LinearProgressWithLabel value={data.percentagePaid * 100} />
                        <TemporaryDrawer isOpen={openDrawer} setIsOpen={setDrawer} rowData={data}/>
                    </Row>
                </TableCellDetail>
            </TableCell>

        </TableRow>
    )
}

export default TransactionInvoiceCard