import React, { useEffect } from "react";
import { styled, experimental_sx as sx, Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import ClouDisplayItem from "components/Display/ClouDisplayItem";
import ClouItemTitle from "components/Display/ClouItemTitle";
import ClouItemInfo from "components/Display/ClouItemInfo";
import ClouSquareFootageExhibit from "components/ClouSquareFootageExhibit";
import ClouStatusConverterExhibit from "components/ClouStatusConverterExhibit";

import { IPremises } from "Straclou/commons/IPremises";
import Row from "components/Row";

// import ClouPropertyOccupancyStatusExhibit from "components/ClouPropertyOccupancyStatusExhibit";



const PropertyWrapper = styled(Box)(
    ({
        padding: "0 5px 0 25px"
    }),
);



interface IProps {
    premises?: IPremises | null
}

const PropertyDetail: React.FC<IProps> = ({ premises }) => {



    useEffect(() => {


    }, [])

    return (
        <PropertyWrapper>
            <Row flex >
                <Row>
                    <Typography sx={{ fontSize: ".8em", margin: "8px 0", color: "InactiveCaptionText" }}>Property Detail</Typography>
                </Row>

                <Row right>
                    <Box>...</Box>
                </Row>
            </Row>

            <ClouDisplayItem>
                <ClouItemTitle>Unit Number</ClouItemTitle>
                <ClouItemInfo>{premises?.unitNumber || ""}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Occupancy</ClouItemTitle>
                <ClouItemInfo><ClouStatusConverterExhibit value={premises?.occupancy || ""} /></ClouItemInfo>
            </ClouDisplayItem>

            <ClouDisplayItem>
                <ClouItemTitle>Property Type</ClouItemTitle>
                <ClouItemInfo>{premises?.type}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Unit Size</ClouItemTitle>
                <ClouItemInfo><ClouSquareFootageExhibit footage={premises?.entitlement ?? 0} /></ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Floor Plan</ClouItemTitle>
                <ClouItemInfo>{premises?.floorPlanName}</ClouItemInfo>
            </ClouDisplayItem>


            {/* <Typography sx={{ fontSize: ".8em", margin:"8px 0 0 0", color: "InactiveCaptionText" }}>Additional Detail</Typography>
            <Divider sx={{ margin: "8px 0 0 0" }} /> */}

            <ClouDisplayItem>
                <ClouItemTitle>Building Name</ClouItemTitle>
                <ClouItemInfo>{premises?.buildingName}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Floor Name</ClouItemTitle>
                <ClouItemInfo>{premises?.floorName}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Phase</ClouItemTitle>
                <ClouItemInfo>{premises?.phase}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Block</ClouItemTitle>
                <ClouItemInfo>{premises?.block}</ClouItemInfo>
            </ClouDisplayItem>

            <Typography sx={{ fontSize: ".8em", margin: "8px 0 0 0", color: "InactiveCaptionText" }}>Address Detail</Typography>
            <Divider sx={{ margin: "8px 0 0 0" }} />
            <ClouDisplayItem>
                <ClouItemTitle>Street</ClouItemTitle>
                <ClouItemInfo>{premises?.address?.street}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Town</ClouItemTitle>
                <ClouItemInfo>{premises?.address?.town}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>City</ClouItemTitle>
                <ClouItemInfo>{premises?.address?.city}</ClouItemInfo>
            </ClouDisplayItem>
            <ClouDisplayItem>
                <ClouItemTitle>Parish</ClouItemTitle>
                <ClouItemInfo>{premises?.address?.parish}</ClouItemInfo>
            </ClouDisplayItem>

        </PropertyWrapper>
    )
}

export default PropertyDetail