import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"

import { Alert, Box, Button, Snackbar, Stack } from "@mui/material"
import Row from "components/Row"
import { Title } from "components/Title"

import { useEffect, useState } from "react"


import EditOwnerFragment from "./EditAccount/EditOwnerFragment"
import SummaryCollection from "./SummaryCollection"
import SummaryItem from "./SummaryItem"
import useSummaryStore from "./SummaryStore"

import EditPropertyTypeFragment from "./EditAccount/EditPropertyTypeFragment"
import EditPropertyNumberFragment from "./EditAccount/EditPropertyNumberFragment"
import EditMaintenanceFeeFragment from "./EditAccount/EditMaintenanceFeeFragment"
import { useWidgetBuilderParams, useWidgetBuilderNavigation, IWidgetBuilderRoute, WidgetBuilder, WidgetBuilderOutlet } from "@knymbus/react-widget-builder"
import { AccountManager } from "Straclou/handler/AccountManager"
import { CreateAccountInput } from "Straclou/API"
import useAccountStore from "hooks/AccountStore"
import { NEWACCOUNTRESPONSESTUB } from "../NewAccountResultStub"
import useAccount from "hooks/useAccount"
import { UIContent, UIWrapper } from "../Helpers/UIWrapper"




const Wrapper = styled(Box)({

    fontSize: "16pt",
    flexDirection: "column",
    padding: "24px"
    // background: 'red'


})


export default function NewAccountSummaryFrame(props: any) {

    const [open, setOpen] = useState(false);

    const { onClose, onNext } = props
    const [fragmentNav, setFragmentNav] = useState<any>()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { addToCollection } = useAccountStore()

    // /**
    //  * Setup a var for the data that is being displayed in this view
    //  */
    // const [list, setList] = useState<any>([])

    const params = useWidgetBuilderParams()
    const { navigate } = useWidgetBuilderNavigation()

    const { list, updateList, newAccountInput, clearSummary } = useSummaryStore()

    /**
     * Summary Fragment views and handlers
     */

    const fragmentRoutes: IWidgetBuilderRoute[] = [
        {
            path: "owner",
            window: <EditOwnerFragment />
        },
        {

            path: "property_type",
            window: <EditPropertyTypeFragment />
        },
        {

            path: "property_number",
            window: <EditPropertyNumberFragment />
        },
        {

            path: "fee",
            window: <EditMaintenanceFeeFragment />
        }
    ]
    const SummaryWidgetBuilder = new WidgetBuilder({
        name: "summary fragments",
        routes: fragmentRoutes,
        type: "fragment"
    })

    const handleClose = () => {
        setOpen(false)
        props?.onClose?.()
    }

    useEffect(() => {
        // console.log(">>>>Params>>>>>", params)
        clearSummary()
        if (Object.prototype.hasOwnProperty.call(params, 'data')) {
            try {
                let p = [...params?.data] || []

                /**
                 * Add a unique id to each item in the array as this will help to 
                 * match the unique signature of the item. 
                 */
                console.log("p list of summary data: ", p)

                p.forEach(item => {
                    updateList(item)
                })
            } catch (error) {
                console.log(error)
            }


        }
        return () => {
            clearSummary()
        }
    }, [params])



    /**
     * Handlers
     */
    const handleEditClick = (e: any) => {
        setFragmentNav({
            path: e.path,
            params: e.params
        })
    }

    const createAccountHandler = () => {
        // let createAccountInput = {
        //     name: "Carol Jonesset",
        //     lotId: "1246",
        //     propertyType: "Villa",
        //     fee: 8500,
        //     cid:
        // }
        setIsLoading(true)


        newAccountInput((input: CreateAccountInput) => {
            // console.log("Input from the user: ", input)
            AccountManager.create(input).then(res => {
                console.log(res)
                if (res.error === false) {
                    addToCollection(res.data.account)
                    navigate({
                        path: "complete",
                        params: res
                    })
                }

                if (res.error === true) {
                    setOpen(true)
                }
                setIsLoading(false)
            }).catch(err => {
                console.log("err >>>> ", err)
                alert("Error receive")
                setIsLoading(false)

            })
        })

    }

    return (
        <UIWrapper>
            <UIContent>
                <WidgetBuilderOutlet builder={SummaryWidgetBuilder} onNavigate={fragmentNav} />
                <Title variant="1">Let's do a quick review before we create!</Title>

                <form style={{ width: '100%' }}>
                    <Box sx={{ mb: 3, mt: 3 }}>
                        <SummaryCollection sx={{ marginTop: "16px" }}>
                            {
                                list?.map((el: any) => {
                                    return <SummaryItem key={el.key} item={el} onEdit={handleEditClick} />
                                })
                            }

                        </SummaryCollection>
                    </Box>

                    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={open} autoHideDuration={26000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                            Duplicated Entry
                        </Alert>
                    </Snackbar>

                    <Row center height={"40px"} sx={{ margin: "50px 0 0 0", display: "flex" }}>

                        <Box sx={{ position: "fixed", right: '32px', bottom: '32px' }}>
                            <Stack direction="row" gap={2}>
                                <Button onClick={handleClose}>Cancel</Button>
                                <LoadingButton
                                    loading={isLoading}
                                    variant="contained"
                                    type="submit"
                                    sx={{ width: "120px" }}
                                    onClick={createAccountHandler}
                                >Create</LoadingButton>
                            </Stack>
                        </Box>
                    </Row>
                </form>


                {/* <p>Your account has been created successfully</p>
            <Button sx={{width: "160px"}} variant="contained" onClick={handleClose} color="primary">Ok</Button> */}
            </UIContent>
        </UIWrapper>
    )
}