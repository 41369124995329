import { Box, styled } from "@mui/material"
import React from "react"
import { useLocation } from "react-router-dom"
import AmountEntryCard from "./AmountEntryCard"
import { ActivePaymentContext } from "./Context/PaymentProvider"
import PaymentSummaryProvider from "./Context/PaymentSummaryProvider"
import InvoiceCard from "./InvoiceCard"
import PaymentMethodsCard from "./PaymentMethodCard"




const GridWrapperLayout = styled(Box, { name: "Clou-GridLayout", label: "grid-wrapper-layout" })(({
    padding: "0",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "5px 5px",
    margin: "5px 0 0 0"
}))

const GridLeft = styled(Box, { name: "Clou-GridLeftLayout" })(({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    gap: "5px 0",
    gridTemplateAreas: `
    "account"
    `
}))

const GridRight = styled(Box, { name: "Clou-GridRightLayout" })(({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: " 1fr",
    gap: "5px 0",
    gridTemplateAreas: `
    "summary"
    
    `
}))


interface ISpaceHolderProps {
    bgcolor?: string
    name?: string
}


const SpaceHolder = styled(Box)(({ bgcolor, name }: ISpaceHolderProps) => (({
    width: "100%",
    height: "100%",
    background: bgcolor ? bgcolor : "0",
    borderRadius: "0px",
    gridArea: name,
})))


interface StateType {
    name: string
}


const PaymentFormControl = () => {

    

    const { setAccountHolder } = React.useContext(ActivePaymentContext)



    React.useEffect(() => {
        setAccountHolder("Bobby Hill")
    }, [])


    return (
        <GridWrapperLayout>
            <GridLeft>
                <SpaceHolder bgcolor="white" name={"account"} >
                    <AmountEntryCard />
                    <PaymentSummaryProvider>
                        <PaymentMethodsCard />
                    </PaymentSummaryProvider>
                </SpaceHolder>
            </GridLeft>

            <GridRight>
                <SpaceHolder bgcolor="white" name={"summary"}>
                    <InvoiceCard />
                </SpaceHolder>
            </GridRight>
        </GridWrapperLayout>
    )
}

export default PaymentFormControl