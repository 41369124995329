import {v4 as uuidV4} from "uuid"

export const notifications = [
    {
        key: uuidV4(),
        id: "435609234835392430",
        type: "promotion",
        body: "Trail period ends in 6 days, upgrade today and keep your data. ",
        image: null,
        createdAt: new Date().toISOString(),
        unread: true
    },
    {
        key: uuidV4(),
        id: "435609234835392430",
        type: "promotion",
        body: "Check out our lastest feature ",
        image: null,
        createdAt: new Date().toISOString(),
        unread: true
    }
]