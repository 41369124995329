import ClouItemTitle from "components/Display/ClouItemTitle";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import Row from "components/Row";
import { theme } from "globals/theme";
import { styled, Box, Divider, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router";
import React, { useContext, useEffect, useState } from "react";


import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import ClouActiveUserExhibit from "components/ClouActiveUserExhibit";
import AddressDirectory from "globals/AddressDirectory";


import { Invoice } from "Straclou/interfaces/Invoice";

import { ActivePaymentContext } from "./Context/PaymentProvider";
import { LoadingButton } from "@mui/lab";
import { PaymentSummaryContext } from "./Context/PaymentSummaryProvider";

import MessageDialog from "./MessageDialog";
import { MessageDialogContext } from "./Context/MessageDialogContext";
import { PaymentConnectionHandler } from "Straclou/handler/PaymentConnectionManager";
import { useActiveAccount } from "./Context/AccountNavigationContext";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Wrapper = styled(Box, { name: "wrapper-property" })(({
    overflow: "hidden",
    width: "100%",
    background: "blue"
}))

const LineItem = styled(Box, { name: "Clou-LineItem" })(({
    height: "30px",
    fontSize: ".9em",
    color: grey[600],
    padding: "5px 0",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
}))

const RightAlignment = styled(Box, { name: "wrapper-invoice-state" })(({
    textAlign: "right",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gap: "0 16px"
}))


interface StateType {
    proprietor: string
}

const SummaryCardDialog: React.FC = () => {

    const {setDrawer} = React.useContext(MessageDialogContext)

    const {account} = useActiveAccount()

    const location = useLocation()

    const { openSummary, setOpenSummary } = useContext(PaymentSummaryContext)
    const { setActivePayment, paymentMethod, invoices, total, property, financialId, accountHolder, description } = useContext(ActivePaymentContext)
    const [paidInvoice, setPaidInvoice] = useState<Invoice[]>([])
    const [advance, setAdvance] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [desc, setDesc] = useState<boolean>(false)
    const [messageOpener, setMessageOpener] = useState<boolean>(false)
    const [state, setState] = useState<StateType>({proprietor: ""})

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    useEffect(()=>{
        setState(location.state as StateType)
    },[location.state])

    useEffect(() => {
        setPaidInvoice([...invoices.filter((el: any) => el.currentPayment !== undefined)])
        setSubTotal(invoices.reduce((total: number, inv: any) => { return total + (inv.currentPayment ?? 0) }, 0))

    }, [invoices])

    useEffect(() => {
        setAdvance(total - subTotal)
    }, [subTotal])

    useEffect(() => {
        if (description.length > 0) {
            setDesc(true)
        } else {
            setDesc(false)
        }
    }, [description])

    const handleClose = () => {
        setIsLoading(false)
        setOpenSummary(false);
    }

    const onMessageClose = (data: any)=>{
        console.log("Data from Dialog box. ",data)
        handleClose()
        
        if(data === "cd"){
            // Here we close the payment Drawer if the Payment was successful
            setDrawer(false)
        }
    }
    const handleProcess = () => {
        setIsLoading(true)
        let submittion = {
            accountNumber: account.accountNumber,
            accountName: state.proprietor,
            paymentMethods: paymentMethod,
            description: description,
            amount: total,
            date: new Date().toISOString(),
            HOAAgent: "Ovel Heslop"

        }

        console.log("Payload for payment option: ", submittion)

        PaymentConnectionHandler.makePayment(submittion).then(res=>{
            setMessageOpener(true)
        }).catch(err=>{
            setMessageOpener(true)
        })


        // AccountConnectionManager.submitPayment(submittion).then((res: any) => {
        //     console.log(res)
        //     if (res) {
        //         setActivePayment(false)
        //         setIsLoading(false)
        //         // navigate(AddressDirectory.rms.transactionManager.receiptLog, { state: res })
        //         alert("Your request has been successful")
        //         handleClose()
        //     } else {
        //         alert(`Unable to process payment at this time.`)
        //         setIsLoading(false)
        //     }

        // })
        // setOpenSummary(false);
    }

    return (
        <Wrapper>

            <MessageDialog onOpen={messageOpener} onClose={onMessageClose}/>
            
            <Dialog
                open={openSummary}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={"xl"}
                sx={{ overflow: "hidden" }}
            >

                <DialogTitle>{`Payment Summary `}</DialogTitle>
                <DialogContent sx={{ minWidth: "650px", background: "0", overflow: "hidden" }}>
                    <Table sx={{ width: "100%" }}>
                        <tbody>
                            <tr style={{ background: "0" }}>
                                <td colSpan={3}>
                                    <Row>
                                        <Box sx={{ fontSize: ".8em", color: grey[600] }}>
                                            {state?.proprietor}
                                        </Box>
                                        <Box sx={{ fontSize: ".8em", color: grey[600] }}>
                                            A/c {account.accountNumber}
                                        </Box>
                                    </Row>
                                </td>
                                <td colSpan={2}>
                                    <Box sx={{ fontSize: ".8em", color: grey[600], display: "flex", justifyContent: "flex-end" }}>
                                        <span style={{marginRight: "5px"}}>Officer:</span>  <ClouActiveUserExhibit />
                                    </Box>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={5} >
                                    <Box sx={{ background: '0', width: "100%", padding: "5px 0 0 0" }}>
                                        <Divider />
                                    </Box>
                                </td>
                            </tr>

                            <tr style={{ height: "30px" }}>
                                <td colSpan={2} style={{}}>
                                    <ClouItemTitle sx={{ fontSize: ".85em", color: "#000" }}>Invoice</ClouItemTitle>
                                </td>
                                <td style={{ textAlign: "right", background: "0", width: "100px" }}>
                                    <ClouItemTitle sx={{ fontSize: ".85em", color: "#000", display: "flex", justifyContent: "flex-end" }}>Outstanding</ClouItemTitle>
                                </td>
                                <td style={{ textAlign: "right", width: "100px" }}>
                                    <ClouItemTitle sx={{ fontSize: ".85em", color: "#000", display: "flex", justifyContent: "flex-end" }}>Payment</ClouItemTitle>
                                </td>
                                <td style={{ textAlign: "right", width: "100px" }}>
                                    <ClouItemTitle sx={{ fontSize: ".85em", color: "#000", display: "flex", justifyContent: "flex-end" }}>Balance</ClouItemTitle>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={5}>
                                    <Box sx={{ background: '0', width: "100%", padding: "5px 0 0 0" }}>
                                        <Divider />
                                    </Box>
                                    <Row height={15} />
                                </td>
                            </tr>


                            {
                                paidInvoice.map(el => {
                                    return (
                                        <tr key={el.id} style={{ fontSize: ".8em", color: grey[600] }}>
                                            <td colSpan={2} >
                                                <Box sx={{ height: '25px' }}>{el.month}</Box>
                                            </td>
                                            <td style={{ textAlign: "right", width: "100px" }}><ClouMoneyExhibit value={el.currentOutstanding} /></td>
                                            <td style={{ textAlign: "right", width: "100px" }}><ClouMoneyExhibit value={el.currentPayment} /></td>
                                            <td style={{ textAlign: "right", width: "100px" }}><ClouMoneyExhibit value={el.currentOutstandingBalance} /></td>
                                        </tr>
                                    )
                                })
                            }

                            <tr>
                                <td colSpan={5}>
                                    <Box sx={{ background: '0', width: "100%", padding: "5px 0 0 0" }}>
                                        <Divider />
                                    </Box>
                                    <Row height={15} />
                                </td>
                            </tr>


                            <tr>
                                <td colSpan={2}><Box sx={{ fontSize: ".9em" }}>Sub total</Box></td>
                                <td ></td>
                                <td style={{ color: theme.palette.secondary.main, fontSize: ".9em", textAlign: "right" }}><ClouMoneyExhibit value={subTotal} /></td>
                                <td></td>
                            </tr>

                            {/* <LineItem sx={{}}>
                            Advance Payment
                            <Row right sx={{ color: theme.palette.secondary.main, fontSize: "1.1em" }}>
                                <MoneyExhibit value={advance} />
                            </Row>
                        </LineItem> */}


                            <tr>
                                <td colSpan={5}>
                                    <Box sx={{ background: '0', width: "100%", padding: "5px 0 0 0" }}>
                                        <Divider />
                                    </Box>
                                    <Row height={15} />
                                </td>
                            </tr>

                            <tr>
                                <td colSpan={5}>
                                    <Row height={24}>
                                        <ClouItemTitle sx={{ fontSize: ".9em", fontWeight: 600, color: "#000" }}>Payment Received</ClouItemTitle>
                                    </Row>

                                </td>
                            </tr>





                            {/* <Row height={15} />
                        <Row sx={{ fontSize: '0.8em', color: grey[500] }}>
                            Your payment was made with the folowing method(s)
                        </Row>*/}

                            {
                                paymentMethod.map((el: any) => {
                                    return (

                                        <tr key={el.option} style={{ height: "25px" }}>
                                            <td colSpan={3} style={{ fontSize: "0.8em", color: grey[600], textTransform: "capitalize" }}>{el.option}</td>
                                            <td colSpan={2} style={{ color: theme.palette.secondary.main, fontSize: ".9em", textAlign: "right" }}>
                                                <ClouMoneyExhibit value={el.amount} />
                                            </td>
                                        </tr>

                                    )
                                })
                            }

                            <tr>
                                <td colSpan={5}>
                                    <Box sx={{ background: '0', width: "100%", padding: "5px 0 0 0" }}>
                                        <Divider />
                                    </Box>
                                    <Row height={15} />
                                </td>
                            </tr>
                            <tr style={{ height: '40px' }}>
                                <td colSpan={4}>
                                    <Box sx={{ fontWeight: 600, fontSize: ".9em", color: grey[700] }}>
                                        Total Paid
                                    </Box>
                                </td>

                                <td colSpan={2} style={{ color: theme.palette.secondary.main, fontSize: ".9em", textAlign: "right" }}>
                                    <ClouMoneyExhibit value={total} />
                                </td>
                            </tr>

                            {
                                desc ? (
                                    <tr style={{ fontSize: ".9em", height: "30px", backgroundColor: grey[200] }}>
                                        <td colSpan={5} style={{ fontWeight: 600, paddingLeft: '8px' }}>Description</td>
                                    </tr>
                                ) : <tr></tr>
                            }

                            {
                                desc ? (
                                    <tr style={{ fontSize: ".8em", height: "30px", backgroundColor: grey[200] }}>
                                        <td colSpan={5} style={{ paddingLeft: '8px' }}>{description}</td>
                                    </tr>
                                ) : <tr></tr>
                            }


                            {
                                (total - subTotal) > 0 ? <tr style={{ fontSize: ".8em" }}>
                                    <td colSpan={3} >
                                        <Row height={26} />

                                        You have funds remaining that will be applied to the next invoice</td>
                                    <td colSpan={2} style={{ textAlign: "right" }}> <ClouMoneyExhibit value={(total - subTotal)} /></td>
                                </tr> : <tr></tr>
                            }


                        </tbody>
                    </Table>
                </DialogContent>
                <DialogActions sx={{ height: "60px" }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton onClick={handleProcess} variant={"contained"} loading={isLoading}>Process</LoadingButton>
                </DialogActions>

            </Dialog>


        </Wrapper>
    );
}

export default SummaryCardDialog
