import React from "react"
import creditCardAnimate from 'assets/svg/credit-card-animate.svg'

import ClouItemInfo from "components/Display/ClouItemInfo"
import AppButton from "components/widget/AppButton"
import Row from "components/Row"
import { styled, Box } from "@mui/material"
import { grey } from "@mui/material/colors"
import AddressDirectory from "globals/AddressDirectory"
import { useNavigate } from "react-router-dom"
import { useINavigate, useIRoutesParams } from "React-Router-Internal"
import MainPaymentActivity from "Features/PaymentServices/MainPaymentActivity"

const Wrapper = styled(Box)(({
    background: grey[100],
    height: "calc(100vh - 140px)"
}))

interface IProps {
    accountId?: string
    accountHolder?: string
}
const ClouBlankExperience: React.FC<IProps> = ({ accountId, accountHolder }) => {
    const navigation = useNavigate();

    const iNavigate = useINavigate()
    const { iPath } = useIRoutesParams()

    const handleClick = () => {
        // navigation(AddressDirectory.rms.transactionManager.payment + accountId, { state: { name: accountHolder } })
        console.log("Args from params: ", iPath)
        iNavigate(['payment', { accountId }])

    }
    return (
        <Wrapper>
            <Row height={25} />
            <ClouItemInfo>
                <Box sx={{ color: grey[800], fontSize: "1.7em", width: "100%", display: "flex", justifyContent: "center" }}>
                    Payment History Zero
                </Box>
            </ClouItemInfo>
            <ClouItemInfo>
                <Box sx={{ color: grey[600], fontSize: ".9em", width: "100%", display: "flex", justifyContent: "center" }}>
                    Payments made to this account will appear here
                </Box>
            </ClouItemInfo>
            <Row height={15} />
            <Row sx={{ width: "50%", height: "450px", background: "0", margin: "-90px auto -66px auto", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={creditCardAnimate} style={{ width: "400px", height: "400px" }} />
            </Row>
            <Row height={25} />
            <ClouItemInfo>
                <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <MainPaymentActivity variant="contained" value="Record A Payment"/>
                </Box>
            </ClouItemInfo>

        </Wrapper>
    )
}

export default ClouBlankExperience