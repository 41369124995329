export class IPasswordError {
    private _typeOf: string = ""

    constructor(error: Error) {
        this._typeOf = error.name
    }

    get typeOf(){
        return this._typeOf
    }

    public getError() {
        return this.list()
    }

    private list() {
        switch (this._typeOf) {
            case "NotAuthorizedException":
                return { message: "Invalid Password" }
            case "UserNotConfirmedException":
                return { message: "please verify user email address" }
        }

    }
}