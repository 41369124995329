import { styled, Box, Stack, Button, Checkbox } from "@mui/material"
import logo from "../../../logo.svg"
import { useNavigate } from "react-router-dom"
import FloatingText from "components/FloatingText"
import WorkSpaceList from "../WorkSpaceList"
import { grey } from "@mui/material/colors"
import { useEffect, useState } from "react"
import FooterActivity from "../FooterActivity"
import { NavigationLink } from "../../Widgets/NavigationLink"
import { FooterPlaceholder } from "Pages/Authenication/Widget"
import { useTranslation } from "react-i18next"
import PolicyCancelDialog from "./PolicyCancelDialog"
import LandingPage from "../LandingPage"
import LandingPageWrapper from "../LandingPageWrapper"
import useAuth from "hooks/useAuth"



const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(({
    width: "100vw",
    height: "100%",
    margin: "40px 0 0 0"
    // overflowY: "auto"
    // ...CenterItem
}))

const Content = styled(Box)(({
    minWidth: "60%",
    ...CenterItem,
    flexDirection: "column"
}))

const Logo = styled(Box)(({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px"
}))

const Instruction = styled(Box)(({
    ...CenterItem,
    fontSize: "1.4em",
    fontWeight: 900
}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))





const InfoBox = styled(Box)(({ theme }) => ({
    textAlign: "center",
    fontSize: "10pt",
    fontWeight: 400,
    // position: "absolute",
    // bottom: "10px",
    display: "flex",
    justifyContent: 'space-evenly',
    border: `1px solid ${grey[200]}`,
    height: "90px",
    padding: "16px",
    margin: "30px 0"
}))

const policyList = [
    {
        type: "customer_agreement",
        value: "Customer Terms of Service",
        version: 1,
        link: ""
    },
    {
        type: "user_agreement",
        value: "User Terms of Service",
        version: 1,
        link: ""
    },
    {
        type: "privacy_agreement",
        value: "Privacy Policy",
        version: 1,
        link: ""
    }
]


const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


export default function PolicyActivity() {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const {user} = useAuth()

    const goto = (link: string) => {
        navigate(link)
    }

    useEffect(()=>{
        let isLoaded = false
        if(user.isPolicyChecked){
            isLoaded = true
        }
        return ()=>{
            console.log("User is loaded: ",isLoaded)
            console.log("User is status: ",user.isPolicyChecked)
        }
    },[user])

    return (
        <LandingPageWrapper>
            <Wrapper>
                <Content>
                    <Stack gap={1}>
                        <Instruction>{t("PolicyActivity.title")}</Instruction>
                        <Box sx={{
                            ...CenterItem, fontSize: "12pt",
                            textAlign: "center", width: "60%", margin: "0 auto", lineHeight: "24px"
                        }}>
                            {t("PolicyActivity.subtitle")}
                        </Box>


                        <Box sx={{ height: "auto", marginTop: "20px", fontSize: "14pt" }}>
                            <Box>
                                <p style={{ lineHeight: "26px", fontWeight: "bold" }}>{t("PolicyActivity.body.p1")}</p>
                                <Box>
                                    <NavigationLink fontSize="12.5pt">Please read User Terms of Services</NavigationLink>
                                </Box>
                                <Checkbox {...label} size="small" /> {t("PolicyActivity.checkbox.c1")}
                            </Box>

                            <Box sx={{ height: "auto", marginTop: "40px" }}>
                                <p style={{ lineHeight: "26px", fontWeight: "bold" }}>{t("PolicyActivity.body.p2")}</p>

                                <Box>
                                    <NavigationLink fontSize="12.5pt">Please read Privacy Policy</NavigationLink>
                                </Box>

                                <Box sx={{ height: "auto", display: "flex", flexDirection: "column" }}>
                                    <Box>
                                        <Checkbox {...label} size="small" />{t("PolicyActivity.checkbox.c2")}
                                    </Box>
                                    <Box sx={{ marginTop: "30px", display: "flex", alignItems: "center" }}>
                                        <PolicyCancelDialog />{t("PolicyActivity.checkbox.c3")}
                                    </Box>
                                </Box>

                            </Box>


                            <Box sx={{ height: "auto", marginTop: "40px", fontWeight: "bold", fontStyle: "italic" }}>
                                <p style={{ lineHeight: "26px" }}> {t("PolicyActivity.body.p3")}</p>
                            </Box>


                        </Box>
                        <Box sx={{ height: "auto", ...CenterItem, flexDirection: "column", mb: 2 }}>
                            <Button variant="contained" onClick={() => { navigate("/landing?v=new_community") }}>{t("button.next")}</Button>
                        </Box>


                    </Stack>


                    <FooterActivity />

                </Content>
            </Wrapper>
        </LandingPageWrapper>
    )
}

