import { Box, CircularProgress, Stack } from "@mui/material";
import useAuth from "hooks/useAuth";
import FooterActivity from "./FooterActivity";

export default function LoadingActivity() {
    return (
        <Box sx={{ height: "50vh", display: 'flex', alignItems: "center", justifyContent: "center" }}>
            <Stack gap={2}>
                <Box sx={{display: "flex", justifyContent: "center"}}>
                <CircularProgress size={60} />
                </Box>
                <FooterActivity />
            </Stack>
        </Box>
    )
}