import { Box, ButtonBase } from "@mui/material";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import { styled, alpha } from "@mui/system";
import React, { useEffect, useState } from "react";


import { useClouFormContext } from "../Form/ClouFormContext";
import Row from "../Row";
import { ThemeColor } from "../Table/GlobalTableProps";

const ButtonShadeStyle = styled(ButtonBase)(({ theme }) => ({
    minWidth: "65px",
    height: "auto",
    margin: 0,
    padding: 0,
    background: alpha(theme.palette.primary.main, 1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}))
const ButtonDefaultStyle = styled(ButtonBase)(({ theme }) => ({
    minWidth: "65px",
    height: "auto",
    margin: 0,
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: grey[500],
    cursor: "pointer",
    borderRadius: "5px",
    
    
    "&:hover":{
        background: alpha(theme.palette.primary.main, .1),

    }

}))

export default function AppButton(props: any) {
    const { activeColor } = useClouFormContext();
    // console.log("Props Settings: ", props)

    const [bcolor, setBColor] = useState<ThemeColor>()
    useEffect(() => {
        if (props.color !== undefined) {
            setBColor(props.color)
            // console.log("Color Settings: ", props.color)
        } else {
            setBColor(activeColor)
            // console.log("activeColor Settings: ", activeColor)

        }

    }, [])

    if (props.variant === "shade")
        return (
            <ButtonShadeStyle>
                <Button variant="text"
                    {...props}
                    color={bcolor}
                    sx={{ textTransform: "capitalize" }}
                >{props.value || props.children}</Button>
            </ButtonShadeStyle>
        )

    if (props.variant === "default")
        return (
            <ButtonDefaultStyle {...props}>
                <Box sx={{marginTop: "4px"}}>{props?.starticon}</Box>

                <Box sx={{ textTransform: "capitalize", fontSize: ".9em", margin: "0 4px" }}>
                    {props.value || props.children}
                </Box>

            </ButtonDefaultStyle>
        )
    return (
        <Button
            {...props}
            color={bcolor}
            sx={{ textTransform: 'capitalize', width: "auto !important" }}
        >{props.value || props.children}</Button>
    )
}


interface FloatingButtonWrapperProps {
    next?: React.ReactNode
    cancel?: React.ReactNode
    skip?: React.ReactNode
    children?: React.ReactNode
    bottom?: number
}

const FloatingButtonWrapperStyle = styled("div", { name: "Cmui-FloatingButton" })((props: { bottom: number }) => (({
    position: "absolute",
    // background: "red",
    height: "60px",
    right: "32px",
    left: 0,
    bottom: props.bottom,
    display: "grid",
    gridTemplateColumns: '1.5fr 80px auto'
})))

const layoutStyle = {
    display: "flex",
    padding: "0 5px",
    justifyContent: "flex-start",
    alignItems: "center",
    // background: "blue"
}
/** 
 *@deprecate("This function is deprecated please use ClouFloatingButtonWrapper") 
*/
export const FloatingButtonWrapper = (props: FloatingButtonWrapperProps) => {
    const { children, next, skip, cancel, bottom } = props

    return <Row>
        <FloatingButtonWrapperStyle bottom={bottom || 0}>
            <Row className="left" sx={layoutStyle} height={60}>{skip}</Row>
            <Row sx={layoutStyle}>{cancel}</Row>
            <Row sx={layoutStyle}>{next}</Row>
            {children}

        </FloatingButtonWrapperStyle>
    </Row>
}
export const ClouFloatingButtonWrapper = (props: FloatingButtonWrapperProps) => {
    const { children, next, skip, cancel, bottom } = props

    return <Row>
        <FloatingButtonWrapperStyle bottom={bottom || 0}>
            <Row className="left" sx={layoutStyle} height={60}>{skip}</Row>
            <Row sx={layoutStyle}>{cancel}</Row>
            <Row sx={layoutStyle}>{next}</Row>

        </FloatingButtonWrapperStyle>
    </Row>
}