
import ClouBlankExperience from 'Features/TransactionServices/BalanceExperience/ClouBlankExperience';
import { IInvoice } from 'Straclou/commons/IInvoice';
import { styled, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Box } from '@mui/material';
import { grey } from '@mui/material/colors';

import React, { useEffect, useState } from 'react';
import TransactionPaymentCard from './TransactionPaymentCard';
import { AccountConnectionManager } from 'Straclou/handler/AccountConnectionManager';
import { TransactionManager } from 'Straclou/handler/TransactionManager';


const TableContainerDetail = styled(TableContainer)(({
    maxHeight: "calc(100vh - 144px)", "&::-webkit-scrollbar": {
        width: "4px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#ebebeb",
        webkitBorderRadius: "0px",
        borderRadius: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
        webkitBorderRadius: "0px",
        borderRadius: "0px",
        background: grey[400]
    }
}))

const TableHeaderDetail = styled(TableCell)(({
    textAlign: 'left',
    fontSize: ".8em",
    color: grey[700],
    fontWeight: 700
}))


interface IProps {
    accountNumber?: string
    accountName?: string
}
const TransactionPaymentTableList: React.FC<IProps> = ({ accountNumber, accountName }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [rows, setRows] = useState<IInvoice[]>([])

    useEffect(() => {
        setIsLoading(true)
        TransactionManager.listPayment({ accountNumber: accountNumber || "" }).then(res => {

            console.log("result from receipts: ",res)
            setRows(res)
            setIsLoading(false)
        })
    }, [accountNumber])



    return (
        <>
        
            {
                rows.length > 0 ?
                    <TableContainerDetail>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow sx={{ height: '35px', fontWeight: "700" }}>
                                    <TableHeaderDetail>Date</TableHeaderDetail>
                                    <TableHeaderDetail>Reference No.</TableHeaderDetail>
                                    <TableHeaderDetail>Description</TableHeaderDetail>
                                    <TableHeaderDetail>Amount</TableHeaderDetail>
                                    {/* <TableHeaderDetail>% Paid</TableHeaderDetail> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows?.map(el => {
                                        return (<TransactionPaymentCard key={el.id} data={el} />);
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainerDetail>

                    :

                    <ClouBlankExperience accountId={accountNumber} accountHolder={accountName} />
            }
        </>
    )
}

export default TransactionPaymentTableList