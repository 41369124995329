import { ReactNode, useEffect, useState } from "react";
import VerifiedExpiredDialog from "./VerifiedExpiredDialog";
import VerifiedInvalidDialog from "./VerifiedInvalidDialog";
import EmptyDialog from "./EmptyDialog";

type DialogShema = "expired" | "invalid" | "empty" | null

interface IProps {
    onOpen: {
        model: boolean;
        dialog: DialogShema
    };
    openStateChange: Function;
    onAnswer: Function
    code: string
}



export default function DialogController(props: IProps) {
    const [element, setElement] = useState<ReactNode | null>(null)

    const WindowDialog = [
        {
            name: "expired",
            component: <VerifiedExpiredDialog onOpen={props.onOpen.model} openStateChange={props.openStateChange} onAnswer={props.onAnswer} code={props.code} />
        },
        {
            name: "invalid",
            component: <VerifiedInvalidDialog onOpen={props.onOpen.model} openStateChange={props.openStateChange} onAnswer={props.onAnswer} code={props.code} />
        },
        {
            name: "empty",
            component: <EmptyDialog onOpen={props.onOpen.model} openStateChange={props.openStateChange} onAnswer={props.onAnswer} code={props.code} />
        }
    ]


    const handleClickOpen = () => {

        let elem = WindowDialog.filter(el => el.name === props.onOpen.dialog)

        if (elem.length > 0) {
            setElement(elem[0].component)
            props.openStateChange(true)
        }
    };

    const handleClose = () => {
        props.openStateChange(false)
        setElement(null)
    };

    useEffect(() => {
        if (props.onOpen) {
            handleClickOpen()
        } else {
            handleClose()
        }
    }, [props.onOpen])



    return (
        <div>
            {
                element
            }
        </div>
    );
}