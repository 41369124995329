import FooterActivity from "Pages/Onboarding/Landing/FooterActivity"
import { FooterPlaceholder } from "./Widget"
import FloatingText from "components/FloatingText"
import { Box, styled, Stack } from "@mui/material"
import { NavigationLink } from "Pages/Onboarding/Widgets/NavigationLink"
import { useNavigate } from "react-router-dom"
import logo from "../../logo.svg"
import { isMobile, isMobileOnly } from "react-device-detect";
import { Children, isValidElement, ReactNode, useEffect, useState } from "react"
import { SubTitle } from "components/Title"


const Logo = styled(Box)(({ theme }) => ({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px",
    [theme.breakpoints.down("md")]: {
        fontSize: ".6em",
        position: "absolute",
        left: "20px",
        top: 0
    }

}))

const Instruction = styled(Box)(({ theme }) => ({
    ...CenterItem,
    fontSize: "2.7em",
    fontWeight: 900,
    width: isMobile ? "90%" : "100%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
        fontSize: "1.7em",
        textAlign: "center",

    }
}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))


const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(({ theme }) => ({
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    overflowY: "auto",
    // ...CenterItem
    [theme.breakpoints.down("md")]: {
        // marginTop: "300px",
        flexDirection: "column"
    }
}))


const Content = styled(Box)(({ theme }) => ({
    minWidth: "60%",
    minHeight: "calc(100vh - 200px)",
    position: "relative",
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }


}))

const FormHolder = styled(Box)(({ theme }) => ({
    minWidth: "400px",
    margin: "30px auto",
    [theme.breakpoints.down("md")]: {
        minWidth: "90%",

    }
}))



interface IProps {
    children?: ReactNode
    title?: string | ReactNode
    subtitle?: string | ReactNode
}

export default function Enfold(props: IProps) {
    const { children, title, subtitle } = props
    const [controlElem, setControlElem] = useState<ReactNode | null>(null)
    const navigate = useNavigate()

    return (
        <Wrapper>
            <Box sx={{ position: "relative", height: "120px" }}>
                <FloatingText>
                    <Box sx={{ fontSize: "9pt" }}>New to Straclou?</Box>
                    <NavigationLink onClick={() => navigate('/signin-process/signup')}>Create an account</NavigationLink>
                </FloatingText>

                <Logo onClick={() => { navigate('/') }}>
                    <img src={logo} width={isMobile ? "15px" : "35px"} color={"#fff"} />
                    <LogoName>Straclou</LogoName>
                </Logo>

            </Box>

            <Content>
                <Stack gap={1}>
                    <Instruction>{title}</Instruction>
                    <Box sx={{margin: "0 auto"}}>
                        {
                            typeof subtitle === "string" ? (
                                <SubTitle variant="2"
                                    sx={{
                                        textAlign: "center",
                                        width: isMobile ? "90%" : "100%",
                                        margin: "0 auto"
                                    }}>
                                    {subtitle}
                                </SubTitle>
                            ) :
                                (<>{subtitle}</>)
                        }

                    </Box>



                    <FormHolder>
                        {
                            Children.map(children, (child, key) => {
                                return isValidElement(child) && child.props.enfoldItem === "form" ? (<>{child}</>) : null
                            })
                        }
                    </FormHolder>




                    <Box sx={{ height: "auto", ...CenterItem }}>
                        {
                            Children.map(children, (child, key) => {
                                return isValidElement(child) && child.props.enfoldItem !== "form" ? (<>{child}</>) : null
                            })
                        }
                    </Box>
                </Stack>

            </Content>
            <FooterPlaceholder>
                <FooterActivity />
            </FooterPlaceholder>
        </Wrapper>
    )
}