import { v4 } from "uuid"
import { UnitType } from "./IPropertyInterface"

interface Author {
    id: string
    name: string
}
export class IAccount {

    private _key: string = v4()

    //All IDs
    /**
     * The id here is no longer the account id this is now the community Id
     * both id + accountId is needed to get account
     */
    private _id: string

    /**
     * The secodary index for the account table
     */
    private _accountId: string
    /**
     * @deprecated this is no longer the case use id for this instead
     */
    private _communityId: string
    private _propertyId: string
    private _proprietorId: string

    // All Account native fields
    private _unitNumber: string
    private _fee: number
    private _dueDate: string
    private _billingDate: string
    private _balance: number
    private _prePayment: number
    private _daysOutstanding: number
    private _monthsOutstanding: number
    private _accountNumber: string
    private _accountName: string
    private _status: string
    private _dueAmount: number
    private _createdAt: string
    private _updatedAt: string
    private _author: Author

    // Property Fields
    private _unitType: UnitType


    constructor(args?: IAccountDataObject) {
        this.assign(args)

    }

    // getters

    get key() { return this._key }

    get id() { return this._id }

    get communityId() { return this._communityId }

    get propertyId() { return this._propertyId }

    get accountId() { return this._accountId }

    get proprietorId() { return this._proprietorId }

    get unitNumber() { return this._unitNumber }

    get fee() { return this._fee }

    get dueDate() { return this._dueDate }

    get billingDate() { return this._billingDate }

    get balance() { return this._balance }

    get prePayment() { return this._prePayment }

    get daysOutstanding() { return this._daysOutstanding }

    get monthsOutstanding() { return this._monthsOutstanding }

    get accountNumber() { return this._accountNumber }

    get accountName() { return this._accountName }

    get status() { return this._status }

    get dueAmount() { return this._dueAmount }

    get createdAt() { return this._createdAt }

    get updatedAt() { return this._updatedAt }

    get author() { return this._author }

    get unitType() {return this._unitType}



    // setters

    set accountName(name: string) {
        this._accountName = name
    }





    /**
    * 
    * @returns 
    */
    public toObject(): any {
        return {
            id: this._id,
            unitNumber: this._unitNumber,
            accountNumber: this._accountNumber,
            status: this._status,
            createdAt: this._createdAt,
            createdBy: this._author?.name,
            uid: this._author?.id,
            prePayment: this.prePayment,
            unitType: this._unitType,
            accountName: this._accountName
        }
    }

    public assign(args: any) {
        if (args !== undefined) {
            this._id = args?.id ?? ""
            this._communityId = args?.communityId
            this._key = args?.key ?? v4()
            this._propertyId = args?.propertyId
            this._accountId = args?.accountId
            this._proprietorId = args?.proprietorId
            this._unitNumber = args?.unitNumber
            this._fee = args?.fee
            this._dueDate = args?.dueDate || ""
            this._billingDate = args?.billingDate || ""
            this._balance = args?.balance
            this._prePayment = args?.prePayment
            this._daysOutstanding = args?.daysOutstanding
            this._monthsOutstanding = args?.months
            this._accountNumber = args?.accountNumber
            this._accountName = args?.accountName
            this._status = args?.status
            this._dueAmount = args?.dueAmount
            this._createdAt = args?.createdAt
            this._updatedAt = args?.updatedAt
            this._author = args?.author
            this._unitType = args?.unitType || "NONE"
        }
    }

}

type AccountStatus = "PENDING" | "DUE" | "OVERDUE" | "NORMAL" | "DELINQUENT"
interface IAccountDataObject {
    id: string;
    createdAt: string;
    updatedAt: string;
    status: AccountStatus;
    balance: number;
    months: number;
    prePayment: number;
    accountNumber: string;
    propertyId: string;
    communityId: string;
    accountName: string;
    fee: number;
    unitNumber: string;
    dueDate?: string;
    billingDate?: string;
    daysOutstanding: number;
    dueAmount: number;
    author: Author;
    proprietorId: string;



}