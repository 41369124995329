import { Box, styled, Button } from "@mui/material"
import { grey } from "@mui/material/colors"
import { theme } from "globals/theme"


const Wrapper = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
}))


interface ICardProps {
    isActive?: boolean
}
const Card = styled(Box)(({ isActive }: ICardProps) => ({
    width: "200px",
    border: `1px solid ${grey[400]}`,
    borderRadius: "25px",
    height: "100%",
    padding: "16px",
    background: isActive ? theme.palette.primary.main : 0,
    color: isActive ? "#fff" : "#000"
}))
const SubscriptionCard = () => {
    return (
        <Wrapper>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "flex-start", height: "100%" }}>
                <Card isActive>
                    <Box sx={{ textAlign: "center", textTransform: "uppercase", fontSize: "1.2em", margin: "10px 0 8px 0", color: theme.palette.secondary.main, fontWeight: 600 }}>Trial</Box>
                    <Box sx={{ textAlign: "center", fontSize: ".94em", margin: "10px 0 30px 0" }}>Free</Box>

                    <Box sx={{ textAlign: "center" }}><span style={{ fontSize: "1.9em", fontWeight: 600 }}>$0</span><span style={{ fontSize: "1em !important" }}>/14 days</span></Box>
                </Card>


            </Box>


            <Card>
                <Box sx={{ textAlign: "center", textTransform: "uppercase", fontSize: "1.2em", margin: "10px 0 8px 0", color: theme.palette.secondary.main, fontWeight: 600 }}>Basic</Box>
                <Box sx={{ textAlign: "center", fontSize: ".94em", margin: "10px 0 30px 0" }}>Paid</Box>

                <Box sx={{ textAlign: "center" }}><span style={{ fontSize: "1.9em", fontWeight: 600 }}>$35K</span><span style={{ fontSize: "1em !important" }}>/month</span></Box>
                <Box sx={{ textAlign: "center", margin:"30px 0 0 0" }}>
                    <Button variant="contained">Upgrade Now</Button>
                </Box>
            </Card>

        </Wrapper>

    )
}

export default SubscriptionCard