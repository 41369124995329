import { VisibilityOff, Visibility } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack, Button, styled, InputLabel, OutlinedInput, IconButton, InputAdornment } from "@mui/material"
import { grey } from "@mui/material/colors"
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from '@hookform/error-message';
import useAuth from "hooks/useAuth";
import userAccess from "hooks/useAccess";
import { IError, IPasswordError } from "Straclou";
import MessageDialogProvider, { MessageDialogContext } from "Features/PaymentServices/Context/MessageDialogContext";
import { IUser } from "Straclou/commons/IUser";



const Wrapper = styled(Box)(({

}))




export default function SignInPasswordForm(props: any) {



    // Cognito
    const { signIn, updateUser } = useAuth()

    const { expired } = userAccess()

    // get props
    const { email } = props

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const [someError, setSomeError] = useState<any>("")

    const { setDrawer } = useContext(MessageDialogContext)


    const { handleSubmit, reset, control, setError, register, formState: { errors } } = useForm();

    const [showPassword, setShowPassword] = useState(false)


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const onSubmit = (data: any) => {
        setIsLoading(true)
        setDrawer(true)

        // firebase signin api
        // signinWithEmailAndPassword(email, data.password).then(user=>{
        //     console.log("user at sign in: ",user)
        //     updateCurrentUser(user.toObject())
        //     setIsLoading(false)
        //     navigate("/getting_ready")
        // }).catch(error=>{
        //     console.log(error)
        //     setIsLoading(false)
        //     setError('password', { message: "Invalid Password" });
        // })


        // cognito sign in api
        signIn({
            ...data,
            email
        }).then((res: IUser) => {

            console.log("User is logged in: ", res)
            updateUser(res)
            setIsLoading(false)
            // navigate("/landing", { state: { email: email } })

            navigate("/getting_ready")

            /**
             * Here we need to run a script to determine what view we go to
             * that is should we go to the get-started/landing (new Community/ workspace/ team space)
             * or landing (list of communities / workspaces / tema spaces)
             */
            // if(!expired){
            //     if(res.isWorkspace){
            //         navigate("/landing", { state: { email: email } })                
            //     }else{
            //         navigate("/landing/create_workspaces", { state: { email: email } })

            //     }

            // }else{
            //     navigate("/subscription", { state: { email: email } })                
            // }


        }).catch(error => {
            let ierror = new IError(new IPasswordError(error))
            // setSomeError(ierror.error)
            if (ierror.typeOfError === "UserNotConfirmedException") {
                // navigate("/signin-process/verify_email", { state: { email: email } })  

            }
            setError('password', ierror.error);
            setIsLoading(false)
        })
    }


    const handleSignIn = () => {
        navigate("/")
    }

    return (
        <Wrapper>
            <Box sx={{ width: "100%", color: grey[900] }}>
                <Box >
                    {
                        email ? (

                            <form>


                                <Stack gap={2}>
                                    <InputLabel sx={{ marginBottom: "-16px", fontWeight: "bold" }}>
                                        Password
                                    </InputLabel>
                                    <Controller
                                        name={"password"}
                                        control={control}
                                        defaultValue={""}
                                        render={({ field: { onChange, value } }) => (
                                            <OutlinedInput
                                                type={showPassword ? 'text' : 'password'}
                                                {...register("password", {
                                                    required: "Password is required",
                                                    minLength: {
                                                        value: 8,
                                                        message: "Minimum length is 8"
                                                    }

                                                })}

                                                onChange={onChange} value={value} placeholder={"Password"}
                                                endAdornment={<InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end">
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                                }
                                            />
                                        )}
                                    />
                                    <Box sx={{ marginBottom: "10px", height: "20px", color: "red", mt: -1, display: "flex", alignItems: "flex-start" }}>
                                        <ErrorMessage
                                            errors={errors}
                                            name="password"
                                            render={({ message }: any) => <span>{message}</span>}
                                        />
                                    </Box>
                                    <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit(onSubmit)} sx={{ height: "45px", fontSize: "14pt" }}>Sign in</LoadingButton>
                                </Stack>
                            </form>) : (

                            <Box sx={{ textAlign: "center", height: "60px" }}>
                                {/* <SignupLink>Login with my email address</SignupLink> */}
                                <Button onClick={handleSignIn} variant="contained" sx={{ height: "45px", fontSize: "14pt" }}>Login with my email address</Button>
                            </Box>
                        )


                    }

                    {someError}


                </Box>
            </Box>
        </Wrapper>
    )
}

