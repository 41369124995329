import { IPremises } from "../commons/IPremises"
import { axiosExecute, AxiosOptions } from "../axios"
import { IClass } from "../IClass"
import { ConnectionStrings } from "../ConnectionStrings"


export module PropertyHandler {

    /**
     * Todo
     * Handle the retrevial of the community stored id based on current logge in user with a function.
     * However for now we will use a static id
     */
    

    /**
     * Handle the Updating of a property 
     * @param obj 
     */
    export const update = async (obj: any) => {

    }

    export const dashboardListing = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const communityId = "e004a3cf-7051-4cd3-8ec2-020dfd2e64a6"
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.property,
                    data: JSON.stringify({
                        data: {
                            cid: communityId
                        },
                        method: 'propertyFullDirectory',
                        operation: {
                            limit: 100,
                            startAt: "",
                            orderBy: { field: "unitNumber", order: "asc" }
                        }
                    })

                }
                const data = await axiosExecute(option) as Array<any>

                console.log("property list response: ", data)

                // TODO ReMove Stub from list
                const StubData: any = [
                    {
                        id: "83y3y3yuwefbiyfeigqe3biy",
                        proprietor: "John Cane Smith",
                        contact: {
                            phone: {
                                number: "876-943-3934",
                                type: "Work"
                            },
                            email: {
                                address: "John.smith@hotmail.com",
                                type: "Personal"
                            }
                        },
                        unitEntitlement: 1260,
                        lotEntitlement: 4500,
                        unitNumber: "947",
                        bedroomCount: 2.5,
                        type: "Apartment",
                    },
                    {
                        id: "83y3y3yuwefbiyfeigqe3biy7hiwhy2",
                        proprietor: "Alegra Bryon",
                        unitEntitlement: 1260,
                        lotEntitlement: 4500,
                        unitNumber: "948",
                        bedroomCount: 2.5,
                        type: "Apartment"
                    }
                ]


                // we need to assign to Premises
                let result = IClass.setList<IPremises>(data, (p) => new IPremises(p))
                resolve(result)
            } catch (error) {
                console.log("something went wrong: ", error)
                reject([])
            }
        })
    }



    /**
     * Get property
     */
    export const getProperty = async (id: string): Promise<IPremises> => {
        console.log("Get Property fn called. ", id)
        try {
            const communityId = "e004a3cf-7051-4cd3-8ec2-020dfd2e64a6"//(await CommunityHandler.getCurrentProfile()).id
            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.property,
                data: JSON.stringify({
                    input:{
                        cid: communityId,
                        pid: id
                    },
                    method: 'getProperty'
                })

            }

            console.log("request: ",option)
            const data = await axiosExecute(option) as any

            console.log("property list response: ", data)
            return new IPremises(data)

        } catch (error) {
            console.log("Something went wrong when call get property.", error)
            return new IPremises()
        }

    }
}


