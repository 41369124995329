import { styled, experimental_sx as sx, Box } from "@mui/material"
import React from "react"


const Wrapper = styled(Box)({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
})


const UserManagementPage: React.FC = () => {
    return (
        <Wrapper>Straclou User Management</Wrapper>
    )
}

export default UserManagementPage