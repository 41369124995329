import DateTimeExhibit from "components/DateTimeExhibit"
import ClouItemInfo from "components/Display/ClouItemInfo"
import ClouItemTitle from "components/Display/ClouItemTitle"
import MoneyExhibit from "components/ClouMoneyExhibit"
import OwnerExhibit from "components/OwnerExhibit"
import Row from "components/Row"
import { IAccount } from "Straclou/commons/IAccount"
import { Divider, styled,  Box, Stack } from "@mui/material"
import React, { useEffect, useState } from "react"
import AccountDetailAddress from "./AccountDetailAddress"
import AccountDetailHeader from "./components/AccountDetailHeader"
import ClouMoneyExhibit from "components/ClouMoneyExhibit"
import { useLocation } from "react-router-dom"
import useAppSync from "hooks/useAppSync"
import { AppsyncRequestMethod } from "hooks/interface"
import { TransactionManager } from "Straclou/handler/TransactionManager"
import useAccount from "hooks/useAccount"
import { useWidgetBuilderParams } from "@knymbus/react-widget-builder"


const FinancialCard = styled("div")(({
    width: "95%",
    display: "grid",
    gridTemplateColumns: "1fr 2px 1fr",
    gridTemplateRows: "repreat(2, 80px)",
    gap: "0 0",
    margin: "25px 10px",
    // color: "InactiveCaptionText",
    // fontSize: '0.9em'

}))


interface IProps {
    account?: IAccount
    name?: string
    address?: any
}




const AccountDetail: React.FC<IProps> = () => {

    const location = useLocation();

    const params = useWidgetBuilderParams()

    const {getAccount} = useAccount()

    const [account, setAccount] = useState<IAccount>(new IAccount())

    useEffect(()=>{
        getAccount(params?.id).then((res:IAccount | null)=>{
            if(res !== null) setAccount(res)
        })
    },[params])

    return (
        <>
            <AccountDetailHeader account={account} />

            <Divider sx={{ margin: "8px 16px 0 16px" }} />



            <FinancialCard>

                <Box sx={{ height: "80px", display: 'flex', alignItems: "center", justifyContent: "flex-start", background: '0' }}>
                    <Stack>
                        <ClouItemTitle sx={{ fontSize: ".8em" }}>Billing Date</ClouItemTitle>
                        <ClouItemInfo sx={{ fontSize: ".8em" }} >  <DateTimeExhibit time={account?.billingDate} /></ClouItemInfo>
                    </Stack>
                </Box>

                <Divider orientation="vertical" />

                <Box sx={{ paddingLeft: "50px", height: "80px", display: 'flex', alignItems: "center", justifyContent: "flex-start", background: '0' }}>
                    <Stack>
                        <ClouItemTitle sx={{ fontSize: ".8em" }}>Due Date</ClouItemTitle>
                        <ClouItemInfo sx={{ fontSize: ".8em" }}> <DateTimeExhibit time={account?.dueDate} /></ClouItemInfo>
                    </Stack>
                </Box>

                <Box sx={{ height: "80px", display: 'flex', alignItems: "center", justifyContent: "flex-start", background: '0' }}>
                    <Stack>
                        <ClouItemTitle sx={{ fontSize: ".8em" }}>Maintenance Fees</ClouItemTitle>
                        <ClouItemInfo sx={{ fontSize: ".8em" }}> <MoneyExhibit value={account?.fee} decimal /> </ClouItemInfo>
                    </Stack>
                </Box>

                <Divider orientation="vertical" />

                <Box sx={{ paddingLeft: "50px", height: "80px", display: 'flex', alignItems: "center", justifyContent: "flex-start", background: '0' }}>
                    <Stack>
                        <ClouItemTitle sx={{ fontSize: ".8em" }}>Pre-payments</ClouItemTitle>
                        <ClouItemInfo sx={{ fontSize: ".8em" }}>  <ClouMoneyExhibit value={account?.prePayment} decimal /> </ClouItemInfo>
                    </Stack>
                </Box>



            </FinancialCard>
        </>
    )
}

export default AccountDetail