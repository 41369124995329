import { alpha, Avatar, Box, SxProps } from "@mui/material"
import { theme } from "globals/theme"
import React, { useEffect, useState } from "react"


type Color = "primary" | "secondary"

const THEMECOLOR: any = {
    "primary": theme.palette.primary.main,
    "secondary": theme.palette.secondary.main
}
const THEMETEXTCOLOR: any = {
    "primary": theme.palette.primary.contrastText,
    "secondary": theme.palette.secondary.contrastText
}
interface NameExhibitIProps {
    name?: string,
    avatar?: boolean
    avatarSize?: number
    sx?: SxProps
    color?: Color
}
const NameExhibit: React.FC<NameExhibitIProps> = ({ name, avatar, avatarSize, sx, color }) => {
    const [isAvatar, setIsAvatar] = useState<boolean>(true)
    const [size, setSize] = useState(24)
    const [myColor, setMyColor] = useState<string>(alpha(theme.palette.primary.main,0.8))
    const [textColor, setTextColor] = useState<string>(theme.palette.primary.contrastText)

    useEffect(() => {
        if (avatar !== undefined) setIsAvatar(avatar)
        if (avatarSize !== undefined) setSize(avatarSize)
        if (color !== undefined) {
            setMyColor(THEMECOLOR[color])
            setTextColor(THEMETEXTCOLOR[color])
        }
    })

    return (
        <Box sx={{ display: "flex", alignItems: "center", ...sx }}>
            {
                isAvatar === true ? <Avatar sx={{ bgcolor: myColor, color: textColor, width: size, height: size, fontSize: ".9em", margin: '0 5px', fontWeight: 500 }}>{name?.substring(0, 1)}</Avatar> : ""
            }

            {name}
        </Box>
    )
}

export default NameExhibit