import { Box, Button } from "@mui/material"
import { Footer, Header, IconButtonStyle, Title } from "./CustomStyles"
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useEffect, useState } from "react";
import useCommentary from "./hooks/useCommentary";

interface IProps {
    onClose?: Function
    onBack?: Function
    accountParams?: { id: string, communityId: string }
}

interface Note{
    rawText: string
    htmlText: string
    accountId?: string
    communityId?:string
}
const NewComment: React.FC<IProps> = ({ onClose, onBack, accountParams }) => {

    const { addNote } = useCommentary()
    const [note, setNote] = useState<Note>({
        rawText: "",
        htmlText: ""
    })

    useEffect(() => {
        if(accountParams !== undefined){
            setNote({
                ...note, 
                accountId: accountParams.id || "",
                communityId: accountParams.communityId || ""
            })

        }
    }, [accountParams])

    const handleClose = () => {
        onBack?.(false)
        onClose?.(false)
    }

    const handleBack = () => {
        onBack?.(false)
    }

    const handleNotes = () => {
        if (accountParams?.id !== undefined) {
            if (note.rawText.trim().length > 0) {
                addNote(note).then(res => {
                    handleBack()
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }


    return (
        <Box
            sx={{
                width: 350, background: "0", flex: 1, display: "grid",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "50px 1fr 50px",
                overflow: "hidden",
                height: "100vh"

            }}
            role="presentation"
        >
            <Header>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start"
                }}>
                    <IconButtonStyle size="small" onClick={handleBack}>
                        <ChevronLeftIcon sx={{ color: "#fff", width: "20px", height: "20px" }} />
                    </IconButtonStyle>
                </Box>
                <Title>
                    New Comment
                </Title>
                <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                }}>
                    <IconButtonStyle size="small" onClick={handleClose}>
                        <CloseIcon sx={{ color: "#fff", width: "20px", height: "20px" }} />
                    </IconButtonStyle>
                </Box>
            </Header>

            <Box sx={{ padding: "16px", margin: "0", overflow: "hidden" }}>



                <div style={{
                    height: "100%",
                    overflow: "hidden",
                    overflowY: "auto",
                    transition: "500ms",
                    outline: "none",
                    padding: "0px 0",
                    fontSize: "11pt",
                    lineHeight: "16pt"

                }}
                    contentEditable={true}
                    placeholder={"Write your comment here"}
                    onInput={(e: any) => {
                        setNote({
                            ...note,
                            rawText: e.currentTarget.textContent,
                            htmlText: e.currentTarget.innerHTML
                        })
                    }}
                >

                </div>
            </Box>
            <Footer>
                <Button onClick={handleNotes}>Save</Button>
            </Footer>

        </Box>
    )
}

export default NewComment