import { usePanel } from "./PanelContext"

export function Commander(){
    return {
        register
    }
}

const register = (commands: Array<any>)=>{
    commands.forEach(command=>{
        console.log(command)
        const upc = usePanel(command)        
    })
    return true
}