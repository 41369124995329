import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField, styled, InputLabel } from "@mui/material"
import { grey } from "@mui/material/colors"
import { Helper } from "globals/helper";
import userAccess from "hooks/useAccess";
import useAuth from "hooks/useAuth";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { userHandler } from "Straclou";



const Wrapper = styled(Box)(({ theme }) => ({
    height: "100%",
    paddingBottom: "30px"

}))

const SignInForm = (props: any) => {

    const navigate = useNavigate()
    const { handleSubmit, reset, control } = useForm();

    const [isLoading, setLoading] = useState<boolean>(false)


    const { checkEmail } = useAuth()
    const { updateAccess } = userAccess()

    const {workspace} = useWorkspaceStore()

    useEffect(() => { 
          
        userHandler.getCurrentUserCredentials().then(user=>{
                try{
                if (Helper.hasOwnProperty(user, "uid") && !Helper.isEmpty(user?.uid)) {
                    navigate("/getting_ready")
                }
            }catch(err){
                console.log(err)
            }
        })
    }, [])

   

    const onSubmit = (data: any) => {
        setLoading(true)

        // navigate("/verify_email")

        // Firebase
        // checkIfEmailExistFirebase(data.email.trim()).then(isExist => {

        //     if (!isExist) {
        //         setLoading(false)
        //         navigate('/signin-process/signup', { state: { email: data.email } })
        //     }

        //     if (isExist) {
        //                 setLoading(false)
        //                 // updateAccess(res?.expired === undefined ? true : res.expired)
        //                 navigate("/signin-process/signin_password", {state: {email: data.email, emailValidated: true }})
        //             }

        // }).catch(error => {
        //     console.log(error)
        //     setLoading(false)
        // })

        checkEmail(data.email.trim()).then(res => {

            console.log("response from check email: ",res)
            switch (res.state) {
                case "NONEXIST":
                    setLoading(false)
                    navigate('/signin-process/signup', { state: { email: data.email } })
                    break;

                case "CONFIRMED":
                    setLoading(false)
                    console.log("Respond ", res)
                    updateAccess(false)
                    navigate("/signin-process/signin_password", { state: { email: data.email, emailValidated: true } })
                    break;

                case "UNCONFIRMED":
                    alert("Please check your email to verify user before you can continue. ")
                    setLoading(false)

            }





            // UserManager.sendVerifyEmailCode(data.email).then(res=>{
            //     console.log("email verification response: ",res)
            //     navigate('/verify_email',{state: {email: data.email}})
            // })


            // if the response is true then we send verification code
            // and navigate to the next view
        }).catch(err => {
            console.log("there was an error validating your email: ", err)
            setLoading(false)
        })
    }

    return (
        <Wrapper>
            <Box sx={{ width: "100%", color: grey[900] }}>
                <Box >
                    <form>
                        <Stack gap={2}>
                            <InputLabel sx={{ marginBottom: "-16px", fontWeight: "bold" }}>
                                Email address
                            </InputLabel>
                            <Controller
                                name={"email"}
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField onChange={onChange} value={value} placeholder="name@community-email.com" />
                                )}
                            />
                            <LoadingButton loading={isLoading} variant="contained" onClick={handleSubmit(onSubmit)} sx={{ height: "45px", fontSize: "14pt" }}>Continue</LoadingButton>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </Wrapper>
    )
}

export default SignInForm