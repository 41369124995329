
/**
 * Check Action Component
 * 
 */

import ClouItemInfo from "components/Display/ClouItemInfo"
import ClouItemTitle from "components/Display/ClouItemTitle"
import MoneyExhibit from "components/ClouMoneyExhibit"
import Row from "components/Row"
import { Table, TableBody, TableRow, TableFooter, styled, Tooltip, tooltipClasses, TooltipProps } from "@mui/material"
import { orange, green, grey } from "@mui/material/colors"
// import { Invoice } from "@Straclou/commons/account/AccountConnectionManager"
import React, { useEffect, useState } from "react"
import CheckIcon from '@mui/icons-material/Check';

// HTML Tooltip
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#000',
        color: 'rgba(255, 255, 255, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        // border: '1px solid #dadde9',
    },
}));

const TableDetail = styled('td', { name: "Table-Detail-custom" })(({
    height: "30px",
    borderBottom: `1px solid ${grey[200]}`,
    // textDecoration: strike.toString() === "true" ? 'line-through' : "none"
}))

interface InvoiceCheckIntf{
    percentage: number
    payment: number
    balance: number
}

 interface ICheckActionIProps {
    invoice?: InvoiceCheckIntf
    prev?:boolean
}
const ClouInvoiceCheck: React.FC<ICheckActionIProps> = ({ invoice, prev }) => {
    const [percentage, setPercentage] = useState<number>(0)
    const [show, setShow] = useState<boolean>(false)
    const [color, setColor] = useState<string>("#fff")
    const colorMatrix: any = {
        "q0": orange[50],
        "q1": orange[200],
        "q2": orange[400],
        "q3": orange[600],
        "q4": green[200],
        "q5": green[500]
    }
    const colorMatrixQuadrient = (value: number): string => {
        if (value === -1) return "q0"
        if (value > 0 && value < 26) return "q1"
        if (value > 25 && value < 51) return "q2"
        if (value > 50 && value < 76) return "q3"
        if (value > 75 && value < 100) return "q4"
        if (value > 99) return "q5"

        return "q0"
    }

    useEffect(() => {

        // console.log("invoice: ",invoice)
        if (invoice !== undefined) {

            let p = invoice.percentage !== undefined ? Math.ceil(invoice.percentage * 100) : -1
            let checker: string = colorMatrixQuadrient(p)
            setColor(colorMatrix[checker])

            setPercentage(p)
            // console.log("quad: ", checker, " and p: ", p, " cp: ", invoice.currentPercentage)
            if (checker !== 'q0') setShow(true); else setShow(false)
        }



    }, [invoice])

    
    // === 1 ? <CheckIcon sx={{ color: green[500] }} /> : el?.currentPercentage < 1 ? <CheckIcon sx={{ color: orange[300] }} /> : ""
    return (
        <>
            {
                show ? (<HtmlTooltip title={
                    <React.Fragment>
                        <Row height={5} />
                        <ClouItemTitle>Payment Inpector</ClouItemTitle>
                        <Row height={8} />
                        <Table>
                            <TableBody sx={{ fontSize: ".95em" }} >
                                <TableRow>
                                    <TableDetail sx={{ borderBottom: "none", height: "12px !important" }}>
                                        <ClouItemInfo sx={{ padding: "0 16px 0 0" }}>Payment</ClouItemInfo>
                                    </TableDetail>
                                    <TableDetail sx={{ borderBottom: "none", height: "12px !important" }}>
                                        <MoneyExhibit value={invoice?.payment} />
                                    </TableDetail>
                                </TableRow>
                                <TableRow sx={{ borderBottom: "1px solid #fff", background: '0' }}>
                                    <TableDetail sx={{ height: "14px !important" }}>
                                        <ClouItemInfo sx={{ padding: "0 16px 0 0" }}>Balance reduce to</ClouItemInfo>
                                    </TableDetail>
                                    <TableDetail sx={{ height: "14px !important" }}>
                                        <MoneyExhibit value={invoice?.balance} />
                                    </TableDetail>
                                </TableRow>
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableDetail sx={{ borderBottom: "none", height: "15px !important" }}>
                                        {percentage}% of this invoice will be paid
                                    </TableDetail>
                                </TableRow>
                            </TableFooter>
                        </Table>

                    </React.Fragment>
                }>
                    <CheckIcon sx={{ color: color }} />
                </HtmlTooltip>) : ""
            }


        </>
    )
}

export default ClouInvoiceCheck     