import { Box, Button, Divider, Fade, IconButton, InputBase, Menu, Paper, Popper, styled } from "@mui/material"
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { grey } from "@mui/material/colors";
import { useRef, useState } from "react";

const Wrapper = styled(Box)({
    height: "60px",
    width: "600px",
    // background: "red",
    zIndex: 999999999999999
    // borderBottom: `1px solid ${grey[100]}`
})

export default function PropertySearchActivity(props: any) {

    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const anchorRef = useRef<HTMLElement>(null);

    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    const handleChange = (e: any) => {
        e.preventDefault()
        console.log(e)
        let s: string = e.target.value
        if(s.length > 0) {
            setSearchTerm(e.target.value)
        }
        setOpen(true)
    }

    const onSubmit = (e: any)=>{
        e.preventDefault();
    }

    return (
        <Wrapper>
            <Box sx={{ background: '0' }}>
                <Paper
                    //  ref={anchorRef}
                    id="composition-button"
                    aria-controls={open ? 'composition-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    elevation={0}
                    component="form"
                    onSubmit={onSubmit}
                    onClick={()=>setOpen(true)}
                    sx={{ p: '2px 4px', display: 'flex', 
                    alignItems: 'center', width: "300px", 
                    height: "30px", cursor: "pointer", 
                    border: `1px solid ${grey[50]}`,
                    background: grey[100],
                    borderRadius:"10px",
                    transform: 'translateY(25%)' }}
                >
                    <IconButton sx={{ p: '10px' }} aria-label="menu">
                        <SearchIcon />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, mr: 1, flex: 1, cursor: "pointer", fontSize: "11pt" }}
                        placeholder="Search property"
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'search property', sx: {cursor: "pointer"} }}
                    />

                    {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">

                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                        <DirectionsIcon />
                    </IconButton> */}
                </Paper>
                <Menu
                    open={open}
                    MenuListProps={{
                        'aria-labelledby': 'search-input',
                    }}
                    TransitionComponent={Fade}
                    sx={{ mt: -16 }}
                >
                    <Paper
                        elevation={0}
                        component="form"
                        onSubmit={onSubmit}
                        sx={{
                            p: '2px 16px',
                            width: "620px",
                            position: "relative",
                            height: "400px"
                        }}
                    >
                        <Box sx={{ display: 'flex', flexDirection: "row", flex: 1 }}>
                            <Paper
                                id="search-input"
                                aria-haspopup="true"
                                elevation={0}
                                sx={{
                                    p: '2px 4px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: "100%",
                                    height: "30px",
                                    border: `1px solid ${grey[200]}`
                                }}
                            >
                                <Box sx={{ pl: '10px' }} aria-label="menu">
                                    <SearchIcon />
                                </Box>
                                <InputBase
                                    ref={(input: HTMLInputElement) => input && input.focus()}
                                    defaultValue={searchTerm}
                                    sx={{ ml: 1, mr: 1, flex: 1 }}
                                    placeholder="Search property"
                                    inputProps={{ 'aria-label': 'search property' }}
                                />

                                {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">

                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
                        <DirectionsIcon />
                    </IconButton> */}
                            </Paper>
                            <Button onClick={handleClose}>Cancel</Button>
                        </Box>

                        {/* Result Window */}
                        <Box>
                            Result goes here
                        </Box>

                        <Box sx={{ p: 1, position: "absolute", bottom: 0, left: 0, right: 0, background: '0' }}>Footer</Box>
                    </Paper>
                </Menu>
            </Box>

        </Wrapper>
    )
}