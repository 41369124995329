import { Box, styled } from "@mui/material";
import { isMobile } from "react-device-detect";
import { NavigationLink } from "../Widgets/NavigationLink";

const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    width: isMobile ? "90%" : "100%",
    justifyContent: "center",
    alignItems: "center",
    margin: "8px 0 24px 0",
    fontSize: "10pt",
    // background: "blue",
    [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        width: "90%",
        "& span":{
            textAlign: "center",
            lineHeight:"22px"

        },
    }
}))


export default function FooterActivity() {
    return (
        <Wrapper>
            <span style={{ marginRight: "4px" }}>
                Copyright &copy; 2022 Straclou. All rights reserved.
                Powered by Knymbus
                <span style={{ marginLeft: "8px" }}>
                    <NavigationLink fontSize={"10pt"}>Privacy & Terms</NavigationLink>
                </span>
                <span style={{ marginLeft: "8px" }}>
                    <NavigationLink fontSize={"10pt"}>Contact Us</NavigationLink>
                </span>
            </span>
            
        </Wrapper>
    )
}