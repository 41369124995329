import { useState } from "react"
import { IAccount } from "Straclou"
import { ListAccountInput, ListAccountResponse } from "Straclou/API"
import { AccountManager } from "Straclou/handler/AccountManager"
import useAccountStore from "./AccountStore"
import { SortingOrder } from "./interface"

export default function useAccount() {
    const { updateAccount, list, clearStore, orderBy } = useAccountStore()

    const [isLoading, setIsLoading] = useState<boolean>(false)

    /**
     * Get the list of accounts and store data to local store for each access
     * @param params 
     */
    const initialize = async (params: ListAccountInput) => {
        try {
            setIsLoading(true)
            console.log("Store init called: ", list)
            let result: ListAccountResponse = await AccountManager.list(params);

            updateAccount(result)
            setIsLoading(false)
            // if (list.empty) {
            // } else {

            //     let result: ListAccountResponse = await AccountManager.list(params);
            //     updateAccount(result)
            //     setIsLoading(false)
            // }
        } catch (error) {
            let response: ListAccountResponse<IAccount> = {
                count: 0,
                items: [],
                totalCount: 0,
                pages: [],
                next: false,
                page: 0,
                limit: 50,
                start: 1,
                end: 50
            }
            updateAccount(response)
            setIsLoading(false)
        }




    }

    const paginate = async (params: ListAccountInput) => {
        setIsLoading(true)
        clearStore()

        let result: ListAccountResponse = await AccountManager.list(params);
        updateAccount(result)
        setIsLoading(false)
    }

    const sortBy = async (fieldName: string, order?: SortingOrder) => {
        order = order !== undefined ? order : "ASC"
        
        switch (fieldName) {

            case "status":       
            case "accountName": 
                orderBy(fieldName,(a: string,b:string)=>{
                    return a < b ? -1 : a > b ? 1 : 0
                })
                break;
        
            case "none": {
                orderBy("createdAt",(a: string,b:string)=>{
                    const aD = new Date(a)
                    const bD = new Date(b)
                    return bD.getTime() - aD.getTime()
                })
                break;
            }
            default: {
                orderBy(fieldName)
            }
        }

       
    }

    const getAccount = async (id: string) => {
        let result = await AccountManager.get({
            id
        }) as any

        return result
    }

    return { initialize, isLoading, paginate, sortBy, getAccount }
}