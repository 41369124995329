import React, { useEffect, useState } from 'react'
// import { useUserAuthentication } from '../globals/Authentication'


interface IProps {
    color?: string
    size?: string
    first?: boolean
    last?: boolean
    
}
const ClouActiveUserExhibit: React.FC<IProps> = ({ color, size, first, last }) => {
    // const appAuth = useUserAuthentication()
    const appAuth = {
        user: {name: "John Hopkins-Robinson"}
    }
    const [firstName, setFirstName] = useState<String>("")
    const [isFirst, setIsFirst] = useState<boolean>(false)
    useEffect(()=>{
        // console.log(appAuth.user.name)
        let n = appAuth.user.name ?? ""
        setFirstName(n.split(" ")[0])
        if(first !== undefined ) setIsFirst(first)
        return()=>{
            // appAuth.update()
            // console.log("active User is unmounted")
        }
    },[appAuth.user])

    return (
        <span>
            {isFirst ? firstName : appAuth.user.name}
        </span>
        )
}

export default ClouActiveUserExhibit
