import { Box, TextField } from "@mui/material"
import { theme } from "globals/theme"
import { styled, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { ProgressStep, useOnboardingProgressStore } from "hooks/useOnboardingProgress"
import { ErrorMessage } from "@hookform/error-message"
import useAuth from "hooks/useAuth"
import useOnboardingProgressRouter from "hooks/useOnboardingProgressRouter"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { UnitSizeInfo } from "./UnitSizeAdditionalInfo/UnitSizeInfo"


const Wrapper = styled(Box)(({
    width: "60vw",
    height: "80vh"
}))

const InputBox = styled(TextField)(({
    width: "100%",
    fontSize: "1.5em !important",

    "&.MuiOutlinedInput-input": {
        textAlign: "center"
    }

}))



const CommunitySetupSizePage = () => {

    const navigate = useNavigate()
    const params = useParams()
    const { user } = useAuth()
    const { goto } = useOnboardingProgressRouter(params?.clientId)

    const { t } = useTranslation()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();
    const { updateProgress, progress } = useOnboardingProgressStore()

    const onSubmit = (e: any) => {
        console.log(e)
        // updateProgress({ CommunitySize: e.size as number })
        // navigate('/client/dynamicClientId/community_structure')

        updateProgress({
            id: params.clientId || "",
            uid: user.uid,
            step: ProgressStep.Community_Size,
            data: { CommunitySize: e.size as number },
        }).then(res => {
            console.log(res)
            // navigate(`/client/${params.clientId}/community_strata_number`)
            goto(ProgressStep.Community_Structure)
        })
    }

    useEffect(() => {
        reset({
            size: progress?.CommunitySize
        })
    }, [progress])

    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>


                <Box sx={{ textAlign: "center", fontSize: '2.3em', fontWeight: "600", margin: "30px 0 30px 0", color: theme.palette.primary.main }}>
                    {t("community_size.title")}
                </Box>

                <Box sx={{ textAlign: "center", margin: "0 0 30px 0", lineHeight: "2em" }}>
                    {t('community_size.information') || ""}
                    <UnitSizeInfo value={"Click for more info"} detail={[t('community_size.description1') || "",t('community_size.description2') || "",t('community_size.description3') || ""]} />
                </Box>


                <Box sx={{ textAlign: "center", margin: "0 0 30px 0" }}>

                    <Controller
                        name={"size"}
                        control={control}
                        defaultValue={progress?.CommunitySize || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("size", {
                                    required: "Community Size is required",
                                    minLength: {
                                        value: 1,
                                        message: "Length of community strata number have to be greater than 1 characters"
                                    }
                                })}
                                onChange={onChange}
                                type="number"
                                value={value}
                                placeholder={t('community_size.input_placeholder') || ""}
                                inputProps={{ style: { textAlign: "center", fontSize: "18pt" } }}
                                sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                        )}
                    />
                    <Box sx={{
                        margin: "8px",
                        height: "40px"
                    }}>
                        <ErrorMessage
                            errors={errors}
                            name="size"
                            render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                        />
                    </Box>

                </Box>

                <Box sx={{ textAlign: "center", margin: "0 0 30px 0", lineHeight: "2em" }}>
                    {t('community_size.description') || ""}
                </Box>

                <Box sx={{ width: "300px", margin: "0 auto" }}>
                    <Button type="submit" variant="contained" fullWidth sx={{ height: "55 px", fontSize: "1.6em" }}>Next</Button>
                </Box>
            </form>
        </Wrapper>
    )
}

export default CommunitySetupSizePage