import { resolve } from "path";
import { CommunityManager } from "Straclou/handler/CommunityManager";
import {create} from "zustand";

export type CommunityStructure = "Apartments" | "Condominiums" | "Single Family Homes" | "Townhouses" | "Villas"
export type Step = "CommunityName" |
    "CommunityStrataNumber" |
    "CommunitySize" |
    "CommunityStructure" |
    "CommunityAddress" |
    "CommunityPhone" |
    "CommunityEmail"
export enum ProgressStep {
    Community_Name = "CommunityName",
    Community_Strata_Number = "CommunityStrataNumber",
    Community_Size = "CommunitySize",
    Community_Structure = "CommunityStructure",
    Community_Address = "CommunityAddress",
    Community_Email = "CommunityEmail",
    Community_Phone = "CommunityPhone",
    Complete = "Complete"
}
export interface Address {
    street: string;
    town: string;
    city: string;
    parish: string;
}
export interface IOnboardingProgress {
    CommunityName?: string;
    CommunityStrataNumber?: string;
    CommunitySize?: number;
    CommunityStructure?: CommunityStructure[];
    CommunityAddress?: Address
    CommunityEmail?: Address
    CommunityPhone?: Address
}

export interface IUpdateProgressInput {
    id: string;
    uid: string;
    step: Step,
    data: { [key in Step]?: any },
}

type CommunityOnboardingStore = {
    progress: IOnboardingProgress,
    updateProgress: (item: IUpdateProgressInput) => Promise<any>,
    load: (workspaceId: string) => void

}
export const useOnboardingProgressStore = create<CommunityOnboardingStore>((set) => ({
    progress: {},
    updateProgress: (item: IUpdateProgressInput) => {
        return new Promise((resolve, reject) => {
            CommunityManager.onboarding(item).then(res => {
                set(state => ({ ...state, progress: { ...state.progress, ...item.data } }))
                resolve(res)
            }).catch((error: any)=>{
                reject(error)
            })

        })
    },
    load: (workspaceId: string) => {
        getStoredData(workspaceId).then((data: any) => {

            set(state => ({ ...state, progress: { ...data } }))
        })
    }
}))


const getStoredData = async (id: string) => {
    return new Promise((resolve, reject) => {
        CommunityManager.workspaceById({ workspaceId: id }).then((res: any) => {
            // console.log(res)
            let d: any = {
                CommunityName: res['CommunityName'],
                CommunityStrataNumber: res['CommunityStrataNumber'],
                CommunitySize: res['CommunitySize'],
                CommunityStructure: res['CommunityStructure'],
                CommunityAddress: res["CommunityAddress"]

            }
            resolve(d)
        })

    })
}