import { styled, SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';

interface IRowProps {
  height?: number | string,
  width?: number | string
  sx?: SxProps,
  center?: boolean
  right?: boolean
  children?: React.ReactNode
  name?: string
  flex?: boolean
  className?: string
}

interface IRowWrapperProps {
  height: number | string
  width: number | string
  center: string
  right?: string
  name?: string
  flex?: string
}

const CenterFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const RightFlex = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end"
}

const FlexRow = {
  display: "flex"
}


const RowWrapper = styled("div", { name: "Cmui-Row", slot: "wrapper" })(({ height, center, right, width, flex }: IRowWrapperProps) => (({
  height: height,
  ...(flex === "true" ? FlexRow : {}),
  ...(center === "true" ? CenterFlex : {}),
  ...(right === 'true' ? RightFlex : {}),
  width: width
})))


export const Row: React.FC<IRowProps> = ({ children, flex, height, sx, center, right, width, name, className }) => {

  /**
   * The center attribute must have a value as such we are going to 
   * by default set a value to false and then listen for user change
   */
  const [isCenter, setIsCenter] = useState(false)

  const [isRight, setIsRight] = useState(false)

  const [isFlex, setFlex] = useState(false)



  //  Listen only on the props changes from user request
  useEffect(() => {
    let loading = true
    //  Check the state and assign a inital value to prop

    if (center !== undefined) setIsCenter(true)


    return () => {
      loading = false
    }
  }, [center])

  //  Listen only on the props changes from user request
  useEffect(() => {

    //  Check the state and assign a inital value to prop
    if (right !== undefined) setIsRight(true)


  }, [right])

  //  Listen only on the props changes from user request
  useEffect(() => {

    //  Check the state and assign a inital value to prop
    if (flex !== undefined) setFlex(flex)


  }, [flex])

  return (
    <RowWrapper
      height={height || "auto"}
      sx={sx}
      center={isCenter.toString()}
      right={isRight.toString()}
      width={width || "100%"}
      flex={isFlex.toString()}
      className={className}
    >
      {children}
    </RowWrapper >
  )
}

export default Row