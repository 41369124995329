
import ClouStatusConverterExhibit from 'components/ClouStatusConverterExhibit';
import ClouMoneyExhibit from 'components/ClouMoneyExhibit';
import { IAccount } from 'Straclou/commons/IAccount';
import { theme } from 'globals/theme';
import { styled, Paper, alpha, Box } from '@mui/material';
import { red } from '@mui/material/colors';
import React, { useEffect, useState } from 'react';
import ClouAccountNumberExhibit from 'components/ClouAccountNumberExhibit';


const Wrapper = styled(Paper)({
    marginTop: "-10px",
    height: "180px",
    padding: "8px",
    transition: "500ms",
    position: "relative",
    bgColor: alpha(theme.palette.primary.main, 0.1)
})

const AccountNumber = styled(Box)(({
    fontSize: ".8em",
    height: "25px",
    color: alpha(theme.palette.primary.dark, 0.9)
}))
const AccountBalance = styled(Box)(({
    fontSize: ".6em",
    height: "auto",
    color: alpha(theme.palette.primary.dark, 0.9),
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center'
}))
const Balance = styled(Box)(({
    fontSize: "2em",
    height: "50px",
    color: "#000000",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '25px',
    transition: '500ms'
}))
const Left = styled(Box)(({    
    height: "auto",
    marginTop: '25px',
    position: "absolute",
    
}))
const Right = styled(Box)(({    
    height: "auto",
    marginTop: '25px',
    position: "absolute",
    right: '16px'
}))
const Days = styled(Box)(({
    fontSize: ".7em",
    height: "auto",
    // color: "#000000",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    color: red[500]
    
    
}))
const Title = styled(Box)(({
    fontSize: ".7em",
    height: "auto",
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    color: alpha(theme.palette.primary.dark, 0.9),
}))

const OMonths: React.FC<{value?:number, balance?: number}> = ({value, balance})=>{
    const [color, setColor]=useState("black")

    useEffect(()=>{
        if(balance !== undefined && balance>=0) setColor(theme.palette.secondary.main)
        if(balance !== undefined && balance<0) setColor(red[500])

    },[balance])
    return (
        <Box sx={{color: color}}>
            {value}
        </Box>
    )
}



interface IProps {
    account?: IAccount
}

const AccountDetailHeader: React.FC<IProps> = ({account}) => {
    return (
        <Wrapper elevation={0} square> 
            <AccountNumber>
                <span>A/C</span> <ClouAccountNumberExhibit value={account?.accountNumber} />
            </AccountNumber>
            <Balance>
                <ClouMoneyExhibit value={account?.balance} />
            </Balance>
            <AccountBalance>
                CURRENT BALANCE
            </AccountBalance>
        <Left>
            <Title>Invoice</Title>
            <OMonths value={account?.monthsOutstanding} balance={account?.balance}/>
        </Left>

        <Right>
            <Title>STATUS</Title>
            <Days><ClouStatusConverterExhibit value={account?.status} /></Days>            
        </Right>

        </Wrapper>
    )
}

export default AccountDetailHeader