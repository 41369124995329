
import { Auth } from "aws-amplify";
import awsmobile from "aws-exports";
import { IUser } from "Straclou/commons/IUser";
import { axiosExecute, AxiosOptions } from "../axios"
import { ConnectionStrings } from "../ConnectionStrings"


import ENV from "../ENV";
import { CheckEmailResponse, EmailVerificationCodeResponse, VerifyEmailCodeResponse, SignupCredentials, SigninCredentials } from "./UserHandlerTypes";


// TODO build a new Auth Library
// This code is a STUD please build out a comprehensive 
// Middleware code to talk to an external API
// and allow it to maintain its original functions
// const Auth: any = {

//     currentUserInfo: ()=>new Promise((resolve,reject)=>{}),
//     signOut: ()=>new Promise((resolve,reject)=>{})

// }


export module UserHandler {

    export const userTag = "straclou.console.active.user"

    export const userAccessTag = "straclou.console.active.user.access"

    export const activeUser: IUser = new IUser()

    export const init = () => {
        getCurrentUserCredentials()
    }


    export async function getCurrentUserCredentials(): Promise<IUser> {
        return new Promise(async (resolve, reject) => {

            Auth.currentUserInfo().then((res: any) => {
                let user: IUser = new IUser()
                if (res) {
                    let attributes = CreateUserAttributes(res)
                    activeUser.init(attributes)
                    user.init(attributes)
                }
                resolve(user)
            })
        })
    }



    export function checkEmail(email: string): Promise<CheckEmailResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.userConnection(),
                    data: JSON.stringify({
                        input: {
                            email,
                            poolId: awsmobile.aws_user_pools_id,
                            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                        },
                        method: 'checkEmail'
                    })

                }

                console.log("Option ", option)
                const data = await axiosExecute(option) as CheckEmailResponse

                console.log("verify email response: ", data)


                // we need to assign to Premises                
                resolve(data)

            } catch (error) {

                console.log("something went wrong when trying to get verify email: ", error)
                reject(false)

            }
        })
    }

    export async function sendVerifyEmailCode(email: string): Promise<EmailVerificationCodeResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.userConnection(),
                    data: JSON.stringify({
                        input: {
                            email
                        },
                        method: 'sendVerifyEmailCode'
                    })

                }
                const data = await axiosExecute(option) as EmailVerificationCodeResponse

                console.log("send verification code response: ", data)


                // we need to assign to Premises                
                resolve(data)

            } catch (error) {

                console.log("something went wrong when trying to get verify email: ", error)
                reject(false)

            }
        })
    }





    export async function verifyCode(code: Array<string>, email: string): Promise<VerifyEmailCodeResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.userConnection(),
                    data: JSON.stringify({
                        input: {
                            code,
                            email: email
                        },
                        method: 'verifyCode'
                    })

                }

                console.log("Option ", option)
                const data = await axiosExecute(option) as VerifyEmailCodeResponse

                // we need to assign to Premises                
                resolve(data)

            } catch (error) {

                console.log("something went wrong when trying to get verify email: ", error)
                reject(false)

            }
        })
    }





    export async function signup(signupCredentials: SignupCredentials) {

        // Successfully Signed up
        // {
        //     "UserConfirmed": false,
        //     "CodeDeliveryDetails": {
        //         "Destination": "k***@g***",
        //         "DeliveryMedium": "EMAIL",
        //         "AttributeName": "email"
        //     },
        //     "UserSub": "3539a120-c314-43ba-8a88-1d90b57f2489"
        // }


        // user name already exist exception




        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.userConnection(),
                    data: JSON.stringify({
                        input: signupCredentials,
                        method: 'createNewUser'
                    })

                }

                console.log("Option for sign up ", option)
                const data = await axiosExecute(option) as VerifyEmailCodeResponse

                console.log("result from signup: ", data)
                resolve(data)

            } catch (error) {
                console.log("error from signup: ", error)
                reject(error)
            }

        })
    }


    export async function signin(signinCredentails: SigninCredentials): Promise<IUser> {
        return new Promise(async (resolve, reject) => {
            console.log("About to call the sign in fn: ", signinCredentails)
            try {
                Auth.signIn(signinCredentails.email, signinCredentails.password).then((user: any) => {
                    console.log(user)
                    let activeUser: IUser = new IUser()

                    if (user) {
                        let attributes = CreateUserAttributes(user)

                        activeUser.init(attributes)
                        resolve(activeUser)
                    }
                }).catch((error: any) => {
                    console.log(error)
                    reject({ ...error })

                })
            } catch (error) {
                console.log(error)
            }

        })
    }


    // User Attribute Object
    function CreateUserAttributes(user: any) {
        return {
            email: user["attributes"]['email'],
            name: user['attributes']['name'],
            uid: user['attributes']['sub'],
            isPolicyChecked: TypeConvertor.boolean(user['attributes']['custom:isPolicyChecked']),
            policy: TypeConvertor.array(user['attributes']['custom:policy'], 'string'),
            communities: TypeConvertor.array(user['attributes']['custom:communities'], "string"),
            isWorkspace: TypeConvertor.boolean(user['attributes']['custom:isWorkspace']),
            expiredDate: user["attributes"]['custom:expired_date'],
            userType: user["attributes"]['custom:userType']
        }
    }





    export async function signout() {
        Auth.signOut().then((res: any) => {
            localStorage.clear()
            return { success: true, message: "user sign out successfully", data: res }
        }).catch((err: any) => {
            return { error: true, message: "something went wrong", data: err.message }
        })
    }



    // Update user Attribute
    export async function updateUserAttribute(input: { communities: string; isWorkspace: boolean; }) {
        Auth.currentAuthenticatedUser().then(async (user: any) => {
            console.log("The current user ?>>>>>> ", user)
            /**
             * Here we need to check if the current id is already assigned to this user
             * If yes the id was found on the user profile
             * then we do nothing
             * else update the user and still set the isWorkspace to true
             */


            // convert the data coming from cognito to correct datatype 
            let userAttributes = CreateUserAttributes(user)

            // if not found then we update 
            // we are going to add the id to the exist array
            if (!userAttributes.communities.includes(input.communities)) {
                userAttributes.communities.push(input.communities)

                // now we update the user profile
                Auth.updateUserAttributes(user, {
                    "custom:communities": JSON.stringify(userAttributes.communities),
                    "custom:isWorkspace": input.isWorkspace.toString()
                })
            }
        })
        return { status: "updated" }
    }
}


export class TypeConvertor {
    static boolean(str: string) {
        try {
            if (str === 'false') {
                return false
            }

            if (str === 'true') return true
        } catch (error) {
            return false
        }
    }

    static array(obj: any, from: string): Array<any> {
        if (from === "string" && typeof obj === 'string') {
            try {
                let res = JSON.parse(obj)
                return res
            } catch (error) {
                return []
            }
        }
        return []
    }
}
