import { useReducer } from "react"
import { PropertyHandler } from "Straclou/handler/PropertyHandler"
import { TransactionManager } from "Straclou/handler/TransactionManager"
import {
    AppsyncRequest,
    AppsyncRequestMethod,
    ReducerAction,
    StateReducer
} from "./interface"


// const that hole initail state
const InitialState = {
    data: undefined,
    isRequestError: null,
    isLoading: false,
}



/**
 * 
 * @param state 
 * @param action 


export function getFullDirectory() {
    throw new Error("Function not implemented.")
}
export function getFullDirectory() {
    throw new Error("Function not implemented.")
}
 * @returns 
 */
const stateReducer = <T>() => (state: StateReducer<T>, action: ReducerAction<T>): StateReducer<T> =>{
    switch (action.type) {
        case 'ON_DATA_FETCH':{
            return {
                ...state,
                isLoading: true,
                isRequestError: null,
                
            }
        }
        case "ON_SUCCESS":{
            return {
                isLoading: false,
                isRequestError: null,
                data: action.payload
            }
        }

        default:
            return state;
    }
}



/**
 * This function get the data from the server and return a given class 
 * @returns Array [data,isLoading,dispatch,isRequestError]
 */
const useAppSync = <T>(): Array<any>=> {
    // Reducer
    const dataFetchRedcer = stateReducer<T>()
    const [state, dispatch] = useReducer(dataFetchRedcer, InitialState)
      

    // Custom dispatch object
    const asyncDispatch: any = async (action: AppsyncRequest) => await reducer(dispatch, action);
    return [state.data, state.isLoading, asyncDispatch, state.isRequestError]
}

const reducer = async (dispatch: any, action: AppsyncRequest): Promise<void> => {
    
    dispatch({ type: "ON_DATA_FETCH" })
    switch (action.method) {
        
        /**
         * @deprecated use RMSListing for firebase function instead
         */
        case AppsyncRequestMethod.FullRMSDashboardList: {
            console.log("RMS list got called.")
            PropertyHandler.dashboardListing().then((res:any) => {
              dispatch({ type: "ON_SUCCESS", payload: res })
            }).catch((error: any) => {
                // set error dispatch
            })
            break;
        }

        case AppsyncRequestMethod.RMSListing: {
            console.log("RMS firebase list called")
            
            break;
        }
        case AppsyncRequestMethod.getAccount: {
            // AccountHandler.get(action.args).then((res: any) => {
            //     // console.log("We are in get account: ", res)
            //   dispatch({ type: "ON_SUCCESS", payload: res })
            // }).catch((error: any) => {
            //     // set error dispatch
            // })
            

            TransactionManager.getAccount(action.args).then(res => {
                // console.log("Account detail: ",res)
                dispatch({ type: "ON_SUCCESS", payload: res })
            }).catch((error: any) => {
                // set error dispatch
            })

            break;
        }
        case AppsyncRequestMethod.getPropertyDetail:{
            PropertyHandler.getProperty(action.args).then(res => {
                dispatch({ type: "ON_SUCCESS", payload: res })
            }).catch(error => {
                dispatch({ type: "ON_ERROR" })
            })
            break;
        }
        default:
            dispatch({ type: "ON_ERROR" })
    }

    //  CreateProperty: (setData: Function, setRequestError: Function, setIsLoading: Function, property: any, ) =>{
    //    
    //  }

}

export default useAppSync