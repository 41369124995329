import { Badge, Box, Divider, IconButton, Menu, Tooltip } from "@mui/material";
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useState } from "react";
import MenuController from "./MenuControl";
import { notifications } from "./sample";
import MessageTemplate from "./MessageTemplate";
import useNotification from "./useNotification";

export default function NotificationMenu() {

    const { count, list } = useNotification()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <Box sx={{ width: "55px", transform: 'translateY(30%) translateX(-10%)' }}>
            <Badge badgeContent={count.unread} color="secondary">
                <Tooltip title="Notifications">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <NotificationsIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                </Tooltip>
            </Badge>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        width: "300px",
                        height: "100%",
                        p: 1,
                        mt: .5,
                        ml: -4,
                        transform: 'translateX(-50%)',

                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(10%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuController />
                <Divider />

                {
                    list.map(data => {
                        return (
                            <MessageTemplate key={data.key} data={data} />
                        )
                    })
                }

            </Menu>
        </Box>
    )
}