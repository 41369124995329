import { styled,Box } from "@mui/material"
import { grey} from "@mui/material/colors"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import logo from "../../logo.svg"
import VerticalMenu from "../HomePage/VerticalMenu"
import MenuIconComponent from "./MenuIconComponent"


const Page = styled(Box)(({

}))

const Header = styled(Box)(({
    // background: red[200],
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex"
}))

const ContentMain = styled(Box)(({
    background: grey[200],
    position: "fixed",
    top: "70px",
    marginLeft: "250px",
    width: "calc(100vw - 250px)",
    bottom: 0,

}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "1.7em",
    fontWeight: 600,
    marginLeft: "8px"
}))



const HomePage: React.FC = () => {
    
    return (
        <Page>
            <Header>
                <Box sx={{ height: "70px", width: "250px", padding: "0 0 0 16px", margin: "0 0 0 -16px", borderBottom: `0px solid ${grey[300]}` }}>
                    <Box sx={{ padding: " 16px 0 0 8px", display: "flex" }}>

                        <img src={logo} width="35px" color={"#fff"} alt="Straclou logo" />
                        <LogoName>Straclou</LogoName>
                        <Box sx={{fontSize: ".8em"}}>Community</Box>
                    </Box>
                </Box>

                <Box sx={{background: "0", position: "fixed", right: "16px", height: "70px"}}>
                    <MenuIconComponent />
                </Box>

            </Header>
            <VerticalMenu/>
            <ContentMain>
                <Outlet/>
            </ContentMain>
        </Page>
    )
}

export default HomePage