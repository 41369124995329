import { Box } from "@mui/material"
import { useEffect, useState } from "react"

export default function SummaryCollection(params: any) {
const {children, sx} = params
const [styles, setStyles] = useState<any>({})

useEffect(()=>{
    if(sx!== undefined){
        setStyles(sx)
    }
},[sx])
    return <Box sx={{width: "100%",...styles}}>{children}</Box>
    
}