import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { MessageDialogContext } from './Context/MessageDialogContext';

export default function MeassgeDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [onOpen, setOnOpen] = React.useState(false);

  // Main Drawer
  

  // const handleClickOpen = () => {
  //   setDrawer(true);
  // };

  // const handleClose = () => {
  //   setDrawer(false);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (response: string) => {
    setOpen(false);
    props.onClose?.(response)
  };

  React.useEffect(()=>{
    console.log("Message Props: ",props)
    if(props.onOpen){
        console.log()
        handleClickOpen()
    }

    if(!props.onOpen){
        handleClose("cancel")
        // props.onMessageClose?.("close")
        // setDrawer(false);
    }
  },[props?.onOpen])

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Message"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your request was posted successfully. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose("cd")} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}