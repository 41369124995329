import * as React from 'react';
import Drawer from '@mui/material/Drawer';
import TransactionInvoiceComponent from './TransactionInvoiceComponent';



type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function TemporaryDrawer(props: any) {
    const [state, setState] = React.useState(false);

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState(open);
            };

    React.useEffect(() => {
       if (props.isOpen) {
            setState(true)
        }

    }, [props?.isOpen])

    React.useEffect(()=>{
        if(state === false){
            props.setIsOpen(false)
        }
    },[state])


    return (
        <div>
            <React.Fragment key={"right"}>
                <Drawer
                    anchor={"right"}
                    open={state}
                    onClose={toggleDrawer(false)}
                >
                    <TransactionInvoiceComponent info={props?.rowData} onClose={setState}/>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
