import { AxiosOptions, axiosExecute } from "../axios"
import { IWorkspace } from "../commons/IWorkspace";
import { ConnectionStrings } from "../ConnectionStrings"
import { Observable } from "rxjs";

type ProductType = "Demo" | "Paid"

export interface CreateCommunityWorkSpaceResponse {
    uid: string;
    workspaceId: string // 9 Character with Letters and Numbers mix
}



/**
 * @deprecated use workspaceManager instead its improved with better function and has more options to connect to the database 
 */

export module CommunityManager {



    export async function getWorkList(userId: string) {

    }

    /**
     * Litener for worklist by owner
     * @param userId string
     * @returns 
     */
    export function onWorklistenger() {
        return new Observable((subscriber) => {

        })
    }

    /**
     * Initialize the onboarding process of the workspace
     * @param payload 
     * @returns 
     */
    export async function init(uid: string): Promise<CreateCommunityWorkSpaceResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let type: ProductType = "Demo"

                console.log("user: user")
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.CommunityConnection(),
                    data: JSON.stringify({
                        input: {
                            type,
                            uid: uid
                        },
                        method: 'init'
                    })

                }

                console.log("Option for request: ", option)
                const data = await axiosExecute(option) as CreateCommunityWorkSpaceResponse
                resolve(data)

            } catch (error) {

                console.log("something went wrong when trying to init workspace: ", error)
                reject(false)

            }
        })
    }


    // Handle the Community/workspace setup update
    export async function onboarding(payload: any) {
        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.CommunityConnection(),
                    data: JSON.stringify({
                        input: {
                            ...payload
                        },
                        method: 'onboarding'
                    })

                }
                const data = await axiosExecute(option) as CreateCommunityWorkSpaceResponse
                resolve(data)

            } catch (error) {

                console.log("something went wrong when trying to update workspace: ", error)
                reject(false)

            }
        })
    }


    export async function workspaceById(payload: { workspaceId: string }): Promise<IWorkspace> {
        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.CommunityConnection(),
                    data: JSON.stringify({
                        input: {
                            ...payload
                        },
                        method: 'getById'
                    })

                }
                const data = await axiosExecute(option)
                if (data !== undefined) resolve(new IWorkspace(data)); else resolve(new IWorkspace())


            } catch (error) {

                console.log("something went wrong when trying to update workspace: ", error)
                reject(false)

            }
        })
    }

    export async function workspaceList(payload: { userId: string }) {

    }


}