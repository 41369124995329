import { useEffect, useState } from "react"
import { notifications } from "./sample"

export default function useNotification(){
    const [unread, setUnread] = useState(0)
    const [read, setRead] = useState(0)
    const [list, setList] = useState<Array<any>>([])

    useEffect(()=>{
        let unread = notifications.filter(el=>el.unread === true)
        let read = notifications.filter(el=>el.unread === false)

        setUnread(unread.length)
        setRead(read.length)

        setList(notifications)
    },[])
    return {
        count: {
            unread,
            read
        },
        list
    }
}