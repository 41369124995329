import { WorkspaceManager } from "Straclou"
import { ICommunityStructure } from "./ICommunityStructure"
import { IIdentity } from "./IIdentity"


type ActivateOption = (ICW: ICommunityWorkspace) => void



export class ICommunityWorkspace {
    private _communityId: string | null | undefined = null
    private _isCommunity: boolean = false
    private _identity: IIdentity


    /**
     * Getters
     * 
     */
    get communityId() { return this._communityId }
    get isCommunity() { return this._isCommunity }
    get identity() { return this._identity }


    /**
     * Setters
     * 
     */
    set identity(identity: IIdentity) {
        this._identity = identity
        // console.log("Object set: ", this._identity)
    }

    constructor(...options: ActivateOption[]) {
        // set the options
        for (const option of options) {
            option(this)
        }
    }

    public static async activate(communityId?: string): Promise<ActivateOption> {
        console.log("inside activate");
        return async (i: ICommunityWorkspace) => {
            try {
                if (i.identity === undefined || !i._identity.isset()) {
                    const data = await WorkspaceManager.getWorkspace(communityId || "")

                    i.identity = new IIdentity(data)

                }
            } catch (error) {
                console.log("we got an error at activate ", error);
                // i.identity = new IIdentity()

            }
        }
    }

    structure(): ICommunityStructure {
        return new ICommunityStructure()
    }
}



