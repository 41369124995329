import { Box, styled } from "@mui/material"
import { grey } from "@mui/material/colors"
import StraclouLogo from "components/StraclouLogo"
import useAuth from "hooks/useAuth"
import useCommunity from "hooks/useCommunity"
import useWorkspace from "hooks/useWorkspace"
import MenuIconComponent from "Pages/HomePage/MenuIconComponent"
import PropertySearchActivity from "Pages/RMS/Widgets/PropertySearchActivity"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const Page = styled(Box)(({
    width: "100vw",
    height: "100vh",
    overflow: "hidden"
}))

const PageContent = styled(Box)(({
    width: "100vw",
    height: "calc(100vh - 50px)",
    marginTop: "50px",
    overflow: "auto"
}))

const Header = styled(Box)(({
    // background: red[200],
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    borderBottom: `1px solid ${grey[200]}`,
    background: "#fff"
}))

export default function ClouMainHeader(){
    const { queryWorkspaceById } = useWorkspace();
    const {user} = useAuth()
    const params = useParams()
    const [workspaceName, setWorkspaceName] = useState<string>("")

    useEffect(()=>{
        let id = params.workspaceId || ""
        queryWorkspaceById(id, ['CommunityName']).then((res: any)=>{
            setWorkspaceName(res?.CommunityName)
        }).catch(error=>{
            console.log("Error unable to get your field from workspace")
        })
    },[params])

    
    return (
        <Header>
            <Box sx={{ height: "50px", width: "auto", padding: "0 0 0 16px", margin: "0 0 0 -16px", borderBottom: `0px solid ${grey[300]}` }}>
                <Box sx={{ padding: " 0 0 0 8px", display: "flex" }}>
                    <StraclouLogo variant="small" />
                    <Box sx={{ml: 2}}>
                    <PropertySearchActivity />
                    </Box>
                </Box>
            </Box>

            <Box sx={{ zIndex: -999999, position: "absolute", background: "0", width: "100%", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                {workspaceName} 
            </Box>

            <Box sx={{ background: "0", position: "fixed", right: "16px", height: "50px" }}>
                <MenuIconComponent />
            </Box>

        </Header>


    )
}