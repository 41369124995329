import { API } from "Straclou/API"


export module TransactionManager {
    export const listPayment = async (input: {accountNumber: string}) => {
        try {
            const data: Array<any> = await API.transactionService("listPayments", input, input.accountNumber) as Array<any>
            // console.log("Result from server: ",data);
            let sorted = data.sort((a, b) => {
                const aDate = new Date(a.transactionDate).getTime()
                const bDate = new Date(b.transactionDate).getTime()
                return bDate - aDate
            })


            return sorted
        } catch (error) {
            console.log("Error on get list of transactions. ", error)
            return []
        }

    }
    export const listInvoice = async (input: {accountId: string}) => {
        try {
            const data: Array<any> = await API.transactionService("listInvoices", input, input.accountId) as Array<any>
            // console.log("Result from server: ",data);
            let sorted = data.sort((a, b) => {
                const aDate = new Date(a.postDate).getTime()
                const bDate = new Date(b.postDate).getTime()
                return bDate - aDate
            })


            return sorted
        } catch (error) {
            console.log("Error on get list of transactions. ", error)
            return []
        }
    }

    export const getAccount = async (accountId: string) => {
        return new Promise(async (resolve, reject) => {
            // const docRef = doc(firestore, `account-${ENV}`, accountId)
            // const docSanpShot = getDoc(docRef)
            
            // const result = (await docSanpShot).data()
            // // console.log("Result: ",result)
            // if(result === undefined){
            //     reject(new IAccount())
            // }else{
            //     resolve(new IAccount(result))
            // }

            

            // onSnapshot((collectionRef), (snapshot: any) => {
            //     let arr: any = []
            //     snapshot.forEach((doc: any) => {
            //         console.log(doc.data())
            //         console.log(doc.id)
            //         arr.push({
            //             id: doc.id,
            //             ...doc.data()
            //         })
            //     })
            // })
        })
    }
}