import { alpha, Box, ButtonBase, TextField } from "@mui/material"
import { theme } from "globals/theme"
import { styled, Button } from "@mui/material"


import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import VillaOutlinedIcon from '@mui/icons-material/VillaOutlined';
import IconButtonSelector from "../../../components/IconButtonSelector";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CommunityStructure, ProgressStep, useOnboardingProgressStore } from "hooks/useOnboardingProgress";
import useAuth from "hooks/useAuth";
import useOnboardingProgressRouter from "hooks/useOnboardingProgressRouter";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({
    width: "60vw",
    height: "80vh"
}))

const InputBox = styled(TextField)(({
    width: "100%",
    fontSize: "1.5em !important",

    "&.MuiOutlinedInput-input": {
        textAlign: "center"
    }

}))



const ButtonItem = styled(ButtonBase)(({
    margin: "0 8px",
    borderRadius: "5%",
    transition: "500ms",
    display: "block",
    padding: "1em !important",
    textAlign: "center",
    cursor: "pointer",

    "&:hover": {
        background: alpha(alpha(theme.palette.primary.main, 0.1), .051),
        color: theme.palette.primary.main
    },
    "&:last-child": {
        // borderBottom: `1px solid ${grey[300]}`,
    }
}))

const ButtonItemTitle = styled(Box)(({
    fontSize: "1.3em",
    padding: "1em 0"
}))



const CommunitySetupStructurePage = () => {

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();
    const { updateProgress, progress } = useOnboardingProgressStore()

    const params = useParams()
    const {user} = useAuth()
    const {goto} = useOnboardingProgressRouter(params?.clientId)

    const { t } = useTranslation()

    const onSelect = (e: string) => {
        console.log(e)

        // we need to add and remove the value
        // check if value exist if yes remove if no add

        let list: Array<CommunityStructure> = [...(progress.CommunityStructure || [])]


        if (list.includes(e as CommunityStructure)) {
            let i = list.indexOf(e as CommunityStructure)
            list.splice(i, 1)
            // updateProgress({
            //     CommunityStructure: list
            // })

            updateProgress({
                id: params.clientId || "",
                step: ProgressStep.Community_Structure,
                uid: user.uid,
                data: { CommunityStructure: list },
            }).then(res => {
                console.log(res)
                // navigate(`/client/${params.clientId}/community_strata_number`)
            })



        } else {

            updateProgress({
                id: params.clientId || "",
                step: ProgressStep.Community_Structure,
                uid: user.uid,
                data: { CommunityStructure: [...(progress?.CommunityStructure || []), e as CommunityStructure] },
            }).then(res => {
                console.log(res)
                // navigate(`/client/${params.clientId}/community_strata_number`)
            })

        }

    }


    


    const onSubmit = (e: any) => {
        goto(ProgressStep.Community_Address)
    }

    const getSelected = (option: CommunityStructure) => {
        if (progress.CommunityStructure !== undefined) {
            let i = progress.CommunityStructure.indexOf(option)
            return i === -1 ? false : true
        }
        return false
    }
    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ textAlign: "center", fontSize: '2.3em', fontWeight: "600", margin: "30px 0 30px 0", color: theme.palette.primary.main }}>
                {t("community_structure.title")}
                </Box>
                <Box sx={{ textAlign: "center", margin: "0 0 30px 0", lineHeight: "2em" }}>
                {t("community_structure.subTitle")}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <ButtonItem onClick={() => onSelect('Apartments')}>
                        <IconButtonSelector
                            icon={<ApartmentOutlinedIcon />}
                            defaultValue={getSelected("Apartments")}

                        />
                        <ButtonItemTitle >Apartments</ButtonItemTitle>
                    </ButtonItem>
                    <ButtonItem onClick={() => onSelect('Condominiums')}>
                        <IconButtonSelector icon={<CorporateFareOutlinedIcon />} defaultValue={getSelected("Condominiums")} />
                        <ButtonItemTitle >Condominiums</ButtonItemTitle>
                        {/* <Box>Manage a single community and all its amenities</Box> */}
                    </ButtonItem>
                    <ButtonItem onClick={() => onSelect('Single Family Homes')}>
                        <IconButtonSelector icon={<HouseOutlinedIcon />} defaultValue={getSelected("Single Family Homes")} />
                        <ButtonItemTitle >Single Family Homes</ButtonItemTitle>
                        {/* <Box>Manage a single community and all its amenities</Box> */}
                    </ButtonItem>
                    <ButtonItem onClick={() => onSelect('Townhouses')}>
                        <IconButtonSelector icon={<MapsHomeWorkOutlinedIcon />} defaultValue={getSelected("Townhouses")} />
                        <ButtonItemTitle >Townhouses</ButtonItemTitle>
                        {/* <Box>Manage a single community and all its amenities</Box> */}
                    </ButtonItem>
                    <ButtonItem onClick={() => onSelect('Villas')}>
                        <IconButtonSelector icon={<VillaOutlinedIcon />} defaultValue={getSelected("Villas")} />
                        <ButtonItemTitle>Villas</ButtonItemTitle>
                        {/* <Box>Manage a single community and all its amenities</Box> */}
                    </ButtonItem>
                </Box>



                <Box sx={{ width: "300px", margin: "30px auto" }}>
                    <Button onClick={onSubmit} variant="contained" fullWidth sx={{ height: "55 px", fontSize: "1.6em" }}>Next</Button>
                </Box>
            </form>
        </Wrapper>
    )
}

export default CommunitySetupStructurePage

function updateProgress(arg0: { workspaceId: any; step: any; data: { CommunityStructure: CommunityStructure[]; }; }) {
    throw new Error("Function not implemented.");
}
