import { styled, Box } from "@mui/system";

const ClouDisplayItem = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "120px auto",
    gridTemplateRows: "1fr",
    gap: "0 0",
    minHeight: "28px",
    margin: "1px 0",
    fontSize: ".8em",
}));


export default ClouDisplayItem