import './App.css';
import { useRoutes } from "react-router-dom";
import useAppRoutes from './useAppRoutes';
// import { RouterGuard } from 'react-router-guard'

function App() {

  const [routes] = useAppRoutes()
  const element = useRoutes(routes);

  return (
    <div className="App">
     {element}
    </div>
  );
}

export default App;
