import { Helper } from "globals/helper";
import useAuth from "hooks/useAuth";
import useCommunityWorkspaceStore from "hooks/useCommunityWorkspaceStore";
import LoadingActivity from "Pages/Onboarding/Landing/LoadingActivity";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { activeUser, userHandler } from "Straclou";



export default function ApplicationLoaderActivity() {

    const {setWorkspace} = useCommunityWorkspaceStore()
    const navigate = useNavigate()
    useEffect(() => {
        /**
         * Get the active user and validate the View
         * If no user then navigate to login || home
         */
        userHandler.getCurrentUserCredentials().then(user => {
            if (user === undefined) {
                navigate("/")
            }
            else {
                // navigate("/landing")
                // Now that the user is logged in lets check user for a community and user type to navigate them to
                console.log(user)
                if (user.isWorkspace) {
                    console.log(user?.communities?.[0])
                    setWorkspace(user?.communities?.[0])
                    navigate(`/landing/workspace/${user?.communities?.[0]}`)
                }
                else {
                    // We need to send the user to the 
                    navigate("/landing/create_workspace")
                }

            }
        })



    }, [])
    return (
        <>
            <LoadingActivity />
        </>
    )
}

const PageMatrix: any = {
    3201761: "/RMS"
}