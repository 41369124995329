import { ICollection } from "./ICollection"

export class IFeeCategory {
    private _data: Array<any> = []
    private _name: string = ''


    constructor(args?: any) {
        if (args !== undefined) {
            if (Array.isArray(args)) {

                this._data = args.map((el: any) => {
                    el['name'] = el?.name === undefined ? "----" : el.name.length === 0 ? "----" : el.name
                    this._name = el?.category
                    return el
                })
            }
        }
    }

    get count() { return 0 }
    get itemDescription() {
        let len = this._data.length
        return len > 0 ? `${len} item${len > 1 ? 's' : ''}` : 'No item'
    }
    get collection() { return new ICollection(this._data) }
    get name(){return this._name}
}