import { styled, Box } from "@mui/material"
import Row from "components/Row"



const Wrapper = styled(Box, { name: "edit-outline-frame-wrapper" })({
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "87%",
    fontSize: "16pt",
    flexDirection: "column",
    // background: 'red'


})

export default function EditOutlineFrame(props: any) {
    const { children, button } = props
    return (
        <Wrapper>
            {children}
            <Row right sx={{ position: "absolute", bottom: "32px", right: "52px" }}>
                {button}
            </Row>
        </Wrapper>
    )
}