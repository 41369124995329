import { Box, Dialog, DialogTitle, Divider, IconButton, ListItem, Paper, Tooltip } from "@mui/material"
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Transition from "globals/Transition";
import React, { ReactNode, useContext, useEffect, useState } from "react";

import CloseIcon from '@mui/icons-material/Close';
import DialogFrame from "./DialogFrame";
import AccountServiceProvider from "./Context/AccountServiceContext";

import Generator from "./Generator";
import FinancialForm from "./FinancialForm";
import CompleteFrame from "./CompletedFrame";

import NewAccountSummaryFrame from "./Summary/NewAccountSummaryFrame";
import useSummaryStore from "./Summary/SummaryStore";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { IWidgetBuilderRoute, WidgetBuilder, WidgetBuilderContext, WidgetBuilderOutlet } from "@knymbus/react-widget-builder";
import { v4 } from "uuid";
import { createRoot } from "react-dom/client";
import ReactDOM from "react-dom";
import ClouItemTitle from "components/ClouItemTitle";
import { Title } from "components/Title";
import GeneratorProperty from "./GeneratorProperty";
import WelcomeFrame from "./WelcomeFrame";




const AddNewAccountMain: React.FC = () => {


    const { clearSummary } = useSummaryStore()
    const { clearStore } = useWorkspaceStore()

    const [NewAccountWidget, setNewAccountWidget] = useState<WidgetBuilder>(new WidgetBuilder({
        name: "New Account",
        routes: []
    }))

    // get the onClose function for the parent fragment to pass to the grand children nodes
    const { goBack } = useContext(WidgetBuilderContext)



    const handleClose = () => {
        clearSummary()
        clearStore()
        goBack()
    };


    /**
     * routes for dialog view
     */
    const routes: IWidgetBuilderRoute[] = [
        {
            index: true,
            path: "welcome",
            window: <WelcomeFrame key={v4()} onClose={handleClose} />,
        },
        {
            
            path: "general",
            window: <Generator key={v4()} onClose={handleClose} />,
            transition: true
        },
        {
            
            path: "property",
            window: <GeneratorProperty key={v4()} onClose={handleClose} />,
            transition: true
        },
        {

            path: "financial",
            window: <FinancialForm key={v4()} onClose={handleClose} />,
            transition: true
        },
        {
            path: "complete",
            window: <CompleteFrame key={v4()} onClose={handleClose} />,
            transition: true
        },
        {
            path: "account_summary",
            window: <NewAccountSummaryFrame key={v4()} onClose={handleClose} />,
            transition: true
        }

    ]

    useEffect(() => {
        setNewAccountWidget(new WidgetBuilder({
            name: "New Account",
            routes: routes
        }))
    }, [])


    return (
        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
            <Box sx={{
                width: '100%',
                // boxShadow: "-2px 3px 11px #cbcbcb",

            }}>
                <WidgetBuilderOutlet builder={NewAccountWidget} />
            </Box>
            {/* <Box sx={{ 
                p: 2, 
                display: "flex", 
            justifyContent: "center", 
            alignItems: "center",
            width: "57%"
            }}>
                <Box>
                    <Title variant="3" sx={{fontWeight: "bold"}}>Straclou</Title>
                    <ListItem sx={{flexDirection: "column", alignItems: "flex-start"}}>
                        <Title variant="6" sx={{fontWeight: "500"}}>1. Get up and ready in no time</Title>
                        <Box>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet aliquid nesciunt dolorum veritatis voluptate at impedit saepe corrupti, labore quam tenetur recusandae necessitatibus natus ab fugiat tempora minus, quos voluptatum.</Box>
                    </ListItem>
                    <ListItem sx={{flexDirection: "column", alignItems: "flex-start"}}>
                        <Title variant="6" sx={{fontWeight: "500"}}>2. Send receipt by email and text </Title>
                        <Box>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet aliquid nesciunt dolorum veritatis voluptate at impedit saepe corrupti, labore quam tenetur recusandae necessitatibus natus ab fugiat tempora minus, quos voluptatum.</Box>
                    </ListItem>
                    <ListItem sx={{flexDirection: "column", alignItems: "flex-start"}}>
                        <Title variant="6" sx={{fontWeight: "500"}}>3. connect with local business and receive discount </Title>
                        <Box>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet aliquid nesciunt dolorum veritatis voluptate at impedit saepe corrupti, labore quam tenetur recusandae necessitatibus natus ab fugiat tempora minus, quos voluptatum.</Box>
                    </ListItem>
                    
                </Box>
            </Box> */}
        </Box>

    )
}

export default AddNewAccountMain