import { IAccount } from 'Straclou/commons/';
import {create} from 'zustand';



type ActiveAccountStore = {
    account: IAccount,
    update: (account: IAccount) => void
}

export const useActiveAccount = create<ActiveAccountStore>((set: any)=>({
    account: new IAccount(),
    update: (account: IAccount) => set((state: any) => ({account: account}))
}))