import { API, CreateCategoryFeeInput, ListFeeByCategoryInput, ServiceName } from "Straclou/API";
import { axiosExecute, AxiosOptions } from "Straclou/axios"
import { ICollection } from "Straclou/commons"
import { IWorkspace } from "Straclou/commons/IWorkspace"
import { ConnectionStrings } from "Straclou/ConnectionStrings"



export interface CreateCommunityWorkSpaceResponse {
    uid: string;
    workspaceId: string // 9 Character with Letters and Numbers mix
}

export interface IUpdateWorkspaceRequest {
    id: string
    data: any
}

export interface QueryParams {
    input: QueryParamsInput,
    method: "dynamicQuery" | "listByOwner" | "getById" | "update" | "init"
}

export interface QueryParamsInput {
    workspaceId: string
    responseField: string[]
}

export interface WorkspaceIdentityResponse{
    isDeletable: false,
    activeState:string
    createdAt:string
    communityId:string
    itemId:string
    sologon:string
    name:string
    type:string
}


export module WorkspaceManager {

    export async function getWorkspace(communityId: string): Promise<WorkspaceIdentityResponse> {
        return new Promise(async (resolve, reject) => {
            if (communityId === undefined) { reject(null) }

            // Query for the workspace
            const result  = await API.straclouql("GetCommunityIdentity", {communityId}, ServiceName.community) as WorkspaceIdentityResponse
            resolve(result)
        })
    }
    export async function getWorkspaceV2(communityId: string): Promise<WorkspaceIdentityResponse> {
        return new Promise(async (resolve, reject) => {
            if (communityId === undefined) { reject(null) }

            // Query for the workspace
            const result  = await API.straclouql("gci", {j: communityId}, ServiceName.community) as WorkspaceIdentityResponse
            resolve(result)
        })
    }

    /**
     * Get the workspace list of the uid passed in.
     * @param uid 
     * @returns IWorkspace[]
     */
    export async function WorkspaceList(uid?: string): Promise<Array<IWorkspace>> {
        return new Promise(async (resolve, reject) => {
            try {
                if (uid === undefined) throw ("uid is not set, please ensure uid is defined.")

                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.CommunityConnection(),
                    data: JSON.stringify({
                        input: {
                            userId: uid
                        },
                        method: 'listByOwner'
                    })

                }

                // console.log("Community List by owner option, ", option)
                const data = await axiosExecute(option) as Array<any>
                // console.log(data)
                // assign data to our UI interface class
                let iWorkspace: IWorkspace[] = ICollection.assign<IWorkspace>(data, c => (new IWorkspace(c)))

                // console.log(iWorkspace)
                resolve(iWorkspace)

            } catch (error) {

                console.log("something went wrong when trying to update workspace: ", error)
                reject(false)

            }
        })
    }

    export async function query(queryParams: QueryParams) {
        return new Promise(async (resolve, reject) => {
            try {
                if (queryParams.input.workspaceId === undefined) throw new Error("workspace id not found, please ensure id is present.");

                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.CommunityConnection(),
                    data: JSON.stringify(queryParams)
                }

                const data = await axiosExecute(option) as Array<any>

                resolve(data)

            } catch (error) {
                console.log(error)
                reject({})
            }
        })
    }


    // Handle the Community/workspace setup update
    export async function update(payload: IUpdateWorkspaceRequest) {
        return new Promise(async (resolve, reject) => {
            try {
                let option: AxiosOptions = {
                    method: "POST",
                    url: ConnectionStrings.CommunityConnection(),
                    data: JSON.stringify({
                        input: {
                            ...payload
                        },
                        method: 'update'
                    })

                }
                const data = await axiosExecute(option) as CreateCommunityWorkSpaceResponse
                resolve(data)

            } catch (error) {

                console.log("something went wrong when trying to update workspace: ", error)
                reject(false)

            }
        })
    }


    export const listFeeByCategory = async (listFeeByCategoryInput: ListFeeByCategoryInput) => {
        try {
            const result: any = await API.straclouql("listFeeByCategory", listFeeByCategoryInput, ServiceName.community)
            return result
        } catch (error) {
            console.log(error)
            return []
        }
    }


    export const getFeeCountByCategory = async (getFeeCountByCategoryInput: ListFeeByCategoryInput) => {
        try {
            const result: any = await API.straclouql("getFeeCountByCategory", getFeeCountByCategoryInput, ServiceName.community)
            return result
        } catch (error) {
            console.log(error)
            return null
        }
    }


    export const addFee = async (createCategoryFeeInput: CreateCategoryFeeInput) => {
        try {
            const result: any = await API.straclouql("addFee", createCategoryFeeInput, ServiceName.community)
            return result
        } catch (error) {
            console.log(error)
            return null
        }
    }
}