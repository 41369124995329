import { Box, styled } from "@mui/material";

export const UIWrapper = styled(Box, {name: "UI-Wrapper"})(({theme})=>({
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    height: 'calc(100vh - 70px)',
    background: `linear-gradient(to bottom right, ${theme.palette.primary.light},${theme.palette.common.white},${theme.palette.secondary.main})`,
    

}))
export const UIContent = styled(Box, {name: "UI-Content"})({
    minWidth: "320px",
    width:"500px",
    display: "flex",
    flexDirection:"column",
    alignItems:"center",
    background: "#fff",
    padding: "16px"
})

export const UIFormWrapper = styled(Box,{name: 'UI-Form_Wrapper'})({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
})