import { styled, Box } from "@mui/material"


const FloatingText = styled(Box)(({
    position: "absolute",
    top: "32px",
    right: "32px"

}))

export default FloatingText