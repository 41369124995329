import { create } from "zustand"
import { persist } from "zustand/middleware"
import { IUser } from "Straclou/commons/IUser"
import {
    CheckEmailResponse,
    EmailVerificationCodeResponse,
    IIUser,
    SigninCredentials,
    SignupCredentials,
    userHandler,
    VerifyEmailCodeResponse
} from "Straclou/handler"


type UserKeys = "email" | "name"

const UserKeyMap: any = {
    email: 'email',
    name: 'name'
}





interface UserStore {
    user: IUser,
    updateUser: (user: IUser) => void
    logout: () => Promise<any>,
    signIn: (signinCredentials: SigninCredentials) => Promise<IUser>,
    signup: (signupCredentials: SignupCredentials) => Promise<any>,
    checkEmail: (email: string) => Promise<CheckEmailResponse>,
    verifyCode: (code: Array<string>, email: string) => Promise<VerifyEmailCodeResponse>,
    get: (key: UserKeys) => any,
    sendVerifyEmailCode: (email: string) => Promise<EmailVerificationCodeResponse>

}

let userStore: any = (set: any) => ({
    user: new IUser(),
    updateUser: (user: IUser) => set(() => {
        console.log(user)
        return {
            user: user
        }
    }),
    logout: userHandler.signout,
    signIn: userHandler.signin,
    signup: userHandler.signup,
    checkEmail: userHandler.checkEmail,
    verifyCode: userHandler.verifyCode,
    sendVerifyEmailCode: userHandler.sendVerifyEmailCode,
    get: (key: UserKeys) => {
        return UserKeyMap[key] || null
    }
})

userStore = persist(userStore, { name: userHandler.userTag })

const useAuth = create<UserStore>(userStore)

export default useAuth
