import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Route } from "./interfaces";


interface IProps {
    children: ReactNode;
    Routes?: Route[]
}

interface IRouterContext {
    activeRoute: Route | null;
    routeParams: any;
    setPath: Function;
    path: string[];
    assignRoutes: Function;
    Routes: Route[];
}
export const RouterContext = createContext<IRouterContext>({
    activeRoute: null,
    path: [],
    routeParams: {},
    setPath: (e: []) => { },
    assignRoutes: (e: []) => { },
    Routes: []


})

export const useInternalRouter = ()=>{
    const context = useContext(RouterContext)
    return {
        Routes: context.assignRoutes
    }
}

const InternalRouter: React.FC<IProps> = ({ children }) => {

    const [path, setPath] = useState<[]>([])
    const [activeRoute, setActiveRoute] = useState<Route | null>(null)
    const [routeParams, setRouteParams] = useState<any>()
    const [Routes, assignRoutes] = useState<Route[]>([])
    




    const Router = (path?: Array<any>) => {
        // console.log("Inside Router: ",path)
        if (path === undefined) return Routes === undefined ? null : Routes[0]

        const index: number = Routes?.findIndex(el => el.path === path[0]) || -1

        if (index === -1) return Routes === undefined ? null : Routes[0]

        setRouteParams(path[0])


        if (Routes !== undefined && Routes !== null) {
            let r: Route = Routes[index]
            if(r.hasOwnProperty("children")){
                // console.log(r?.children)
            }
            r.isActive = true
            return r
        }
        return Routes[0]
    }

    useEffect(() => {
        // console.log(path)
        setActiveRoute(Router(path))
    }, [path, Routes])


    return (
        <RouterContext.Provider value={{
            setPath,
            path,
            activeRoute,
            routeParams,
            assignRoutes,
            Routes

        }}>
            {children}
        </RouterContext.Provider>
    )
}

export default InternalRouter