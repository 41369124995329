import ENV from "./ENV"

/**
 * We are no longer using these strings to connect to our servics
 * @method API.straclou(method, input) use this method instead
 * @argument method - name of the method you wish to call
 * @argument input - the input object of that method
 * @deprecated
 */
export module ConnectionStrings {

    export const property = "https://xyvppqooed.execute-api.us-east-1.amazonaws.com/Dev/property/"

    /**
     * Please use accountUrl instead
     * @deprecated
     */
    export const account = "https://xyvppqooed.execute-api.us-east-1.amazonaws.com/Dev/account/"

    export const accountConnection = () => `https://l0bxpl56zc.execute-api.us-east-1.amazonaws.com/${ENV}`

    /**
     * User connection string
     * This is the address for the User Management API
     * @returns string | user URI
     */
    export const userConnection = () => `https://t2kbcroxx0.execute-api.us-east-1.amazonaws.com/${ENV}`

    /**
     * Community connection string 
     * This is the address for the Community Management API
     * @returns string | community URI
     */
    export const CommunityConnection = () => `https://xyvppqooed.execute-api.us-east-1.amazonaws.com/${ENV}/community/`

    export const PAYMENTCONNECTION = "https://xyvppqooed.execute-api.us-east-1.amazonaws.com/Dev/payment"
}