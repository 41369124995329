import { styled, Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import AppCard from "./AppCard";

const Wrapper = styled(Box)({
    background: grey[300],
    padding: "16px",
    margin: "16px",
    borderRadius: "8px"

})
export default function AppCarousel() {
    return (
        <Wrapper>
            <AppCard />
        </Wrapper>
    )
}