import { AccountConnectionString, AccountMethod, AddressMethod, APIMethods, APIROUTESPATHNAME, BASEURL, BASEURL_ADDRESS, CommentaryConnectionString, CommentaryMethod, CommunityConnectionString, TransactionConnectionString, TransactionMethod } from "./APIMethodRoutes"
import { axiosExecute, AxiosOptions } from "./axios"


/**
     * This interface is mapped to the list accounts query 
     * @field count 
     * @description return the total count of the current items
     */
export interface ListAccountResponse<T = any> {
    /**
     * @description
     * return the total count of the current items
     */
    count: number
    /**
     * limited list of items found base on limit requested
     */
    items: Array<T>
    /**
     * return the total count of all items found from query
     */
    totalCount: number
    /**
     * return an array of number listing all the pages of the request
     */
    pages: Array<number>
    /**
     * Tell us wheather there is a next page to request or not
     */
    next: boolean
    /**
     * Tell us the current page 
     */
    page: number
    /**
     * return the page limit of the request 
     * @default 50
     */
    limit: number
    /**
     * Tell the starting position of the data
     */
    start: number
    /**
     * Tell at what position in the dataset has ended
     */
    end: number
}

export interface GetAccountInput {
    id: string;
    uid?: string;
}
export interface ListAccountInput {
    cid: string
    nextPage?: number
    limit?: number
}
export interface CreateAccountInput {
    name: string
    lotId: string
    propertyType: string
    fee: number
    cid: string
}

export interface AccountQueryInput{
    propertyType?: string
    lotId?: string
    fee?: number
    cid?: string
    authorId?: string
    authorName?: string

}

export interface AccountManagerQueryResult{
    Count: number,
    Items: Array<any>
}


export interface AccountManagerQueryErrorResult{
    error: boolean,
    message: any
}
export interface CreateCommentaryInput {
    accountId: string
    author: string
    htmlText: string
    rawText: string
    timestamp: string
    uid: string
    communityId: string
}

export interface ListCommentaryInput {
    accountId: string;
    communityId?: string;
}

export enum ServiceName {
    account = 'account',
    commentary = 'commentary',
    address = 'address',
    transaction = 'transaction',
    community = 'community'
}

export interface ListFeeByCategoryInput{
    communityId: string
    category: string
}

export interface CreateCategoryFeeInput{
    communityId: string
    category: string
    size: number
    fee: number
    name?: string
    author: string
    authorId: string

}




export module API {

    export const straclouql = async (method: APIMethods, input: any, service?: ServiceName) => {
        // console.log(">>>>>>> request from caller: <<<<<<<<", { method, input, service })
        switch (service) {
            case "account": {
                return await straclouqlAccount(method, input)
            }
            case "commentary": {
                return await commentaryService(method, input)
            }
            case "address": {
                return await straclouqlAddress(method, input)
            }
            case 'transaction': {
                return await transactionService(method, input)
            }
            case 'community': {
                return await communityService(method, input)
            }

            default:
                return await generalService(method, input)
        }

    }


    const generalService = async (method: APIMethods, input: any) => {
        return new Promise(async (resolve, reject) => {
            try {

                // const { data }: any = await API.graphql(getAccount, { input: {id: accountId, communityId: communityId} }))
                let option: AxiosOptions = {
                    method: "POST",
                    url: BASEURL,
                    data: JSON.stringify({
                        input: input,
                        method: method
                    })

                }
                const data: any = await axiosExecute(option)

                resolve(data)


            } catch (error) {
                console.log("something went wrong when try to : ", method, JSON.stringify(error, null, 6));
                reject(null)
            }
        })
    }


    export const straclouqlAddress = async (method: APIMethods, input: any) => {
        return new Promise(async (resolve, reject) => {
            try {

                // const { data }: any = await API.graphql(getAccount, { input: {id: accountId, communityId: communityId} }))
                let option: AxiosOptions = {
                    method: "POST",
                    url: BASEURL_ADDRESS,
                    data: JSON.stringify({
                        input: input,
                        method: method
                    })

                }
                const data: any = await axiosExecute(option)

                resolve(data)


            } catch (error) {
                console.log("something went wrong when try to : ", method, JSON.stringify(error, null, 6));
                reject(null)
            }
        })
    }

    export const straclouqlAccount = async (method: APIMethods, input: any, params?: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let connect = AccountConnectionString(method, params)
                let option: AxiosOptions = {
                    method: connect.method,
                    url: connect.url(),
                    data: JSON.stringify(input)

                }

                const data: any = await axiosExecute(option)

                resolve(data)
            } catch (error) {
                console.log("something went wrong when try to perform your operation at << Account Service >> : ", method, error);
                reject(null)
            }
        })
    }


    export const commentaryService = async (method: APIMethods, input: any, params?: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let connect = CommentaryConnectionString(method, params)
                let option: AxiosOptions = {
                    method: connect.method,
                    url: connect.url(),
                    data: JSON.stringify(input)

                }

                const data: any = await axiosExecute(option)

                resolve(data)
            } catch (error) {
                console.log("something went wrong when try to perform your operation at commentary service : ", method, error);
                reject(null)
            }
        })
    }

    export const transactionService = async (method: APIMethods, input: any, params?: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let connect = TransactionConnectionString(method, params)
                let option: AxiosOptions = {
                    method: connect.method,
                    url: connect.url(),
                    data: JSON.stringify(input)

                }

                const data: any = await axiosExecute(option)

                resolve(data)
            } catch (error) {
                console.log("something went wrong when try to perform your operation at commentary service : ", method, error);
                reject(null)
            }
        })
    }


    export const communityService = async (method: APIMethods, input: any, params?: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let connect = CommunityConnectionString(method, params)
                let option: AxiosOptions = {
                    method: connect.method,
                    url: connect.url(),
                    data: JSON.stringify({method,input})

                }

                const data: any = await axiosExecute(option)

                resolve(data)
            } catch (error) {
                console.log("something went wrong when try to perform your operation at community service : ", method, error);
                reject(null)
            }
        })
    }


}