import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, styled, Snackbar, Alert, Divider } from "@mui/material";
import ClouItemTitle from "components/ClouItemTitle";
import ClouHeader from "components/headers/ClouHeader";


import { theme } from "globals/theme";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { useParams } from "react-router-dom";
import { ChangeDetectionManager } from "Straclou";
import { ButtonController, IMessageControl } from "./component/ButtonController";
import { Identity } from "./interface";
import HomeIcon from '@mui/icons-material/Home';
import { grey } from "@mui/material/colors";
import UsageCapacity from "./component/UsageCapacity";
import Row from "components/Row";


const Container = styled(Box, { name: "Container" })({
    display: "grid",
    alignItems: "center",

    gridTemplateColumns: `90px 1fr 1fr 1fr`,
    gridTemplateRows: `60px 1fr 200px`,
    gap: "0px 10px",
    gridTemplateAreas: `
    "sh_name c_name c_name c_name"
    "blanco s_number s_number s_number"
    "c_capacity c_capacity c_capacity c_capacity"`,

    "& .sh_name": { gridArea: "sh_name" },
    "& .c_name": { gridArea: "c_name", width: "100%" },
    "& .s_number": { gridArea: "s_number" },
    "& .c_capacity": { gridArea: "c_capacity", height: "200px" },
    "& .blanco": { gridArea: "blanco" }

})

const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))



export default function CommunityGeneral(IProps: {onClose?: Function}) {

    const {onClose} = IProps

    const { workspace, updateWorkspace } = useWorkspaceStore()
    const { navigateBack } = useWidgetBuilderNavigation()
    const { queryWorkspaceById, updateWorkspaceById } = useWorkspace()
    const [identity, setIdentity] = useState<Identity | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const params = useParams()

    const [message, setMessage] = useState<IMessageControl>({
        open: false,
        message: "",
        type: "info"
    });


    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();


    const onSubmit = (e: any) => {
        setIsLoading(true)
        ChangeDetectionManager.check(identity, {
            CommunityName: e.name,
            CommunityStrataNumber: e.number
        }).then(res => {
            console.log("Value of change Detected: ", res)
            if (res.isChange) {
                updateWorkspaceById(workspace.workspaceId || "", res.change).then((updateRes: any) => {
                    console.log("data from update: ", updateRes);

                    updateWorkspace(updateRes?.Attributes)
                    const newData: any = {}
                    Object.keys(updateRes?.Attributes).forEach(key => {
                        if (key === "CommunityStrataNumber" || key === "CommunityName") {
                            newData[key] = updateRes?.Attributes[key]
                        }
                    })
                    setIdentity({ ...identity, ...newData })

                    setIsLoading(false)


                    setMessage({
                        open: true,
                        message: "data saved .... ",
                        type: "success"
                    })
                })
            } else {
                setMessage({
                    open: true,
                    message: "No change detected, nothing to save!",
                    type: "info"
                })

                setIsLoading(false)
            }




        })
    }



    useEffect(() => {
        
        setIdentity({
            CommunityName: workspace.name,
            CommunityStrataNumber: workspace.strataNumber
        })

        reset({
            name: workspace?.name,
            number: workspace.strataNumber
        })

    }, [workspace])






    return (
        <Box sx={{ pr: 3, height: "calc(100vh - 90px)", width: "350px" }}>
            {/* <ClouItemTitle sx={{mb: 2}}>Community Identity</ClouItemTitle> */}

            <form onSubmit={handleSubmit(onSubmit)}>


                {/* Community Name */}

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    position: "relative"
                    // mt: 3
                }}>


                    <Box sx={{ mb: 4, width: "100%" }}>

                        <InputLabel>Community Name</InputLabel>
                        <Controller
                            name={"name"}
                            control={control}
                            defaultValue={identity?.CommunityName || ""}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register("name", {
                                        required: "Community name is required", minLength: {
                                            value: 3,
                                            message: "Length of community name have to be greater than 3 characters"
                                        }
                                    })}
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    placeholder="Official Community Name"
                                    inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                    sx={{ width: "100%" }} />
                            )}
                        />
                    </Box>

                </Box>

                {/* Community Strata Number */}
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    position: "relative"
                    // mt: 3
                }}>


                    <Box sx={{ mb: 3, width: "100%" }}>

                        <InputLabel>Community Name</InputLabel>
                        <Controller
                            name={"number"}
                            control={control}
                            defaultValue={identity?.CommunityStrataNumber || ""}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register("number")}
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    placeholder="Official Community Strata Id"
                                    inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                    sx={{ width: "100%" }} />
                            )}
                        />
                    </Box>

                </Box>


                <Box>
                    <ButtonController onCancel={()=>{onClose?.()}} isLoading={isLoading} message={message} setMessage={setMessage} />
                </Box>

            </form>
        </Box>
    )
}