import { styled, Box, Stack, Button } from "@mui/material"
import logo from "../../../logo.svg"
import { useNavigate } from "react-router-dom"
import FloatingText from "components/FloatingText"
import WorkSpaceList from "./WorkSpaceList"
import { grey } from "@mui/material/colors"
import { useEffect, useState } from "react"
import FooterActivity from "./FooterActivity"
import { NavigationLink } from "../Widgets/NavigationLink"
import { FooterPlaceholder } from "Pages/Authenication/Widget"
import { useTranslation } from "react-i18next"
import LandingPageWrapper from "./LandingPageWrapper"
import useLocalStorage, { LocalStorageParams } from "hooks/useLocalStorage"
import { AccountInformation, ApplicationViewer, TeamMembers } from "Features/Dashboard"
import UserMenu from "Features/UserProfileService/Menu"



const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(({
    width: "100%",
    height: "100%",
    margin: "0 0 0 0",
    background: grey[50]
    // overflowY: "auto"
    // ...CenterItem
}))

const Content = styled(Box)(({
    minWidth: "90%",
    ...CenterItem,
    flexDirection: "column"
}))

const Logo = styled(Box)(({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px"
}))

const Instruction = styled(Box)(({
    ...CenterItem,
    fontSize: "1.4em",
    fontWeight: 900
}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))





const InfoBox = styled(Box)(({ theme }) => ({
    textAlign: "center",
    fontSize: "10pt",
    fontWeight: 400,
    // position: "absolute",
    // bottom: "10px",
    display: "flex",
    justifyContent: 'space-evenly',
    border: `1px solid ${grey[200]}`,
    height: "90px",
    padding: "16px",
    margin: "30px 0"
}))

export default function WorkSpacesActivity() {

    const navigate = useNavigate()
    const { t } = useTranslation()


    return (
        <Wrapper>
                {/* // <LandingPageWrapper> */}
                            {/* < Box sx={{width:"100vw"}}>
                                <UserMenu />
                            </Box> */}
                <Content>
                    <Stack gap={2}>
                        {/* <Instruction>{t("WorkSpacesActivity.title")}</Instruction> */}
                        <Box sx={{ ...CenterItem, margin: "0px 0 0 0", fontSize: "12pt" }}>
                            {t("WorkSpacesActivity.subtitle")}
                        </Box>
                        <Box>
                            <AccountInformation />
                        </Box>

                        <ApplicationViewer />

                        <TeamMembers />

                        <InfoBox sx={{ height: "auto", ...CenterItem }}>
                            {/* Here we do the forget password */}
                            {/* <Box sx={{ fontSize: "11pt", marginRight: "8px" }}>
                                {t("WorkSpacesActivity.call_to_action.description")} </Box>
                            <Button variant="contained" onClick={() => { navigate("/landing/create_workspaces") }}>{t("WorkSpacesActivity.call_to_action.button")}</Button> */}
                        </InfoBox>

                        <Box sx={{ height: "auto", ...CenterItem, flexDirection: "column", mb: 2 }}>

                            {/* <Box sx={{ margin: "8px 0", fontSize: "10pt" }}>
                                {t("WorkSpacesActivity.link.question")}
                            </Box>
                            <NavigationLink fontSize={'10pt'}>{t("WorkSpacesActivity.link.different_email")}</NavigationLink> */}
                        </Box>


                    </Stack>


                    <FooterActivity />

                </Content>
        {/* // </LandingPageWrapper> */}
            </Wrapper>
    )
}

