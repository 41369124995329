
import { Feed } from "@mui/icons-material"
import { Subject } from "rxjs"
import { WorkspaceManager } from "Straclou"
import { ListFeeByCategoryInput } from "Straclou/API"
import { IFeeCategory } from "Straclou/commons/IFeeCategory"
import { create } from "zustand"

/**
 * subject for fee listing
 */
const subject = new Subject()
/**
 * subject for fee count
 */
const subjectCount = new Subject()

const initialState = {
    feeDirectory: new IFeeCategory(),
    newDataCount: 0
}

const initialFeeCountState:any = {
    Apartments: {number: -1, message: 'No items', isCount: false},
    'Single Family Homes': {number: -1, message: 'No items', isCount: false},
    Condominiums: {number: -1, message: 'No items', isCount: false},
    Townhouses: {number: -1, message: 'No items', isCount: false},
    Villas: {number: -1, message: 'No items', isCount: false}
}


let state = initialState

let countState = initialFeeCountState

// TODO
// set up a listener that will check for changes on the server

const useCategoryFeeStore = {
    initFeeCount: (payload : ListFeeByCategoryInput)=>{
        WorkspaceManager.getFeeCountByCategory(payload).then(res=>{
            countState[payload.category] = {
                ...res,
                isCount: res.number > 0 ? true : false
            }
            countState = {
                ...countState
            }
            subjectCount.next(countState)
        })
    },
    init: (cid: string, cat: string) => {
        WorkspaceManager.listFeeByCategory({
            communityId: cid,
            category: cat
        }).then(fee=>{
            state.feeDirectory = new IFeeCategory(fee)
            state = { ...state, newDataCount: 0 }
            subject.next(state)

        })
    },
    subscribeCount: (setState: any) => subjectCount.subscribe(setState),
    subscribe: (setState: any) => subject.subscribe(setState),
    AddFee: async (fee: any): Promise<any> => {
        return new Promise((resolve, reject) => {
            // Add to the database as well
            WorkspaceManager.addFee(fee).then((res: any) => {
                state.feeDirectory.collection.update(res)
                state = {
                    ...state,
                    newDataCount: state.newDataCount + 1
                };

                

                subject.next(state)

                resolve(res)
            })

        })


    },
    initialState,
    initialFeeCountState

}

// {
//     Apartments: number,
//     Condominiums: number,
//     'Single Family Homes': number,
//     Townhouses: number,
//     Villas: number,
// }
// interface CategoryFeeStore {
//     feeDirectory: IFeeCategory,
//     feeCounter: any
//     feeWatcher: Observable<string>
//     clearStore: () => void
//     getCategoryFeeList: (type: string) => IFeeCategory | null
//     updateFeeDirectory: (ifc: any) => void
// }

// let categoryFeeStore: any = (set: any) => ({
//     feeDirectory: new IFeeCategory(),
//     feeCounter: {
//         Apartments: 0,
//         Condominiums: 0,
//         'Single Family Homes': 0,
//         Townhouses: 0,
//         Villas: 0,
//     },
//     feeWatcher: (ifc: any) => {
//         return new Observable((subscribe) => {
//             subscribe.next(ifc)
//         })
//     },
//     clearStore: () => {
//         return {
//             feeDirectory: new IFeeCategory()
//         }
//     },
//     updateFeeDirectory: (ifc: any) => set((s: CategoryFeeStore) => {
//         s.feeDirectory.collection.update(ifc)
//         s.feeCounter[ifc.category] = s.feeDirectory.collection.sumIf({
//             criteria: 'category',
//             value: ifc.category,
//             sumField: "fee"
//         }, (t: number, v: number) => {
//             return t + v
//         })

//         return {
//             ...s
//         }
//     })


// })

// const useCategoryFeeStore = create<CategoryFeeStore>(categoryFeeStore)

export default useCategoryFeeStore