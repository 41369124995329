import { Box, Button, Divider, IconButton, Input, styled, Tooltip } from "@mui/material"
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import { theme } from "globals/theme";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import { grey } from "@mui/material/colors";
import Row from "components/Row";
import ClouItemTitle from "components/ClouItemTitle";
import ClouItemInfo from "components/Display/ClouItemInfo";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

import { useEffect, useState } from "react";
import useWorkspace from "hooks/useWorkspace";
import AccountWorkspaceListMenu from "./AccountWorkspaceListMenu";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Settings } from "http2";
import { useWidgetBuilderNavigation, WidgetBuilder, WidgetBuilderOutlet } from "@knymbus/react-widget-builder";
import DialogFrame from "Features/UserProfileService/CommunityPreferences/DialogFrame";
import { useParams } from "react-router-dom";
import { IWorkspace } from "Straclou/commons/IWorkspace";
import { useTranslation } from "react-i18next";
import useCommunityWorkspaceStore from "hooks/useCommunityWorkspaceStore";
import { WorkspaceManager } from "Straclou";


const Wrapper = styled(Box)(({ theme }) => ({
    background: grey[50],
    borderRadius: "20px",
    padding: "24px 16px",
    boxShadow: `1px 2px 1px ${grey[300]}`
}))
const Avatar = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    background: theme.palette.primary.main,
    color: "#fff",
    borderRadius: "15px"
}))

const IconBox = styled(Box)(({ theme }) => ({
    height: "50px",
    width: "50px",
    background: grey[100],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px"

}))

const InfoWrapper = styled(Box)(({ }) => ({
    background: grey[200],
    padding: "8px",
    display: "grid",
    gridTemplateColumns: `70px 1fr 160px`
}))

/**
 * OnHover menu controller
 */
interface ContactMenuControllerIProps {
    variant?: 'default' | "active"
}

interface SettingsState {
    add: {
        btn: boolean,
        color: "inherit" | "primary"
    },
    remove: {
        btn: boolean,
        color: "inherit" | "primary"
    },
    edit: {
        btn: boolean,
        color: "inherit" | "primary"
    }
}
const ContactMenuController: React.FC<ContactMenuControllerIProps> = ({ variant }) => {
    const [settings, setSettings] = useState<SettingsState>({
        add: {
            btn: true,
            color: "inherit"
        },
        remove: {
            btn: true,
            color: "inherit"
        },
        edit: {
            btn: true,
            color: "inherit"
        }
    })
    useEffect(() => {
        if (variant === undefined || variant === "default") {
            setSettings({
                ...settings,
                add: {
                    btn: false,
                    color: "primary"
                },
                remove: {
                    btn: true,
                    color: "inherit"
                },
                edit: {
                    btn: true,
                    color: "inherit"
                }
            })
        }

        if (variant === "active") {
            setSettings({
                ...settings,
                add: {
                    btn: true,
                    color: "inherit"
                },
                remove: {
                    btn: false,
                    color: "primary"
                },
                edit: {
                    btn: false,
                    color: "primary"
                }
            })
        }
    }, [variant])
    return (
        <Box className="menu">
            <Tooltip title="Add Phone">
                <IconButtonMenu disabled={settings.add.btn}>
                    <AddIcon sx={{ width: "0.8em", height: "0.8em" }} color={settings.add.color} />
                </IconButtonMenu>
            </Tooltip>
            <Tooltip title="Remove Phone">
                <IconButtonMenu disabled={settings.remove.btn} >
                    <DeleteIcon sx={{ width: "0.8em", height: "0.8em" }} color={settings.remove.color} />
                </IconButtonMenu>
            </Tooltip>
            <Tooltip title="Edit Phone">
                <IconButtonMenu disabled={settings.edit.btn} >
                    <EditIcon sx={{ width: "0.8em", height: "0.8em" }} color={settings.edit.color} />
                </IconButtonMenu>
            </Tooltip>
        </Box>
    )
}


const AddContact = (props: any) => {
    return (
        <Box sx={{
            position: "absolute",
            left: 0,
            background: "red",
            right: 0,
            height: "70px",
            display: "none"
        }}>
            <Input placeholder="000-000-0000" />
        </Box>
    )
}

/**
 * Contact Style Controller
 */
const ContactStyle = styled(ClouItemTitle)({
    fontSize: "11pt",
    transition: "500ms",
    position: "relative",
    height: "22px",
    padding: "0 8px 0 0",
    "& > .menu": {
        display: "none",
        position: "absolute",
        right: " -70px",
        width: "4.34em",
        background: "#18010130",
        borderTopRightRadius: "3px",
        borderBottomRightRadius: "3px",
        padding: " 0 3px",
        height: "22px",
        top: "0px",

    },
    "&:hover > .menu": {
        display: "block"
    },
    "&:hover": {
        color: grey[900]
    }
})

const DefaultValueStyle = styled(ClouItemTitle)({
    fontStyle: "italic",
})

const IconButtonMenu = styled(IconButton, { name: "IconButtonMenu" })({
    width: "0.8em",
    height: "0.8em",
    borderRadius: "0",
    marginRight: "2px",
    "&:hover": {
        backgroundColor: "rgb(0 0 0 / 16%)"
    }

})

const DisplayContact = (type: "email" | "phone", contact: string) => {
    // <ClouItemTitle sx={{ fontSize: "11pt" }}></ClouItemTitle>
    const defaults: any = {
        phone: <ContactStyle>
            <DefaultValueStyle>
                000-000-0000
            </DefaultValueStyle>

            <AddContact type="phone" />
        </ContactStyle>,
        email: <ContactStyle>
            <DefaultValueStyle>
                email@community.com
            </DefaultValueStyle>

        </ContactStyle>,
    }

    if (contact === undefined) return defaults[type]
    if (contact === null) return defaults[type]
    if (contact.trim().length === 0) return defaults[type]
    return <ContactStyle>{contact}</ContactStyle>
}

const AccountInformation: React.FC<any> = ({ data }) => {
    const { wIdentity, setWorkspace } = useCommunityWorkspaceStore()
    // const { setWorkspace } = useWorkspaceStore()
    const { queryWorkspaceById } = useWorkspace()
    const params = useParams()
    const { t } = useTranslation()

    const [fragmentNav, setFragmentNav] = useState<any>()
    // const { navigate } = useWidgetBuilderNavigation()

    const CommunityProfileBuilder = new WidgetBuilder({
        name: "Community Profile",
        type: "fragment",
        routes: [
            {
                index: true,
                window: <DialogFrame />,
                path: "home",
                fragmentHeaderOptions: {
                    isBorder: false
                }
            }
        ]
    })

    const onProfileHandler = () => {
        setFragmentNav({
            path: 'home',
            params: wIdentity
        })
    }

    useEffect(() => {
        // if (workspace.communityId === undefined) {
        //     if (params !== undefined) {
        //         let id = params.workspaceId || ""
        //         if (id.length > 0) {
        //             queryWorkspaceById(id, []).then((response: any) => {
        //                 if (Object.keys(response).length !== 0) {
        //                     setWorkspace(new IWorkspace(response))
        //                 } else {
        //                     alert(`invaild key ${id}`)
        //                 }

        //             })
        //         } else {
        //             alert("No account id detected")
        //         }
        //     }
        // }
        if (params !== undefined) {
            // setWorkspace(params.workspaceId || "").then(res=>{
            //     console.log(res)
            // })

            init(params.workspaceId || "")
        }
    }, [params])

    const [name, setName] = useState<string>("")
    const init = async (communityId: string) => {
        const identity = await WorkspaceManager.getWorkspaceV2(communityId)

        console.log(identity)
        if (typeof identity === 'string') {
            setName(identity)
        }
    }

    useEffect(() => {
        console.log(wIdentity)
    }, [wIdentity])

    return (
        <Wrapper>
            <Row>
                <Row sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Box>
                        <ClouItemInfo>
                            {/* Account Information */}
                            {t("WorkSpacesActivity.AccountInfo.title")}

                        </ClouItemInfo>
                        <ClouItemTitle sx={{ fontSize: "10pt" }}>
                            {t("WorkSpacesActivity.AccountInfo.subtitle")}
                        </ClouItemTitle>
                    </Box>
                    <Box sx={{ dislay: "flex" }}>
                        {/* <AccountWorkspaceListMenu /> */}
                        <Tooltip title="Add Community">
                            <IconButton>
                                <DomainAddIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="More options">
                            <IconButton>
                                <MoreVertIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Row>
                <Row height={'8px'} />
                <Divider />
            </Row>


            <Row sx={{ mt: 1 }}>
                <InfoWrapper>
                    <Avatar>
                        {wIdentity?.shortName}
                    </Avatar>

                    <Box sx={{
                        margin: "2px 0",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        height: "60px",
                        width: "auto"
                    }}>
                        {/* Community Name */}
                        <Box dangerouslySetInnerHTML={{__html: wIdentity?.name || name}}></Box>
                        {DisplayContact("email", "no Email")}
                        <ClouItemTitle sx={{ fontSize: "11pt" }}>{DisplayContact("phone", "no phone")}</ClouItemTitle>
                    </Box>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60px"
                    }}>
                        <Button variant="outlined" onClick={onProfileHandler} >Manage Profile</Button>
                    </Box>

                </InfoWrapper>
                <WidgetBuilderOutlet builder={CommunityProfileBuilder} onNavigate={fragmentNav} />
            </Row>

            {/* Subscription */}
            {/* <Row sx={{mt:4}}>
                <ClouItemInfo sx={{fontSize: "10pt", pb: 1}}>Subscription</ClouItemInfo>
                <Box sx={{
                    background: grey[200],
                    padding: "8px",
                    display: "grid",
                    gridTemplateColumns: `1fr 1fr`
                }}>
                    <Box>
                        <ClouItemInfo>Demo</ClouItemInfo>
                        <ClouItemTitle sx={{fontSize: "10.5pt"}}>30 days left</ClouItemTitle>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginRight: "12px"
                    }}>Active</Box>
                </Box>
            </Row> */}
        </Wrapper>
    );
}

export default AccountInformation