import StraclouLogo from "../../../components/StraclouLogo"
import { Box, styled} from "@mui/material"
import { Outlet } from "react-router-dom"
import OnboardingProgressPane from "./OnboardingProgressPane"


const Wrapper = styled(Box)(({
    width: "100vw",
    height: "100vh",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const NavigationPane = styled(Box)(({
    position: "absolute",
    top: "60px",
    left: "0",
    width: "250px",
    // background: 'red',
    bottom: 0,
    overflow: "hidden",
    overflowY: "auto",
    zIndex: 99999
}))

const CommunityMainPage = () => {
    return (
        <Wrapper>
            <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                <StraclouLogo />
            </Box>
            <NavigationPane >
                <OnboardingProgressPane />
            </NavigationPane>
            <Outlet />
        </Wrapper>
    )
}

export default CommunityMainPage