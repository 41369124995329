import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, styled, Select, MenuItem, SelectChangeEvent, FormControl } from "@mui/material";
import ClouItemTitle from "components/ClouItemTitle";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { useParams } from "react-router-dom";
import { ChangeDetectionManager } from "Straclou";
import { API } from "Straclou/API";
import { ButtonController, IMessageControl } from "./component/ButtonController";
import { Identity } from "./interface";

const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))
export default function CommunityAddress(IProps: any) {

    const { onClose } = IProps

    const { workspace, updateWorkspace } = useWorkspaceStore()
    const { updateWorkspaceById } = useWorkspace()
    const [identity, setIdentity] = useState<Identity | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    const [message, setMessage] = useState<IMessageControl>({
        open: false,
        message: "",
        type: "info"
    });



    const [parish, setParish] = useState<Array<{ key: string, value: string }>>([])
    const params = useParams()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();


    const onSubmit = (e: any) => {
        setIsLoading(true)
        ChangeDetectionManager.check({
            street: identity?.CommunityAddress?.street,
            town: identity?.CommunityAddress?.town,
            city: identity?.CommunityAddress?.city,
            parish: identity?.CommunityAddress?.parish
        }, {
            street: e.street,
            town: e.town,
            city: e.city,
            parish: e.parish
        }).then(res => {
            if (res.isChange) {
                updateWorkspaceById(workspace.workspaceId || "", { CommunityAddress: e }).then((updateRes: any) => {

                    updateWorkspace(updateRes?.Attributes)

                    /**
                     * Update the identity here to prevent continous on save of the same data
                     */
                    setIdentity({
                        CommunityAddress: updateRes?.Attributes?.CommunityAddress
                    })
                    setIsLoading(false)
                    setMessage({
                        open: true,
                        message: "data saved .... ",
                        type: "success"
                    })

                })

            } else {
                setMessage({
                    open: true,
                    message: "No change detected, nothing to save!",
                    type: "info"
                })

                setIsLoading(false)
            }
        })
    }

    useEffect(() => {
        console.log("Workspce is ready: ", workspace);

        setIdentity({
            CommunityAddress: workspace.address
        })
        reset({
            street: workspace?.address?.street,
            town: workspace?.address?.town,
            city: workspace?.address?.city,
            parish: workspace?.address?.parish,
        })

        console.log("Before we request the parish: >>>>", parish)
        if (parish.length === 0) {
            API.straclouqlAddress("getParishList", {
                country: "JM"
            }).then((res: any) => {
                console.log("Parish Result >>>>", res);
                setParish([...res])
            })
        }
    }, [workspace])


    const handleChange = (event: any) => {
        console.log(event.target.value);
    };


    return (
        <Box sx={{ pr: 3, height: "calc(100vh - 90px)", width: "350px" }}>

            {/* <ClouItemTitle sx={{mb: 3}}>Community Address</ClouItemTitle> */}

            <form onSubmit={handleSubmit(onSubmit)}>
                <p>

                </p>

                <Box sx={{ mb: 3 }}>

                    <InputLabel>Street</InputLabel>
                    <Controller
                        name={"street"}
                        control={control}
                        defaultValue={identity?.CommunityAddress?.street || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("street")}
                                onChange={onChange}
                                value={value}
                                size="small"
                                placeholder="Street"
                                inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                sx={{ width: "97%" }} />
                        )}
                    />
                </Box>

                <Box sx={{ mb: 3 }}>

                    <InputLabel>Town</InputLabel>
                    <Controller
                        name={"town"}
                        control={control}
                        defaultValue={identity?.CommunityAddress?.town || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("town")}
                                onChange={(e) => {
                                    onChange(e);
                                    handleChange(e)
                                }}
                                value={value}
                                size="small"
                                placeholder="Town"
                                inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                sx={{ width: "97%" }} />
                        )}
                    />
                </Box>

                <Box sx={{ mb: 3 }}>

                    <InputLabel>City</InputLabel>
                    <Controller
                        name={"city"}
                        control={control}
                        defaultValue={identity?.CommunityAddress?.city || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("city")}
                                onChange={onChange}
                                value={value}
                                size="small"
                                placeholder="City"
                                inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                sx={{ width: "97%" }} />
                        )}
                    />
                </Box>

                <Box sx={{ mb: 3 }}>

                    <InputLabel>Parish</InputLabel>

                    <Controller
                        name={"parish"}
                        control={control}
                        defaultValue={identity?.CommunityAddress?.parish || ""}
                        render={({ field }) => {
                            return (
                                <select
                                    // {...register("parish")}
                                    {...field}
                                    name={"parish"}

                                    // size="small"
                                    placeholder="Parish"
                                    // inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                    style={{
                                        width: "97%",
                                        height: "auto",
                                        minHeight: "1.4375em",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        borderRadius: "4px",
                                        padding: "11.5px 14px",
                                        color: "currentColo",
                                        border: "1px solid #bfbebe",
                                        cursor: "pointer",
                                        fontSize: "inherit",
                                        "animationDuration": "10ms",
                                    }}>
                                    {
                                        parish.map(el => (
                                            <option key={el.key} value={el.value} >
                                                {el.value}
                                            </option>
                                        ))
                                    }



                                </select>
                            );
                        }}
                    />
                </Box>


                <ButtonController onCancel={() => { onClose?.(null) }} isLoading={isLoading} message={message} setMessage={setMessage} />

            </form >
        </Box >
    )
}