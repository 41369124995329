import { v4 as uuidV4 } from 'uuid'
import { IAddress } from './IAddress';
import { IContact } from './IContact';
import { IFee } from './IFee';
import { IMember } from './IMember'

interface WorkspaceStatus {
    progress: number;
    state: "CommunityAddress"
}



export class IWorkspace {
    private _key: string = uuidV4()
    private _name: string
    private _shortName: string
    private _members: IMember[]
    private _membersCount: number = 0
    private _communitySize = 0
    private _workspaceId: string;
    private _status: WorkspaceStatus
    private _type: string
    private _createdAt: string
    private _updatedAt: string
    private _address: IAddress
    private _strataNumber: string
    private _contact: IContact
    private _email: string
    private _phone: string
    private _structure: Array<string>
    private _fees: IFee
    


    constructor(args?: any) {
        this._assign(args)
    }


    private _assign(args: any) {
        if (args !== undefined && args !== null) {
            this._name = args?.CommunityName || ""
            this._communitySize = args?.CommunitySize || 0
            this._shortName = args?.CommunityShortName || this._shortNameConverter(this._name)
            this._members = this._membersCountHandler(args?.members)
            this._membersCount = args?.members?.length || 0
            this._workspaceId = args?.id
            this._address = args?.CommunityAddress
            this._structure = args?.CommunityStructure
            this._strataNumber = args?.CommunityStrataNumber
            this._createdAt = args?.createdAt
            this._updatedAt = args?.updatedAt
            this._status = args?.status
            this._type = args?.type
            this._contact = new IContact(args?.contact)
            this._email = args?.CommunityEmail || ""
            this._phone = args?.CommunityPhone || ""
            this._fees = new IFee(args?.CommunityFees)
        }
    }


    update(input: any) {
        Object.keys(input).forEach(el => {
            if (el === "CommunityName") {
                this._name = input["CommunityName"]
                this._shortName = input?.CommunityShortName || this._shortNameConverter(this._name)
            }

            if (el === "CommunitySize") {
                this._communitySize = input?.CommunitySize || 0
            }

            if (el === "CommunityShortName") {
                this._shortName = input?.CommunityShortName || this._shortNameConverter(this._name)
            }

            if (el === "members") {
                this._members = this._membersCountHandler(input?.members)
            }

            if (el === "CommunityAddress") {
                this._address = input?.CommunityAddress
            }

            if (el === "CommunityStrataNumber"){
                this._strataNumber = input?.CommunityStrataNumber
            }

            if(el === "status"){
                this._status = input?.status
            }

            if(el === "CommunityEmail"){
                this._email = input?.CommunityEmail || ""
            }

            if(el === "CommunityPhone"){
                this._phone = input?.CommunityPhone || ""
            }

            if(el === "CommunityStructure"){
                this._structure = input?.CommunityStructure || ""
            }
        })
    }

    get key() {
        return this._key
    }

    //Getters
    get workspaceId() { return this._workspaceId }
    get name() { return this._name }
    get shortName() { return this._shortName }
    get members() { return this._members }
    get communitySize() { return this._communitySize }
    get membersCount() { return this._membersCount }
    get status() { return this._status }
    get type() { return this._type }
    get createdAt() { return this._createdAt }
    get updatedAt() { return this._updatedAt }
    get address() { return this._address }
    get strataNumber() { return this._strataNumber }
    get contact() { return this._contact }
    get email() { return this._email }
    get phone() { return this._phone }
    get structure() { return this._structure }
    get fees(){return this._fees}
    

    // Setters

    // Helpers
    private _shortNameConverter(str: string) {
        return str.split(" ").reduce((t, v) => (t += v.charAt(0).toUpperCase()), "")
    }

    private _membersCountHandler(members: any): IMember[] {
        const m: IMember[] = []
        members?.forEach((el: string) => {
            m.push(new IMember({ uid: el }))
        })
        return m
    }

}