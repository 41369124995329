import * as React from 'react';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import ClouSquareFootageExhibit from 'components/ClouSquareFootageExhibit';
import ClouMoneyExhibit from 'components/ClouMoneyExhibit';
import { Button, DialogActions, IconButton, InputLabel, Skeleton, Stack, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { IconButtonSmall } from 'Features/Commentary/CustomStyles';
import Tooltip from '@mui/material/Tooltip';
import { useEffect, useLayoutEffect, useState } from 'react';
import { grey } from '@mui/material/colors';
import useFeeTableData from './SampleFeeData';
import { CommunityPreferenceDialogStructure } from '../SettingsData';
import { IFeeCategory, userHandler } from 'Straclou';
import useWorkspaceStore from 'hooks/useWorkspaceStore';
import useWorkspace from 'hooks/useWorkspace';
import ClouDateTimeExhibit from 'components/ClouDateTimeExhibit';
import ReactDOM from 'react-dom';
import { LoadingButton } from '@mui/lab';
import useAuth from 'hooks/useAuth';
import { IUser } from 'Straclou/commons/IUser';
import { CreateCategoryFeeInput } from 'Straclou/API';
import useCategoryFeeStore from 'hooks/useCategoryFeeStore';


const TableRowWrapper = styled(Box, { name: "Table_Row_Wrapper" })(({ theme }) => ({
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: '8px 0'
}))


const TableHeaderRowWrapper = styled(Box, { name: "Table_Header_Row_Wrapper" })(({ theme }) => ({
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    padding: '8px 0',
    color: theme.palette.grey[600]
}))

const TableNewRowWrapper = styled(Box, { name: "Table_New_Row_Wrapper" })(({ theme }) => ({
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '8px 0',
    color: theme.palette.grey[600],
    margin: "0"
}))
const TableNewRowFormContent = styled(Box, { name: "Table_New_Row_Form_Content" })(({ theme }) => ({
    display: "flex",
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    color: theme.palette.grey[600],
    padding: '0',
    width: "100%"
}))

const TableRow = (props: any) => {
    const { name, size, fee, author, createdAt } = props
    return (
        <TableRowWrapper>
            <Box sx={{ width: "200px" }}>{author}</Box>
            <Box sx={{ width: "200px" }}>{name}</Box>
            <Box sx={{ width: "200px" }}><ClouSquareFootageExhibit footage={size} /></Box>
            <Box sx={{ width: "200px" }}><ClouMoneyExhibit value={fee} /></Box>
            <Box sx={{ width: "200px" }}><ClouDateTimeExhibit time={createdAt} /></Box>
            <Box sx={{ width: "200px" }} >
                <Tooltip title="Edit item">
                    <IconButtonSmall>
                        <EditIcon sx={{ width: '20px', height: '20px' }} />
                    </IconButtonSmall>
                </Tooltip>

                {/* <Tooltip title="Delete item">
                    <IconButtonSmall>
                        <DeleteIcon sx={{ width: '20px', height: '20px' }} />
                    </IconButtonSmall>
                </Tooltip> */}
            </Box>
        </TableRowWrapper>
    )
}

const TableSkeletonRow = () => {

    return (
        <TableRowWrapper>
            <Box sx={{ width: "200px" }}><Skeleton width={70} animation="wave"/></Box>
            <Box sx={{ width: "200px" }}><Skeleton sx={{ width: "100px" }} animation="wave"/></Box>
            <Box sx={{ width: "200px" }}><Skeleton sx={{ width: "50px" }} animation="wave"/></Box>
            <Box sx={{ width: "200px" }}><Skeleton sx={{ width: "50px" }} animation="wave"/></Box>
            <Box sx={{ width: "200px" }}><Skeleton sx={{ width: "70px" }} animation="wave"/></Box>
            <Box sx={{ width: "200px" }} ><Skeleton sx={{ width: "20px" }} animation="wave"/></Box>
        </TableRowWrapper>
    )
}

const TableHeaderRow = () => {



    return (
        <TableHeaderRowWrapper>
            <Box sx={{ width: "200px" }}>Author</Box>
            <Box sx={{ width: "200px" }}>Name</Box>
            <Box sx={{ width: "200px" }}>Property Size</Box>
            <Box sx={{ width: "200px" }}>Maintenace Fee</Box>
            <Box sx={{ width: "200px" }}>Created on</Box>
            <Box sx={{ width: "200px" }} >
                Action
            </Box>
        </TableHeaderRowWrapper>
    )
}



interface IPropsNewTableRow {
    isOpen?: boolean,
    type: CommunityPreferenceDialogStructure
    onLoading?: (e: boolean) => void
    onClose?: Function
}


export const NewTableRow: React.FC<IPropsNewTableRow> = ({ isOpen, type, onLoading, onClose }) => {


    const { workspace } = useWorkspaceStore()
    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();
    const [isLoading, setIsLoading] = React.useState(false);
    const [user, setUser] = useState<IUser | null>(null)


    const handleClose = () => {
        reset({
            name: "",
            size: "",
            fee: ""
        })
        onClose?.(true)
    }


    const onSubmit = (e: any) => {
        console.log("args: >>>>>> ", e, ' and type: ', type)
        let cat: CreateCategoryFeeInput = {
            category: type,
            ...e,
            authorId: user?.uid,
            author: user?.name,
            communityId: workspace.workspaceId
        }
        console.log("this is data to be added >>>>> : ", cat)
        useCategoryFeeStore.AddFee(cat).then(res => {
            useCategoryFeeStore.initFeeCount({
                category: type,
                communityId: workspace.workspaceId
            })
            handleClose()
        })
    }

    useEffect(() => {
        reset({
            name: "",
            size: "",
            fee: ""
        })

        userHandler.getCurrentUserCredentials().then(user => {
            if (user !== undefined) {
                setUser(user)
            }
        })

    }, [])





    return (
        <TableNewRowWrapper >
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <TableNewRowFormContent>
                    <Stack gap={2} sx={{ width: '80%' }}>
                        <Box sx={{ width: "100%" }}>
                            <InputLabel sx={{ margin: '0 0 0 5px', fontSize: '10.7pt', color: grey[500] }}>Unit Name</InputLabel>
                            <Controller
                                name={"name"}
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("name")}
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        placeholder="Property Name"
                                        inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                        sx={{ width: "97%" }} />
                                )}
                            />

                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <InputLabel sx={{ margin: '0 0 0 5px', fontSize: '10.7pt', color: grey[500] }}>Square Footage of Unit</InputLabel>
                            <Controller
                                name={"size"}
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("size")}
                                        onChange={onChange}
                                        value={value}
                                        required
                                        type="number"
                                        size="small"
                                        placeholder="Property Size"
                                        inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                        sx={{ width: "97%" }} />
                                )}
                            />

                        </Box>
                        <Box sx={{ width: "100%" }}>
                            <Box sx={{ mb: 0 }}>
                                <InputLabel sx={{ margin: '0 0 0 5px', fontSize: '10.7pt', color: grey[500] }}>Maintenance Fee</InputLabel>
                                <Controller
                                    name={"fee"}
                                    control={control}
                                    defaultValue={""}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            {...register("fee")}
                                            onChange={onChange}
                                            value={value}
                                            required
                                            type="number"
                                            size="small"
                                            placeholder="Property Fee"
                                            inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                            sx={{ width: "97%" }} />
                                    )}
                                />
                            </Box>
                        </Box>
                    </Stack>
                </TableNewRowFormContent>
                <DialogActions sx={{ mr: 1, mt: 3 }}>
                    <Button onClick={handleClose}>Cancel</Button>
                    <LoadingButton variant={"contained"} loading={isLoading} type="submit">Create</LoadingButton>
                </DialogActions>
            </form>
        </TableNewRowWrapper>
    )
}

export default function FeesTable(props: { type: any, open?: boolean, data?: any }) {
    const { workspace } = useWorkspaceStore()



    const [category, setCategory] = useState<any | null>(null)
    const { type, data } = props
    const [list, setList] = useState<any | null>(null)

    const skeletonListCount = [0,0,0,0]

    useEffect(() => {
        console.log("selected type: ", type)
        console.log("selected data: ", data.feeDirectory.name)
        if (type === data.feeDirectory.name) {
            data?.feeDirectory.collection?.orderBy('createdAt', (a: any, b: any) => {
                let aD = new Date(a)
                let bD = new Date(b)
                return bD.getTime() - aD.getTime()
            })

            console.log("me mod data: ", data)
            setList(data)
        } else if(data.feeDirectory.name.length === 0) {
            setList(data)
        }else{
            setList(null)

        }
    }, [data, type])



    return (
        <Box sx={{ transition: '500ms' }}>
            <TableHeaderRow />
            {
                list === null ?  skeletonListCount.map((el)=>{
                    return <TableSkeletonRow />
                }) : list?.feeDirectory.collection?.map((el: any) => {
                    return <TableRow {...el} />
                })
            }


        </Box>
    );
}