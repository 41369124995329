import { createContext, ReactNode, useEffect, useState } from "react"
import FinancialForm from "../FinancialForm";
import Generator from "../Generator";
import CompleteFrame from "../CompletedFrame";

type ScreenName = "financial" | "complete" | "general"

interface IProps {
    children: React.ReactNode
}

export const AccountServiceContext = createContext<any>({
    currentView: null,
    setScreens: (screensList: any, defaultScreen?: ScreenName)=>{},
    handleNextView: (viewName: ScreenName, data?: any )=>{}
})


export default function AccountServiceProvider({ children }: IProps) {
    const [onClose, setOnClose] = useState<Function>(()=>{})
    const [onReady, setOnReady] = useState<boolean>(false)
    const [screenList, assignScreens] = useState<any>({})
    const [currentView, setCurrentView] = useState<ReactNode | null>(null)
    // const [onNextView, onNext] = useState<{
    //     next: ScreenName,
    //     data: null | any
    // }>({
    //     next: "general",
    //     data: null
    // })

    const views = {
        "general": <Generator onClose={onClose} />,
        "financial": <FinancialForm onClose={onClose} />,
        "complete": <CompleteFrame onClose={onClose} />
    }

    const [context, setContext] = useState<any | null>(null)

    const setScreens = (screensList: any, defaultScreen: ScreenName) => {
        console.log("Views Assigned: ", screenList);
        console.log("Default View: ", defaultScreen);
        
        if (screensList) {
            assignScreens(screensList)
            setOnReady(true)

            setCurrentView(screenList[defaultScreen])

        }
    }

    const handleNextView = (viewName: ScreenName, data?: any) => {
        setCurrentView(screenList[viewName])
        setContext(data)

        console.log("on Next View triggered: ", { view: viewName, contextData: data });
    }

    useEffect(()=>{
        handleNextView('general')
    },[])

    return <AccountServiceContext.Provider value={{
        currentView, 
        setScreens,
        handleNextView,
        setOnClose,
        onClose
    }}>{children}</AccountServiceContext.Provider>
}




    // return {
    //     handleNextView,
    //     onReady,
    //     setScreens,
    //     currentView,
    //     context
    // }

