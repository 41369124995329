import CommunityProfilePage from "Pages/communitySettings/CommunityProfile";
import HomePage from "Pages/HomePage/HomePage";
import NotificationPage from "Pages/Notification/NotificationPage";
import CommunityMainPage from "Pages/Onboarding/Community/CommunityMainPage";
import CommunitySetupNamePage from "Pages/Onboarding/Community/CommunitySetupNamePage";
import CommunitySetupStructurePage from "Pages/Onboarding/Community/CommunitySetupStructurePage";


import OnboardingPage from "Pages/Onboarding/OnboardingPage";
import OnboardingStepOne from "Pages/Onboarding/OnboardingStepOne";
import SubscriptionPage from "Pages/Subscription/SubscriptionPage";
import UserManagementPage from "Pages/UserManagement/UserManagementPage";
import { Navigate  } from "react-router-dom";
import PageNotFound from "./PageNotFound";

import DashboardPage from "./Pages/Dashboard/DashboardPage";
import CommunitySetupStrataNumberPage from "Pages/Onboarding/Community/CommunitySetupStrataNumberPage";
import CommunitySetupSizePage from "Pages/Onboarding/Community/CommunitySetupSizePage";
import CommunitySetupAddressPage from "Pages/Onboarding/Community/CommunitySetupAddressPage";
import SetupComplete from "Pages/Onboarding/SetupComplete";
import RMSLandingPage from "Pages/RMS/RMSLandingPage";



import SignupActivity from "Pages/Authenication/SignupActivity";
import AuthMainActivities from "Pages/Authenication/AuthMainActivities";
import VerifyEmailActivity from "Pages/Authenication/VerifyEmailActivity";
import SignInPasswordActivity from "Pages/Authenication/SignInPasswordActivity";
import CreateCommunityWorkSpace from "Pages/Onboarding/Landing/CreateCommunityWorkSpace";
import SignInActivity from "./Pages/Authenication/SignInActivity";
import ForgetPasswordActivity from "Pages/Authenication/ForgetPasswordActivity";
import PolicyUserTerm from "Pages/Onboarding/Landing/Policy/PolicyUserTerm";
import PolicyMainActivity from "Pages/Onboarding/Landing/Policy/PolicyMainActivity";
import PrivacyPolicy from "Pages/Onboarding/Landing/Policy/PrivacyPrivacy";
import LandingProtectedRoutes from "Pages/Onboarding/Landing/LandingProtectedRoutes";

import PolicyActivity from "Pages/Onboarding/Landing/Policy/PolicyActivity";
import SubscriptionMain from "Features/SubscriptionService";
import SubscriptionList from "Features/SubscriptionService/SubscriptionList";
import SubscriptionPurchase from "Features/SubscriptionService/SubscriptionPurchase";
import LandingLoader from "Pages/Onboarding/Landing/LandingLoader";
import WhoAreYou from "Pages/Onboarding/Landing/WhoAreYouPage";
import ApplicationLoaderActivity from "Pages/Loaders/ApplicationLoaderActivity";
import { AdminDashboard } from "Features/Dashboard";


const useAuthPolicy = () => {

}
// interface RouteConfig {
//     canActivate?: Array<Function>
    
// }

const useAppRoutes = () => {
    const routes: Array<any> = [
        {
            path: "/",
            element: <Navigate to="signin-process" />
        },
        {
            path: "signin-process",
            element: <AuthMainActivities />,
            children: [
                {
                    index: true,
                    element: <Navigate to="signin"/>
                },
                {
                    path: "signin",
                    element: <SignInActivity />
                },
                {
                    path: "forget_password",
                    element: <ForgetPasswordActivity />
                },
                {
                    path: "signup",
                    element: <SignupActivity />
                },
                {
                    path: "verify_email",
                    element: <VerifyEmailActivity />
                },
                {
                    path: "signin_password",
                    element: <SignInPasswordActivity />
                },
            ]

        },
        {
            /**
             * This path is the main path after the user is logged in therefore 
             * if user is not authenticated then the user must be redirected
             */
            path: "getting_ready",
            element: <ApplicationLoaderActivity/>
        },
        {
            path: "subscription",
            element: <SubscriptionMain/>,
            children: [
                {
                index: true,
                element: <Navigate to="list"/>
                },
                {
                    path: "list",
                    element: <SubscriptionList/>
                },
                {
                    path: "purchase",
                    element: <SubscriptionPurchase/>
                }
            ]
        },
        // {
        //     path: "",
        //     element: <ProtectedRoutes />,
        //     children: [

        //     ]
        // },

        {
            path: "user_policy_agreement",
            element: <PolicyActivity />,
            canActivate: [useAuthPolicy]

        },
        {
            path: "policy",
            element: <PolicyMainActivity />,
            children: [
                {
                    index: true,
                    element: <Navigate to="privacy_policy" />
                },
                {
                    path: "user_term_of_use",
                    element: <PolicyUserTerm />
                },
                {
                    path: "privacy_policy",
                    element: <PrivacyPolicy />
                },
            ]
        },

        /**
         * The lobby area of our app
         * this is the first area a user will see after login
         * now they can be directed to creating a new Community/ workspace / team space 
         * or if they already have a work space then direct them to their community list.
         * 
         * This have to be a protected route as we only want authenticated users only
         */
        {
            path: "adcon",
            element: <LandingProtectedRoutes />,
            children: [
                {
                    path: "u/:workspaceId/",
                    element: <AdminDashboard />
                },
            ]
        },

        {
            path: "landing",
            element: <LandingProtectedRoutes />,
            children: [
                {

                    index: true,
                    element: <Navigate to="workspace" />
                },
                {
                    path: "checkout_user_access",
                    element: <LandingLoader />
                },
                {
                    path: "workspace/:workspaceId",
                    element: <AdminDashboard />
                },
                {
                    path: "create_workspace",
                    element: <CreateCommunityWorkSpace />
                },
                {
                    path: "who_are_you",
                    element: <WhoAreYou />
                },
            ]

        },



        {
            path: 'home',
            element: <HomePage />,
            children: [
                {

                    index: true,
                    element: <Navigate to="dashboard" />
                },
                {
                    path: "dashboard",
                    element: <DashboardPage />
                },

                {
                    path: "community_profile",
                    element: <CommunityProfilePage />
                },
                {
                    path: "subscription",
                    element: <SubscriptionPage />
                },
                {
                    path: "user_management",
                    element: <UserManagementPage />
                },
                {
                    path: "notifications",
                    element: <NotificationPage />
                },
            ]
        },
        {
            path: "RMS/:workspaceId",
            element: <RMSLandingPage />
        },

        {
            path: "client",
            element: <OnboardingPage />,
            children: [
                {
                    index: true,
                    element: <Navigate to={'dynamicClientId'} />
                },
                {
                    /**
                     * Not focused on this setup right now
                     */
                    path: "setup_app_type",
                    element: <OnboardingStepOne />
                },
                {
                    path: "setup_complete",
                    element: <SetupComplete />
                },
                {
                    path: ":clientId",
                    element: <CommunityMainPage />,
                    children: [
                        {
                            index: true,
                            element: <Navigate to={"community_name"} />
                        },
                        {
                            path: "community_name",
                            element: <CommunitySetupNamePage />
                        },
                        {
                            path: "community_strata_number",
                            element: <CommunitySetupStrataNumberPage />
                        },
                        {
                            path: "community_size",
                            element: <CommunitySetupSizePage />
                        },
                        {
                            path: "community_structure",
                            element: <CommunitySetupStructurePage />
                        },
                        {
                            path: "community_address",
                            element: <CommunitySetupAddressPage />
                        },
                    ]
                }
            ]
        },
        {
            path: "*",
            element: <PageNotFound />
        },


    ]
    return [routes]
}

export default useAppRoutes