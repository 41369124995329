import { Box, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import MemberItem from "./MemberItem";

export default function Members() {
    const users = [
        {
            key: "0",
            name: "Kirk Knight",
            email: "kirk,knight@gmail.com",
            type: "Owner"
        },
        {
            key: "1",
            name:"Alice Wonders",
            email:"wonderingalice@lost.com",
            type: "PRO"
        },
        {
            key: "2",
            name:"Robin Shadow",
            email:"shadows@hotmail.com",
            type: "Finance"
        }
    ]
    return (
        <>
            <Box sx={{ mt: 2, mb: 1, width: "90%", ml: "auto", mr: "auto"}}>Members</Box>

            <Box sx={{
                width: "90%",
                margin: "0 auto",
                background: grey[100],
                padding: "16px",
                borderRadius: "16px"
            }}>
                <Stack gap={2}>
                    {
                        users.map(el=>{
                            return <MemberItem key={el.key} data={el}/>
                        })
                    }
                    
                    
                </Stack>
            </Box>

        </>
    )
}