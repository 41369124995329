import { Box, TextField } from "@mui/material"
import { theme } from "globals/theme"
import { styled, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { ProgressStep, useOnboardingProgressStore } from "hooks/useOnboardingProgress"
import { Controller, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import useAuth from "hooks/useAuth"
import useOnboardingProgressRouter from "hooks/useOnboardingProgressRouter"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"


const Wrapper = styled(Box)(({
    width: "60vw",
    height: "80vh"
}))

const InputBox = styled(TextField)(({
    width: "100%",
    fontSize: "1.5em !important",

    "&.MuiOutlinedInput-input": {
        textAlign: "center"
    }

}))



const CommunitySetupStrataNumberPage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { user } = useAuth()

    const { t } = useTranslation()

    const { updateProgress, progress } = useOnboardingProgressStore()
    const { goto } = useOnboardingProgressRouter(params?.clientId)

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();

    const onSubmit = (e: any) => {
        // updateProgress({ StrataNumber: e.number as string })
        // navigate('/client/dynamicClientId/community_size')

        updateProgress({
            id: params.clientId || "",
            uid: user.uid,
            step: ProgressStep.Community_Strata_Number,
            data: { CommunityStrataNumber: e.number as string },
        }).then(res => {
            console.log(res)
            goto(ProgressStep.Community_Size)
        })
    }

    const handleClick = () => {
        goto(ProgressStep.Community_Size)
    }

    useEffect(() => {
        reset({
            number: progress?.CommunityStrataNumber
        })
    }, [progress])

    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ textAlign: "center", fontSize: '2.3em', fontWeight: "600", margin: "30px 0 30px 0", color: theme.palette.primary.main }}>
                    {t("community_strata_number.title")}
                </Box>

                <Box sx={{ textAlign: "center", margin: "0 0 30px 0" }}>

                    <Controller
                        name={"number"}
                        control={control}
                        defaultValue={progress?.CommunityStrataNumber || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("number", {
                                    minLength: {
                                        value: 4,
                                        message: "Length of community strata number have to be greater than 4 characters"
                                    }
                                })}
                                onChange={onChange}
                                value={value}
                                placeholder={t('community_strata_number.input_placeholder') || ""}
                                inputProps={{ style: { textAlign: "center", fontSize: "18pt" } }}
                                sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                        )}
                    />
                    <Box sx={{
                        margin: "8px",
                        height: "40px"
                    }}>
                        <ErrorMessage
                            errors={errors}
                            name="number"
                            render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                        />
                    </Box>
                </Box>

                <Box sx={{ textAlign: "center", margin: "0 0 30px 0", lineHeight: "2em" }}>{t("community_strata_number.description")}</Box>

                <Box sx={{ width: "300px", margin: "0 auto" }}>
                    <Button variant="contained" type="submit" fullWidth sx={{ height: "55px", fontSize: "1.6em" }} >Next</Button>
                </Box>
                <Box sx={{ width: "300px", margin: "30px auto" }}>
                    <Button variant="text" fullWidth sx={{ height: "55px", fontSize: "1.2em" }} onClick={handleClick}>Skip this step</Button>
                </Box>
            </form>
        </Wrapper>
    )
}

export default CommunitySetupStrataNumberPage


