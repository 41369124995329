import { useWidgetBuilderNavigation, useWidgetBuilderParams, WidgetBuilderContext } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, FormControlLabel, InputLabel, Radio, RadioGroup, styled, TextField } from "@mui/material"
import Row from "components/Row";
import { IAccountInput } from "Features/AccountService/classes/IAccountInput";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"

import useSummaryStore from "../SummaryStore";
import EditOutlineFrame from "./EditOutlineFrame";



export default function EditPropertyTypeFragment(props: any) {

   const {structureList} = useWorkspaceStore()

    const { navigateBack } = useWidgetBuilderNavigation()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();

    const params: IAccountInput = useWidgetBuilderParams()
    // const { setFragmentHeader } = useContext(WidgetBuilderContext)

    const { updateList } = useSummaryStore()
    

    

  

    const onSubmit = (e: any) => {
        console.log(e)

        params.value = e.property_type
        params.text = e.property_type

        updateList(params)
        navigateBack()

    }


    useEffect(() => {
        // setFragmentHeader("Edit property type")
    }, [])

    useEffect(() => {

    }, [params])

    return (
        <EditOutlineFrame>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "80%" }}>
                <Box sx={{ margin: "0 auto" }}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        {params.title}
                    </Box>

                    <Box sx={{ mb: 3, mt: 3, width: "100%" }}>

                        {/* <InputLabel>Account Name</InputLabel> */}
                        <Controller
                            name={params.fieldName}
                            control={control}
                            defaultValue={params.text || ""}
                            render={({ field }) => (
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    defaultValue={params.value}
                                    {...field}
                                    
                                >
                                {
                                    structureList.map(el=>{
                                        return <FormControlLabel key={el} value={el} control={<Radio />} label={el} />
                                    })
                                }
                                    
                                    
                                </RadioGroup>
                                // <TextField
                                //     {...register(params.fieldName)}
                                //     autoFocus
                                //     onChange={onChange}
                                //     value={value}
                                //     size="small"
                                //     required
                                //     placeholder="Property type"
                                //     inputProps={{ style: { textAlign: "center", fontSize: "12pt" } }}
                                //     sx={{ width: "100%" }} />
                            )}
                        />
                        {/* <Box>Name cannot be empty</Box> */}
                    </Box>
                    <Row right sx={{ position: "absolute", bottom: "32px", right: "52px" }}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            sx={{ width: "auto" }}
                        >Done</LoadingButton>
                    </Row>
                </Box>
            </form>
        </EditOutlineFrame>
    )
}