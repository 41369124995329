import DateTimeExhibit from "components/DateTimeExhibit"
import ClouMoneyExhibit from "components/ClouMoneyExhibit"
import Row from "components/Row"
import { Box, TableRow, TableCell } from "@mui/material"

import React from "react"



interface IProps {
    data: any
}
const TransactionPaymentCard: React.FC<IProps> = ({ data }) => {
    return (
        <TableRow hover sx={{cursor: "pointer"}}>
            <TableCell>
                <DateTimeExhibit time={data.transactionDate} />
            </TableCell>

            <TableCell>
                <Box>
                    <Row>{data.referenceNumber}</Row>
                </Box>
            </TableCell>

            {/* Description */}
            <TableCell>
                {data.description}
            </TableCell>


            {/* Amount */}
            <TableCell>
                <ClouMoneyExhibit value={data.amount} />
            </TableCell>



            {/* Balance */}
            {/* <TableCell>
                <MoneyExhibit value={data.balance} />
            </TableCell> */}


        </TableRow>
    )
}

export default TransactionPaymentCard