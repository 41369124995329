import axios, {AxiosRequestHeaders} from "axios";

export const axiosExecute = async (options: AxiosOptions) => {
    return new Promise(async (resolve, reject) => {
        axios(options).then((res: any) => {
            resolve(res["data"])
        }).catch(error => {
            console.log("something is wrong: ", error)
            reject(error)
        })
    })
}

export interface AxiosOptions{
    method: 'POST' | 'GET' | 'PUT' | 'DELETE',
    url: string,
    data: any,
    headers?: AxiosRequestHeaders
}