import React from "react";
import { styled, Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import { IWork } from "Straclou/commons/IWork";



const PropertyWrapper = styled(Box)(
    ({
        padding: "0 5px 0 25px"
    }),
);

const DisplayItem = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "120px auto",
    gridTemplateRows: "1fr",
    gap: "0 0",
    minHeight: "28px",
    margin: "1px 0",
    fontSize: ".8em",
}));

const ItemTitle = styled(Box)(
    ({
        // background: "gold",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "InactiveCaptionText"

    }),
);

const ItemInfo = styled(Box)(
    ({
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",


    }),
);

interface IProps{
    work?: IWork
}

const WorkDetail: React.FC<IProps> =({work}) =>{
    const data = {
        unitNumber: "564",
        propertyType: "Apartment",
        unitSize: "1,200 sq ft",
        floorPlan: "The Manor 2",
        buildingName: "North West",
        floorName: "Ground Floor",
        phase: "1",
        block: "A",
        street: "23 Coral Spring Drive",
        town: "Great Valley",
        city: "Portmore",
        parish: "St. Catherine"
    }
    return (
        <PropertyWrapper>
            
            <Typography sx={{ fontSize: ".8em", margin:"8px 0 8px 0", color: "InactiveCaptionText" }}>Work Detail</Typography>
           
            <DisplayItem>
                <ItemTitle>Company</ItemTitle>
                <ItemInfo>{work?.companyName}</ItemInfo>
            </DisplayItem>
            <DisplayItem>
                <ItemTitle>Job Position</ItemTitle>
                <ItemInfo>{work?.occupation}</ItemInfo>
            </DisplayItem>

            <Divider sx={{ margin: "8px 0 0 0" }} />
            
            <DisplayItem>
                <ItemTitle>Street</ItemTitle>
                <ItemInfo>{work?.address?.street}</ItemInfo>
            </DisplayItem>
            <DisplayItem>
                <ItemTitle>Town</ItemTitle>
                <ItemInfo>{work?.address?.town}</ItemInfo>
            </DisplayItem>
            <DisplayItem>
                <ItemTitle>City</ItemTitle>
                <ItemInfo>{work?.address?.city}</ItemInfo>
            </DisplayItem>
            <DisplayItem>
                <ItemTitle>Parish</ItemTitle>
                <ItemInfo>{work?.address?.parish}</ItemInfo>
            </DisplayItem>

        </PropertyWrapper>
    )
}

export default WorkDetail