import { Box } from "@mui/material"
import React, { useEffect, useState } from "react"


interface IProps{
    value?: string
    fontSize?: string
}

const STAT = (value: string) =>{
    let arr = String(value).toLowerCase().split("_")

    return arr.map(w=>{
        return w.charAt(0).toUpperCase().concat(w.slice(1))
    }).join(" ")
}


const ClouStatusConverterExhibit: React.FC<IProps> = ({value, fontSize}) =>{
    
    const [status, SetStatus] = useState("")
    const [ifontSize, setIFontSize] = useState("1em")

    useEffect(()=>{
        if(value) SetStatus(STAT(value))
    },[value])

    useEffect(()=>{
        if(fontSize !== undefined){
            setIFontSize(fontSize)
        }
    },[fontSize])
    return <Box sx={{fontSize: ifontSize}}>{status}</Box>
}

export default ClouStatusConverterExhibit