import { Box, IconButton, ListItemIcon, Menu, MenuItem, styled, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { WidgetBuilderOutlet } from "@knymbus/react-widget-builder";
import { IWidgetBuilderRoute } from "@knymbus/react-widget-builder";
import { IWidgetBuilderNavigate } from "@knymbus/react-widget-builder";
import { WidgetBuilder } from "@knymbus/react-widget-builder";
import AddNewAccountMain from "Features/AccountService/AddNewAccountDialog";
// import MenuController from "./MenuControl";
// import { notifications } from "./sample";
// import MessageTemplate from "./MessageTemplate";
// import useNotification from "./useNotification";

const MenuItemCust = styled(MenuItem)(({
    fontSize: "10pt"
}))

export default function NewPropertyAccountMenu() {

    const [onNavigate, setOnNavigate] = useState<IWidgetBuilderNavigate | undefined>(undefined)
    const fragmentRoutes: IWidgetBuilderRoute[] = [
        {
            path: "home",
            window: <AddNewAccountMain />,
            fragmentHeaderOptions: {
                // header: "Create New Account"
                isBackNav: false,
                isBorder: false
            },

        }
    ]

    const NewAccountBuilder = new WidgetBuilder({
        name: "New Account Outlet",
        routes: fragmentRoutes,
        type: "fragment"
    })
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleUpload = () => {
        handleClose()
    }

    const handleCreate = () => {
        handleClose()
        setOnNavigate({
            path: "home"
        })
    }

    


    return (
        <Box sx={{ width: "auto", transform: 'translateY(30%) translateX(-5%)' }}>
            <Tooltip title="Create">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <NoteAddIcon sx={{ width: "20px", height: "20px" }} />
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                // onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        width: "auto",
                        height: "auto",
                        p: 1,
                        mt: .5,
                        ml: 0,
                        transform: 'translateX(-50%)',

                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(10%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >


                <MenuItemCust onClick={handleUpload}>
                    <ListItemIcon>
                        <UploadFileIcon fontSize="small" />
                    </ListItemIcon>
                    Upload file
                </MenuItemCust>
                <MenuItemCust onClick={handleCreate}>
                    <ListItemIcon>
                        <PostAddIcon fontSize="small" />
                    </ListItemIcon>
                    Create record
                </MenuItemCust>
            </Menu>

            <Box sx={{position: "absolute"}}>
                <WidgetBuilderOutlet builder={NewAccountBuilder} onNavigate={onNavigate} />
            </Box>
        </Box>
    )
}