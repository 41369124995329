import { styled, SxProps } from "@mui/system";
import React, { useEffect, useState } from "react";
import {v4 as uuidV4} from 'uuid'

type Align = "left" | "right"

interface IPropsWrapper{
    align: Align
}
const Wrapper = styled("div")(({align}: IPropsWrapper)=>({
    width: "100%",
    height: "35px",
    display: "flex",
    justifyContent: align
    
}))
const Item = styled("div")(({gap}: IButtonGroupItemProps)=>({
    margin: `0 ${gap}`,
    display: "flex",
    // background: "red"
}))
interface IButtonGroupItemProps{
    gap: string
}
interface IButtonGroupProps{
    children: React.ReactNode
    spacing?: number,
    align?: Align
    sx?: SxProps
}
const ClouButtonGroup: React.FC<IButtonGroupProps> = ({children, sx, spacing, align})=>{

    const [hGap, setHGap] = useState<string>("4px")
    const [bgAlign, setBGAlign] = useState<Align>("left")

    useEffect(()=>{
        if(align !== undefined) setBGAlign(align)
    },[align])

    useEffect(()=>{
        if(spacing !== undefined) setHGap(`${(4 * spacing)}px`)
    },[spacing])
    return (
        <Wrapper align={bgAlign} sx={sx}>
        {
          React.Children.toArray(children).map(el=>{
              return <Item key={uuidV4()} gap={hGap} >{el}</Item>
          })  
        }
        
        </Wrapper>
    )
}

export default ClouButtonGroup