import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Stack, TextField, Button, styled, InputLabel } from "@mui/material"
import { grey } from "@mui/material/colors"
import AutoTabProvider from "components/AutoTabProvider";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import DialogController from "./Dialog/DailogController";




const Wrapper = styled(Box)(({
   
}))

const InputBoxSX = {
    sx: {
        textAlign: "center",
        fontSize: "20pt",
        textTransform: "uppercase"
    },
    maxLength: 1,
    required: true,


}


interface StateParams {
    email: string
    codeId: string
}

export default function EmailVerificationForm(props: any) {

    const InputSize = isMobile ? "45px" : "60px"

    const verifyCode = useAuth((state)=>state.verifyCode)
    const sendVerifyEmailCode = useAuth((state)=>state.sendVerifyEmailCode)

    const navigate = useNavigate()
    const location = useLocation()
    const State: StateParams = location.state as StateParams

    const { handleSubmit, reset, control, register } = useForm();
    const [openModel, setModelState] = useState<{
        model: boolean;
        dialog: "expired" | "invalid" | "empty" | null
    }>({
        model: false,
        dialog: null
    })
    const [myCode, setCode] = useState<string>("")
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isBtnLoading, setIsBtnLoading] = useState<boolean>(false)

    const onSubmit = (data: any) => {
        console.log(data);
        setIsBtnLoading(true)

        try {

            let code: any = []
            Object.keys(data).map(key => {
                code.push(data[key].toLowerCase())
            })

            let c = [...code]
            c.splice(3, 0, "-")
            setCode(c.join("").toUpperCase())


            verifyCode(code, State.email).then((res: any) => {
                // Handle invalid
                if (!res.valid) {
                    // display message to retype 
                    setModelState({
                        model: true,
                        dialog: "invalid"
                    })

                }

                // Valid but expired
                if (res.valid && res.expired) {
                    // display message to retype 
                    setModelState({
                        model: true,
                        dialog: "expired"
                    })

                }

                // Valid and not expired
                if (res.valid && !res.expired) {
                    navigate("/signin-process/signin_password", {state: {email: State.email, emailValidated: true }})
                    console.log('we can navigate ', res)
                }

                setIsBtnLoading(false)
            })

        } catch (error) {
            // console.log(error)
            setModelState({
                model: true,
                dialog: "empty"
            })

            // setIsBtnLoading(false)
        }
    }

    const handleAnswer = (e: any) => {
        console.log("dialog response: ", e)

        if (e === 'Yes') {
            reset()
            setIsLoading(true)
            sendVerifyEmailCode(State.email).then(res => {
                console.log("email verification response: ", res)
                setIsLoading(false)

            })
        }

        if (e === "Ok") {
            setIsBtnLoading(false)
        }
    }


    useEffect(() => {
        if (props.reloadForm !== undefined
            && props?.reloadForm !== null && props?.reloadForm === true) {

            handleAnswer("Yes")
            props.cancelReloadForm(false)
        }
    }, [props.reloadForm])

    return (
        <Wrapper>
            <Box sx={{ width: "100%", color: grey[900] }}>
                <Box >
                    {
                        isLoading && <Box sx={{ display: "flex", justifyContent: "center", height: "30px" }}>sending email</Box>
                    }
                    <form>
                        <Stack gap={2}>


                            <Box sx={{ display: "flex" }}>


                                <Box sx={{ width: InputSize, margin: "0 4px" }}>
                                    <Controller

                                        name={"1"}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                {...register("1", { required: "required" })}
                                                onChange={onChange}
                                                value={value}
                                                autoFocus
                                                inputProps={{
                                                    ...InputBoxSX
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ width: InputSize }}>
                                    <Controller

                                        name={"2"}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                {...register("2", { required: "required" })}
                                                onChange={onChange}
                                                value={value}
                                                inputProps={{
                                                    ...InputBoxSX,
                                                }} />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ width: InputSize, margin: "0 4px" }}>
                                    <Controller
                                        name={"3"}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                {...register("3", { required: "required" })}
                                                onChange={onChange}
                                                value={value}
                                                inputProps={{
                                                    ...InputBoxSX
                                                }} />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "30px", fontSize: "24pt" }}>
                                    -
                                </Box>
                                <Box sx={{ width: InputSize, margin: "0 4px" }}>
                                    <Controller
                                        name={"4"}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                {...register("4", { required: "required" })}
                                                onChange={onChange}
                                                value={value}
                                                inputProps={{
                                                    ...InputBoxSX
                                                }} />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ width: InputSize }}>
                                    <Controller
                                        name={"5"}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                {...register("5", { required: "required" })}
                                                onChange={onChange}
                                                value={value}
                                                inputProps={{
                                                    ...InputBoxSX,

                                                }} />
                                        )}
                                    />
                                </Box>
                                <Box sx={{ width: InputSize, margin: "0 4px" }}>
                                    <Controller
                                        name={"6"}
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                {...register("6", { required: "required" })}
                                                onChange={onChange}
                                                value={value}
                                                inputProps={{
                                                    ...InputBoxSX,
                                                }} />
                                        )}
                                    />
                                </Box>
                            </Box>


                            {/* <Controller
                            name={"password"}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextField onChange={onChange} value={value} label={"Password"} />
                            )}
                        /> */}

                            <DialogController code={myCode} onOpen={openModel} openStateChange={setModelState} onAnswer={(e: string) => handleAnswer(e)} />
                            <Box sx={{ margin: "0 auto" }}>
                                <LoadingButton loading={isBtnLoading} variant="contained" onClick={handleSubmit(onSubmit)} sx={{ height: "45px", fontSize: "14pt", width: "160px" }}>Verify</LoadingButton>
                            </Box>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </Wrapper>
    )
}
