import { grey } from "@mui/material/colors"
import CheckIcon from '@mui/icons-material/Check';
import { alpha, Box } from "@mui/material"
import { theme } from "globals/theme"
import { styled } from "@mui/material"
import { useEffect, useState } from "react";

interface IPropsChecker{
    selected?: boolean
}
const MatChecker = styled(Box)(({selected}: IPropsChecker)=>({
    border: `2px solid ${selected ? theme.palette.primary.main : grey[400]}`,
    color: selected ? theme.palette.primary.main : grey[400],
    background: "#fff",
    borderRadius: "50%",
    fontSize: "1.7em",
    height: "120px",
    width: "120px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    position:"relative",
    "svg:last-child":{
        color: selected ? alpha(theme.palette.primary.main,0.8) : grey[400], 
        fontSize: "2.7em",
        
    }
}))
const MatCheck = styled(CheckIcon)(({selected}: IPropsChecker)=>({
    border: `2px solid ${theme.palette.primary.main}`,
    color: "#fff",
    background: theme.palette.primary.main,
    borderRadius: "50%",
    fontSize: "1.5em",
    position: "absolute",
    top: "-4px",
    right: 0,
    display: selected ? "block" : "none"
}))


interface IProps{
    icon?: React.ReactNode;
    defaultValue?: boolean;
}
const IconButtonSelector: React.FC<IProps> = ({icon, defaultValue})=>{
    const [selected, setSelected] = useState<boolean>(false)
    const handleToggle = ()=>{
        setSelected(!selected)
    }

    useEffect(()=>{
        if(defaultValue !== undefined){
            setSelected(defaultValue)
        }
    },[defaultValue])
    return (
        <MatChecker onClick={handleToggle} selected={selected}>
            <MatCheck selected={selected} />
            {icon}
        </MatChecker>
    )
}
export default IconButtonSelector