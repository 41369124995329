import { Box, Button } from "@mui/material";
import Row from "components/Row";

export default function UpgradeButton(){
    return (
        <Box sx={{
            position: "absolute", 
            right: "32px", 
            top: "32px", 
            display: "flex", 
            justifyContent: "flex-end", 
            flexDirection: "column", 
            width: "200px",
            textAlign: "right"
            }}>
           <Box sx={{width: "100%", display: "flex", justifyContent: "flex-end", mb: 2 }}>
               <Button variant="outlined" color="secondary">Upgrade</Button>
           </Box>
           <Row sx={{fontSize: "9pt"}}>upgrade your community capcity to add more accounts</Row>
       </Box>

    )
}