import { IIUser } from "Straclou/handler";

type ClearUser = Partial<IIUser>

export class IUser {
    private _uid: string = "";
    private _email: string = "";
    private _name: string = "";
    private _isPolicyChecked: boolean = false;
    private _policy: Array<any> = []
    private _isWorkspace: null | boolean;
    private _communities: string[] = [];
    private _userType: string | null = null


    constructor(user?: any) {
        this.init(user)
    }

    get uid() { return this._uid }
    get email() { return this._email }
    get name() { return this._name }
    get isPolicyChecked() { return this._isPolicyChecked }
    get policy() { return this._policy }
    get isWorkspace() { return this._isWorkspace }
    get communities() { return this._communities }
    get userType() { return this._userType }


    init(user: any) {
        if (user !== undefined && user !== null) {
            this._uid = user?.uid || ""
            this._email = user?.email || ""
            this._name = user?.name || ""
            this._isPolicyChecked = user?.isPolicyChecked || false
            this._policy = user?.policy || []
            this._isWorkspace = user?.isWorkspace || false
            this._communities = user?.communities
            this._userType = user?.userType || "Some User Type"

        }
    }

    get isAdmin() {
        return this._userType === "admin" ? true : false

    }

    toObject(): IIUser {
        return {
            name: this._name,
            uid: this._uid,
            email: this._email,
            policy: this._policy,
            isPolicyChecked: this._isPolicyChecked,
            isWorkspace: this._isWorkspace,
            communities: this._communities
        }
    }

    clear(): IIUser {
        return {
            name: "",
            uid: "",
            email: ""
        }
    }

}


