import React, { ReactNode, useEffect } from "react"
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import TextArea from "components/TextArea";
import Item from "./Item";
import { alpha, IconButton, styled, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import AddCommentIcon from '@mui/icons-material/AddComment';

import CloseIcon from '@mui/icons-material/Close';
import Search from "./Search";
import { IconButtonStyle, Header, Title, Footer } from "./CustomStyles";
import NewComment from "./NewComment";
import { sample_data } from "./sample";
import useCommentary from "./hooks/useCommentary";
import useCommentaryStore from "./hooks/CommentaryStore";






const List = styled(Box)({
    height: "100%",
    overflow: "hidden",
    overflowY: "auto",
    padding: "0 15px",

})


interface IProps {
    params?: {id:string, communityId: string};
    cbutton?: ReactNode
}
const Comments: React.FC<IProps> = ({ params, cbutton }) => {
    const [state, setState] = React.useState<boolean>(false)
    const [openNew, setNew] = React.useState<boolean>(false)

    const [query, setQuery] = React.useState<string>("")

    const {getNotes} = useCommentary()
    const {notes} = useCommentaryStore()

    useEffect(()=>{
        if(state === true && params!==undefined){
            console.log("this was called getNotes with id: ",params);  
            console.log("my list of notes: ", notes)      
            getNotes(params.id)
        }
    },[state, params])

    useEffect(()=>{
        console.log("active list notes: ", notes)
    },[notes])


    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState(open);
            };

            const search = (data: any)=>{
                const keys = ["author", 'rawText']
                return data.filter((item: any)=>keys.some(key=>item[key].toLowerCase().includes(query.toLowerCase())))
            }

    return (
        <>
            <React.Fragment>
                <Box onClick={toggleDrawer(true)}>
                    {cbutton}
                </Box>
                <Drawer
                    anchor={'right'}
                    open={state}
                    onClose={toggleDrawer(false)}
                    sx={{zIndex: 99999999999999}}
                >

                    {
                        openNew && <NewComment onClose={toggleDrawer(false)} onBack={setNew} accountParams={params}/>
                    }

                    {

                        !openNew &&


                        <Box>

                            <Box
                                sx={{
                                    width: 350, background: "0", flex: 1, display: "grid",
                                    gridTemplateColumns: "1fr",
                                    gridTemplateRows: "50px 1fr 50px",
                                    overflow: "hidden",
                                    height: "100vh"

                                }}
                                role="presentation"
                            >

                                <Header>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start"
                                    }}>
                                        <Search onChange={(value: string)=>setQuery(value)}/>
                                    </Box>
                                    <Title>
                                        Comments
                                    </Title>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end"
                                    }}>
                                        <IconButtonStyle size="small" onClick={toggleDrawer(false)}>
                                            <CloseIcon sx={{ color: "#fff", width: "20px", height: "20px" }} />
                                        </IconButtonStyle>
                                    </Box>
                                </Header>

                                {/* list */}
                                <List>
                                    {
                                        search(notes).map((el: any) => {
                                            return (
                                                <Item key={el.id} comment={el} />
                                            )
                                        })
                                    }

                                </List>


                                {/* Input area */}
                                <Footer>
                                    <Tooltip title="Add comment">
                                        <IconButtonStyle size="small" onClick={() => setNew(true)}>
                                            <AddCommentIcon sx={{ width: "20px", height: "20px", }} />
                                        </IconButtonStyle>
                                    </Tooltip>
                                </Footer>
                            </Box>
                        </Box>
                    }
                </Drawer>
            </React.Fragment>
        </>
    )
}

export default Comments