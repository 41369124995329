import { PolicyWrapper } from "./PolicyWrapper";

export default function PrivacyPolicy(){
    return (
        <PolicyWrapper>
        <div className="heading">
          <h1>Privacy Policy</h1>
        </div>
        <div className="section">
          <ol>
            <li>
              <h2>Introduction</h2>
              <p>
                Knymbus processes certain types of information about the individuals
                who use Stratous who come into contact with Knymbus in order to carry
                on our work. These individuals will be known as Service Users. The
                policy serves to provide details on Knymbus’ practices regarding the
                collection, use and disclosure of the information we collect from and
                about Service Users when they use Knymbus’ product, Stratous.
              </p>
            </li>
            <li>
              <h2>Data Controller</h2>
              <p>
                Knymbus is the Data Controller under the Data Protection Act 2020,
                which means that it determines what purposes and the manner in which
                Service Users’ personal information held will be used for. It is also
                responsible for notifiying the Information Commissioner of the data it
                holds or is likely to hold and the general purposes that this data
                will be used for.
              </p>
            </li>
            <li>
              <h2>Data Collection</h2>
              <p>
                The Data Protection Act 2020, defines consent as any informed,
                specific, unequivocal, freely given, expression of will by which the
                Service User (data subject) agrees to the processing of that Service
                User’s personal information.
              </p>
      
              <p>
                It also defines informed with reference to the giving of consent to be
                at the time in question the Service User (data subject) is informed
                about how the personal data will be processed, including the purpose
                for which the data may be used and the classNamees of persons to whom the
                personal data may be transferred.
              </p>
      
              <p>
                Knymbus will ensure that data is collected within the boundaries
                defined in this policy.
              </p>
      
              <h3>The Information We Collect</h3>
              <p>
                This section explains the information we collect from Service Users.
                We do not require Service Users to provide us with information.
                However, certain information, such as account log-in data, is required
                to provide you with access to Stratous, and other information may be
                collected automatically as you use the application.
              </p>
              <ol type="A">
                <li>
                  <h4>Information You Provide to Us</h4>
                  <p>Knymbus collects information from you through:</p>
                  <ul>
                    <li>
                      <b>Community Registration.</b> To start using Stratous, you will
                      first register your community with us by providing us with
                      certain information such as your community name, address, number
                      of units, email, phone number, estimated number of system users
                      and contact person information which would include name, email,
                      phone and position within the community.
                    </li>
                    <li>
                      <b>Account Information.</b> Service Users who would be using the
                      application must create user accounts. The information needed to
                      create user accounts are name, email and password. You will then
                      have the ability to provide optional profile information, such
                      as a photograph or basic demographic data.
                    </li>
                    <li>
                      <b>Information about others.</b> When you use our invitation
                      service to invite other Service users within your community
                      board or community office to Stratous, we will ask you for that
                      person’s contact information, which will include their name,
                      email address and position which are used to send an invitation
                      to these individuals. Knymbus stores the information you provide
                      to send the invitation, to register this user if your invitation
                      is accepted.
                    </li>
                    <li>
                      <b> Service Usage Information.</b> When using Stratous, we
                      collect information about how you use and interact with the
                      application about how you use and interact with Stratous. The
                      information collected include:
                      <ul>
                        <li>
                          <b>Log Files.</b> When you use Stratous, our servers
                          automatically record certain information in server logs and
                          these logs are reviewed on a monthly basis to ensure optimal
                          performance of Stratous. These server logs may include
                          information such as time stamp information, number of clicks
                          and how you interact with components in the application,
                          errors that happen in the background while you use Stratous,
                          processes that causes the application to crash and how the
                          various components of Stratous are used.
                        </li>
                        <li>
                          <b>Device Identifiers.</b> When you access the Stratous
                          using computers we collect specific device information,
                          including your IP address, MAC address (a unique identifier
                          that is used as a network address in communications with a
                          network), operating system such as Windows 10, host name of
                          the computer and the computer architecture such as 32-bit or
                          64-bit. We may associate this device identifier with your
                          account and will use data associated with your device
                          identifier to customize our application to your device and
                          to analyze any device-related issues.
                        </li>
                        <li>
                          <b> Location Information.</b>
                          We collect and process general information about the
                          location of the device from which you are accessing Stratous
                          (e.g., approximate geographic location inferred from an IP
                          address).
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <h4>Other Information</h4>
                  <p>
                    You may provide us with information when you interact with us in
                    other ways, such as when you submit requests or questions to us
                    via forms (technical support form) or email to receive customer
                    support and technical assistance.
                  </p>
                </li>
                <li>
                  <h4>Information We Automatically Collect</h4>
                  <p>
                    When you use links in Stratous that connect to our webiste,
                    including, but not limited to, Privacy Policy or other Stratous
                    related information, we automatically collect certain information
                    using cookies. These cookies are used to identify and track users
                    as they navigate different pages and it is also used to identify
                    returning users to our website. As discussed further below, we and
                    our service providers (Amazon Web Services who work on our behalf
                    to provide and enhance Stratous) use a variety of technologies,
                    including cookies and similar tools, to assist in collecting this
                    information.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>How Knymbus Use Your Information?</h2>
              <p>
                This section explains how Knymbus uses information collected from
                Service Users.
              </p>
              <p>
                <b
                  >Account Information, Service Usage Information and Other
                  Information.</b
                >
                Knymbus may use these categories of information collected from and
                about Service Providers to:
              </p>
              <ul >
                <li>
                  Send expiration notices via emails or in-app for plans you have
                  purchased.
                </li>
                <li>
                  Extending the life of the plan purchased after verification of
                  payment.
                </li>
                <li>
                  Respond to your request for customer service and technical
                  assistance.
                </li>
                <li>
                  Provide you with notifications about product updates within the
                  application.
                </li>
                <li>
                  Provide you with service notifications via email or within the
                  application based on your notification settings.
                </li>
                <li>
                  Communicate with you via email or through the app about important
                  notices and updates regarding Stratous to inform you about changes
                  in the application, our service offerings and important
                  software-related notices such as security issues. You can determine
                  if you want to receive these notices via email or within the app or
                  both in the settings.
                </li>
                <li>
                  Synthesize and derive insights from log data which can help us to
                  continue to manage and improve Stratous for your benefit.
                </li>
                <li>
                  Prevent or address technical or security issues based on log data.
                </li>
                <li>
                  Understand and resolve app crashes and other issues being reported
                  via the log data.
                </li>
                <li>
                  In accordance with applicable legal obligations, communicate with
                  you about promotions, offers and news about Stratous. You have the
                  ability to unsubcribe from such promotional communications.
                </li>
              </ul>
            </li>
            <li>
              <h2>How Knymbus Share Your Information?</h2>
              <p>
                Knymbus uses a third-party to store your data. This third-party is
                Amazon Web Services (AWS). This third party provides a variety of
                services to us such as data storage and security and other services
                that Stratous is built on. Your information stored with AWS is in
                encrypted form and we work with them to ensure your data is
                safeguarded using the tools available to us to mitigate against
                breaches.
              </p>
            </li>
            <li>
              <h2>Data Storage</h2>
              <p>
                As stated above, we use AWS as our data storage provider, however,
                information and records relating to Service Users will will be stored
                securely and will only be accessible to authorised staff.
              </p>
      
              <h4>How Long We Store Your Information?</h4>
              <p>
                We will retain your information for the period necessary to fulfill
                the purposes outlined in this Policy unless a longer retention period
                is required or permitted by law. <br />
                Information will be stored for only as long as it is needed or
                required statute and will be disposed of appropriately according to
                the Data Protection Act 2020.
              </p>
            </li>
            <li>
              <h2>Data Access and Accuracy</h2>
              <h4>Your Rights</h4>
              <p>
                The Data Protection Act 2020 provides rights to users (data subjects)
                which Knymbus will adhere to in order to be compliant with the Act.
                Therefore, Service Users have the right to access the information
                Knymbus holds about them. If you would like a copy of some or all of
                the personal information, please email at info@knymbus.com. We may
                charge a fee for this service. <br />
      
                In addition, you can make rectification of any inaccuracies of your
                data or delete certain information within the application in the
                approciate sections such as settings or profile settings. Where you
                have provided consent, you may withdraw your consent at any time
                within the application, without affecting the lawfulness of the
                processing that was carried out prior to withdrawing your consent.
                <br />
      
                This policy will be updated as necessary to reflect best practice in
                data management, security and control and to ensure compliance with
                any changes or amendments made to the Data Protection Act 2020.
              </p>
            </li>
            <li>
              <h2>Security</h2>
              <h4>How Knymbus Protect Your Information?</h4>
              <p>
                Knymbus takes technical and organizational measures to protect your
                personal data against accidental or unlawful destruction or accidental
                loss, alteration, unauthorized disclosure or access. However, no
                method of transmission over the Internet, and no means of electronic
                or physical storage, is absolutely secure, and thus we cannot ensure
                or warrant the security of that information. If you have any questions
                about security on our Services, you can contact us at
                info@straclou.com.
              </p>
            </li>
            <li>
              <h2>Changes To Our Privacy Policy</h2>
              <p>
                We reserve the right to amend this Privacy Policy at any time to
                reflect changes in the law, our data collection and use practices, the
                features of Straclou, or advances in technology. We will make the
                revised Privacy Policy accessible through Stratous, so you should
                review the Privacy Policy periodically. If we make a material change
                to the Privacy Policy, you will be provided with appropriate notice
                and we will seek your consent to the updated Privacy Policy in
                accordance with legal requirements.
              </p>
            </li>
            <li>
              <h2>Straclou Contact Information</h2>
              <p>
                If you wish to contact us or have any questions about or complaints in
                relation to this Privacy Policy, please contact us at
                info@straclou.com.
              </p>
            </li>
          </ol>
        </div>
      </PolicyWrapper>
      
    )
}