import DateTimeExhibit from "components/DateTimeExhibit"
import ClouItemInfo from "components/Display/ClouItemInfo"
import ClouItemTitle from "components/Display/ClouItemTitle"
import Row from "components/Row"
import { Box, Stack } from "@mui/material"
import { info } from "console"
import React from "react"
import { useLocation } from "react-router-dom"

interface StateType {
    name: string
    address: any
    unitNumber: string
    phase: string


}
interface IProps {
    accountId?: string
}
const InvoicePrimaryOwnerCard: React.FC<IProps> = ({ accountId }) => {
    const location = useLocation()
    const state = location.state as StateType

    return (
        <Box sx={{ width: "250px", paddingTop: "230px" }}>
            <Row>
                <ClouItemInfo fontSize={"1.4em"}>
                    Bill to:
                </ClouItemInfo>
            </Row>
            <Row>
                <ClouItemInfo fontWeight={700}>
                    {state.name}
                </ClouItemInfo>
            </Row>
            <Row sx={{lineHeight: "1.1em"}}>
                <ClouItemTitle fontSize={'.8em'}>
                    Unit {state.unitNumber}, Phase {state.phase},
                </ClouItemTitle>
                <ClouItemTitle fontSize={'.8em'}>
                    {state.address.street}
                </ClouItemTitle>
            </Row>
        </Box>
    )
}

export default InvoicePrimaryOwnerCard