import { create } from "zustand"
import { IAccountInput } from "../classes/IAccountInput"

const NewAccountInput = new Map()
NewAccountInput.set('owner_name', 'name')
NewAccountInput.set('property_type', 'propertyType')
NewAccountInput.set('lot_number', 'lotId')
NewAccountInput.set('fee', 'fee')
interface SummaryStore {
    list: Array<IAccountInput>,
    updateList: (item: any) => void,
    clearSummary: ()=>void
    newAccountInput: (fn: Function)=>void
}

let summaryStore: any = (set: any) => ({
    list: [],
    updateList: (item: IAccountInput) => set((s: SummaryStore) => {
        let myList = s.list || []
        let isIndex = myList.findIndex((i: IAccountInput) => (i.key === item.key ))
                
        // We did not fnd a key therefore this object is not part of the list
        if (isIndex === -1) {
            //The item is new 
            myList.push(item)
        } else {
            // The item is old but we are going to update it 
            myList[isIndex] = item
        }

        return {
            list: [...myList]
        }
    }),
    clearSummary: ()=>set((s:SummaryStore)=>({
        list: []
    })),
    newAccountInput: (fn: Function)=>set((s: SummaryStore)=>{
        let obj: any = {}

        /**
         * Convert the class object to the input for the server send off
         */
        s.list.forEach(el=>{
            obj[NewAccountInput.get(el.fieldName)] = el.value
        })

        // Attach the cid to the object for object 0
        obj['cid'] = s.list[0].cid
        fn(obj)
        return s
    })
})

// userAccess"" = persist(userAccess"", { name: userHandler.userAccessTag })

const useSummaryStore = create<SummaryStore>(summaryStore)

export default useSummaryStore