import React from 'react'
import { Controller, useFormContext } from "react-hook-form"
import { InputIProps } from './interface'
import { FormControl, FormHelperText, InputAdornment, OutlinedInput } from '@mui/material'
import { useClouFormContext } from './ClouFormContext'
import ClouItemTitle from '../Display/ClouItemTitle'





const ClouFormInputNumber: React.FC<InputIProps> = ({ name, label, hint, placeholder }) => {
    const { control } = useFormContext()
    const { activeColor } = useClouFormContext();



    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => (
                <FormControl variant="filled" fullWidth>
                    {/* <InputLabel id="textfield-label">{label}</InputLabel> */}
                    <ClouItemTitle fontSize={'.8em'} paddingLeft="8px">{label}</ClouItemTitle>
                    <OutlinedInput
                    type='number'
                        size="small"
                        error={!!error}
                        onChange={e => onChange(e.target.value)}
                        value={value}
                        placeholder={placeholder}
                        fullWidth
                        color={activeColor}
                        startAdornment={
                            <InputAdornment position="start">
                            </InputAdornment>
                        }
                    />
                    <FormHelperText id="component-helper-text">
                        <>
                            {error ? error.message : hint}

                        </>
                    </FormHelperText>
                </FormControl>
            )}
        />

    )
}

export default ClouFormInputNumber