import { useState, useEffect } from "react"
import useWorkspace from "hooks/useWorkspace"
import { ListFeeByCategoryInput } from "Straclou/API"
import { CircularProgress } from "@mui/material"
import useCategoryFeeStore from "hooks/useCategoryFeeStore"
import useWorkspaceStore from "hooks/useWorkspaceStore"

interface IProps {
    type: ListFeeByCategoryInput
}
export default function CategoryButtonItemCount(props: IProps) {

    const { type } = props

    const [detail, setDetail] = useState<any>(useCategoryFeeStore.initialFeeCountState)

   useEffect(() => {
       useCategoryFeeStore.subscribeCount(setDetail)
        useCategoryFeeStore.initFeeCount(type)
    }, [type])

    return (
        <>
            {
                detail?.[type.category].number === -1 ?
                    <CircularProgress size={"1em"} color="secondary" /> :
                    detail[type.category].message
            }
        </>
    )
}