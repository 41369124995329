import { IconButton, alpha, styled, Box } from "@mui/material";
import { grey } from "@mui/material/colors";

export const IconButtonStyle = styled(IconButton)(({ theme }) => ({
    borderRadius: "5px",
    // background: "purple",
    "&:hover": {
        background: alpha(theme.palette.secondary.light, .2)
    }

}))

export const IconButtonSmall = styled(IconButton)(({ theme }) => ({

    // background: "purple",
    "&:hover": {
        background: alpha(theme.palette.secondary.light, .2),

    }

}))


export const Header = styled(Box)(({ theme }) => ({
    background: theme.palette.primary.main,
    height: "50px",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    padding: "0 8px",
    color: "#fff"
}))

export const Title = styled(Box)(({ theme }) => ({
    fontSize: "12pt",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff"
}))

export const Footer = styled(Box)({
    fontSize: "8pt",
    paddingLeft: "8px",
    height: "50px",
    borderTop: `1px solid ${grey[200]}`,
    display: "flex",
    alignItems: "center",
    background: "#fff"
})