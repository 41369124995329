import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { grey } from '@mui/material/colors';

export default function MemberButton(props: any) {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            border: `1px solid ${grey[300]}`,
            padding: "0 16px",
            borderRadius: "16px",
            fontSize: "10pt",
            minWidth: "120px"

        }}>
            <SettingsIcon sx={{width: "18px", mr: 2}}/>
            {props?.type}
            <ExpandMoreIcon sx={{width: "18px", ml: 2}}/>
        </Box>
    )
}