import { useState } from "react";
import { IWorkspace } from "Straclou/commons/IWorkspace";
import { QueryParams, WorkspaceManager } from "Straclou/handler"
import useAuth from "./useAuth"

interface IResponseField{
    createdAt?: string
    members?: string[]
    owner?: string
    status?: {
        progress?: number
        state?: string
    }
    type?: string
}

export default function useWorkspace() {

    //Hooks
    const { user } = useAuth();

    // States
    const [iWorklist, setIWorkspaceList] = useState<Array<IWorkspace>>([]);
    const [isLoadingList, setLoadingList] = useState<boolean | null>(null)

    const getWorkspaceListing = async () => {
        setLoadingList(true)
        WorkspaceManager.WorkspaceList(user?.uid).then(res => {
            setIWorkspaceList([...res])
            setLoadingList(false)
        })
    }

    const queryWorkspaceById = async (workspaceId: string, responseField: Array<string>)=>{
        return new Promise((resolve, reject)=>{
            let queryParams: QueryParams = {
                input: {
                    workspaceId,
                    responseField
                },
                method: "dynamicQuery"
            }
            WorkspaceManager.query(queryParams).then(res=>{
                resolve(res)
            }).catch(error=>{
                reject({})
            })
        })
    }

    const updateWorkspaceById = async (workspaceId: string, query: any)=>{
        return new Promise((resolve, reject)=>{
            WorkspaceManager.update({
                id: workspaceId,
                data: {...query}
            }).then(res=>{
                resolve(res)
            }).catch(err=>{
                console.log(err)
            })
        })
    }

    const getFeeCountByCategory = async (payload: {communityId: string, category: string})=>{
        return new Promise((resolve, reject)=>{
            WorkspaceManager.getFeeCountByCategory(payload).then(res=>{
                resolve(res)
            })
        })
    }


    const listFeeByCategory = async (payload: {communityId: string, category: string})=>{
        return new Promise((resolve, reject)=>{
            WorkspaceManager.listFeeByCategory(payload).then((res: Array<any>)=>{
                resolve(res)
            })
        })
    }

   

    // const onWorkspace = ()=>{
    //     CommunityManager.onWorklistenger().subscribe((res:any)=>{
    //         console.log("result: ",res)
    //         setList([...res])
    //         setWorkspaceCount(res.length)
    //         if (res.length > 0) {
    //             setIsWorkspace(true)
    //         } else {
    //             setIsWorkspace(false)
    //         }
    //         setLoadingList(false)
    //     })
    // }

 
    return { 
        getWorkspaceListing, 
        iWorklist, 
        isLoadingList, 
        queryWorkspaceById, 
        updateWorkspaceById, 
        getFeeCountByCategory,
        listFeeByCategory,
        
    }
}