import { axiosExecute, AxiosOptions } from "Straclou/axios"
import { ConnectionStrings } from "Straclou/ConnectionStrings"

export module ApplicationNavigationHandler{

    export function onLogin(params: any){
        return new Promise(async(resolve, reject)=>{
            
            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.userConnection(),
                data: JSON.stringify({
                    input: params,
                    method: 'onLogin'
                })

            }
            const data = await axiosExecute(option)
            resolve(data)
        })
    }
}