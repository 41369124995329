import ClouMoneyExhibit from 'components/ClouMoneyExhibit'
import Row from 'components/Row'
import { Box, styled, Table, TableHead, TableRow, TableBody } from '@mui/material'
import { grey } from '@mui/material/colors'
import React from 'react'


interface IProps {
    accountId?: string
}


const TableHeader = styled('th')(({
    // fontSize: ".9em",
    color: grey[500]

}))

const TableRowDetail = styled('tr')(({
    height: "32px",
    // fontSize: ".9em",
    color: grey[900],
    borderBottom: `1px solid ${grey[300]}`

}))
const TableDetail = styled('td')(({
    // fontSize: ".9em",
    color: grey[800]

}))
const TableHeaderDetail = styled('th')(({
    textAlign: "left",
    // fontSize: ".9em",
    color: grey[500]

}))
const InvoicePaymentCard: React.FC<IProps> = ({ accountId }) => {
    return (
        <Box>

            <Row sx={{
                paddingLeft: "15px",
                width: "90%",
                margin: '0 auto',
                fontWeight: 700
            }}>Recorded Payment(s)</Row>

            <Row height={15} />
            <Row sx={{
                paddingLeft: "15px", width: "90%",
                margin: '0 auto',
            }}>

                <Table sx={{ fontSize: ".9em" }}>
                    <TableHead>
                        <TableRow sx={{ borderBottom: `1px solid ${grey[300]}`, height: "30px" }}>
                            <TableHeaderDetail>Date</TableHeaderDetail>
                            <TableHeaderDetail>Reference Id</TableHeaderDetail>
                            <TableHeaderDetail>Payment</TableHeaderDetail>
                            <TableHeaderDetail>Balance</TableHeaderDetail>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRowDetail>
                            <TableDetail>Jun 12, 2022</TableDetail>
                            <TableDetail>REF000000000097</TableDetail>
                            <TableDetail><ClouMoneyExhibit value={5000} /></TableDetail>
                            <TableDetail><ClouMoneyExhibit value={30000} /></TableDetail>
                        </TableRowDetail>
                        <TableRowDetail>
                            <TableDetail>Jun 14, 2022</TableDetail>
                            <TableDetail>REF000000000107</TableDetail>
                            <TableDetail><ClouMoneyExhibit value={15000} /></TableDetail>
                            <TableDetail><ClouMoneyExhibit value={15000} /></TableDetail>
                        </TableRowDetail>
                        <TableRowDetail>
                            <TableDetail>Jun 16, 2022</TableDetail>
                            <TableDetail>REF000000000139</TableDetail>
                            <TableDetail><ClouMoneyExhibit value={15000} /></TableDetail>
                            <TableDetail><ClouMoneyExhibit value={0} /></TableDetail>
                        </TableRowDetail>
                    </TableBody>
                </Table>
            </Row>
        </Box>
    )
}

export default InvoicePaymentCard