import { Box, styled} from "@mui/material"
import React, { useEffect } from "react"
import logo from "../logo.svg"
import "./Logo.css"

const Wrapper = styled(Box)(({
    padding: " 16px 0 0 8px",
    display: "flex"
}
))

interface LogoNameIProps{
    fsize: string | number
}
const LogoName = styled('span', { name: "Logo-Name" })(({fsize}: LogoNameIProps)=>({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: fsize,
    fontWeight: 600,
    marginLeft: "8px"
}))

interface IProps {
    variant?: "small" | "normal" | "large" | "extra_large"
}

const LogoSize: any = {
    small: {
        width: "25px",
        pfontSize: ".7em",
        bfontSize: "1.2em"
    },
    normal: {
        width: "35px",
        pfontSize: ".8em",
        bfontSize: "1.7em"
    },
    large: {
        width: "45px",
        pfontSize: "1.1em",
        bfontSize: "2em"
    },
    'extra_large': {
        width: "55px",
        pfontSize: "1.5em",
        bfontSize: "2.7em"
    }
}
/**
 * Todo: We need to make this function determine the type of subscription based on the following 
 *       Category:
 *      1. Community
 *      2. Individual
 *      3. Commercial
 *      4. Hybrid Mix
 * 
 * @param param0 
 * @returns 
 */
const StraclouLogo: React.FC<IProps> = ({ variant }) => {
    const [size, setSize] = React.useState(LogoSize["normal"])

    // TODO: Add your useSubscriptionType function and get {sType}
    // const {subscriptionType} = useSubscriptionType()

    // Stub
    const subscriptionType = ""

    useEffect(() => {
        if(variant !==undefined) setSize(LogoSize[variant])
    }, [variant])
    return (
        <Wrapper>
            <img src={logo} width={size.width} color={"#fff"} alt="Straclou logo" />
            <LogoName className="logo_name" fsize={size.bfontSize}>Straclou</LogoName>
            <Box sx={{ fontSize: size.pfontSize }}>{subscriptionType}</Box>
        </Wrapper>
    )
}

export default StraclouLogo