import React from "react";
import { styled, SxProps } from "@mui/system";
import { alpha } from '@mui/material'
import { theme } from "globals/theme";



const Center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
}

const Wrapper = styled('div', {name: "clou-wrapper"})(({
    // ...Center,
    height: "calc(100vh - 30px)",
    width: "100vw",
    position: "relative",
    padding: "0",
    transition: '2s',
    overflow: "hidden",
    overflowY: "auto",
    '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
        opacity: 1,
    }

}))


export default function ClouPageWrapper(props: { children?: React.ReactNode, sx?: SxProps }) {
    const { children, sx } = props
    return <Wrapper sx={sx}>{children}</Wrapper>
}



const PageContainerStyle = styled('div')(({
    position: "relative",
    margin: "0 auto",
    minWidth: "600px",
    width: "75%",
    height: "auto"
}))

export function ClouPageContainer(props: { children: React.ReactNode, sx?: SxProps }) {
    const { children, sx } = props

    return (
        <PageContainerStyle sx={sx}>{children}</PageContainerStyle>
    )

}

interface IPageContentStyle{
    height?: string
}
const PageContentStyle = styled("div", { name: "Cmui-Page-Content", slot: "root" })(({height}: IPageContentStyle)=>({
    width: "98%",
    margin: "8px auto",
    minWidth: "400px",
    height: height ?? "97.5%",
    position: "relative",
    overflow: "hidden",
    overflowY: "auto",
    background: "#fff",
    '&::-webkit-scrollbar': {
        width: '5px',
        height: '5px',
        opacity: 1,
    }
}))

const PageContentContainerStyle = styled('div', { name: "Cmui-Page-Content", slot: "Container" })(({
    height: "auto",
    width: "97%",
    padding: "15px",
    position: "relative",
    // background: "red",
    transition: '2s',
    
   

}))

export const PageContent = (props: { children: React.ReactNode }) => {
    const { children } = props
    return <PageContentStyle >
        <PageContentContainerStyle>
            {children}
        </PageContentContainerStyle>
    </PageContentStyle>
}
export const ClouPageContent = (props: { children: React.ReactNode, height?: string }) => {
    const { children, height } = props
    return <PageContentStyle height={height}>
        <PageContentContainerStyle>
            {children}
        </PageContentContainerStyle>
    </PageContentStyle>
}