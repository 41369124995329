import { ButtonBase, styled, Box, CircularProgress, Tooltip } from "@mui/material"
import { grey } from "@mui/material/colors"
import useWorkspaceStore from "hooks/useWorkspaceStore"
import { ReactNode, useEffect, useState } from "react"
import { CategoryButtonProps } from "./ButtonInterface"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const CategoryHolder = styled(ButtonBase, { name: "Category-Holder" })({
    background: "white",
    color: "#181717",
    width: "250px",
    height: "82px",
    borderRadius: "25px",
    border: "1px solid #1a1a1a1a",
    padding: "0",

    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    cursor: "pointer",
    transition: '500ms',
    "&:hover": {
        boxShadow: "0px 1px 9px 0px #c9c9c9e6",
        border: "1px solid #1a1a1a26",
    },
    "&:hover.Category-Holder-Name": {

    }
})


const CHImage = styled(Box, { name: "Category-Holder-Image" })({
    // background: "#009688",
    width: "88px",
    height: "82px",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "8px",
})

const CHContent = styled(Box, { name: "Category-Holder-Content" })({
    padding: '0 16px',
    width: 'calc(100% - 88px)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column'
})

const CHName = styled(Box, { name: "Category-Holder-Name" })<{ mycolor: string }>(({ mycolor }) => ({
    fontWeight: 'bold',
    marginBottom: '4px',
    color: mycolor === '#009688' ? "inherit" : mycolor
}))

const CHCount = styled(Box, { name: "Category-Holder-Count" })({
    fontSize: "10pt",
    color: grey[500]
})



const CHCheck = styled(Box, { name: "Category-Holder-Check" })({
    position: "absolute",
    bottom: "10px",
    right: '10px',
    background: "white",
    // width: "10px",
    // height: "10px",
    borderRadius: "50%",
    // transform: "translateY(25px)",
    // border: "2px solid #71c56a8f",
    // boxShadow: "0px 5px 9px 0px #71c56a8f",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
})



export default function (props: CategoryButtonProps) {

    const { icon, primaryText, secondaryText, onClick, check, value, subValue } = props

    const [disabled, setDisabled] = useState("#009688")
    const [hoverText, setHoverText] = useState<string>("")

    const handleClick = () => {
        let args = {
            icon,
            primaryText,
            secondaryText,
            value,
            subValue,
            check
        }
        onClick?.(args)
    }

    useEffect(() => {
        if (check) {
            setDisabled("#009688")
            setHoverText(primaryText || "")
        } else {
            setDisabled("#d0d5d4")
            setHoverText("Not part of your structure")
        }

    }, [check])

    
    return (
        <CategoryHolder onClick={() => { handleClick() }} disabled={!check}>
            <Tooltip title={hoverText}>
                <CHImage bgcolor={disabled}>
                    {icon}
                </CHImage>
            </Tooltip>
            <CHContent>
                <CHName mycolor={disabled}>{primaryText}</CHName>
                <CHName mycolor={disabled}>{value}</CHName>
                <CHCount>{subValue}</CHCount>
            </CHContent>
            {
                check ? <CHCheck><CheckCircleIcon htmlColor={disabled} /></CHCheck> :
                    <CHCheck><CancelIcon htmlColor="#eb8f8f" /></CHCheck>
            }

        </CategoryHolder>
    )
}