import { API, CreateCommentaryInput, ListCommentaryInput } from "Straclou/API"




export module CommentaryManager{
    export const create = async (params: CreateCommentaryInput)=>{
        try {
            const data = await API.commentaryService('create', params)

            console.log("Comment successfully added: ", data)
            return data
        } catch (error) {
            return null
        }
        
    }

    export const listCommentary = async(params: ListCommentaryInput)=>{
        try {
            const data = await API.commentaryService('list', params, params.accountId)

            console.log("Comment successfully retrieved: ", data)
            return data
        } catch (error) {
            return null
        }
        
    }
}