import { IWidgetBuilderRoute, WidgetBuilder, WidgetBuilderOutlet } from "@knymbus/react-widget-builder";

import { Box, styled, Divider } from "@mui/material"
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { v4 } from "uuid";
import FullDirectory from "./Widgets/FullDirectory";


const Wrapper = styled(Box)({
    margin: "0",
    padding: "1.25em",
    // background: 'red',
    height: "calc(100vh - 90px)",
    display: "grid",
    gridTemplateColumns: '30% 1fr',
    gap: "0 16px"
})

const ResidentListing = () => {

    const routes: IWidgetBuilderRoute[] = [
        {
            index: true,
            path: "general",
            window: <FullDirectory  key={v4()} />,
            transition: true
        }

    ]

    const ListAccountWidget = new WidgetBuilder({
        name: "listing",
        routes: routes
    })
   
    
    return (
        <Wrapper>
            <Box sx={{ background: grey[100], display: "flex", alignItems: "flex-start", justifyContent: "flex-start", padding: "30px 15px", flexDirection: "column"  }}>
                Performance Analysis of your community view of one year to date.

                <Divider />

                <ul style={{listStyle: "none", padding: 0, lineHeight: "30px"}}>
                    <li>Collection today $623,674.90</li>
                    <li>Collection yesterday $185,000.00</li>
                </ul>
            </Box>
            <WidgetBuilderOutlet builder={ListAccountWidget}  />
        </Wrapper>
    )
}

export default ResidentListing