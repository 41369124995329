import { Box, styled, alpha } from "@mui/material"
import { grey } from "@mui/material/colors"
import { Outlet } from "react-router-dom"


const Wrapper = styled(Box)(({
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: alpha(grey[100], 0.6),

}))
export default function AuthMainActivities(){
    return (
        <Wrapper>
            <Outlet />
        </Wrapper>
    )
}

