import { Observable } from "rxjs";
import { IWorkspace } from "Straclou/commons/IWorkspace";
import { useState } from "react";


export default function useCommunity() {
    
    const [isWorkspace, setIsWorkspace] = useState(false);
    const [list, setList] = useState<Array<IWorkspace>>([]);
    const [workspaceCount, setWorkspaceCount] = useState<number>(0);
    const [isLoadingList, setLoadingList] = useState<boolean | null>(null)
    const [activeCommunity, setActiveCommunity] = useState<IWorkspace | null>(null)

    

 


    // useEffect(() => {
    //         if (params !== undefined && params.hasOwnProperty('workspaceId')) {
    //         CommunityManager.workspaceById({ workspaceId: params?.workspaceId || "" }).then(res => {
    //             setActiveCommunity(res)
    //         }).catch(err => {
    //             console.log("Error: ", err)
    //         })
    //     }
    // }, [params])


    /**
     * Observable to watch the creation of a workspace
     * @param workspaceId 
     * @param workspaceCredential 
     * @returns 
     */
    const createWorkspace= (workspaceId: string, workspaceCredential: any): Observable<any>=>{
        
        return new Observable((subscriber)=>{
            console.log("progress: ",workspaceCredential)
            console.log("workspace ID: ", workspaceId)
        })
    }

    


    return { createWorkspace, isWorkspace, communityList: list, workspaceCount, isLoadingList, activeCommunity }
}