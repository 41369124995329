import StraclouLogo from "../../components/StraclouLogo"
import { Box, styled, alpha } from "@mui/material"
import { grey } from "@mui/material/colors"
import { theme } from "globals/theme"
import { ButtonBase } from '@mui/material';
import { useNavigate } from "react-router-dom";

const Wrapper = styled(Box)(({
    width: "60vw",
    height: "80vh",
    
}))

const ButtonItem = styled(ButtonBase)(({
    borderTop: `1px solid ${grey[300]}`,
    display: "block",
    padding: "1.75em 0 !important",
    textAlign: "center",
    cursor: "pointer",
    width: "100%",
    "&:hover": {
        background: alpha(alpha(theme.palette.primary.main, 0.1), .051),
        color: theme.palette.primary.main
    },
    "&:last-child": {
        borderBottom: `1px solid ${grey[300]}`,
    }
}))

const OnboardingStepOne = () => {

    const navigate = useNavigate()

    const handleClick = (e: string)=>{
        switch (e) {
            case 'c':
                navigate('/onboarding/community_setup')
                break;
        
            default:
                break;
        }
    }
    return (
        <Wrapper>
            <Box sx={{position: "absolute", top: 0, left: 0}}>
            <StraclouLogo />
            </Box>
            <Box sx={{
                fontSize: '2.3em', fontWeight: "600", margin: "30px 0 50px 0",
                color: theme.palette.primary.main,
                textAlign: 'center'
            }}>Choose the workspace type you are interested in managing.</Box>
            <ButtonItem onClick={()=>handleClick('c')}>
                <Box sx={{ fontSize: "2.7em" }}>Community</Box>
                <Box>Manage a single community and all its amenities</Box>
            </ButtonItem>
            {/* <ButtonItem>
                <Box sx={{ fontSize: "2.7em" }}>Individual</Box>
                <Box>Manage multiple properties in multiple communities and all there amenities.</Box>
            </ButtonItem> */}
        </Wrapper>
    )
}

export default OnboardingStepOne