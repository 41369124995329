import { Amplify, Auth } from "aws-amplify";
import awsmobile from '../../aws-exports'
import { UserHandler } from "./UserHandlerCognito";



Amplify.configure(awsmobile)
Auth.configure(awsmobile);

UserHandler.init()



export const activeUser = UserHandler.activeUser

export const userHandler = UserHandler

export default function handler(){
    if(activeUser?.uid?.length === 0){
        userHandler.init()
    }
}