import { WorkspaceManager } from "Straclou"
import { ICommunityWorkspace } from "Straclou/commons/ICommunityWorkspace"
import { IIdentity } from "Straclou/commons/IIdentity"
import { create } from "zustand"

interface CommunityWorkspaceStore{
    workspace: ICommunityWorkspace
    wIdentity: IIdentity
    setWorkspace: (communityId: string)=>Promise<void>
    setIdentity: (wid: IIdentity)=>void
}

let communityWorkspaceStore: any = (set: any) => ({
    workspace: new ICommunityWorkspace(),
    wIdentity: new IIdentity,
    setWorkspace: async (communityId: string)=> set(async (s: CommunityWorkspaceStore)=>{
        console.log(">>>>>> Community Id: >>>>>>>>", communityId)
        const icw = new ICommunityWorkspace(await ICommunityWorkspace.activate(communityId))
        const identity = await WorkspaceManager.getWorkspace(communityId || "")
        s.setIdentity(new IIdentity(identity))
        // s.setIdentity(icw.identity)
        console.log("We got our Identity: ",icw.identity)
        return {
            ...s,
            workspace: icw,
        }
    }),
    setIdentity: (wid: IIdentity)=>set((s: CommunityWorkspaceStore)=>{
        return {
            ...s,
            wIdentity: wid
        }
    })
})

const useCommunityWorkspaceStore = create<CommunityWorkspaceStore>(communityWorkspaceStore)

export default useCommunityWorkspaceStore