import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Box, Button, FormHelperText, MenuItem, Select, Stack, styled, TextField } from "@mui/material";
import { SubTitle, Title } from "components/Title";
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import RoofingIcon from '@mui/icons-material/Roofing';
import { grey } from "@mui/material/colors";
import ClouSquareFootageExhibit from "components/ClouSquareFootageExhibit";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { Controller, useForm } from "react-hook-form";
import { UIFormWrapper } from "./Helpers/UIWrapper";
import Row from "components/Row";
import { useEffect, useState } from "react";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import useCategoryFeeStore from "hooks/useCategoryFeeStore";
import { AccountManager } from "Straclou/handler/AccountManager";
import { IFeeCategory, WorkspaceManager } from "Straclou";

const IWrapper = styled(Box, { name: "welcome-frame-wrapper" })({
    width: "100%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",


})
const IContent = styled(Box, { name: "welcome-frame-content" })({
    height: "auto",
    width: "800px",
    padding: '16px',
    background: "#fff",
    position: "relative",
    borderRadius: "15px",
    boxShadow: `1px 5px 8px 0px #34343421`
})
const ILineItems = styled(Stack, { name: "welcome-frame-LineItems" })({
    marginTop: "32px",
    width: "700px"
})
const IItem = styled(Box, { name: "welcome-frame-Item" })({
    margin: "8px 0",
    height: "auto",
    padding: "8px 8px",
    display: "grid",
    gridTemplateColumns: "40px 1fr",
    gridTemplateRows: "1fr 1fr",
    gridTemplateAreas: `"welcome-frame-Icon welcome-frame-item-primary-text" 
    "welcome-frame-Icon welcome-frame-item-secondary-text"
    "welcome-frame-Icon welcome-frame-input-holder"
    `,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: "#000"
})

const IIcon = styled(Box, { name: "welcome-frame-Icon" })({
    gridArea: "welcome-frame-Icon",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
        color: grey[600]
    }

})

const InputHolder = styled(Box, { name: "welcome-frame-input-holder" })({
    gridArea: "welcome-frame-input-holder",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "16px",
})

const PrimaryText = styled(Box, { name: "welcome-frame-item-primary-text" })({
    gridArea: "welcome-frame-item-primary-text",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginLeft: "16px"
})
const SecondaryText = styled(Box, { name: "welcome-frame-item-secondary-text" })({
    gridArea: "welcome-frame-item-secondary-text",
    marginLeft: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: grey[600],
    width: "100%"
})


const IActionButtons = styled(Stack, { name: "welcome-frame-IActionButtons" })({
    mrginTop: "24px",
    height: "auto",
    justifyContent: "flex-end",
    padding: "0",
    // position: "absolute",
    right: "24px",
    left: 0,
    bottom: "24px"
})

const MyTitle = styled(Box)(({ theme }) => ({
    width: "100%",
    lineHeight: '1.5em',
    fontSize: '1.9em',
    textTransform: 'capitalize',
    fontWeight: "bold !important",
    color: theme.palette.primary.main
}))

export default function WelcomeFrame(props: any) {
    const { onClose } = props
    const { navigate } = useWidgetBuilderNavigation()

    const [value, setValue] = useState<{ name: string, value: string }>({ name: "", value: "" })
    const [fee, setFeeValue] = useState<{ name: string, value: string }>({ name: "", value: "" })
    const [feeState, setFeeState] = useState(new IFeeCategory())

    const { structureList, workspace } = useWorkspaceStore()


    const { handleSubmit, reset, control, register, setError, clearErrors, getValues, formState: { errors } } = useForm({
        defaultValues: {
            unitNumber: '',
            ownerName: "",
            propertyType: "",
            unitFees: ""
        }
    });

    const handleClose = () => {
        setValue({ name: "", value: "" })
        setFeeValue({ name: "", value: "" })
        onClose?.()

    }

    const handleClick = (e: any) => {
        navigate({
            path: "general",
            params: {}
        })
    }

    const onSubmit = (e: any) => {
        console.log("Form Data submitted: ", e)
    }


    const onSelectionChange = (e: any) => {
        setValue(e.target)
        let uNum = getValues("unitNumber")
        ValidateUnitNumberHandler(uNum, e.target.value)
        const params = {
            category: e.target.value,
            communityId: workspace.workspaceId
        }

        console.log(params)
        WorkspaceManager.listFeeByCategory(params).then(list => {
            setFeeState(new IFeeCategory(list))
        })



    }
    const onSelectionChangeFee = (e: any) => {
        setFeeValue(e.target)

        return e
    }

    const onKeyUpUnitNumber = (e: any) => {
        // get the entered unit number
        const uNum = e.target.value
        ValidateUnitNumberHandler(uNum, value.value)

        // console.log("key up value: ", e.target.value)
        // console.log("key up char: ", e.key)
        // console.log("key up Char Code: ", e.keyCode)





    }

    const ValidateUnitNumberHandler = (unitNum: string, pType: string) => {
        if (pType.length > 0) {
            // We can then call the server to check

            AccountManager.query({
                lotId: unitNum,
                propertyType: pType
            }).then((res: any) => {
                console.log("Result from server ", res)
                if (res.Count > 0) {
                    setError('unitNumber', { type: 'custom', message: `Duplicated lot Number found with type ${pType}` });
                }else {
                    clearErrors("unitNumber")
                }
            })
        }
    }

    useEffect(() => {
        setFeeState(new IFeeCategory())
        setValue({ name: "", value: "" })
        setFeeValue({ name: "", value: "" })
    }, [])

    return (
        <IWrapper>

            <IContent>
                <SubTitle variant="4" sx={{ fontWeight: 200 }}>Welcome to manual account creation</SubTitle>
                <MyTitle>Create New Account</MyTitle>
                <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate style={{ width: '100%' }}>
                    <Box>
                        <ILineItems>
                            <IItem>
                                <IIcon>
                                    <PersonIcon />
                                </IIcon>
                                <PrimaryText >
                                    <SubTitle variant="2">Name</SubTitle>
                                </PrimaryText>
                                <SecondaryText>
                                    Enter the name of the main account holder for this property
                                </SecondaryText>
                                <InputHolder>
                                    <UIFormWrapper sx={{ mb: 0, mt: 0, width: "100%" }}>
                                        <Controller
                                            aria-describedby="owner-name-helper-text"
                                            name={"ownerName"}
                                            control={control}
                                            defaultValue={""}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    {...register("ownerName")}
                                                    autoFocus
                                                    onChange={onChange}
                                                    value={value}
                                                    size="small"
                                                    required
                                                    placeholder="@example John Downer"
                                                    helperText=""
                                                    focused={true}
                                                    inputProps={{ style: { fontSize: "12pt" } }}
                                                    sx={{ width: "100%" }} />
                                            )}
                                        />
                                        <FormHelperText id="owner-name-helper-text" sx={{ width: "100%" }}>Enter the first and last name only</FormHelperText>
                                    </UIFormWrapper>
                                </InputHolder>
                            </IItem>
                            <IItem>
                                <IIcon>
                                    <LocationOnIcon />
                                </IIcon>
                                <PrimaryText>Unit Number</PrimaryText>
                                <SecondaryText>
                                    Enter the lot or unit number of this property
                                </SecondaryText>
                                <InputHolder>
                                    <UIFormWrapper sx={{ mb: 0, mt: 0, width: "100%" }}>
                                        <Controller
                                            aria-describedby="filled-weight-helper-text"
                                            name={"unitNumber"}
                                            control={control}
                                            defaultValue={""}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    {...register("unitNumber")}
                                                    autoFocus
                                                    error={errors?.unitNumber ? true : false}
                                                    onChange={onChange}
                                                    onKeyUp={onKeyUpUnitNumber}
                                                    value={value}
                                                    size="small"
                                                    required
                                                    placeholder="@example 0023"
                                                    helperText={errors.unitNumber && errors?.unitNumber?.message}
                                                    focused={true}
                                                    inputProps={{ style: { fontSize: "12pt" } }}
                                                    sx={{ width: "100%" }} />
                                            )}
                                        />
                                        <FormHelperText id="filled-weight-helper-text" sx={{ width: "100%" }}>Unit Number cannot be duplicated, please ensure that your numbers are unique on entry.</FormHelperText>
                                        {/* <Hint>Unit Number cannot be duplicated</Hint> */}
                                    </UIFormWrapper>
                                </InputHolder>
                            </IItem>
                            <IItem>
                                <IIcon>
                                    <RoofingIcon />
                                </IIcon>
                                <PrimaryText>Property Type</PrimaryText>
                                <SecondaryText>
                                    choose the category for this unit. note the selection comes from settings.
                                </SecondaryText>
                                <InputHolder>
                                    <UIFormWrapper sx={{ width: "100%" }}>
                                        <Controller
                                            name={"propertyType"}
                                            control={control}
                                            defaultValue={structureList[0] || ""}

                                            render={({ field }) => (
                                                <Select
                                                    {...register("propertyType")}
                                                    labelId="demo-simple-select-disabled-label"
                                                    id="demo-simple-select-disabled"
                                                    placeholder="Enter property type"
                                                    {...field}
                                                    value={value.value}
                                                    onChange={(e) => { onSelectionChange(e) }}
                                                    sx={{ width: "100%" }}
                                                    size="small"
                                                >

                                                    {
                                                        workspace.structure.map((el: any) => {
                                                            return (<MenuItem key={el} value={el}>{el}</MenuItem>)
                                                        })
                                                    }


                                                </Select>
                                            )}
                                        />
                                    </UIFormWrapper>
                                </InputHolder>
                            </IItem>
                            <IItem>
                                <IIcon>
                                    <SquareFootIcon />
                                </IIcon>
                                <PrimaryText>Unit Size & fee</PrimaryText>
                                <SecondaryText>
                                    Indicate the unit size in square feet and the associated fees
                                </SecondaryText>
                                <InputHolder>
                                    <UIFormWrapper sx={{ width: "100%" }}>
                                        <Controller
                                            name={"unitFees"}
                                            control={control}
                                            defaultValue={structureList[0] || ""}

                                            render={({ field }) => (
                                                <Select
                                                    {...register("unitFees")}
                                                    labelId="demo-simple-select-disabled-label"
                                                    id="demo-simple-select-disabled"
                                                    placeholder="Enter property type"
                                                    {...field}
                                                    value={fee.value}
                                                    onChange={(e) => { onSelectionChangeFee(e) }}
                                                    sx={{ width: "100%" }}
                                                    size="small"
                                                >

                                                    {
                                                        feeState.collection.orderBy("size").map(el => {
                                                            return (<MenuItem key={el.key} value={el.fee}>
                                                                <Box sx={{ display: "flex", width: "100%" }}>
                                                                    <ClouSquareFootageExhibit footage={el.size} /> @ <ClouMoneyExhibit value={el.fee} />
                                                                </Box>
                                                            </MenuItem>)
                                                        })
                                                    }


                                                </Select>
                                            )}
                                        />
                                    </UIFormWrapper>
                                </InputHolder>
                            </IItem>
                        </ILineItems>
                    </Box>

                    <Row>
                        <Alert severity="error">Please correct the errors above</Alert>
                    </Row>

                    <Row height={40} />

                    <IActionButtons gap={2} direction={"row"}>
                        <Button variant="outlined" size="small" sx={{ width: "120px" }} onClick={handleClose} >Cancel</Button>
                        <LoadingButton variant="contained" size="small" sx={{ width: "120px" }} type="submit">Create account</LoadingButton>
                    </IActionButtons>

                </form>
            </IContent>

        </IWrapper>
    )
}