
import React, { useEffect } from "react"
import { useParams } from "react-router-dom";

import DirectoryRowItem from "./DirectoryRowItem";

import useAccountStore from "hooks/AccountStore";
import useAccount from "hooks/useAccount";


import Row from "components/Row";
import { Box, Divider, IconButton, LinearProgress, Tooltip } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';


import AccountPagination from "Features/AccountPagination/AccountPagination";
import AccountSortFilter from "Features/AccountSortFilter/AccountSortFilter";
import { IWidgetBuilderRoute, WidgetBuilder, WidgetBuilderOutlet } from "@knymbus/react-widget-builder";
import PaymentMonitor from "Features/PaymentServices/PaymentMonitor";
import CustomerDetail, { DetailHeaderFragment } from "../pages/CRM/CustomerDetail";

const FullDirectory: React.FC = () => {


    const params = useParams()

    const { initialize, isLoading } = useAccount()
    const { sortList, list } = useAccountStore()
    const [fragmentNav, setFragmentNav] = React.useState<any>()



    const refresh = () => {
        console.log("url Params yes: ", params)
        initialize({
            cid: params?.workspaceId || "",
            nextPage: 2
        })

    }

    const fragmentRoutes: IWidgetBuilderRoute[] = [
        {
            path: "detail",
            fragmentHeaderOptions: {
                header: <DetailHeaderFragment/>
            },
            window: <CustomerDetail/>,
            order: 1
        },
        {

            path: "main_payment",
            window: <PaymentMonitor />,
            fragmentHeaderOptions: {
                header: "Payment Module",
            },
            order: 2


        }
    ]

    const widgetBuilder = new WidgetBuilder({
        name: "Account Detail fragments",
        routes: fragmentRoutes,
        type: "fragment"
    })


    // This is the init of the function
    useEffect(() => {
        console.log("List state: ", list.empty)
        console.log("Sorted list>>>>>",sortList)
        if (list.empty) {
            console.log("this is my list: ",list)
            refresh()
        }
    }, [params])

    return (
        <Box>

            <Row>
                <Row sx={{ height: "50px", background: "0", position: "relative", display: "flex", alignItems: "center" }}>

                    <AccountSortFilter />

                    <Box sx={{ ml: 0 }}>
                        <Tooltip title="Refresh">
                            <IconButton
                                aria-label="refresh accounts"
                                component="label"
                                onClick={refresh}>
                                <RefreshIcon sx={{ height: "20px", width: "20px" }} />
                            </IconButton>
                        </Tooltip>
                    </Box>


                    <AccountPagination />
                </Row>
                <Divider />
                <Row sx={{ width: "100%", height: "10px" }}>
                    {
                        isLoading ? (
                            <Box sx={{ width: "100%" }}>
                                <LinearProgress />
                            </Box>
                        ) : null
                    }
                </Row>

            </Row>
            {
                // <Box>Loading Please wait....</Box> ??
                <DirectoryRowItem data={sortList} loading={isLoading} onNavigate={setFragmentNav}/>

            }
            <WidgetBuilderOutlet builder={widgetBuilder} onNavigate={fragmentNav} />
        </Box >
    )
}

export default FullDirectory