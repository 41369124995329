// import { PhoneFormatter } from "globals/helper"
// import { Contact } from "API"

import { PhoneFormatter } from "globals/helper"

export class IContact{

    private _id: string

    private _address: string = "default Data"

    private _type: string

    private _category: string

    private _createdAt: string

    private _updatedAt: string

    private _isAssigned: boolean = false

    private _main: boolean = false
    
    private _uid: string

    private _version: number

    constructor(args? : any){

        if(args !== undefined){

            this._isAssigned = true

            this._id = args?.id

            this._category = args?.category 
            
            this._address = this.setPhoneFormat(args?.address ?? "")

            this._type = args?.type ?? ""


            this._createdAt = args?.createdAt

            this._updatedAt = args.updatedAt

            this._main = args.main ?? false

            this._uid = args?.owner ?? ""

            this._version = args?._version
        }
    }

    get id(){return this._id}

    get address(){return this._address}

    get type(){return this._type}

    get isAssigned(){return this._isAssigned}

    get category(){return this._category}

    get main(){return this._main}

    get createdAt(){return this._createdAt  }

    get updatedAt(){return this._updatedAt}

    get uid(){return this._uid}

    get version(){return this._version}

    // Setters
    set type(value: string){this._type = value}
    
    set address(value: string){this._address = value}

    // Private Methods
    setPhoneFormat(args: string){
        return this._category === "phone" ? PhoneFormatter(args) : args
    }
}