import { Box, styled } from "@mui/material"

const Wrapper = styled(Box)(({theme})=>({
    minHeight: "60px",
    borderBottom:`1px solid ${theme.palette.grey[100]}`,
    padding: "8px"
}))

export default function MessageTemplate(props: any){
    const {data} = props
    return (
        <Wrapper>

            {data.body}

        </Wrapper>
    )
}