import { ThemeProvider } from "@emotion/react";
import React from "react";
import {theme} from './theme'


function ProviderComposer(props: any) {
    return props.contexts.reduceRight(
      (kids: any, parent: any) =>
        React.cloneElement(parent, {
          children: kids,
        }),
      props.children
    );
  }
  
  function ContextProvider(props: any) {
    return (
      <ProviderComposer
        contexts={[<ThemeProvider theme={theme} children={undefined} />]}
      >
        {props.children}
      </ProviderComposer>
    );
  }
  
  export { ContextProvider };