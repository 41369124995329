import { PolicyWrapper } from "./PolicyWrapper";

export default function PrivacyUserTerm(){
    return (
        <PolicyWrapper>
  <div className="heading">
    <h1>End User License Agreement</h1>
  </div>
  <div className="section">
    <div className="intro">
      <p>
        <br />

        IMPORTANT LEGAL NOTICE TO ALL USERS: CAREFULLY READ THE FOLLOWING LEGAL
        AGREEMENT BEFORE YOU START USING THE SOFTWARE.
        <br />
        <br />

        Running the Software, clicking the button that confirms that You accept
        the License Agreement during installation, constitutes Your
        unconditional acceptance of the terms of this License Agreement. If You
        do not agree with the terms of this License Agreement, You must abort
        the installation of the Software and\or delete the Software.

        <br />
        <br />
        AFTER CLICKING THE BUTTON, THAT CONFIRMS YOUR ACCEPTANCE IN THE LICENSE
        AGREEMENT WINDOW, YOU HAVE THE RIGHT TO USE THE SOFTWARE IN ACCORDANCE
        WITH THE TERMS AND CONDITIONS OF THIS AGREEMENT.
        <br />
        <br />
      </p>
    </div>
    <div className="section-a">
      <h1>SECTION A. GENERAL PROVISIONS</h1>
      <br />
      <ol>
        <li>
          <h2>Definitions</h2>
          <br />
          <p>
            <b>1.1.</b> Software means software including any Updates and
            related materials.
          </p>
          <br />
          <p>
            <b> 1.2.</b> Rightholder (owner of all rights, whether exclusive or
            otherwise to the Software) means Knymbus, a business registered
            according to the laws of Jamaica.
          </p>
          <br />
          <p>
            <b>1.3.</b> Computer — the operating system, virtual machine or
            hardware, including the workstation, mobile device or server for
            which the Software is intended and/or on which the Software is to be
            installed and/or used.
          </p>
          <br />
          <p>
            <b>1.4.</b> End User (You/Your) means individual(s) installing or
            using the Software on their own behalf or who are legally using a
            copy of the Software; or, if the Software is being downloaded or
            installed on behalf of an organization, such as an employer, “You”
            further means the organization for which the Software is downloaded
            or installed and it is represented hereby that this organization has
            authorized the person accepting this agreement to do so on its
            behalf. For purposes hereof the term “organization”, without
            limitation, includes any partnership, limited liability company,
            corporation, association, joint stock company, trust, joint venture,
            labor organization, unincorporated organization, or governmental
            authority.
          </p>
          <br />
          <p>
            <b> 1.5.</b> Partner(s) means organizations or individual(s) who
            distribute the Software based on an agreement and license with the
            Rightholder.
          </p>
          <br />
          <p>
            <b>1.6.</b>Update(s) — improvements, security patches, extensions
            and/or modifications for the Software.
          </p>
          <br />
          <p>
            <b>1.7.</b> License Certificate means a document that may be given
            to the End User which is accompanied by an activation code as well
            as further information about the license.
          </p>
          <br />
          <p>
            <b>1.8.</b> Software extensions — additional software components and
            services provided by the Rightholder that extend the functionality
            of the Software and can be used with the Software or independently
            of it and for which a new license certificate may need to be
            acquired or the existing one extended. You can find out more about
            these extensions before acquiring them.
          </p>
          <br />
          <p>
            <b>1.9.</b> User Manual means user manual, administrator guide,
            reference book and related explanatory or other materials.
          </p>
          <br />
          <p>
            <b>1.10.</b> The Web-Portal means the Rightholder’s web resource
            intended to manage the installed Software and the licenses acquired.
          </p>
          <br />
          <p>
            <b>1.11.</b> User Account means the personal section of the
            Web-Portal created using data provided by the End User when
            registering at the Web-Portal. The End User Account allows the User
            to gain access to the Web-Portal to carry out the actions listed
            under Section A Clause 1.10.
          </p>
          <br />
        </li>
        <li>
          <h2>Grant of License</h2>
          <br />
          <p>
            <b>2.1.</b> You are granted a non-exclusive license to use the
            Software within the scope of the functionality described in the User
            Manual,provided You comply with all technical requirements,
            restrictions and terms of use specified in this End User License
            Agreement.
          </p>
          <br />
          <p>
            <b>Trial Version.</b> If you have received, downloaded and/or
            installed a trial version of the Software and are hereby granted an
            evaluation license for the Software, you may use the Software only
            for evaluation purposes and only during the single applicable
            evaluation period, unless otherwise indicated, from the date of the
            initial installation. Any use of the Software for other purposes or
            beyond the applicable evaluation period is strictly prohibited.
          </p>
          <br />
          <p>
            <b>Multiple Copies.</b> If you receive multiple copies of the
            Software, the total permitted number of your Computers on which all
            versions of the Software are installed shall correspond to the
            number of computers specified in licenses you have obtained from the
            Rightholder provided that, unless the licensing terms provide
            otherwise, each acquired license entitles you to install and use the
            Software on the number of Computer(s) and/or for the number of User
            Accounts as is specified in Section A Clause 2.2 and 2.3.
          </p>
          <br />
          <p>
            <b>2.2.</b> If the Software was received/acquired via the Internet
            You have the right to use the Software for the number of Computers
            and/or User Accounts as was specified when You acquired the License
            to the Software.
          </p>
          <br />
          <p>
            <b>2.3.</b> You have the right to make a copy of the Software solely
            for backup purposes and only to replace the legally owned copy if
            this copy is lost, destroyed or becomes unusable. This backup copy
            cannot be used for other purposes and must be destroyed when you
            lose the right to use the Software or when Your license expires or
            is terminated for any other reason according to the legislation in
            force in the country of your principal residence or in the country
            where You are using the Software.
          </p>
          <br />
          <p>
            <b>2.4.</b> You can transfer the non-exclusive license to use the
            Software to other individuals within the scope of the license
            granted by the Rightholder to You, provided that the recipient
            agrees to be bound by all the terms and conditions of this Agreement
            and to replace you in full in the license granted by the
            Rightholder. In case You fully transfer the rights granted from the
            Rightholder to use the Software You must destroy all copies of the
            Software, including the backup copy. If You are a recipient of a
            transferred license You must agree to abide by all the terms and
            conditions of this Agreement. If You do not agree to be bound by all
            the terms and conditions of this Agreement, You may not install
            and/or use the Software. You also agree as the recipient of a
            transferred license that You do not have any additional or better
            rights than those of the original End User who acquired the Software
            from the Rightholder.
          </p>
          <br />
          <p>
            <b>2.5.</b> After activating the Software within the period
            specified during purchase (if the Software acquired online), You may
            receive automatic Updates and the latest versions of the Software
            from the Rightholder or its Partners, as well as technical support
            according to Section A Paragraph 4.
          </p>
          <br />
        </li>
        <li>
          <h2>Activation and Term</h2>
          <br />
          <p>
            <b>3.1.</b> If You modify Your Computer or make changes to other
            vendors’ software installed on it, You may be required by the
            Rightholder to repeat activation of the Software, the count of which
            may be limited by the Rightholder.
          </p>
          <br />
          <p>
            <b>3.2.</b> If the Software was received/acquired via the Internet,
            the Software can be used, upon your acceptance of this Agreement,
            for the period that was specified during acquisition.
          </p>
          <br />
          <p>
            <b>3.3.</b> If you have received the Software from a Partner, the
            period of effective use of the Software may be agreed upon between
            you and the Partner.
          </p>
          <br />
          <p>
            <b>3.4.</b> Where there is a License Certificate, the period of use
            of the Software is specified in the License Certificate.
          </p>
          <br />
          <p>
            <b>3.5.</b> Where there is a subscription, the period of use of the
            Software is specified when confirming the subscription.
          </p>
          <br />
          <p>
            <b>3.6.</b> If You received the Software from the Rightholder for
            Trial Period (evaluation purposes), the period of use of the
            Software is indicated in the relevant section of the settings in the
            Software.
          </p>
          <br />
          <p>
            <b>3.7.</b> For Software activated with a license for evaluation
            purposes as specified in Section A Clause 2.1, information about the
            period of use of the Software can be obtained using the methods
            described in the User Manual.
          </p>
          <br />
          <p>
            <b>3.8.</b> After expiration of the Software license, You may be
            entitled to continue use of the Software for a defined period of
            time, while the functionality of the Software may be limited.
            Details are available at https://stratous.com.
          </p>
          <br />
          <p>
            <b>3.9.</b> Without prejudice to any other remedy in law or in
            equity that the Rightholder may have, in the event of any breach by
            You of any of the terms and conditions of this Agreement, the
            Rightholder shall at any time without notice to You be entitled to
            terminate this License to use the Software without refunding the
            purchase price or any part thereof.
          </p>
          <br />
          <p>
            <b>3.10.</b> To check the legitimacy of the Software’s use the
            Rightholder reserves the right to use means to verify that You have
            a licensed copy of the Software. The Software can transmit
            Rightholder license information needed to verify the legitimacy of
            the Software use. If the check cannot be performed in a reasonable
            amount of time, the functionality of the Software may be limited.
          </p>
          <br />
          <p>
            <b>3.11</b> You agree that use of the Software must be in accordance
            with its intended purpose and must not violate local legislation.
          </p>
          <br />
          <p>
            <b>3.12.</b> Your email address and other data provided during the
            account registration process can be transferred and stored by a
            trusted third-party service provider of the Rightholder which is
            Amazon Web Services (AWS). This third-party service provider stores
            the data in the USA where the level of personal data protection is
            equal to that of our Data Protection Act 2020 in Jamaica.
          </p>
          <br />
          <p>
            <b> 3.13.</b> You are responsible for any actions performed using
            Your account involving the resources of the Rightholder and/or its
            Partners. You agree that the Rightholder shall not be liable for the
            unauthorized use of Your account.
          </p>
          <br />
        </li>
        <li>
          <h2>Technical Support</h2>
          <br />
          <p>
            <b>4.1.</b> The Technical Support is provided to You in accordance
            with our Customer Support Programme. Technical support service and
            the programme are located at: https://stratous.com.
          </p>
          <br />
          <p>
            <b>4.2.</b> User data stored on the Rightholder’s and/or its
            Partner’s resources may be used by Technical Support only when
            processing a request from the User and in accoradance to the Jamaica
            Data Protection Act 2020.
          </p>
          <br />
        </li>
        <li>
          <h2>Limitations</h2>
          <br />
          <p>
            <b>5.1.</b> You shall not emulate, clone, rent, lend, lease, sell,
            modify, decompile, or reverse-engineer the Software or disassemble
            or create derivative works based on the Software or any portion
            thereof with the sole exception of a non-waiverable right granted to
            You by applicable legislation, and you shall not otherwise reduce
            any part of the Software to human-readable form or transfer the
            licensed Software, or any subset of the licensed Software, nor
            permit any third party to do so, except to the extent the foregoing
            restriction is expressly prohibited by applicable law. Neither the
            Software’s binary code nor source may be used or reverse-engineered
            to re-create the program algorithm, which is proprietary. All rights
            not expressly granted herein are reserved by Rightholder and/or its
            suppliers, as applicable. Any unauthorized use of the Software shall
            result in immediate and automatic termination of this Agreement and
            the License granted hereunder and may result in criminal and/or
            civil prosecution against You.
          </p>
          <br />
          <p>
            <b>5.2.</b> You shall not transfer the rights to use the Software to
            any third party except as set forth in Section A Clause 2.4 of this
            Agreement.
          </p>
          <br />
          <p>
            <b>5.3.</b> You shall not provide the activation code to third
            parties or allow third parties access to the activation code and/or
            license key, which are deemed the confidential data of the
            Rightholder and you shall exercise reasonable care in protecting the
            activation code in confidence provided that you can transfer the
            activation code and/or license key to third parties as set forth in
            Section A Clause 2.4 of this Agreement. You are responsible for
            keeping Your activation code confidential during use of the
            Software.
          </p>
          <br />
          <p>
            <b>5.4.</b> You shall not rent, lease or lend the Software to any
            third party.
          </p>
          <br />
          <p>
            <b> 5.5.</b> If You are using the trial version of the Software You
            do not have the right to transfer the license or the rights to use
            the Software to any third party.
          </p>
          <br />
          <p>
            <b>5.6.</b> Violation of the intellectual rights to the Software
            shall result in civil, administrative or criminal liability in
            accordance with the law.
          </p>
          <br />
        </li>
        <li>
          <h2>Limited Warranty and Disclaimer</h2>
          <br />
          <p>
            <b>6.1.</b> The Rightholder guarantees the operation of the Software
            as described in the User Manual and, if supported versions of the
            Software are used, the installation by the End User of all the
            latest updates for the Software, unless otherwise stipulated in the
            License Agreement.
          </p>
          <br />
          <p>
            <b>6.2.</b> You agree that the Software is supplied with the option
            of automatic renewal, which provides automatic downloading and
            installation of enhancements, patches and/or modifications for the
            Software and components, as well as new versions of the Software.
          </p>
          <br />
          <p>
            <b>6.3.</b> You acknowledge, accept and agree that no software is
            error-free and You are advised to back up the Computer with the
            frequency and reliability suitable for You.
          </p>
          <br />
          <p>
            <b> 6.4.</b> The Rightholder does not provide any guarantee that the
            Software will work correctly in case of violations of the terms
            described in the User Manual or in this Agreement.
          </p>
          <br />
          <p>
            <b>6.5.</b> The Rightholder does not guarantee that the Software
            will work correctly if You do not regularly download Updates
            specified in Section A Clause 2.5 of this Agreement.
          </p>
          <br />
          <p>
            <b>6.6.</b> The Rightholder does not guarantee the availability of
            the functionality described in the User Manual on expiry of the
            period specified in Section A Paragraph 3 of this License Agreement.
          </p>
          <br />
          <p>
            <b>6.7.</b> You acknowledge and agree that the Software will engage
            in actions necessary for performance.
          </p>
          <br />
          <p>
            <b>6.8.</b> THE SOFTWARE IS PROVIDED “AS IS” AND THE RIGHTHOLDER
            MAKES NO REPRESENTATION AND GIVES NO WARRANTY AS TO ITS USE OR
            PERFORMANCE. EXCEPT FOR ANY WARRANTY, CONDITION, REPRESENTATION OR
            TERM THE EXTENT TO WHICH CANNOT BE EXCLUDED OR LIMITED BY APPLICABLE
            LAW. THE RIGHTHOLDER AND ITS PARTNERS MAKE NO WARRANTY, CONDITION,
            REPRESENTATION, OR TERM (EXPRESSED OR IMPLIED, WHETHER BY STATUTE,
            COMMON LAW, CUSTOM, USAGE OR OTHERWISE) AS TO ANY MATTER INCLUDING,
            WITHOUT LIMITATION, NON-INFRINGEMENT OF THIRD-PARTY RIGHTS,
            MERCHANTABILITY, SATISFACTORY QUALITY, INTEGRATION, OR APPLICABILITY
            FOR A PARTICULAR PURPOSE. YOU ASSUME ALL FAULTS, AND THE ENTIRE RISK
            AS TO PERFORMANCE AND RESPONSIBILITY FOR SELECTING THE SOFTWARE TO
            ACHIEVE YOUR INTENDED RESULTS, AND FOR THE INSTALLATION OF, USE OF,
            AND RESULTS OBTAINED FROM THE SOFTWARE. WITHOUT LIMITING THE
            FOREGOING PROVISIONS, THE RIGHTHOLDER MAKES NO REPRESENTATION AND
            GIVES NO WARRANTY THAT THE SOFTWARE WILL BE ERROR-FREE OR FREE FROM
            INTERRUPTIONS OR OTHER FAILURES OR THAT THE SOFTWARE WILL MEET ANY
            OR ALL OF YOUR REQUIREMENTS WHETHER OR NOT DISCLOSED TO THE
            RIGHTHOLDER.
          </p>
          <br />
        </li>
        <li>
          <h2>Exclusion and Limitation of Liability</h2>
          <br />
          <p>
            <b>7.1.</b> TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
            EVENT SHALL THE RIGHTHOLDER OR ITS PARTNERS BE LIABLE FOR ANY
            SPECIAL, INCIDENTAL, PUNITIVE, INDIRECT, OR CONSEQUENTIAL DAMAGES
            WHATSOEVER (INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF
            PROFITS OR CONFIDENTIAL OR OTHER INFORMATION, FOR BUSINESS
            INTERRUPTION, FOR LOSS OF PRIVACY, FOR CORRUPTION, DAMAGE AND LOSS
            OF DATA OR PROGRAMS, FOR FAILURE TO MEET ANY DUTY INCLUDING ANY
            STATUTORY DUTY, DUTY OF GOOD FAITH OR DUTY OF REASONABLE CARE, FOR
            NEGLIGENCE, FOR ECONOMIC LOSS, AND FOR ANY OTHER PECUNIARY OR OTHER
            LOSS WHATSOEVER) ARISING OUT OF OR IN ANY WAY RELATED TO THE USE OF
            OR INABILITY TO USE THE SOFTWARE, THE PROVISION OF OR FAILURE TO
            PROVIDE SUPPORT OR OTHER SERVICES, INFORMATION, SOFTWARE, AND
            RELATED CONTENT THROUGH THE SOFTWARE OR OTHERWISE ARISING OUT OF THE
            USE OF THE SOFTWARE, OR OTHERWISE UNDER OR IN CONNECTION WITH ANY
            PROVISION OF THIS AGREEMENT, OR ARISING OUT OF ANY BREACH OF
            CONTRACT OR ANY TORT (INCLUDING NEGLIGENCE, MISREPRESENTATION, ANY
            STRICT LIABILITY OBLIGATION OR DUTY), OR ANY BREACH OF STATUTORY
            DUTY, OR ANY BREACH OF WARRANTY OF THE RIGHTHOLDER OR ANY OF ITS
            PARTNERS, EVEN IF THE RIGHTHOLDER OR ANY PARTNER HAS BEEN ADVISED OF
            THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            <br />
            YOU AGREE THAT IN THE EVENT THE RIGHTHOLDER AND/OR ITS PARTNERS ARE
            FOUND LIABLE, THE LIABILITY OF THE RIGHTHOLDER AND/OR ITS PARTNERS
            SHALL BE LIMITED BY THE COSTS OF THE SOFTWARE. IN NO CASE SHALL THE
            LIABILITY OF THE RIGHTHOLDER AND/OR ITS PARTNERS EXCEED THE FEES
            PAID FOR THE SOFTWARE TO THE RIGHTHOLDER OR THE PARTNER (AS MAY BE
            APPLICABLE).
            <br />
            <br />
            NOTHING IN THIS AGREEMENT EXCLUDES OR LIMITS ANY CLAIM FOR DEATH AND
            PERSONAL INJURY. FURTHER IN THE EVENT ANY DISCLAIMER, EXCLUSION OR
            LIMITATION IN THIS AGREEMENT CANNOT BE EXCLUDED OR LIMITED ACCORDING
            TO APPLICABLE LAW THEN ONLY THAT DISCLAIMER, EXCLUSION OR LIMITATION
            SHALL NOT APPLY TO YOU AND YOU CONTINUE TO BE BOUND BY ALL THE
            REMAINING DISCLAIMERS, EXCLUSIONS AND LIMITATIONS.
          </p>
          <br />
        </li>
        <li>
          <h2>GNU and Other Third-Party Licenses</h2>
          <br />
          <p>
            <b>8.1.</b> The Software may include some software programs that are
            licensed (or sublicensed) to the user under the GNU General Public
            License (GPL) or other similar free software licenses which, among
            other rights, permit the user to copy, modify and redistribute
            certain programs, or portions thereof, and have access to the source
            code (“Open-Source Software”). If these licenses require that for
            any software, which is distributed to someone in an executable
            binary format, that the source code also be made available to those
            users, then the source code could be found either supplied with the
            Software, or could be made available by sending a request to
            source@kaspersky.com. If any Open-Source Software licenses require
            that the Rightholder provide rights to use, copy or modify an
            Open-Source Software program that are broader than the rights
            granted in this Agreement, then these rights shall take precedence
            over the rights and restrictions herein.
          </p>
          <br />
        </li>
        <li>
          <h2>Intellectual Property Ownership</h2>
          <br />
          <p>
            <b>9.1.</b> You agree that the Software and the authorship, systems,
            ideas, methods of operation, documentation and other information
            contained in the Software are proprietary intellectual property
            and/or the valuable trade secrets of the Rightholder or its partners
            and that the Rightholder and its partners, as applicable, are
            protected by civil and criminal law, and by the law of copyright,
            trade secret, trademark and patents of the Russian Federation,
            European Union and the United States, as well as other countries and
            international treaties. This Agreement does not grant You any rights
            to the intellectual property, including any Trademarks or Service
            Marks of the Rightholder and/or its partners (“Trademarks”).
            <br />
            <br />
            You may use the Trademarks only insofar as to identify printed
            output produced by the Software in accordance with accepted
            trademark practice, including identification of the Trademark
            owner’s name. Such use of any Trademark does not give you any rights
            of ownership in that Trademark. The Rightholder and/or its partners
            own and retain all right, title, and interest in and to the
            Software, including without limitation any error corrections,
            enhancements, Updates or other modifications to the Software,
            whether made by the Rightholder or any third party, and all
            copyrights, patents, trade secret rights, trademarks, and other
            intellectual property rights therein. Your possession, installation
            or use of the Software does not transfer to you any title to the
            intellectual property in the Software, and you will not acquire any
            rights to the Software except as expressly set forth in this
            Agreement.

            <br />
            <br />
            All copies of the Software made hereunder must contain the same
            proprietary notices that appear on and in the Software. Except as
            stated herein, this Agreement does not grant you any intellectual
            property rights in the Software and you acknowledge that the
            License, as further defined herein, granted under this Agreement
            only provides you with a right of limited use under the terms and
            conditions of this Agreement. Rightholder reserves all rights not
            expressly granted to you in this Agreement.
          </p>
          <p>
            <b>9.2.</b> You agree not to modify or alter the Software in any
            way. You may not remove or alter any copyright notices or other
            proprietary notices on any copies of the Software.
          </p>
          <br />
        </li>

        <li>
          <h2>Governing Law</h2>
          <br />
          <p>
            <b>10.1.</b> Except as provided in Section A Clauses 10.2 below,
            this Agreement shall be governed by and construed in accordance the
            laws specified below for the country or territory in which you
            obtained the Software, without reference to or application of
            conflicts of laws principles:
          </p>
          <ol type="a">
            <li>
              Jamaica. If you obtained the Software in Jamaica, the laws of
              Jamaica.
            </li>
          </ol>
          <p>
            <b>10.2.</b> Notwithstanding the foregoing, if the mandatory laws or
            public policy of any country or territory in which this Agreement is
            enforced or construed prohibit the application of the law specified
            herein, then the laws of such country or territory shall instead
            apply to the extent required by such mandatory laws or public
            policy. Similarly, if you are an individual consumer, the provisions
            of Section A Clause 15.1 shall not affect any mandatory right you
            may have to take action in your country of residence under the laws
            of that country.
          </p>
          <p>
            <b> 10.3.</b> The End User is responsible for contacting only the
            Rightholder or their partners directly if having any problems with
            the product.
          </p>
          <br />
        </li>
        <li>
          <h2>Period for Bringing Actions</h2>
          <br />
          <p>
            <b>11.1.</b> No action, regardless of form, arising out of the
            transactions under this Agreement may be brought by either party
            hereto more than one (1) year after the cause of action has
            occurred, or was discovered to have occurred, except that an action
            for infringement of intellectual property rights may be brought
            within the maximum applicable statutory period.
          </p>
          <br />
        </li>
        <li>
          <h2>Entire Agreement; Severability; No Waiver</h2>
          <br />
          <p>
            <b>12.1.</b> This Agreement is the entire agreement between you and
            Rightholder and supersedes any other prior agreements, proposals,
            communications or advertising, oral or written, with respect to the
            Software or to the subject matter of this Agreement. You acknowledge
            that you have read this Agreement, understand it and agree to be
            bound by its terms. If any provision of this Agreement is found by a
            court of competent jurisdiction to be invalid, void, or
            unenforceable for any reason, in whole or in part, such provision
            will be more narrowly construed so that it becomes legal and
            enforceable, and the entire Agreement will not fail on account
            thereof and the balance of the Agreement will continue in full force
            and effect to the maximum extent permitted by law or equity while
            preserving, to the fullest extent possible, its original intent.
            <br />
            <br />
            No waiver of any provision or condition herein shall be valid unless
            in writing and signed by you and an authorized representative of
            Rightholder provided that no waiver of any breach of any provisions
            of this Agreement will constitute a waiver of any prior, concurrent
            or subsequent breach. Rightholder’s failure to insist upon or
            enforce strict performance of any provision of this Agreement or any
            right shall not be construed as a waiver of any such provision or
            right.
          </p>
          <br />
        </li>
        <li>
          <h2>Rightholder Contact Information</h2>
          <br />
          <p>
            Should you have any questions concerning this Agreement, or if you
            desire to contact the Rightholder for any reason, please contact us
            at:
            <br />
            <b>Knymbus</b> <br />
            <b>E-mail:</b> info@knymbus.com <br />
            <b>Website:</b> https://www.knymbus.com
          </p>
          <br />
          <br />
        </li>
      </ol>
    </div>
    <div className="section-b">
      <h1>SECTION B. CONDITIONS REGARDING DATA PROCESSING</h1>
      <br />
      <h2>Provision of information (if applicable)</h2>
      <br />
      <p>
        In order to enhance the protection of information and improve the
        quality of the Software and services, we automatically collect the
        following information such license data, technical information about the
        Computer and devices connected to it.
        <br />
        <br />
        More information is provided in our Privacy Policy which requires your
        consent. Please read and review the document when prompted to do so if
        you agree with the terms and conditions set out in this End User License
        Agreement.
      </p>
    </div>
  </div>
</PolicyWrapper>

    )
}