import { Box, styled, IconButton, Tooltip, alpha } from "@mui/material"
import React, { ReactNode, useContext } from "react"
import CloseIcon from '@mui/icons-material/Close';
import { ActivePaymentContext, PaymentMethod } from "./Context/PaymentProvider"



const Wrapper = styled(Box, { name: "Wrapper-sticker" })(({theme})=>({
    position: "relative",
    background: alpha(theme.palette.primary.main, 0.2),
    color: alpha(theme.palette.primary.main, 1),
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "20px 1fr",
    width: "80px",
    height: "40px",
    textTransform: "capitalize",
    margin: "5px",
    padding: "10px 15px",
    borderBottomRightRadius: "13px",
    borderTopLeftRadius: "13px",
    borderRadius: "13px",
    fontSize: ".8em",
    boxShadow: `2px 2px 5px 0px ${alpha(theme.palette.primary.main, 0.2)}`,
    "&:first-of-type": {
        margin: "5px 5px 5px 0"
    }
}))

const CloseBox = styled(Box, { name: "Payment-Method-Close-Button" })(({
    position: "absolute",
    right: 0
}))

interface IProps {
    label: string | ReactNode
    value: string | ReactNode
    icon?: ReactNode
}
const PaymentTypeSticker: React.FC<IProps> = ({ label, value, icon }) => {

    const {setPaymentMethod} = useContext(ActivePaymentContext)

        const handleDelete = ()=>{
            setPaymentMethod((prev: PaymentMethod[]) =>{
                let index = prev.findIndex(el=>el.option === label)
                prev.splice(index, 1)   
                console.log(prev)             
                return [...prev]
            })
    }


    return (
        <Wrapper>
            <CloseBox>
                <Tooltip title={`Delete ${label} payment method`} >
                    <IconButton onClick={handleDelete}>
                        <CloseIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                </Tooltip>
            </CloseBox>
            <Box>{value}</Box>
            <Box sx={{ fontSize: ".86em", textDecoration: "capitalize" }}>{label}</Box>
        </Wrapper>
    )
}

export default PaymentTypeSticker