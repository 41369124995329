import { Box, SxProps } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { NumericFormat } from 'react-number-format'


interface IProps {
    /**
     * use value instead
     * @deprecated
     */
    amount?: number
    value?: number
    separator?: boolean
    decimal?: boolean
    prefix?: string
    balanceColor?: boolean
    sx?: SxProps
}
const ClouMoneyExhibit: React.FC<IProps> = ({ balanceColor, sx, amount, separator, decimal, value, prefix }) => {
    const [thousandSeparator, setSeparator] = useState<boolean>(true)
    const [isBalanceColor, setIsBalanceColor] = useState<boolean>(false)
    const [isDecimal, setDecimal] = useState<{}>({
        fixedDecimalScale: false,
        decimalScale: 0
    })

    const [myPrefix, setPrefix] = useState("$")

    useEffect(() => {
        if (separator !== undefined) setSeparator(separator)
        if (decimal !== undefined && decimal === true) setDecimal({
            fixedDecimalScale: true,
            decimalScale: 2
        })
        if (balanceColor !== undefined) setIsBalanceColor(balanceColor)
    }, [])


    useEffect(() => {
        if (prefix !== undefined) {
            setPrefix(prefix)
        }
    })

    return (
        <Box sx={sx}>
            <NumericFormat
                value={amount || value}
                displayType="text"
                thousandSeparator={thousandSeparator}
                allowNegative={true}
                decimalSeparator="."
                prefix={`${myPrefix}`}

                {...isDecimal}

            />
        </Box>
    )
}

export default ClouMoneyExhibit