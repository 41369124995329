import { Helper } from "globals/helper"
import useAuth from "hooks/useAuth"
import useQuery from "hooks/useQuery"
import useWorkspace from "hooks/useWorkspace"
import useWorkspaceStore from "hooks/useWorkspaceStore"
import React, { Component, ReactNode, useEffect, useState } from "react"
import { Navigate, Outlet, Route, useNavigate } from "react-router-dom"
import { activeUser, userHandler } from "Straclou"
import LoadingActivity from "./LoadingActivity"


interface IProps {
    component?: Component
    path: string
}
const LandingProtectedRoutes = () => {
    // const { user } = useAuth()

    const [element, setElement] = useState<ReactNode | null>(null)

    const routes = {
        outlet: <Outlet />,
        home: <Navigate to={'/'} />,
        loader: <LoadingActivity />
    }

    useEffect(() => {

        setElement(routes["loader"])

        userHandler.getCurrentUserCredentials().then(user => {
            // If community data is found then we need to ask them to setup or get invited 
            if (!Helper.isEmpty(user?.uid)) {
                setElement(routes["outlet"])
            } else {
                setElement(routes["home"])
            }

        })


    }, [])
    useEffect(() => {
        // we need to load the workspace for the logged in user 
        // Therefore we need to get the community they are from. 



    }, [])





    return (
        <>
            {element}
        </>
    )
}

export default LandingProtectedRoutes