import { styled, Box, Stack } from "@mui/material"
import { useLocation } from "react-router-dom"
import EmailVerificationForm from "./EmailVerificationForm"
import { useState } from "react"
import Enfold from "./Enfold"



const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(({
    width: "100vw",
    height: "100vh",
    // ...CenterItem
}))

const Content = styled(Box)(({
    width: "60%",
    ...CenterItem,
    margin: "0 auto"
}))

const Logo = styled(Box)(({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px"
}))

const Instruction = styled(Box)(({
    ...CenterItem,
    fontSize: "2.7em",
    fontWeight: 900
}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))


const SignupLink = styled(Box)<{ fontSize?: string }>(({ theme, fontSize }) => ({
    fontSize: fontSize || "9pt",
    margin: "0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
        textDecoration: "underline",
    }
}))

interface StateParams {
    email: string
    codeId: string
}
export default function VerifyEmailActivity() {

    const location = useLocation()
    const State: StateParams = location.state as StateParams

    const [reloadForm, setReloadForm] = useState<boolean>(false)

    const resendHandler = () => {
        setReloadForm(true)

    }

    return (
        <Enfold title="Check your email for a code" subtitle={
            <Box sx={{ textAlign: "center", margin: "16px auto 0 auto", fontSize: "1em", width: "90%", lineHeight: "24px"}}>
                <span>
                    We've sent a 6-character code to
                    <span style={{ fontWeight: "bold", margin: "0 4px" }}>{State?.email}.</span>
                    This code expires shortly, so please enter it soon.
                </span>
            </Box>
        }>
            <EmailVerificationForm enfoldItem="form" reloadForm={reloadForm} cancelReloadForm={setReloadForm} />
            <Box sx={{ width: "80%", textAlign: "center", lineHeight: "24px" }}>
                <Box sx={{ height: "auto", ...CenterItem }}>
                    {/* Here we do the forget password */}
                    Can't find your code? Check your spam folder!
                </Box>
                <Box sx={{ height: "auto", ...CenterItem, marginTop: "16px" }}>
                    {/* Here we do the forget password */}
                    <SignupLink onClick={resendHandler}>Resend code</SignupLink>
                </Box>
            </Box>
        </Enfold>
    )
}

