import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { grey } from "@mui/material/colors";
import Row from "components/Row";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IPremises } from "Straclou";
import { v4 as uuidV4 } from 'uuid';


interface Column {
    id: 'start_date' | 'plan' | 'renewal_date' | 'days_left' | 'status' | 'menu';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'plan', label: 'Plan' },
    {
        id: 'status',
        label: 'Status',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'start_date',
        label: 'Start Date',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'renewal_date',
        label: 'Renewal Date',
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'days_left',
        label: 'Days Remaining',
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
    {
        id: 'menu',
        label: '',
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
];

export default function SubscriptionList() {

    const navigate = useNavigate()
    const data: any = [
        {
            id: uuidV4(),
            plan: "Residential",
            status: "Pending",
            start_date: "Nov 1, 2022",
            renewal_date: "Dec 1, 2022",
            days_left: 30
        },
        {
            id: uuidV4(),
            plan: "Residential",
            status: "Active",
            start_date: "Oct 1, 2022",
            renewal_date: "Nov 1, 2022",
            days_left: 22
        },
        {
            id: uuidV4(),
            plan: "Residential",
            status: "Expired",
            start_date: "Sep 1, 2022",
            renewal_date: "Oct 1, 2022",
            days_left: 0
        },
        {
            id: uuidV4(),
            plan: "Residential",
            status: "Expired",
            start_date: "Aug 1, 2022",
            renewal_date: "Sep 1, 2022",
            days_left: 0
        }
    ]

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequest = ()=>{
        navigate("/subscription/purchase")
    }


    return (
        <>
            <Box sx={{ margin: "24px 0", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Row>
                My Subscription
                </Row>
                <Row sx={{display: "flex", justifyContent: "flex-end"}}>
                    <Button variant="contained" onClick={handleRequest}>Renew Subscription</Button>
                </Row>
            </Box>


            <Box sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{
                    maxHeight: "calc(100vh - 90px)", "&::-webkit-scrollbar": {
                        width: "4px"
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#ebebeb",
                        webkitBorderRadius: "0px",
                        borderRadius: "0px"
                    },
                    "&::-webkit-scrollbar-thumb": {
                        webkitBorderRadius: "0px",
                        borderRadius: "0px",
                        background: grey[400]
                    }
                }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ height: "70px", cursor: "pointer" }} >

                                            {/* Plan */}
                                            <TableCell >
                                                <Row sx={{ display: "flex" }}>
                                                    {row?.plan}
                                                </Row>
                                            </TableCell>

                                            {/*Status */}
                                            <TableCell >
                                                <Box>
                                                    {row.status}
                                                </Box>
                                            </TableCell>

                                            {/* Start Date */}
                                            <TableCell >
                                                <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                    {row?.start_date}
                                                </Box>
                                            </TableCell>

                                            {/* Renewal Date */}
                                            <TableCell align="right">
                                                {row.renewal_date}
                                            </TableCell>


                                            {/* Days Left */}
                                            <TableCell >
                                                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                                    {row.days_left}
                                                </Box>
                                            </TableCell>


                                            {/* Menu */}
                                            <TableCell >
                                                ...
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box >
        </>
    )
}