import { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from "react";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    onOpen: boolean;
    openStateChange: Function;
    onAnswer: Function
    code: string

}

interface Answer {
    button: "Yes" | "No"
}


export default function EmptyDialog(props: IProps) {
    const [open, setOpen] = useState(false);
    const [codeX, setCodeX] = useState("");

    
    const handleClickOpen = () => {
        setOpen(true);
        props.openStateChange(true)
    };

    const handleClose = () => {
        setOpen(false);
        props.openStateChange(false)
    };

    useEffect(() => {
        if (props.onOpen) {
            handleClickOpen()
        } else {
            handleClose()
        }
    }, [props.onOpen])

   
    const handleYes = () => {
        props.onAnswer("Ok")
        handleClose()
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog"
            >
                <DialogTitle>{"Form Validation Error"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog">
                        You must enter the 6 digit code that was sent to your email
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleYes}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}