import Row from "components/Row";
import { IContact } from "Straclou/commons/IContact";
import { deepOrange } from "@mui/material/colors";
import { styled, Box } from "@mui/system";
import React from "react";

import CustomTab from "./components/CustomTab";

// import { useActiveAccount } from "../Inventory/AccountNavigation/AccountNavigationContext";
// import useAppSync from "hooks/useAppSync";

// import { IProprietor } from "Straclou/commons/IProprietor";
// import { useLocation } from "react-router-dom";

const avSize = 40

const iconColor = deepOrange[300]


const OwnerDetail = styled(Box)(
  ({
    height: "100%",
  }),
);
const IconItem = styled(Box)(
  ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

  }),
);
const ItemInfo = styled(Box)(
  ({
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: ".9em"

  }),
);
const DisplayItem = styled(Box)(({
  display: "flex",
  minHeight: "28px",
  height: "auto",
  margin: "1px 0",
  // background: "gold"
}));

const DisplayMultipleItem = styled(Box)(({
  display: "grid",
  gridTemplateColumns: "auto",
  gridTemplateRows: "repeat('28px')",
  gap: "0 0",
  minHeight: "28px",
  margin: "1px 0",
  // background: "gold",
  ".info": {
    // background: "green",
    fontSize: '.99em'
  },
  ".type": {
    margin: '0 12px',
    fontSize: '.75em',
    color: "GrayText",
    position: "relative",
    '&:before': {
      content: `''`,
      width: '4px',
      height: "4px",
      top: "6px",
      left: "-8px",
      borderRadius: "50%",
      background: "GrayText",
      position: "absolute"
    }
  }
}));

interface IEmailProps {
  index: number
  address?: IContact
}
const EmailInfo: React.FC<IEmailProps> = ({ index, address }) => {
  return (
    <DisplayMultipleItem>
      <ItemInfo>
        <div className="info">{address?.address}</div>
        <div className="type">{address?.type}</div>
      </ItemInfo>
    </DisplayMultipleItem>
  )
}

interface IPhoneProps {
  index: number
  contact?: IContact
}
const PhoneInfo: React.FC<IPhoneProps> = ({ index, contact }) => {
  return (
    <DisplayMultipleItem>
      <ItemInfo>
        <div className="info">{contact?.address}</div>
        <div className="type">{contact?.type}</div>
      </ItemInfo>
    </DisplayMultipleItem>
  )
}

const PersonalDetail: React.FC = () => {
  return (
    <OwnerDetail>
      <Row sx={{ padding: "0 10px", width: "calc(100% - 20px)" }}>
        <CustomTab />
      </Row>

    </OwnerDetail>
  );
}

export default PersonalDetail