import { Box, IconButton, styled, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";


import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useAccountStore from "hooks/AccountStore";
import { useEffect, useState } from "react";
import useAccount from "hooks/useAccount";
import { useParams } from "react-router-dom";



const Wrapper = styled(Box, { name: "Pagination-Wrapper" })({
    background: "0",
    color: grey[500],
    position: "absolute",
    right: "10px",
    top: "10%",
    display: "flex",
    flexDirection: "row",
    fontSize: "10pt",
    alignItems: "center"
})

export default function AccountPagination(props: any) {

    const { count, start, end, totalCount, next, page } = useAccountStore()
    const { paginate, isLoading } = useAccount()

    const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false)

    const params = useParams()

    useEffect(() => {
        setShowLeftArrow(start > 0 ? false : true)
    }, [start])

    const handleNext = ()=>{
        let nextPage = page + 1
        paginate({
            cid: params?.workspaceId || "",
            nextPage: nextPage
        })
    }

    const handlePrevious = ()=>{
        let nextPage = page - 1
        paginate({
            cid: params?.workspaceId || "",
            nextPage: nextPage
        })
    }

    return (
        <Wrapper>
            <Box sx={{mr: 1}}>
                {start === 0 ? 1 : start}-{end} of {totalCount}
            </Box>

            <Tooltip title="Previous">
                <IconButton
                    aria-label="previous accounts"
                    component="label"
                    onClick={handlePrevious}
                    disabled={showLeftArrow}
                    color={"primary"}
                >
                    <ChevronLeftIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title="Next">
                <IconButton
                    aria-label="next accounts"
                    component="label"
                    onClick={handleNext}
                    color={"primary"}
                    disabled={!next}
                    >
                    <ChevronRightIcon />
                </IconButton>
            </Tooltip>
        </Wrapper>
    )
}