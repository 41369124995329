

// import { useUserAuthentication } from "globals/Authentication";
import { theme } from "globals/theme";

import { CreditCard } from "@mui/icons-material";
import { styled, Box, Divider, alpha, Button, Stack } from "@mui/material";
import useAppSync from "hooks/useAppSync";
import React, { useContext, useEffect } from "react";
import { useParams } from "react-router";
// import PaymentTypeSelection from "./PaymentTypeSelection";
// import PaymentTypeSticker from "./PaymentTypeSticker";
// import SummaryCardDialog from "./SummaryCardDialog";
import { grey } from "@mui/material/colors";
import { ActivePaymentContext } from "./Context/PaymentProvider";
import { PaymentSummaryContext } from "./Context/PaymentSummaryProvider";
import ClouItemTitle from "components/ClouItemTitle";
import ClouItemInfo from "components/Display/ClouItemInfo";
import Row from "components/Row";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import ClouButtonGroup from "components/ClouButtonGroup";
import ClouTextArea from "components/ClouTextArea";
import PaymentTypeSelection from "./PaymentTypeSelection";
import SummaryCardDialog from "./SummaryCardDialog";
import MoneyExhibit from "components/MoneyExhibit";
import PaymentTypeSticker from "./PaymentTypeSticker";



const Wrapper = styled(Box, { name: "wrapper-property" })(({
    overflow: "hidden",
    overflowY: "auto",
    position: "relative",
    height: "calc(100vh - 150px)",
    // background:"blue"
}))

const PaymentButton = styled(Button, { name: "Clou-Payment-Button" })(({
    width: "88%"
}))


const LearnMoreButton = styled(Button, { name: "Clou-more-Button" })(({
    width: "auto",
    height: "16px",
    textAlign: "start",
    margin: "5px 0 0 0",
    padding: 0,
    borderRadius: "15px"
}))


const NotificationCard = styled(Box, { name: "notification-card-info" })(({
    borderRadius: "5px",
    background: alpha(theme.palette.secondary.main, 0.1),
    color: alpha(theme.palette.secondary.main, 0.8),
    fontSize: ".7em",
    padding: "15px"
}))

interface IProps {
    accountId?: string
}

const PaymentMethodsCard: React.FC<IProps> = ({ accountId }) => {

    const { financialId } = useParams()

    const [data, isLoading, dispatch ] = useAppSync()

    // const { user } = useUserAuthentication()

    const { total, paymentMethod, setFinancialId, setDescription } = useContext(ActivePaymentContext)
    const { setOpenSummary } = useContext(PaymentSummaryContext)


    useEffect(() => {
        setFinancialId(financialId)
    }, [financialId])

    const handleDescription = (e: any) => {
        setDescription(e.target.value)
    }

    const handleSubmit = () => {
        if (total > 0) {
            setOpenSummary(true)
        }
    }

    return (
        <Wrapper>
            <Row sx={{ padding: "15px 30px" }} height={'auto'}>
                {/* <ClouItemTitle sx={{ fontSize: "1em", color: "#000" }}></ClouItemTitle> */}

                <Stack>
                    <ClouItemTitle sx={{ fontSize: "1em", color: grey[800] }}>Transaction</ClouItemTitle>
                    <ClouItemInfo sx={{ fontSize: ".75em", color: grey[500] }}>Payments made to this account will apply to the oldest unpaid reciepts.</ClouItemInfo>
                </Stack>

                <Box sx={{ background: '0', width: "88%", padding: "5px 0 0 0" }}>
                    <Divider />
                </Box>

                <Box sx={{ height: "auto", overflow: "hidden", bgcolor: '0' }}>
                    <Stack>

                        <Box sx={{ background: '0', width: "88%", padding: "15px 0 0 0" }}>
                            <PaymentTypeSelection />
                           
                        </Box>

                        <Box sx={{ height: "100px", display: "flex" }}>

                            {
                                paymentMethod.map(el => {
                                    return (
                                        <PaymentTypeSticker key={el.option}
                                            icon={<CreditCard />}
                                            label={el.option}
                                            value={<MoneyExhibit value={el.amount} />}
                                        />
                                        // <Box key={el.option}>{el.amount}</Box>

                                    )
                                })
                            }
                        </Box>


                        <Box sx={{ width: "88%", padding: "15px 0 0 0", background: 0, height: "auto" }}>
                            <ClouTextArea
                                height={600}
                                minRows={5}
                                maxRows={5}
                                placeholder="Description"
                                label=""
                                onChange={(e: any) => { handleDescription(e) }}
                            />
                        </Box>

                        <SummaryCardDialog />

                        <Box sx={{ width: "88%", display: "flex", justifyContent: "center", alignItems: "center", background: '0', padding: "5px 0 0 0", position: "inherit" }}>
                            <PaymentButton variant={'contained'} color="primary" onClick={() => { handleSubmit() }} >
                                <ClouButtonGroup sx={{ width: "auto", height: "auto" }} >
                                    Record Payment <ClouMoneyExhibit value={total} />
                                </ClouButtonGroup>
                            </PaymentButton>
                        </Box>
                    </Stack>
                </Box>

            </Row>

        </Wrapper>
    );
}

export default PaymentMethodsCard