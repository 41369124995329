import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from 'i18next-http-backend';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

i18n
.use(Backend)
.use(I18nextBrowserLanguageDetector)
.use(initReactI18next) // passes i18m down to the react-i18next
.init({
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false // false ready safes from xss
    },
    detection: {
        order: ['cookie','htmlTag','localStorage', 'path','subdomain'],
        caches: ['cookie']
    },
    backend: {
        loadPath: '/locales/{{lng}}/translation.json'
    }
})

export default i18n