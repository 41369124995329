import { Box, styled } from "@mui/material";
import useApplicationAccess from "hooks/useApplicationAccess";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoadingActivity from "./LoadingActivity";

const Wrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column-reverse"
})

export default function LandingLoader() {
    /**
     * Get the information to determine the page you want to 
     * Display
     */
    const navigate = useNavigate()
    const access = useApplicationAccess()

    useEffect(() => {
        // navigate(access.page)
    }, [access])
    return (
        <Wrapper>
            <LoadingActivity />
            
        </Wrapper>
    )
}