import FloatingText from "components/FloatingText"
import useAuth from "hooks/useAuth"
import React from "react"

export default function UserInfo() {
    const { user } = useAuth()

    return (
        <FloatingText >
            {user?.email}
        </FloatingText>
    )
}