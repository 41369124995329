import { styled, Box } from "@mui/material"
import React from "react"


const Wrapper = styled(Box)(({
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))


const NotificationPage: React.FC = () => {
    return (
        <Wrapper>Straclou app wide Notification</Wrapper>
    )
}

export default NotificationPage