import { Observable } from 'rxjs'
// import { Auth } from "aws-amplify";

import { Invoice } from "../interfaces/Invoice";
import { ConnectionStrings } from '../ConnectionStrings';

import { IInvoice } from '../commons/IInvoice';
import { axiosExecute, AxiosOptions } from '../axios';
import { Auth } from 'aws-amplify';
export module AccountConnectionManager {

    // constance
    const PAYMENTS = "payments"

    const TOTAL = 'total'

    const TOTAL_PAYMENT = 'totalPayment'

    const PERCENTAGE_PAID = 'percentagePaid'

    const IS_PAID = 'isPaid'

    const TOTAL_PERCENTAGE = "totalPercentage"

    const communityIdx = "e004a3cf-7051-4cd3-8ec2-020dfd2e64a6"

    /**
     * List all Invoices for a specific Account.
     * @param filter 
     * @returns 
     */    
    export const listAllInvoices = async (filter: any) => {
        try {
            const communityId = communityIdx//(await CommunityActivityHandler.GetCommunityProfileFromLocalStorage()).id

            filter['communityId'] = communityId

            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.account,
                data: JSON.stringify({
                    input: filter,
                    method: 'getAllInvoices'
                })

            }

            const data = await axiosExecute(option) as Array<any>            
            let inv: IInvoice[] = []
            data.forEach(res=>{
                inv.push(new IInvoice(res))
            })
            return inv
        } catch (error) {
            return []
        }
    }

    export const listAllReceipt = async(filter: any)=>{
        try {
            const communityId = communityIdx//(await CommunityActivityHandler.GetCommunityProfileFromLocalStorage()).id
            filter['communityId'] = communityId

            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.account,
                data: JSON.stringify({
                    input: filter,
                    method: 'listAllReceipt'
                })

            }
            const data = await axiosExecute(option) as Array<any>
            console.log(data)
            return data
        } catch (error) {
            console.log("Error on get list of transactions. ", error)
            return []
        }
    }

    /**
     * ListInvoices this fn will be depreciated to List Outstanding Invoice
     * @param filter 
     * @returns 
     */
    export const listInvoices = async (filter: any) => {
        try {
            // const communityId = communityIdx //(await CommunityActivityHandler.GetCommunityProfileFromLocalStorage()).id
            // filter['communityId'] = communityId

            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.account,
                data: JSON.stringify({
                    input: filter,
                    method: 'getAllOutStandingInvoices'
                })

            }



            const data = await axiosExecute(option) as Array<any>

            // console.log("Invoice Result from server: ", data);

            let sorted = Array.isArray(data) ? data.sort((a, b) => {
                const aDate = new Date(a.createdAt).getTime()
                const bDate = new Date(b.createdAt).getTime()
                return aDate - bDate
            }) : []

            return sorted

        } catch (error) {
            console.log("Error on get list of transactions. ", error)
            return []
        }
    }

    // Get the session Id for the payment process
    export const getSessionId = async () => {
        try {
            // Get the community id from app settings
            const communityId = communityIdx//(await CommunityActivityHandler.GetCommunityProfileFromLocalStorage()).id

            // setup the Post request
            let option: AxiosOptions = {
                method: "POST",
                url: ConnectionStrings.account,
                data: JSON.stringify({
                    input: { input: { communityId } },
                    method: 'getPaymentSessionId'
                })
            }


            const data = await axiosExecute(option)

            console.log("Result from server session ID: ", data);

            return data

        } catch (error) {

        }
    }

    export const submitPayment = async (payload: any) => {

        let user = await Auth.currentUserInfo()
        // let user: any = {}
        // console.log(user)
        // const communityId = ''//(await CommunityActivityHandler.GetCommunityProfileFromLocalStorage()).id
        const communityId = communityIdx

        payload['communityId'] = communityId


        console.log("Active User: ",user)
        if(Object.keys(user).length > 0){
            payload["author"] = {
                id: user.username,
                name: user["attributes"]["name"]
            }

        }

        console.log("Final payload: ",payload)
        // setup the Post request
        let option: AxiosOptions = {
            method: "POST",
            url: ConnectionStrings.account,
            data: JSON.stringify({
                input: payload,
                method: 'makePayment'
            })
        }

        try {
            const data: any = await axiosExecute(option)

            console.log("Make Payment Result: ",data)
            
            if (data.hasOwnProperty("error")) {
                throw data.message
            }
            
            return data

        } catch (error) {
            console.log(error)
            return false
        }

        // return {}


    }

    // set Payment method
    export const setPaymentMethod = async (payload: any) => {
        let storage = localStorage.getItem(payload.sessionId)
        let data: any = {}
        if (storage !== null) {
            data = JSON.parse(storage ?? {})
            if (Object.keys(data).length > 0) {
                data['method'].push({ option: payload['option'], amount: payload['amount'] })
            } else {
                data['method'] = []
                data['method'].push({ option: payload['option'], amount: payload['amount'] })
            }
        }

        if (storage === null) {
            data['method'] = []
            data['method'].push({ option: payload['option'], amount: payload['amount'] })
        }

        localStorage.setItem(payload.sessionId, JSON.stringify(data))
    }

    export const getCurrentPaymentProcess = async (sessionId: string): Promise<any> => {
        let storage = localStorage.getItem(sessionId)
        try {
            return JSON.parse(storage || '{}')
        } catch (error) {
            return {}
        }
    }

    export const getTotalPayment = (): Observable<number> => {
        return new Observable((subscriber: any) => {
            let total = 0;
            // setInterval(()=>{
            getCurrentPaymentProcess("PaymentSessionProcessData_1").then(res => {
                // if(res.hasOwnProperty('method')){
                //     res['method'].forEach(el=>{
                //         total += parseInt(el.amount)
                //         console.log(total)
                //         subscriber.next(total)
                //     })
                //     subscriber.complete()
                // }
            })
            // },1000)
        })
    }

    export const checkInvoices = async ({ invoices, payment }: IInvoiceCheck): Promise<Array<any>> => {
        const inv: [] = invoices
        let pay = payment
        inv.map((invoice: any) => {
            if (pay > 0) {
                // console.log("Payment greater than 0: ", pay)
                // we are going to get the state of the difference of applying this payment to the current invoice
                let r = (invoice.totalPayment + pay) - invoice.total

                // Apply the payment 
                if (r < 0) {
                    invoice = updateInvoiceWithReceipt(invoice, pay)
                    pay = 0
                }
                else {
                    let paid = invoice.total - invoice.totalPayment
                    invoice = updateInvoiceWithReceipt(invoice, paid)
                    pay = r
                }
                return invoice

            }

            // if (invoice?.id === "b101ddf4-8c23-436c-b55c-34a1c5f530c9") invoice['clear'] = true
            //     return invoice
        })
        return inv
    }

    function updateInvoiceWithReceipt(payload: Invoice, payment: number): Invoice {

        let total = payment + payload.totalPayment
        let percentage = total / payload[TOTAL]
        // payload.percentagePaid += percentage

        // setup the current payment
        payload.currentOutstanding = payload.total - payload.totalPayment
        payload.currentPayment = payment
        payload.currentOutstandingBalance = payload.currentOutstanding - payment
        payload.currentPercentage = percentage

        // If the full amount is covered then update the payment state
        if (payload.currentPercentage === 1) payload["currentIsPaid"] = true


        return payload
    }
}


export interface IInvoiceCheck {
    invoices: any
    payment: number
}

