import { styled, Box, Stack, Divider, ButtonGroup, IconButton, alpha, TableRow, SxProps, TableBody, TableFooter, Tooltip, TooltipProps, tooltipClasses, Chip, Paper } from "@mui/material";
import ClouItemInfo from "components/Display/ClouItemInfo";
import { green, grey, red } from "@mui/material/colors";
// import { AccountConnectionManager } from "@Straclou/commons/account/AccountConnectionManager";
import React, { useContext, useEffect, useState } from "react";



import { useLocation } from "react-router-dom";

import Row from "components/Row";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import { useActiveAccount } from "./Context/AccountNavigationContext";
import { ActivePaymentContext } from "./Context/PaymentProvider";
import ClouItemTitle from "components/ClouItemTitle";
import { AccountConnectionManager } from "Straclou/handler/AccountConnectionManager";
import ClouInvoiceCheck from "components/widget/ClouInvoiceCheck";




interface ITallyProps {
    count: number
}
const Tally: React.FC<ITallyProps> = ({ count }) => {
    const [display, setDisplay] = useState<string>("")
    const message = {
        0: "No invoices found",
        1: " invoice found",
        3: " outstanding invoices found"
    }

    useEffect(() => {
        setDisplay("")
    }, [])
    useEffect(() => {
        let opt = count > 1 ? ">" : count === 0 ? '0' : "1"
        switch (opt) {
            case "0":
                setDisplay(message[0])
                break;
            case "1":
                setDisplay(`${count}${message[1]}`)
                break;
            case '>':
                setDisplay(`${count}${message[3]}`)
                break;
            default: setDisplay("")
        }
    }, [count])
    return (
        <Box sx={{ padding: "0", minWidth: "160px", width: "auto" }}>{display}</Box>
    )
}
interface IPaymentTallyProps {
    invoices?: Array<any>
    pay: number
}

const PaymentTally: React.FC<IPaymentTallyProps> = ({ invoices, pay }) => {
    const [display, setDisplay] = useState<string>("")
    const [count, setCount] = useState<number>(0)
    const [invoice, setInvoices] = useState<Array<any>>([])
    const [total, setTotal] = useState<number>(0)
    const [percentage, setPercentage] = useState<number>(0)

    const message = {
        0: "",
        1: " invoice",
        3: " invoices" //affected
    }

    useEffect(() => {
        if (invoices !== undefined) {
            setCount(Math.ceil(invoices.reduce((total, inv) => { return total + (inv.currentPercentage ?? 0) }, 0)))

            let t = 0
            invoices.forEach(res => {
                if (res?.currentPayment) {
                    console.log(res.currentOutstanding)
                    t += res.currentOutstanding
                }
            })
            setTotal(t)
            setPercentage(Math.ceil((pay / t) * 100))
        }
    }, [invoices])

    useEffect(() => {
        setDisplay("")
    }, [])
    useEffect(() => {
        let opt = count > 1 ? ">" : count === 0 ? '0' : "1"
        switch (opt) {
            case "0":
                setDisplay(message[0])
                break;
            case "1":
                setDisplay(`${count}${message[1]}`)
                break;
            case '>':
                setDisplay(`${count}${message[3]}`)
                break;
            default: setDisplay("")
        }
    }, [count])
    return (
        <Box >
            {
                pay ?
                    <Row flex sx={{ padding: "0 2px", color: green[300], "&:hover": { color: green[500] } }}>
                        <Box>intented payment covers {percentage}% for the {display} totalling &nbsp; </Box>
                        <ClouMoneyExhibit value={total} />
                    </Row>
                    :
                    ""
            }
        </Box>
    )
}










const Wrapper = styled(Paper, { name: "wrapper-property" })(({
    // display: "grid",
    // gridTemplateColumns: "1fr",
    // gridTemplateRows: "30px 120px 40px",
    // gap: "10px 20px",
    height: "calc(100% - 45px)",
    overflow: "hidden",
    // background: '#f3f3f3',
    // borderRadius: '13px'
    fontSize: ".95em"
}))

const Table = styled('table', { name: "Table-custom" })(({
    height: "100%",
    fontSize: ".9em",
    color: grey[600],
    width: "100%",
    // background: "red"

}))

const TableHeader = styled('th', { name: "Table-Header-custom" })(({
    height: "30px",
    fontSize: ".9em",
    color: grey[600],
    textAlign: "left"
}))

const ITableRow = styled(TableRow, { name: "custom-Table-Row-invoice" })((({
    display: "table",
    width: "100%",
    tableLayout: "fixed"
})))


interface ITDProps {
    children: React.ReactNode, 
    strike?: boolean
}

const TableDetail = styled('td', { name: "Table-Detail-custom" })(({
    height: "30px",
    borderBottom: `1px solid ${grey[200]}`,
    // textDecoration: strike.toString() === "true" ? 'line-through' : "none"
}))

const ITableBody = styled(TableBody)(({
    display: "block",
    width: "100%",
    overflow: "hidden",
    overflowY: "auto",
    height: "calc(calc(100vh - 500px) - 8px)",
    "&::-webkit-scrollbar": {
        width: "4px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#ebebeb",
        webkitBorderRadius: "0px",
        borderRadius: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
        webkitBorderRadius: "0px",
        borderRadius: "0px",
        background: grey[400]
    }
}))




interface IProps {
    accountId?: string
}

interface StateType {
    proprietor: string
}
const InvoiceCard: React.FC = () => {

    const { account } = useActiveAccount()

    // const { state: { data, isLoading }, dispatch } = useAppSync<any>()
    const { invoices, setInvoices, total } = useContext(ActivePaymentContext)
    const [isLoading, setLoading] = useState(true)
    const [state, setState] = useState<StateType>({proprietor: ""})


    const location = useLocation()
    

    useEffect(()=>{
        setState(location.state as StateType)
        console.log('Location state: ',location.state)
    },[location.state])

    useEffect(() => {
        console.log("Invoice account: ",account)
        if (account.accountNumber !== undefined) {
            AccountConnectionManager.listInvoices({ accountId: account.accountNumber, communityId: account.communityId }).then(res => {
                // console.log(res)
                setInvoices([...res])
                
               

                setLoading(false)
            })
        }
        
    }, [account?.accountNumber])

    useEffect(() => {

        AccountConnectionManager.listInvoices({ accountId: account.accountNumber, communityId: account.communityId }).then(response => {
            AccountConnectionManager.checkInvoices({ invoices: response, payment: total }).then((res: any) => {
                // console.log(res)
                setInvoices([...res])
            })
        })

    }, [total])

   



    return (
        <Wrapper>
            <Row sx={{ padding: "8px 30px" }} height={'auto'}>
                <Row flex sx={{ width: "88%" }}>
                    <Row>
                        <Stack>

                            <ClouItemTitle sx={{ fontSize: ".8em", color: grey[500] }}>Account Name</ClouItemTitle>
                            <ClouItemInfo sx={{ fontSize: ".8em" }}>{state?.proprietor}</ClouItemInfo>
                        </Stack>

                    </Row>
                    <Row right>
                        <Stack>
                            <ClouItemTitle sx={{ fontSize: ".7em", color: grey[500] }}>Account Number</ClouItemTitle>
                            <ClouItemInfo sx={{ fontSize: ".75em" }}>{account.accountNumber}</ClouItemInfo>
                        </Stack>
                    </Row>

                </Row>



                <Box sx={{ background: '0', width: "88%", padding: "5px 0 0 0" }}>
                    <Divider />
                </Box>

                <Box sx={{ height: "calc(100vh - 154px)", width: "88%", overflow: "hidden", padding: "8px 0 50px 0" }}>
                    <Table>
                        <thead>
                            <ITableRow >
                                {/* <Row height={30} flex sx={{ fontSize: ".74em", justifyContent: "space-between", paddingTop: "5px" }}> */}
                                <TableHeader sx={{ width: "24px" }}></TableHeader>
                                <TableHeader>Invoice No.</TableHeader>
                                <TableHeader>Month</TableHeader>
                                <TableHeader>Amount</TableHeader>
                                <TableHeader sx={{ textAlign: "right", paddingRight: "16px" }}>Outstanding</TableHeader>
                            </ITableRow>
                        </thead>
                        <ITableBody>
                            {
                                invoices?.map((el: any) => {
                                    return (
                                        <ITableRow key={el?.id}>
                                            <TableDetail sx={{ width: "24px" }}>
                                                <ClouInvoiceCheck invoice={{
                                                    percentage: el.currentPercentage, payment: el.currentPayment, balance: el.currentOutstandingBalance}} />
                                            </TableDetail>
                                            <TableDetail>
                                                {el?.trackingNumber}
                                            </TableDetail>
                                            <TableDetail>{el?.month}</TableDetail>
                                            <TableDetail><ClouMoneyExhibit value={el?.amount} /></TableDetail>
                                            <TableDetail style={{ textAlign: "right", paddingRight: "16px", color: red[300] }}><ClouMoneyExhibit value={el?.total - el?.totalPayment} /> </TableDetail>
                                        </ITableRow>
                                    )
                                })

                            }

                        </ITableBody>
                        <TableFooter>
                            <tr>
                                <td style={{ fontSize: ".75em", color: grey[500] }}>
                                    <Row flex>
                                        <Tally count={invoices.length} />
                                        <PaymentTally invoices={invoices} pay={total} />
                                    </Row>
                                </td>

                            </tr>
                        </TableFooter>
                    </Table>
                </Box>

            </Row>

        </Wrapper>
    );
}

export default InvoiceCard

