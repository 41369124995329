import AppButton from "components/widget/AppButton";


import { ArrowBack } from "@mui/icons-material";
import { Box, Breadcrumbs, IconButton, Paper, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PersonalDetail from "./PersonalDetail";

import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
// import HailIcon from '@mui/icons-material/Hail';
// import ReceiptIcon from '@mui/icons-material/Receipt';

import Comments from "Features/Commentary/Comments";

import { theme } from "globals/theme";

import TransactionHistoryV2 from "Features/TransactionServices/TransactionHistoryV2";
import { Route } from "React-Router-Internal";
import { useINavigate, useIRoutesParams } from "React-Router-Internal";
import MainPaymentActivity from "Features/PaymentServices/MainPaymentActivity";

import ClouAccountNumberExhibit from "components/ClouAccountNumberExhibit";
import { useWidgetBuilderNavigation, useWidgetBuilderParams, WidgetBuilderContext } from "@knymbus/react-widget-builder";


const DetailContainer = styled("div")({
  // background:"blue",
  borderTop: `1px solid ${theme.palette.divider}`,
  width: "100%",
  overflow: "hidden",
  height: "calc(100vh - 62px)",

  '.cdcontent': {
    display: 'grid',
    gridArea: 'cdcontent',
    gridTemplateColumns: '330px 1fr',
    gridTemplateRows: 'calc(100vh - 98px) 1fr',
    gridTemplateAreas: `"detail transaction" "property transaction"`,
    gap: '5px 5px',
    padding: '10px 10px 0 12px',
    height: "100%",
    '.detail': {
      gridArea: 'detail',
      // background: "red",
      padding: "0px 0",
      margin: "0",
      bgcolor: "background.paper"
    },
    '.property': {
      gridArea: 'property',
      // background: "purple"
      bgcolor: "background.paper",
      padding: "10px 0",
    },
    '.transaction': {
      gridArea: 'transaction',
      // background: "green",
      bgcolor: "background.paper",
      padding: "0px",
      height: "calc(100vh - 98px)",
      overflow: "hidden"
    },
    '.cd-item': {
      // borderRight: "1px solid grey",

    }
  }


})

export const DetailHeaderFragment: React.FC<any> = () => {
  const params = useWidgetBuilderParams()

  const { navigate } = useWidgetBuilderNavigation()

  useEffect(() => {
    console.log("selected item params: ", params);

  }, [params])

  const handlePaymentOnClick = () => {
    navigate({
      path: "main_payment"
    })
  }
  return (
    <Paper elevation={0}

      sx={{
        display: "flex",
        flexWrap: "nowrap",
        position: "fixed",
        width: "calc(100% - 100px)",
        height: "33px",
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >

      <Box className="crumbs" sx={{ width: 'auto', padding: "0 5px", }} >

        <Breadcrumbs>
          <Typography color={grey[600]} fontSize=".75em">
            Unit #{params?.unitNumber}
          </Typography>
          <Typography color={grey[600]} fontSize=".75em">
            {params?.accountName}
          </Typography>
          <Typography color={grey[600]} fontSize=".75em">
            <ClouAccountNumberExhibit value={params?.accountNumber} />
          </Typography>
        </Breadcrumbs>
      </Box>

      <Box sx={{ width: 'auto', padding: "0 5px", position: "absolute", right: "16px", display: 'flex', flexDirection: "row-reverse", alignItems: "center" }}>

        {/* Detail Menu */}
        {/* <DetailAction /> */}


        {/* Comments Component with Button */}
        <Comments params={{ id: params?.id, communityId: params?.communityId }} cbutton={
          <AppButton value="Comments" variant="default" starticon={<NoteAddIcon sx={{ width: "18px", height: "18px" }} />} />
        } />

        {/* Payment Button */}
        <AppButton value="Record Payment" variant="default" starticon={<AttachMoneyIcon sx={{ width: "18px", height: "18px" }} />} onClick={handlePaymentOnClick} />

        {/* Reminder Button */}
        {/* <AppButton startIcon={<NotificationAddIcon sx={{ width: "18px", height: "18px" }} />} value="Reminder" /> */}

        {/* Vistors Button */}
        {/* <AppButton startIcon={<HailIcon sx={{ width: "18px", height: "18px" }} />} value="Visitors List" /> */}

        {/* Transactions Button */}
        {/* <AppButton startIcon={<ReceiptIcon sx={{ width: "18px", height: "18px" }} />} value="Transactions" /> */}


      </Box>
    </Paper>
  )
}


const CustomerDetail = () => {

  const navigation = useNavigate();
  const location = useLocation();

  const { accountId } = useParams<{ accountId: string }>();
  const [state, setState] = useState<any>(null);

  // internal Routes 

  const iNavigate = useINavigate()
  const { iPath, routeParams } = useIRoutesParams()

  const [destination, setDestination] = useState<Route | null>(null)

    const handleDestination = (path: string) => {
    // setDestination(Navigation(path))
    // iNavigate([path,{accountId}])
  }

  const handlePayment = () => {

  }


  useEffect(() => {
    if (location?.state) setState(location.state);
  }, [location])


  const isActiveLink = (path: string) => {
    // console.log("active path: ", iPath)
    // console.log("params: ", routeParams)
    return path === iPath ? theme.palette.primary.main : grey[400]
  }

  return (
    <DetailContainer className="detail-container" >
      <Box className="cdcontent">
        <Box className="detail cd-item" sx={{ bgcolor: 'background.paper' }}>
          <PersonalDetail />
        </Box>
        <Box className="transaction cd-item">
          <TransactionHistoryV2 />
        </Box>
      </Box>
    </DetailContainer>
  );
};



export default CustomerDetail