import { styled,  Box,  } from "@mui/material"
import UserMenu from "Features/UserProfileService/Menu";
import NotificationMenu from "Features/Notification/Menu";
import NewPropertyAccountMenu from "Features/CreatePropertyAccount/Menu";


const Wrapper = styled(Box)(({
    display: "flex",
    // background: "gold",
    height: "100%",
    width: "100%"

}))

const MenuContainer = styled(Box)(({
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    margin: 0,
    padding: 0,
    // bgcolor: "gold"
}))

const hwtt = "auto"

const MenuItem = styled(Box)(({
    // background: "red",
    margin: "0",
    display: "flex",
    // alignItems: "center",
    justifyContent: "flex-start",
    width: hwtt,
    height: "50px",
    padding: "0px",
    overflow: "hidden",
    mr: .5

}))


const MenuIconComponent = () => {
    return (
        <Wrapper>
            <MenuContainer>
                <MenuItem>
                    <UserMenu />
                </MenuItem>
                <MenuItem>
                    <NotificationMenu />
                </MenuItem>
                <MenuItem>
                    <NewPropertyAccountMenu />
                </MenuItem>
            </MenuContainer>
        </Wrapper>
    )
}

export default MenuIconComponent