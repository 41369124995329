export module NameManipulator {
    export function getIniitals(name: string, numberOfInitials?: number) {
        if (name !== undefined){
            let initials = name.split(" ").reduce((t, b) => (t.concat(b.charAt(0).toUpperCase())), "")
            if(numberOfInitials !== undefined && numberOfInitials !== null && numberOfInitials !== 0){
                return initials.substring(0,numberOfInitials)
            }
            return initials
        }
        return ""
    }
}