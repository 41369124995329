 import { IClassInterface } from "./commons/IClassInterface"


export module IClass {
    /**
     * Take a list and modify each item in the callback function and return a list of the modify data as a new array. The original array data is not modify.
     * @param args any list of array
     * @param fn callback function with each item from the lisr
     * @returns a new list based on what is done in the callback to the data from args
     */
    export const setList = <T extends IClassInterface>(args: T[], fn: (p: any)=>T) => {
        let res: any

        if (Array.isArray(args)) {
            res = []
            args.forEach(el => {
               res.push(fn(el))
            })
        }

        return res
    }
}