import React, { useEffect, useState } from "react"
import creditCardAnimate from 'assets/svg/add-incident-report.svg'

import ClouItemInfo from "components/Display/ClouItemInfo"
import Row from "components/Row"
import { styled, Box } from "@mui/material"
import { grey } from "@mui/material/colors"


const Wrapper = styled(Box)(({
    background: grey[100],
    height: "calc(100vh - 140px)"
}))

/**
 * TODO: Stud to display the next invoice date
 */
const nextInvoice = ()=>{

    return new Date().toUTCString() 
}


const ClouInvoiceBlankExperience: React.FC = () => {

    const [nextDate, setNextDate] = useState<any>("")

    useEffect(()=>{
        setNextDate(nextInvoice())
        
    },[])
    return (
        <Wrapper>
            <Row height={25} />
            <ClouItemInfo>
                <Box sx={{ color: grey[800], fontSize: "1.7em", width: "100%", display: "flex", justifyContent: "center" }}>
                    Invoice History
                </Box>
            </ClouItemInfo>
            <ClouItemInfo>
            <Box sx={{ color: grey[600], fontSize: ".9em", width: "100%", display: "flex", justifyContent: "center" }}>
                    No Invoice have been generated for this invoice.
                    An invoice is shedule to auto-generate {nextDate}.
                </Box>
            </ClouItemInfo>
            <Row height={15} />
            <Row sx={{ width: "60%",height: "550px", margin: "-70px auto -66px auto", background: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={creditCardAnimate} style={{ width: "500px", height: "auto" }} />
            </Row>
        </Wrapper>
    )
}

export default ClouInvoiceBlankExperience