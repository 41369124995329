import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ContextProvider } from './globals/ContextProvider';
import { BrowserRouter } from "react-router-dom";




import { Amplify } from '@aws-amplify/core'
import { Auth } from "aws-amplify";
import awsmobile from './aws-exports';
import InternalRouter from 'React-Router-Internal';
import LoadingActivity from 'Pages/Onboarding/Landing/LoadingActivity';


import 'i18n/i18n';
import handler from 'Straclou/handler/handler';


Amplify.configure(awsmobile)
Auth.configure(awsmobile);

// i18n.createInstance()
handler()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Suspense fallback={<LoadingActivity/>} >
  <React.StrictMode>
    <ContextProvider>
      <BrowserRouter>
        <InternalRouter>
          <App />
        </InternalRouter>
      </BrowserRouter>
    </ContextProvider>
  </React.StrictMode>
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
