import { Box, Stack, TextField, Button, styled, InputLabel, InputAdornment, IconButton, OutlinedInput } from "@mui/material"
import { grey } from "@mui/material/colors"
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import useAuth from "hooks/useAuth";
import { SignupCredentials } from "Straclou/handler";
import awsmobile from "aws-exports";


const Wrapper = styled(Box)(({
    height: "100%",
    paddingBottom: "30px"
}))

const InputLabelCust = styled(InputLabel)(({ theme }) => ({
    marginBottom: "4px",
    fontWeight: "bold"
}))

export default function SignUnForm(props: any) {

    const signup = useAuth((state)=>state.signup)

    const navigate = useNavigate()
    const { handleSubmit, reset, control, register } = useForm();

    const [showPassword, setShowPassword] = useState(false)


    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    const onSubmit = (data: any) => {
        console.log(data);
        // navigate("/verify_email")
        let sfCredentials: SignupCredentials = {
            clientId: awsmobile.aws_user_pools_web_client_id,
            username: data.email,
            password: data.password,
            name: data.name
            
        }
        signup(sfCredentials).then(res => {
            console.log(res)
            
            // navigate('/signin-process/signin', { state: { email: data.name } })


            // if the response is true then we send verification code
            // and navigate to the next view
        })
    }

    return (
        <Wrapper>
            <Box sx={{ width: "100%", color: grey[900], margin: "0 auto" }}>
                <Box >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Stack gap={2}>
                            <Box >
                                <InputLabelCust>Name</InputLabelCust>
                                <Controller
                                    name={"name"}
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            required
                                            {...register("name", { required: "Name is required" })}
                                            onChange={onChange}
                                            value={value}
                                            placeholder="Ex. John Doe"
                                            sx={{ width: "100%" }} />
                                    )}
                                />
                            </Box>

                            <Box>
                                <InputLabelCust>
                                    Email address
                                </InputLabelCust>
                                <Controller
                                    name={"email"}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField required
                                            type="email"
                                            onChange={onChange} value={value}
                                            placeholder="name@community-email.com"
                                            sx={{ width: "100%" }}
                                            helperText="Invalid Email"
                                        />
                                    )}
                                />
                            </Box>


                            <Box>
                                <InputLabelCust>
                                    Password
                                </InputLabelCust>
                                <Controller
                                    name={"password"}
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <OutlinedInput
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={onChange}
                                            value={value}
                                            placeholder={"Password"}
                                            sx={{ width: "100%" }}


                                            endAdornment={<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }

                                        />
                                    )}
                                />
                            </Box>

                            <Box>
                                <InputLabelCust>
                                    Password
                                </InputLabelCust>
                                <Controller
                                    name={"retype-password"}

                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <OutlinedInput
                                            type={showPassword ? 'text' : 'password'}
                                            onChange={onChange}
                                            value={value}
                                            placeholder={"Retype Password"}
                                            sx={{ width: "100%" }}
                                            endAdornment={<InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end">
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                            }

                                        />
                                    )}
                                />
                            </Box>
                            <Button variant="contained" type="submit" sx={{ height: "45px", fontSize: "14pt" }}>Sign up</Button>
                        </Stack>
                    </form>
                </Box>
            </Box>
        </Wrapper>
    )
}

