import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { IconButtonSmall } from "Features/Commentary/CustomStyles"
import AddHomeIcon from '@mui/icons-material/AddHome';
import { Tooltip } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { NewTableRow } from './FeesTable';



const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FeesTableCreateDialog(props: { type: any }) {
    const [open, setOpen] = React.useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Add fee">
                <IconButtonSmall onClick={handleClickOpen}>
                    <AddHomeIcon />
                </IconButtonSmall>
            </Tooltip>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
            >
                <DialogTitle>{"Create Fee"}</DialogTitle>
                <DialogContent >
                    <NewTableRow type={props.type} onClose={handleClose} />
                </DialogContent>
            </Dialog>
        </div>
    );
}
