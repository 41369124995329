import { Box, styled } from "@mui/material";
import ClouMainHeader from "components/headers/ClouMainHeader/ClouMainHeader";
import ClouPageWrapper, { ClouPageContainer, PageContent } from "components/PageWrapper";
import { Outlet } from "react-router-dom";

const Content = styled(Box)(({
   marginTop: "70px"
}))

export default function SubscriptionMain() {
    return (
        <ClouPageWrapper>
            <ClouMainHeader />
            <Content>
                <ClouPageContainer>
                    <Outlet />
                </ClouPageContainer>
            </Content>
        </ClouPageWrapper>
    )
}