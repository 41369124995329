import { v4 as uuidV4 } from "uuid"
import { IPerson } from "./IPerson";
import { IOwner } from './IOwner'
import { IFinancial } from "./IFinancial";
// import { IProperty } from "interfaces";
import { IClassInterface } from "./IClassInterface";
import { IWork } from "./IWork";
import { Address } from "./IShareInterface";





export class IPremises implements IClassInterface{

    private _id: string = uuidV4();
    
    private _documentId: string

    private _address: Address

    private _accountId: string

    private _propertyType: any

    private _unitNumber: string

    private _communityId: string

    private _createdAt: string;

    private _updatedAt: string;

    private _floorPlanName: string

    private _proprietor: string

    private _alias: string

    private _bedroomCount: number

    private _bathroomCount: number

    private _description: string

    private _fee: number

    private _status: string

    private _occupancy: string

    private _author: Author

    private _entitlement: number

    private _contact: Array<any>

    private _type: string

    private _phase: string

    private _block: string

    private _buildingName: string

    private _floorName: string

    private _work: IWork 


    constructor(args?: any) {

        
        this.superSetters(args)
        /**
         * We are going to handle all the data we need if value
         */
        
    }


    public update(args?: any){
        this.superSetters(args)
    }

    public clear(){
        this.superSetters(this.default())
    }

    private default(){
        return {
            id: this._id = uuidV4(),
            accouunt: this._accountId = ""
        }
    }

    public characteristic(){
        return {
            documentId: this._documentId,
            id: this._id,
            address: this.address,
            alias:this._alias,
            author: this._author,
            bathroomCount: this._bathroomCount,
            bedroomCount: this._bedroomCount,
            communityId: this._communityId,
            createdAt: this._createdAt,
            description: this._description,
            fee: this._fee,
            floorPlanName: this._floorPlanName,
            occupancy: this._occupancy,
            proprietor: this._proprietor,
            type: this._type,
            unitEntitlement: this._entitlement,
            unitNumber: this._unitNumber,
            updatedAt: this._updatedAt,
            lastviewed: new Date().toISOString(),
            phase: this._phase,
            block: this._block
        }
    }


    private superSetters(args?: any){
        // console.log("Property Data", args)
        if (args !== undefined) {
            // handle property id
            this._id = args?.id

            this._documentId = args?.docId

            this._communityId = args?.communityId

            this._accountId = args.accountId

            this._unitNumber = args?.unitNumber

            // Handle the property Address 
            this._address = args?.address

            this._proprietor = args?.proprietor

            //Handle the unit size of the property floor plan
            this._floorPlanName = args?.floorPlanName

            //Handle 
            this._createdAt = args?.createdAt

            this._updatedAt = args?.updatedAt

            this._occupancy = args?.occupancy

            this._author = args?.author

            this._bedroomCount = args?.bedroomCount

            this._bathroomCount = args?.bathroomCount

            this._entitlement = args?.unitEntitlement

            this._contact = args?.contact

            this._fee = args?.fee

            this._status = args?.status

            this._type = args?.ptype || args?.type

            this._phase = args?.phase

            this._block = args?.block

            this._buildingName = args?.buildingName
            
            this._floorName = args?.floorName

            this._work = new IWork(args?.workDetail)

        }

    }

    /**
     * Return a unque id 
     */
    get className() {
        return "IPremises"
    }

    get id() { return this._id }

    get documentId() { return this._documentId }

    get communityId() { return this._communityId }

    get accountId() { return this._accountId }

    get bedroomCount() { return this._bedroomCount }

    get bathroomCount() { return this._bathroomCount }

    get contact() { return this._contact }

    get status() { return this._status }


    get author() { return this._author }

    get proprietor() { return this._proprietor }

    get occupancy() { return this._occupancy }

    /**
     * 
     */
    get floorPlanName() { return this._floorPlanName }


    /**
     * Return the premises unit number eg. 0987
     */
    get unitNumber() { return this._unitNumber }

    /**
     * Return the premises unit entitlement eg. 0987
     */
    get entitlement() { return this._entitlement }

    /**
     * Return the address 
     */
    get address() { return this._address }

    /**
     * Return property type
     * @deprecated use category instead
     */
    get propertyType() { return this._propertyType }

    get type() { return this._type }

    /**
     * 
     */
    get createdAt() { return this._createdAt }

    /**
     * 
     */
    get updatedAt() { return this._updatedAt }

    get fee() { return this._fee}

    get phase() { return this._phase}

    get block() { return this._block}
    
    get buildingName() { return this._buildingName}
    
    get floorName() { return this._floorName}

    get workDetail(){return this._work}

    /**
     * 
     * @returns 
     */
    public toObject(): any {
        return {
            id: this._id,
            unitNumber: this._unitNumber,
            propertyType: this._propertyType,
            createdAt: this._createdAt,
            updatedAt: this._updatedAt,
            createdBy: this._author.name,
            uid: this._author.id
        }
    }
}


interface Author {
    id: string
    name: string
}