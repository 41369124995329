import { styled, Box, Badge } from "@mui/material"
import { theme } from "globals/theme"
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { v4 as uuidV4} from 'uuid'

import grey from "@mui/material/colors/grey";
import "./VerticalStyles.css"
import NavLinkExhibit from "../../components/Navigation/NavLinkExhibit";





const VerticalMenuStyle = styled(Box)(({
    fontFamily: `'Fira Sans', sans-serif`,
    position: "fixed",
    top: "70px",
    left: 0,
    width: 250,
    bottom: 0
}))


const MenuContainer = styled(Box)(({
    alignContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    position: "relative",
}))


const SideMenu = styled(Box)(({}))
const MenuList = styled('ul')(({
    paddingInlineStart: "16px !important"
}))


const MenuListItemTitle = styled('li')(({
    color: theme.palette.primary.dark,
    listStyle: "none",
    fontSize: ".85em",
    fontWeight: 700,
    padding: '12px 0px !important',
    pointerEvent: "none"
}))

const MenuListItem = styled('li')(({
    listStyle: "none",
    cursor: "pointer",
    color: grey[800],

    "&:hover": {
        fontWeight: "500 !important",
        "a": {
            color: theme.palette.primary.dark,
            "span": {

            }
        }
    },
    "a": {
        padding: "0.82rem 1.5rem",
        display: "flex",
        transition: "all .4s",
        position: 'relative',

        "svg": {
            marginRight: "10px",
            marginTop: "0px",
            width: "16px",
            height: "16px"
        },

        "span": {
            marginTop: "0px",
            display: "block",
            fontWeight: 500,
            fontSize: ".9em"
        }
    }
}))


const Menu = [
    {
        key: uuidV4(),
        icon: <DashboardIcon />,
        title: "Dashboard",
        status: "active",
        link: "/dashboard",
        subMenu: [
            {
                title: "Report"
            },
            {
                title: "Remiders"
            }
        ]
    },
    {
        key: uuidV4(),
        icon: <HomeWorkIcon />,
        title: "Community Profile",
        status: "",
        link: "/community_profile",
    },
    {
        key: uuidV4(),
        icon: <SubscriptionsIcon />,
        title: "Subscription",
        status: "",
        link: "/subscription",
    },
    {
        key: uuidV4(),
        icon: <AdminPanelSettingsIcon />,
        title: "User Management",
        status: "",
        link: "/user_management",
    },
    {
        key: uuidV4(),
        icon: <NotificationsIcon />,
        title: "Notifications",
        status: "",
        link: "/notifications",
        badge: <Badge color="secondary" badgeContent={17} max={10} />
    },
    {
        key: uuidV4(),
        icon: <HomeWorkIcon />,
        title: "Onboarding",
        status: "",
        link: "/client",
        badge: <Badge color="secondary" badgeContent={17} max={10} />
    },
    {
        key: uuidV4(),
        icon: <HomeWorkIcon />,
        title: "landing",
        status: "",
        link: "/landing",
        badge: <Badge color="secondary" badgeContent={17} max={10} />
    },
]

const VerticalMenu: React.FC = () => {
      
    return (
        <VerticalMenuStyle>
            <MenuContainer>

                <SideMenu>
                    <MenuList>
                        <MenuListItemTitle>Menu</MenuListItemTitle>

                        {
                            Menu.map(m => {
                                return (
                                    <MenuListItem key={m.key}>
                                        <NavLinkExhibit to={m.link}>
                                            {m.icon}
                                            <span>{m.title}</span>
                                        </NavLinkExhibit>
                                    </MenuListItem>
                                )
                            })
                        }
                    </MenuList>
                </SideMenu>

            </MenuContainer>
        </VerticalMenuStyle>
    )
}

export default VerticalMenu