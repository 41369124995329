import React, { useEffect, useState } from 'react'
import { format, setDate } from 'date-fns'
import { Box, SxProps } from '@mui/material'

interface IProps {
    time?: string
    sx?: SxProps,
    formatter?: string
}

const checkTime = (t?: string) => {
    if (t !== undefined && t?.length > 0) return new Date(t)
    if (t?.length === 0) return new Date()
    return new Date()
}
// This shows an active clock
const DateTimeExhibit: React.FC<IProps> = ({ time, sx, formatter }) => {

    const [myFormat, setMyFormat] = useState("PP")
    

    let dt = new Date();
    // let now = format(dt, myFormat)

    const [iDate, setIDate] = useState<string>("")
    const [iNow, setINow] = useState<Date>(dt)




    useEffect(() => {
        setIDate(format(iNow, myFormat))
    },[])

    useEffect(() => {
       
        if(iNow.toDateString().length > 0){
            setIDate(format(iNow, myFormat))
        }
    },[iNow, myFormat])


    useEffect(() => {
        // console.log(time)
       if(time !== undefined && time.length > 0) {
            setINow(new Date(time))
        }

    }, [time])


    

    useEffect(() => {
        if (formatter !== undefined) setMyFormat(formatter ?? "PP")        
    }, [formatter])
    return <Box sx={sx}>{iDate}</Box>
}

export default DateTimeExhibit