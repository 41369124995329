
import useAuth from "hooks/useAuth"
import { useEffect, useState } from "react"
import { CreateCommentaryInput } from "Straclou/API"
import { v4 } from "uuid"
import { CommentaryManager } from "./CommentaryManager"
import useCommentaryStore from "./CommentaryStore"




/**
 * 
 * @returns 
 */
export default function useCommentary() {


    const { updateNotes, updateAccountId } = useCommentaryStore()

    const { user } = useAuth()



    const prepareListOfNotes = (ref: any) => {
        // onSnapshot((ref), (snapshot: any) => {
        //     let arr: any[] = []
        //     snapshot.forEach((doc: any) => {
        //         arr.push({
        //             id: doc.id,
        //             ...doc.data()
        //         })
        //     });
        //     console.log("Notes list: ",arr)
        //     if (arr.length > 0) {
        //         setNotes([...arr])
        //     }
        // })
    }

    /**
     * Add a note to the database 
     */
    const addNote = async (entry: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                let note: CreateCommentaryInput = {
                    id: v4(),
                    author: user.name,
                    uid: user.uid,
                    timestamp: new Date().toISOString(),
                    ...entry
                }

                CommentaryManager.create(note).then(res => {
                    updateNotes(res)
                    resolve(res)

                })


            } catch (error) {
                console.log("Error: ", error)
                reject(null)
            }

        })
    }


    const updateNote = async (docId: string, item: any, accountId: string) => {
        // const noteRef = doc(firestore, `${communityId}-notes-${accountId}`, docId)
        // setDoc(noteRef, item, { merge: true })
    }

    /**
     * Request the notes for a given account id
     * @param accountId string
     */
    const getNotes = async (accountId: string) => {
        updateAccountId(accountId)
        if(accountId !== null){
            const list: any = await CommentaryManager.listCommentary({accountId})
            list.map((el:any)=>{
                updateNotes(el)
                return el
            })
        }
        //     if (accountId !== null) {
        //         fireRef = collection(firestore, `${communityId}-notes-${accountId}`);
        //         const q = query(fireRef, orderBy('timestamp', "desc"))
        //         prepareListOfNotes(q);            
        //     }
    }

    return { getNotes, addNote }
}