import * as React from 'react';


import { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { Box, Stack, Typography } from '@mui/material';
import ClouItemInfo from 'components/Display/ClouItemInfo';
import Row from 'components/Row';
import { theme } from 'globals/theme';

import { grey } from '@mui/material/colors';
import DateTimeExhibit from 'components/DateTimeExhibit';
import ClouMoneyExhibit from 'components/ClouMoneyExhibit';
// import { useCommunityStore } from '@Applications/Administrator/Pages/Settings/CommunityRegistry/common/CommunityStoreProvider';
import InvoicePaymentCard from './InvoicePaymentCard';
import InvoicePrimaryOwnerCard from './InvoicePrimaryOwnerCard';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import ClouButtonGroup from 'components/Button/ClouButtonGroup';
import CloseIcon from '@mui/icons-material/Close';
import AppButton from 'components/widget/AppButton';
import { useNavigate } from 'react-router';


const ITEM_HEIGHT = 48;


const options = [
    {
        key: 1,
        icn: "",
        label: 'View Invoice',
        onclick: (setOpen: any) => {
            // alert("Invoice was click.")
            setOpen(true)

        }
    }
    ,
    // 'Print Invoice',
    // 'Email Invoice',
    // 'View Payments',
]

interface props{
    info?: any
    onClose?: Function 
}

export default function TransactionInvoiceComponent({info, onClose}: props) {
    
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
    // const  community: any  = useCommunityStore()
    const  community: any  = {}

    const navigate = useNavigate()

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(!open);
        // setScroll(scrollType);
    };

    

    // Small menu

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const id = "665-788-151"



    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    React.useEffect(() => {
        console.log(info)
    }, [info])

    const handleClose = ()=>{
        onClose?.(false)
    }

    return (
        <div >
            <Box sx={{ height: "26px", marginBottom: "25px" }}>
                <Typography sx={{ fontSize: "1.2em", color: "InfoText", position: "absolute", left: '16px', top: '11px'}}>Invoice</Typography>
                {/* <Typography sx={{ fontSize: ".776em", color: "InactiveCaptionText" }}>{rows.length} records found</Typography> */}
                <Box sx={{ position: "absolute", right: '24px', top: '11px', background: "0", color: grey[600] }}>
                    <ClouButtonGroup>
                        {/* <Tooltip title="Print Invoice"> */}
                            <AppButton color="secondary" startIcon={<LocalPrintshopIcon />} >
                                Print
                            </AppButton>
                        {/* </Tooltip>
                        <Tooltip title="Forward To Customer"> */}
                            <AppButton color="secondary" startIcon={<ForwardToInboxIcon />}>
                                Email
                            </AppButton>
                        {/* </Tooltip>
                        <Tooltip title="Return to History"> */}
                            <AppButton color="secondary" startIcon={<CloseIcon />} onClick={()=>{handleClose()}}>
                                Close
                            </AppButton>
                        {/* </Tooltip> */}
                    </ClouButtonGroup>
                </Box>
            </Box>

            <DialogContent dividers={scroll === 'paper'} sx={{height: "calc(100vh - 96px)", "&::-webkit-scrollbar": {
                            width: "4px"
                        },
                        "&::-webkit-scrollbar-track": {
                            backgroundColor: "#ebebeb",
                            webkitBorderRadius: "0px",
                            borderRadius: "0px"
                        },
                        "&::-webkit-scrollbar-thumb": {
                            webkitBorderRadius: "0px",
                            borderRadius: "0px",
                            background: grey[400]
                        }}}>
                <Box sx={{ minWidth: "840px", height: "auto", position: "relative", background: grey[300], border: `2px solid ${grey[200]}`, fontSize: ".9em"  }}>
                    <Row sx={{ display: "flex", position: "absolute", height: "150px", top: "45px", margin: "0 0 15px 0", paddingBottom:"50px" }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", height: "100%", background: theme.palette.primary.dark, }}>
                            <Box sx={{ height: "120px", color: "#fff", width: "300px", padding: "0 16px" }}>
                                <Stack>
                                    <Row>
                                        <ClouItemInfo>{community?.name}</ClouItemInfo>
                                        <ClouItemInfo fontSize={".75em"} color={'Highlight'}>{community?.tagline}</ClouItemInfo>
                                    </Row>
                                    <Row height={8} />
                                    <Row sx={{lineHeight: "1.1em"}}>
                                        <ClouItemInfo>{community?.address?.streetNameNumber}</ClouItemInfo>
                                        <ClouItemInfo fontSize={".75em"}>{community?.address?.town}</ClouItemInfo>
                                        <ClouItemInfo fontSize={".75em"}>{community?.address?.city}</ClouItemInfo>
                                        <ClouItemInfo fontSize={".75em"} >{community?.address?.parish}</ClouItemInfo>
                                    </Row>
                                </Stack>
                            </Box>
                        </Box>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "3.7em", letterSpacing: "3px" }}>INVOICE</Box>


                    </Row>

                    <Box sx={{ display: "flex", height: "100%", background: "0", padding: '0 0 0 15px' }}>
                        {/* Left */}
                        <Box>
                            <InvoicePrimaryOwnerCard accountId={info.accountId} />

                            <Row height={50}></Row>
                            <Stack spacing={1}>
                                <ClouItemInfo fontSize={'.9em'}>
                                    <ClouItemInfo fontSize={'1.1em'} fontWeight={700}>Invoice #</ClouItemInfo> &nbsp; {info.trackingNumber}
                                </ClouItemInfo>

                                <ClouItemInfo fontSize={'.9em'}>
                                    <ClouItemInfo fontSize={'1.1em'} fontWeight={700}>Date &nbsp;</ClouItemInfo>  <DateTimeExhibit time={info.postDate} />
                                </ClouItemInfo>

                            </Stack>

                            <Row height={20}/>
                            <ClouItemInfo fontSize={'.9em'}>
                                {info.status}% Paid
                            </ClouItemInfo>
                        </Box>

                        {/* line */}
                        <Box sx={{ width: "5px", background: theme.palette.primary.dark, height: "100%", fontSize: ".9em" }}></Box>

                        {/* Right */}
                        <Box sx={{ width: "100%", background: "#fff", paddingTop: "230px" }}>
                            <Row height={30} sx={{
                                background: theme.palette.primary.dark, color: "#fff",
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "90%",
                                margin: '0 auto'
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-start" }} center >Description</Row>
                                <Row right sx={{ paddingRight: '15px' }}>Total</Row>
                            </Row>

                            {/* Items */}
                            <Row height={45} sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "90%",
                                margin: '0 auto',
                                borderBottom: `1px solid ${grey[200]}`
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-start" }} center >{info.description}</Row>
                                <Row right sx={{ paddingRight: '15px' }}><ClouMoneyExhibit value={info.amount * -1} /></Row>
                            </Row>

                            {/* Discount */}
                            <Row height={45} sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "90%",
                                margin: '0 auto',
                                borderBottom: `1px solid ${grey[200]}`
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-start" }} center >Discount</Row>
                                <Row right sx={{ paddingRight: '15px' }}><ClouMoneyExhibit value={0} /></Row>
                            </Row>


                            {/* Interest Applied */}
                            <Row height={45} sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "90%",
                                margin: '0 auto',
                                borderBottom: `1px solid ${grey[200]}`
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-start" }} center >Interest applied</Row>
                                <Row right sx={{ paddingRight: '15px' }}><ClouMoneyExhibit value={0} /></Row>
                            </Row>

                            {/* Subtotal */}
                            <Row height={45} sx={{
                                display: "flex",
                                flex: '2 1',
                                width: "90%",
                                margin: '0 auto',
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-end" }} center >Subtotal</Row>
                                <Row right sx={{ paddingRight: '15px' }}><ClouMoneyExhibit value={info.amount * -1} /></Row>
                            </Row>

                            {/* GCT */}
                            <Row height={45} sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "90%",
                                margin: '0 auto',
                                borderBottom: `1px solid ${grey[100]}`
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-end" }} center >GCT</Row>
                                <Row right sx={{ paddingRight: '15px' }}><ClouMoneyExhibit value={0} /></Row>
                            </Row>
                            {/* Grand Total */}
                            <Row height={45} sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                width: "90%",
                                margin: '5px auto',

                                background: grey[300]
                            }}>
                                <Row sx={{ paddingLeft: "15px", justifyContent: "flex-end" }} center >Total</Row>
                                <Row right sx={{ paddingRight: '15px' }}><ClouMoneyExhibit value={info.amount * -1} /></Row>
                            </Row>


                            <Row height={15} />
                            <InvoicePaymentCard accountId={info.accountId} />

                            <Row height={50}/>
                        </Box>


                    </Box>

                </Box>

            </DialogContent>
            {/* </Dialog> */}
        </div>
    );
}

function useState<T>(arg0: {}): [any] {
    throw new Error('Function not implemented.');
}

