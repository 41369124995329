import { alpha, Box, styled } from "@mui/material";
import ClouItemTitle from "components/ClouItemTitle";
import UserMenu from "Features/UserProfileService/Menu";
import MenuIconComponent from "Pages/HomePage/MenuIconComponent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const HeaderMap = new Map()
HeaderMap.set("#dashboard", "Dashboard")
HeaderMap.set("#subscription", "Subscription")
HeaderMap.set("#staff", "Staff")

const Wrapper = styled(Box, { name: 'admin-header-wrapper' })(({ theme }) => ({
    width: "calc(100% - 8px)",
    height: "55px",
    // background: theme.palette.grey[200],
    background: alpha(theme.palette.primary.dark, 0.1),
    // background: theme.palette.secondary.main,
    color: "#fff",
    padding: "0 0 0 8px",
    margin: "0 8px 0 0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"

}))

export default function AdminHeader() {
    const location = useLocation()
    const [headerName, setHeaderName] = useState<string | null>(null)

    useEffect(() => {
        console.log(location)
        let hash = location.hash
        setHeaderName(HeaderMap.get(hash))
    }, [location])
    return (
        <Wrapper>
            <ClouItemTitle sx={{ color: "#fff !important" }}>{headerName}</ClouItemTitle>

            
            <Box className="User Menu Holder" sx={{ width: "auto" }}>
                <MenuIconComponent />
            </Box>
        </Wrapper>
    )
}
