import { WidgetBuilder, WidgetBuilderOutlet } from "@knymbus/react-widget-builder";
import MessageDialogProvider from "./Context/MessageDialogContext";
import PaymentDialog from "./PaymentDialog";
import PaymentMonitor from "./PaymentMonitor";




export default function MainPaymentActivity(props: any) {

    const paymentWidgetBuilder = new WidgetBuilder({
        name: "Payment Module",
        type:"fragment",
        routes: [
            {
                index: true,
                path: "main_payment",
                window:   <PaymentMonitor />
            }
        ]
    })
    return (
        <MessageDialogProvider>
            {/* <PaymentDialog {...props}/> */}
            <WidgetBuilderOutlet builder={paymentWidgetBuilder} />
        </MessageDialogProvider>
    )
}