import { useEffect, useState } from "react"

export default function ClouAccountNumberExhibit(props: any) {
    const { value } = props
    const [formatV, setFormatV] = useState<string>("")

    useEffect(() => {
        if (value !== undefined && value !== null) {
            setFormatV(
                value.split("").map((a: string,i: number)=>(i==2 || i==5 ? a+"-" : a)).join("")
            )
        }
    }, [value])
    return <>{formatV}</>
}