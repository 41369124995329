import { useWidgetBuilderNavigation, useWidgetBuilderParams } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, FormHelperText, MenuItem, Select, styled, TextField } from "@mui/material";
import ClouAccountNumberExhibit from "components/ClouAccountNumberExhibit";
import Row from "components/Row";
import { Title } from "components/Title";
import { motion } from "framer-motion";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";




const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))


export default function (props: any) {
    const transition = { duration: 1.4, ease: [0.6, 0.01, 0.05, 0.9] }

    const { onClose, onNext, context } = props
    const [identity, setIdentity] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();

    const params = useWidgetBuilderParams()

    const {navigate} = useWidgetBuilderNavigation()

    const handleClose = () => {
        onClose?.()
        reset()
    }

    const onSubmit = (e: any) => {
        console.log("form data: ", e)
        setIsLoading(true)



        const createTransactionInput: any = {
            cid: params?.account?.communityId,
            accountNumber: params?.account?.accountNumber,
            accountName: params?.account?.accountName,
            amount: e.balance_carry_down,
            type: e.balance_carry_down_type,
            description: "",
            transactionDate: new Date().toISOString(),
            transactionGroupType: "BCD"
        }

        console.log(createTransactionInput)
        setTimeout(() => {
            setIsLoading(false)
            navigate({
                path: "complete",
                params
            })
            // onNext({
            //     next: ,
            //     data: context
            // })
        }, 3000);



    }

    const handleSkip = () => {
        onNext({
            next: "complete",
            data: params
        })
    }


   
    return (
        <motion.div
            exit={{ opacity: 0 }}>

            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.3, ...transition } }}
            >
                <Box sx={{ p: 5, overflow: 'hidden', marginBottom: '0px' }}>

                    <form onSubmit={handleSubmit(onSubmit)}>

                        <Box sx={{ mt: 1 }}>
                            <Title variant="5" sx={{fontWeight: "bold"}}>Financials</Title>
                            <p style={{fontSize: '10pt'}}>
                                Your account <b><ClouAccountNumberExhibit value={params?.account?.accountNumber} /></b> was successfully created.
                                Use the form below to initilize your account with a starting balance if your property already have one.
                                if your property does not have any balance to start with you can skip this step.
                            </p>
                            <Divider />
                        </Box>

                        <p>
                            The Balance carry down is the starting balance if any for the given property.
                        </p>

                        <Box sx={{ mb: 0 }}>

                            <InputLabel>Balance Carry Forward</InputLabel>
                            <Controller
                                name={"balance_carry_down"}
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("balance_carry_down")}
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        type={'number'}
                                        required
                                        placeholder="Enter outstanding amount"
                                        inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                        sx={{ width: "100%" }} />
                                )}
                            />
                        </Box>

                        <p>
                            Indicate wheather the amount is an outstanding balance or an advance payment.
                        </p>



                        {/* Select  */}
                        <Box sx={{ mb: 3 }}>

                            <InputLabel id="balance-carry-down-type-label">Balance Carry Forward type</InputLabel>
                            <Controller
                                name={"balance_carry_down_type"}
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value } }) => (
                                    <Select
                                        {...register("balance_carry_down_type")}
                                        labelId="balance-carry-down-type-label"
                                        id="balance-carry-down-type-helpher"
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        required
                                        sx={{ width: "100%" }}
                                    >
                                        <MenuItem value={"DR"}>Outstanding Amount</MenuItem>
                                        <MenuItem value={"CR"}>Advance Payment</MenuItem>
                                    </Select>

                                )}
                            />
                            <FormHelperText>Select amount type</FormHelperText>
                        </Box>



                        <Row right height={"40px"} sx={{ margin: "50px 0 0 0" }}>

                            <Box sx={{ position: "relative" }}>
                                <Button onClick={handleSkip}>Skip</Button>
                                <LoadingButton loading={isLoading} variant="contained" type="submit">Post</LoadingButton>
                            </Box>
                        </Row>

                    </form>
                </Box>
            </motion.div>
        </motion.div >
    )
}