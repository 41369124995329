import { Box, Button, styled } from "@mui/material"
import bgld from "../../../images/report_animated_img.svg"
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { theme } from "globals/theme";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { CommunityManager } from "Straclou/handler/CommunityManager";
import { useTranslation } from "react-i18next";
import LandingPageWrapper from "./LandingPageWrapper";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { UserHandler } from "Straclou/handler/UserHandlerCognito";



const Content = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: "30px 0"

}))

export default function CreateCommunityWorkSpace() {

    const navigate = useNavigate()
    const { t } = useTranslation();
    const {user, updateUser} = useAuth()

    const handleClick = () => {
        // Here we need to create the workpace and get its 
        // id so that we can save the other dataset as we move along
        
        CommunityManager.init(user.uid).then(res => {
            console.log("Response from server: ", res)
            UserHandler.updateUserAttribute({communities: res.workspaceId, isWorkspace: true})
            navigate(`/client/${res.workspaceId}`)

        }).then(error => {
            console.log("FN init: Something went wrong: ", error)
        })
    }

    useEffect(()=>{
        console.log("Create workspace component activated: ");
        
    },[])

    return (
        <LandingPageWrapper>
            <Content>
                <Box>
                    <Box sx={{
                        fontSize: '2.3em',
                        fontWeight: "600", margin: "30px 0 30px 0",
                        color: theme.palette.primary.main
                    }}>
                        {t('landing.create.title')}
                    </Box>
                    <Box sx={{ margin: "0 0 30px 0", lineHeight: "2em" }}>
                        {t('landing.create.description')}
                    </Box>

                    <Box sx={{}}>
                        <Button sx={{ fontSize: "1.2em" }} variant={'contained'} endIcon={<ArrowForwardOutlinedIcon />} onClick={() => { handleClick() }}>
                            {t('landing.create.button')}
                        </Button>
                    </Box>

                    <Box sx={{ margin: "20px 0 0 0", fontSize: ".8em", fontWeight: "500", color: grey[600], lineHeight: "1.3em" }}>
                        {t('landing.create.policy')}
                    </Box>
                </Box>
                <Box>
                    <img src={bgld} alt="landing Page Image" />
                </Box>
            </Content>
        </LandingPageWrapper>
    )
}


