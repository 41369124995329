export class IError{
    private _errorCls: any;
    private _typeOfError: string

    constructor(args: any){
        this._errorCls = args
        this._typeOfError =args.typeOf
    }

    get error(){
        return this._errorCls.getError()
    }

    get typeOfError(){
        return this._typeOfError
    }
}