import { Box, styled } from "@mui/material";
import StraclouLogo from "components/StraclouLogo";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { IWorkspace } from "Straclou/commons/IWorkspace";


const Wrapper = styled(Box, { name: 'admin-side-menu-wrapper' })(({ theme }) => ({
    width: "100%",
    height: "90px",
    // background: theme.palette.secondary.main,
    // borderBottom: `1px solid ${theme.palette.primary.light}`,
    // color: "white",
    padding: "0 0 0 8px",
    margin: "0 8px 16px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column"

}))


const SettingsStyle = styled(Box)(({ theme }) => ({
    fontSize: "17pt",
    mt: 3,
    color: theme.palette.grey[900],
    fontWeight: 500
}))

const NameStyle = styled(Box)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: "11pt"
}))

export default function AdminSideMenuHeader() {

    const { workspace, setWorkspace } = useWorkspaceStore()
    const { queryWorkspaceById } = useWorkspace()
    const params = useParams()

    useEffect(() => {
        if (workspace.workspaceId === undefined) {
            if (params !== undefined) {
                let id = params.workspaceId || ""
                if (id.length > 0) {
                    queryWorkspaceById(id, []).then((response: any) => {
                        if (Object.keys(response).length !== 0) {
                            setWorkspace(new IWorkspace(response))
                        } else {
                            // alert(`invaild key ${id}`)
                        }

                    })
                } else {
                    alert("No account is detected")
                }
            }
        }
    }, [params, workspace])

    return (
        <Wrapper>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: "column" }}>
                <StraclouLogo variant="normal" />
                <SettingsStyle>Dashboard</SettingsStyle>
                {/* <NameStyle>{workspace.name}</NameStyle> */}
            </Box>
        </Wrapper>
    )
}