import * as React from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Menu from '@mui/material/Menu';
import { Box, Button, Chip, IconButton, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect } from 'react';
import useAccount from 'hooks/useAccount';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

interface Option {
    value: string
    fieldName: string
}


const OPTIONS = [
    {
        value: "None",
        fieldName: "none"
    },
    {
        value: "Unit",
        fieldName: "unitNumber"
    },
    {
        value: "Account",
        fieldName: "accountNumber"
    },
    {
        value: "Proprietor",
        fieldName: "accountName"
    },
    {
        value: "Fees",
        fieldName: "fee"
    },
    {
        value: "Status",
        fieldName: "status"
    }
]

export default function AccountSortFilter() {

    // Store
    const { sortBy } = useAccount()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = React.useState(OPTIONS[0]);
    const open = Boolean(anchorEl);

    const [isChip, setIsChip] = React.useState<boolean>(false)

    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (
        event: React.MouseEvent<HTMLElement>,
        option: Option,
    ) => {

        if (option.value === "None") {
            setIsChip(false)
            setSelectedIndex(option);
            setAnchorEl(null);
            sortBy(option.fieldName)
        } else {

            setIsChip(true)
            setSelectedIndex(option);
            setAnchorEl(null);
            sortBy(option.fieldName)
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChipClick = () => { }

    const handleChipClose = () => {
        setIsChip(false)
        setSelectedIndex(OPTIONS[0])
        sortBy(OPTIONS[0].fieldName)
    }


    useEffect(() => {

    }, [selectedIndex])
    return (
        <Box sx={{ mr: 1, background: "0", width: "auto", display: "flex", alignItems: "center" }}>
            <Box sx={{ ml: 1 }}>
                <Tooltip title="Sort by field">
                    <IconButton
                        aria-label="Sort by field"
                        component="label"
                        onClick={handleClickListItem}
                    >
                        <ArrowDropDownIcon sx={{ width: "20px", height: "20px" }} />
                    </IconButton>
                </Tooltip>
            </Box>
            {
                isChip ?
                    <Paper elevation={0} sx={{ transition: "500ms", width: "auto", padding: "0 8px", position: "absolute", zIndex: 99, left: "45px", fontSize: "10pt" }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly" }}>
                            {/* <Chip
                                label={selectedIndex.value}
                                onClick={handleChipClick}
                                onDelete={handleChipClose}
                            /> */}
                            <Box sx={{ mr: 1 }}>
                                {selectedIndex.value}
                            </Box>
                            <Box sx={{ mr: 1, ml: 1 }}>
                                <Divider orientation={"vertical"} sx={{ height: "20px" }} />
                            </Box>
                            <Box>
                                <Tooltip title="Sort by ascending order">
                                    <IconButton
                                        aria-label="Sort by ascending order"
                                        component="label"
                                        size='small'
                                    >
                                        <ArrowUpwardIcon sx={{ width: "20px", height: "20px" }} />
                                        
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box>
                                <Tooltip title="Sort by descending order">
                                    <IconButton
                                        aria-label="Sort by descending order"
                                        component="label"
                                        size='small'
                                    >
                                        <ArrowDownwardIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Box>
                                <Tooltip title="Clear sort">
                                    <IconButton
                                        aria-label="Clear sort"
                                        component="label"
                                        size='small'
                                        onClick={handleChipClose}

                                    >
                                        <HighlightOffIcon sx={{ width: "20px", height: "20px" }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Paper>
                    : null
            }
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'lock-button',
                    role: 'listbox',
                }}
            >
                <Paper sx={{ width: 180 }} elevation={0}>
                    <MenuList dense>
                        {
                            OPTIONS.map((option, index) => (
                                <MenuItem
                                    key={option.value}
                                    selected={option.value === selectedIndex.value}
                                    onClick={(event) => handleMenuItemClick(event, option)}
                                >
                                    <ListItemIcon>
                                        {
                                            option.value === selectedIndex.value ? <Check color={"primary"} /> : null
                                        }

                                    </ListItemIcon>
                                    <ListItemText>{option.value}</ListItemText>
                                </MenuItem>
                            ))
                        }
                    </MenuList>
                </Paper>
            </Menu>

        </Box>

    );
}