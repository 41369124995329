export class ICollection<T extends {key?: string}> implements Iterable<T>{
    private _list: T[] = []



    constructor(list: T[]) {
        this._list = list
    }

    //Collection Object properties

    /**
     * Tell if the collection is empty or not
     */
    get empty() {
        return this._list.length === 0 ? true : false
    }

    /**
     * Clear the collection data array to an empty list
     */
    clear(){
        this._list = []
        return this
    }

    /** 
     * Update an item in the collection if exist else add item to collection
    */
    update(item: T){
        if(item !== null){
            // We first look for the item in our current list 
            let index = this._list.findIndex((i: any) => (i.key === item?.key ))

            // if item then we update that index else we add the item to the list
            if(index === -1){
                this._list.push(item)
            }else{
                this._list[index] = item
            }
        }
    }

    orderBy(fieldName: string, callbackFn?: Function) {
        this._list.sort((a: any, b: any) => {
            // console.log("field: ", fieldName," value: ",a[fieldName])

            if(callbackFn !== undefined){
                return callbackFn(a[fieldName], b[fieldName])
            }
            return a[fieldName] - b[fieldName]
        })
        return this
    }

    // make the generic iterator for this object
    // [Symbol.iterator](): Iterator<T, any, undefined> {
    //     throw new Error("Method not implemented.")
    // }
    [Symbol.iterator](): Iterator<T, any, undefined> {
        let counter = 0
        return {
            next: () => {
                return {
                    done: false,
                    value: this._list[counter]
                }
            }
        }
    }

    public map<U>(callbackFN: (value: T, index?: number, array?: T[]) => U, thisArgs?: any): U[] {
        return this._list.map((v, i, a) => {
            return callbackFN(v, i, a)
        })
    }

    public static assign<T>(arr: Array<any>, fn: (a: any) => T): Array<T> {
        let list: T[] = []
        arr.forEach(obj => {
            list.push(fn(obj))
        })

        return list
    }

    public sumIf(args: {criteria: string, value: string | number, sumField: string}, callback: Function){
        let sum = 0
        this._list.forEach((row: any)=>{
            if(row[args.criteria] === args.value){
                sum = callback(sum, row[args.sumField])
            }
        })
        return sum
    }
}