import { LoadingButton } from "@mui/lab"
import { Button, Snackbar, Alert, Box } from "@mui/material"

export interface IMessageControl {
    open: boolean;
    message: string;
    type: "info" | "success" | "error" | "warning"
}

interface IProps {
    onCancel: Function,
    isLoading: boolean,
    message?: IMessageControl
    setMessage?: any
}
export const ButtonController: React.FC<IProps> = ({ onCancel, isLoading, message, setMessage }) => {

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setMessage({
            ...message,
            open: false
        });
    };

    return (
        <>
            <Box sx={{ position: "absolute", bottom: "32px", right: "32px" }}>
                <Button onClick={() => { onCancel?.() }}>Close</Button>
                <LoadingButton autoFocus variant="contained" type="submit" loading={isLoading}>Save</LoadingButton>
            </Box>

            <Snackbar open={message?.open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: "top", horizontal: "right" }} >
                <Alert onClose={handleClose} severity={message?.type || "info"} sx={{ width: '100%' }}>
                    {message?.message}
                </Alert>
            </Snackbar>
        </>
    )
}   