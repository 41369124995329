import { useWidgetBuilderNavigation, useWidgetBuilderParams, WidgetBuilderContext } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, InputLabel, styled, TextField } from "@mui/material"
import Row from "components/Row";
import { IAccountInput } from "Features/AccountService/classes/IAccountInput";
import { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form"

import useSummaryStore from "../SummaryStore";
import EditOutlineFrame from "./EditOutlineFrame";



export default function EditOwnerFragment(props: any) {

    const { navigateBack } = useWidgetBuilderNavigation()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();

    const params: IAccountInput = useWidgetBuilderParams()

    

    const {updateList} = useSummaryStore()

    const onSubmit = (e: any) => {
        console.log(e)

        params.value = e.owner_name
        params.text = e.owner_name

        updateList(params)
        navigateBack()

    }

    useEffect(()=>{
        // setFragmentHeader("Edit owner's name")
    },[])

    return (
        <EditOutlineFrame>
            <form onSubmit={handleSubmit(onSubmit)} style={{width: "80%"}}>
                <Box sx={{margin: "0 auto"}}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%"
                    }}>
                        Owner's Name
                    </Box>

                    <Box sx={{ mb: 3, mt: 3, width: "100%" }}>

                        {/* <InputLabel>Account Name</InputLabel> */}
                        <Controller
                            name={"owner_name"}
                            control={control}
                            defaultValue={params.text || ""}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register("owner_name")}
                                    autoFocus
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    required
                                    placeholder="The account name is the name of the main owner of the property"
                                    inputProps={{ style: { textAlign: "center", fontSize: "12pt" } }}
                                    sx={{ width: "100%" }} />
                            )}
                        />
                        {/* <Box>Name cannot be empty</Box> */}
                    </Box>
                    <Row right sx={{ position: "absolute", bottom: "32px", right: "52px" }}>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            sx={{ width: "auto" }}
                        >Done</LoadingButton>
                    </Row>
                </Box>
            </form>
        </EditOutlineFrame>
    )
}