import { styled, Box,  } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"
import SignInPasswordForm from "./SignInPasswordForm"
import Enfold from "./Enfold"
import { NavigationLink } from "Pages/Onboarding/Widgets/NavigationLink"
import MessageDialogProvider from "Features/PaymentServices/Context/MessageDialogContext"


const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(({
    width: "100vw",
    height: "100vh",
    // ...CenterItem
}))

const Content = styled(Box)(({
    minWidth: "60%",
    ...CenterItem
}))

const Logo = styled(Box)(({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px"
}))

const Instruction = styled(Box)(({
    ...CenterItem,
    fontSize: "2.7em",
    fontWeight: 900
}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))


const SignupLink = styled(Box)<{ fontSize?: string }>(({ theme, fontSize }) => ({
    fontSize: fontSize || "9pt",
    margin: "0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
        textDecoration: "underline",
    }
}))

interface StateParams {
    email: string
    emailValidated: boolean
}

export default function SignInPasswordActivity() {

    const navigate = useNavigate()
    const location = useLocation()
    const State: StateParams = location.state as StateParams


    return (
        <MessageDialogProvider>
            <Enfold title="Sign in to Straclou" subtitle={
                State?.email ? <Box sx={{ textAlign: "center" }}>Email verified, you can now sign in with
                    <span style={{ fontWeight: "bold", margin: "0 4px" }}>{State?.email}</span></Box>
                    :
                    <>You enter this page without the proper credentials. use the link below to sign in.</>
            }>

                <SignInPasswordForm enfoldItem="form" email={State?.email} />
                <Box sx={{ fontSize: "10pt", marginRight: "4px" }}>Forgot your password?</Box> <NavigationLink onClick={() => { navigate('/signin-process/forget_Password') }} fontSize={"10pt"}>Get help with fixing that</NavigationLink>


            </Enfold>
        </MessageDialogProvider>
    )
}

// signin_password