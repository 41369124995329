import { Box, IconButton, styled, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import EditIcon from '@mui/icons-material/Edit';
import { IAccountInput } from "../classes/IAccountInput";

/**
 * Styles components
 */
const Wrapper = styled(Box)({
    borderBottom: `1px solid ${grey[200]}`,
    width: "100%",
    padding: "8px 0"
})

const Title = styled(Box)({
    fontSize: "10pt"
})

const ButtonContainer = styled(Box, { name: "ButtonContainer-Wrapper" })({
    position: "absolute",
    right: 0,
    top: 0
})

const TextDisplay = styled(Box)({
    position: "relative"
})

interface IProps {
    item?: IAccountInput
    onChange?: Function
    onEdit?: Function
}
export default function SummaryItem(props: IProps) {

    const { item, onEdit } = props

    const handleEdit = ()=>{

        onEdit?.({
            path: item?.path,
            params: item
        })
    }

    return (
        <Wrapper>
            <Title>
                {item?.title}
            </Title>
            <TextDisplay>
                <Box>
                    {item?.text}
                </Box>
                <ButtonContainer>
                    <Tooltip title="Edit">
                        <IconButton aria-label="edit" size="small" onClick={()=>{handleEdit()}}>
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                    
                </ButtonContainer>
            </TextDisplay>
        </Wrapper>
    )
}