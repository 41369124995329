import * as React from 'react';
import useWorkspaceStore from 'hooks/useWorkspaceStore';
import { CommunityPreferenceDialogStructure } from '../SettingsData';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import { IconButtonSmall } from 'Features/Commentary/CustomStyles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Button, Divider, FormControlLabel, FormGroup, Stack } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import useWorkspace from 'hooks/useWorkspace';
import { v4 } from 'uuid';
import { AnySrvRecord } from 'dns';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StructureOutletCategoryDropDown() {
    const [open, setOpen] = React.useState(false);
    const [selection, setSelection] = React.useState<any>({})

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelection({})
    };


    const { workspace, updateWorkspace } = useWorkspaceStore();
    const { updateWorkspaceById } = useWorkspace();

    const CATEGORY: Array<string> = [
        CommunityPreferenceDialogStructure.apartment,
        CommunityPreferenceDialogStructure.singleFamilyHome,
        CommunityPreferenceDialogStructure.townhouses,
        CommunityPreferenceDialogStructure.condominiums,
        CommunityPreferenceDialogStructure.villas,
    ]


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log("Checked: ", event.target.checked)
        console.log("Target: ", event.target.name)
        let state = event.target.checked
        let key = event.target.name

        setSelection({ ...selection, [key]: state })

    }


    const onsubmit = (e: any) => {
        e.preventDefault()

        let ma: any = []
        Object.keys(selection).forEach(key => {
            if (selection[key]) {
                ma.push(key)
            }
        })

        console.log("new array: >>>>>", ma)
        if (ma.length === 0) {
            //TODO
            // display some message about the empty Selection
            alert("Empty Selection")
        }

        if (ma.length > 0) {
           
            //  We save data now and close dialog
            let totalStructure: string[] = [...workspace.structure, ...ma]
            console.log("data to submit ", totalStructure)
            updateWorkspaceById(workspace.workspaceId, { CommunityStructure: totalStructure }).then((res: any) => {
                console.log("response from server: ", res)
                workspace.update(res.Attributes)
                updateWorkspace(workspace)
                handleClose()
                
            })
        }
    }
    return (
        <Box >
            <IconButtonSmall onClick={handleClickOpen}>
                <DomainAddIcon />
            </IconButtonSmall>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"

            >
                <form onSubmit={onsubmit}>
                    <DialogTitle sx={{ width: "350px" }}>{"Category selection"}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <FormGroup >

                            {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
                            {
                                CATEGORY.map(el => {
                                    return <FormControlLabel key={v4()} control={<Checkbox
                                        name={el}
                                        checked={selection[el]}
                                        onChange={handleChange}
                                        {...(workspace.structure.includes(el) ? { defaultChecked: true, disabled: true } : {})} />}
                                        label={el}
                                    />
                                })
                            }
                        </FormGroup>
                    </DialogContent>
                    <DialogActions sx={{ mb: 2, mr: 2 }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button variant="contained" type="submit">Select</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}





