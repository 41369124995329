import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Radio, RadioGroup, Select, Stack, styled, TextField } from "@mui/material";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import ClouSquareFootageExhibit from "components/ClouSquareFootageExhibit";
import Row from "components/Row";
import { Title } from "components/Title";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useState, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

import { IAccountInput } from "./classes/IAccountInput";
import { AccountServiceContext } from "./Context/AccountServiceContext";
import { UIContent, UIFormWrapper, UIWrapper } from "./Helpers/UIWrapper";




const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))

export default function (props: any) {

    const { onClose } = props
    const [identity, setIdentity] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { handleNextView } = useContext(AccountServiceContext)

    const { updateStructureList, structureList } = useWorkspaceStore()

    const { queryWorkspaceById } = useWorkspace()

    const params = useParams()
    const { navigate } = useWidgetBuilderNavigation()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();



    useEffect(() => {

        console.log("Params: ", params)

        if (params !== undefined) {

            let wid = params?.workspaceId || ""
            queryWorkspaceById(wid, ["CommunityStructure",]).then((res: any) => {
                console.log("Identity: ", res)
                res["CommunityStructure"].forEach((s: string) => {
                    updateStructureList(s)
                })
            })
        }

    }, [params])


    const handleClose = () => {
        navigate({
            path: "welcome"
        })
        reset()
    }

    const onSubmit = (e: any) => {
        setIsLoading(true)
        console.log("form data: ", e)
        console.log(params)
        const createNewAccountInput: any = {
            meta: {
                cid: params.workspaceId
            },
            data: [
                new IAccountInput({
                    key: v4(),
                    value: e.owner_name,
                    text: e.owner_name,
                    fieldName: "owner_name",
                    title: "Owner Name",
                    path: "owner",
                    cid: params.workspaceId || ""

                }),
                new IAccountInput({
                    key: v4(),
                    value: e.property_type,
                    text: e.property_type,
                    fieldName: "property_type",
                    title: "Property Type",
                    path: "property_type",
                    cid: params.workspaceId || ""
                })
            ]
        }

        console.log("Input: ", createNewAccountInput)

        // TODO remove stud with real data from server.


        setIsLoading(false)
        navigate({
            path: "property",
            params: createNewAccountInput
        })

    }

    return (
        <UIWrapper>
            <UIContent>


                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>

                <Title variant="1" sx={{ fontWeight: 400 }}>Account name</Title>
                    <UIFormWrapper sx={{ mb: 3, mt: 0 }}>

                        {/* <InputLabel>Account Name</InputLabel> */}
                        <Controller
                            name={"owner_name"}
                            control={control}
                            defaultValue={identity?.CommunityAddress?.street || ""}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register("owner_name")}
                                    autoFocus
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    required
                                    placeholder="John Downer"
                                    focused={true}
                                    inputProps={{ style: { textAlign: "center", fontSize: "12pt" } }}
                                    sx={{ width: "100%" }} />
                            )}
                        />
                    </UIFormWrapper>

                    {/* <UIFormWrapper sx={{ mt: 5 }}>

                        <InputLabel>Property unique lot number</InputLabel>
                        <Controller
                            name={"lot_number"}
                            control={control}
                            defaultValue={identity?.CommunityAddress?.street || ""}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register("lot_number")}
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    required
                                    placeholder="Enter property number"
                                    inputProps={{ style: { textAlign: "center", fontSize: "12pt" } }}
                                    sx={{ width: "100%" }} />
                            )}
                        />
                    </UIFormWrapper> */}

                    <Row center height={"40px"} sx={{ margin: "50px 0 0 0", display: "flex" }}>

                        <Box sx={{ position: "fixed", right: '32px', bottom: '32px' }}>
                        <Stack direction={"row"} gap={2}>
                            <Button variant="outlined" onClick={handleClose}>Back to main</Button>
                            <LoadingButton
                                loading={isLoading}
                                variant="contained"
                                type="submit"
                                sx={{ width: "120px" }}
                            >Next</LoadingButton>
                            </Stack>
                        </Box>
                    </Row>

                </form>
            </UIContent>
        </UIWrapper>
    )
}


