import React from "react"
import { Box } from "@mui/material";
import { motion } from "framer-motion";
import TransactionCardViewDetail from "./TransactionCardViewDetail";


// const transition = { duration: 0.6, ease: [0.43, 0.13, 0.23, 0.96] }
const transition = { duration: 1.4, ease: [0.6, 0.01, 0.05, 0.9] }

interface StateType {
    name: string
    address: any
    unitNumber: string


}

const TransactionHistoryV2: React.FC = () => {
    return (
        <motion.div exit={{ opacity: 0 }}>
            <Box className="transaction">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1, transition: { delay: 1, ...transition } }}
                >
                    <TransactionCardViewDetail />
                </motion.div>
            </Box>
        </motion.div>
    )
}

export default TransactionHistoryV2