import { Box, styled } from "@mui/material"
import { PanelContext, usePanel } from "./PanelContext"


const PanelWrapper = styled(Box)({

})
export function Panel(props: any) {
    return usePanel(props);
  }
        
    
