import { Box, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Button, LinearProgress, Tooltip } from "@mui/material"
import HouseIcon from '@mui/icons-material/House';
import { grey } from "@mui/material/colors";


// import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
// import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
// import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

import Row from "../../../components/Row";
import NameExhibit from "components/NameExhibit";
// import ProprietorContact from "./ProprietorContact";
// import AccountDetailAddress from "./AccountDetailAddress";
import ClouStatusConverterExhibit from "components/ClouStatusConverterExhibit";
import ClouSquareFootageExhibit from "components/ClouSquareFootageExhibit";
// import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import ClouOutstandingInvoiceExhibit from "components/ClouOutstandingInvoiceExhibit";
import React from "react";

import { IAccount, ICollection } from "Straclou";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import ProprietorContact from "./ProprietorContact";
import ClouAccountNumberExhibit from "components/ClouAccountNumberExhibit";
import ClouDateTimeExhibit from "components/ClouDateTimeExhibit";


// import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const UnitNumber = styled("div")({
    gridArea: 'unitNumber',
    fontSize: ".98em",
    fontWeight: 500,
    marginTop: "8px"
})

const CivicAddress = styled("div")({
    gridArea: 'civicAddress',
    fontSize: "1em",
    color: grey[500],
})

const CardButton = styled(Button)({
    fontSize: ".9em",
    height: "25px",
    width: "auto",
    borderRadius: "12px",
})

interface Column {
    id: 'timestamp' | 'unit' | "account_number" | 'proprietor' | 'address' | 'floor_plan' | 'entitlement' | 'status' | 'maintenance' | 'invoice' | 'action' | 'menu';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    // { id: 'timestamp', label: 'Timestamp' },
    { id: 'unit', label: 'Unit' },
    { id: 'account_number', label: 'Account' },
    { id: 'proprietor', label: 'Proprietor' },
    // { id: 'address', label: 'Address' },
    // {
    //     id: 'floor_plan',
    //     label: 'Floor Plan',
    //     align: 'right',
    //     format: (value: number) => value.toLocaleString('en-US'),
    // },
    {
        id: 'entitlement',
        label: 'Fees',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'status',
        label: 'Status',
        align: "right",
        format: (value: number) => value.toFixed(2),
    },
    // {
    //     id: 'maintenance',
    //     label: 'Maintenance',
    //     align: 'right',
    //     format: (value: number) => value.toFixed(2),
    // },
    // {
    //     id: 'invoice',
    //     label: 'Balance',
    //     align: 'right',
    //     format: (value: number) => value.toFixed(2),
    // },
    // {
    //     id: 'action',
    //     label: '',
    //     align: 'right',
    //     format: (value: number) => value.toFixed(2),
    // },
    {
        id: 'menu',
        label: '',
        format: (value: number) => value.toFixed(2),
    },
];


const TableRowStyled = styled(TableRow)({
    cursor: "pointer"
})
interface IProps {
    data?: ICollection<IAccount>
    loading?: boolean
    onNavigate: Function
}

const DirectoryRowItem: React.FC<IProps> = ({ data, loading, onNavigate }) => {


    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const goto = (row: IAccount) => {
        onNavigate({
            path: "detail",
            params: row.toObject()
        })

        // PropertyHandlerLocalStorage.setRecentActivies(row).then(res => {
        // navigation(`/rms/${params.workspaceId}/${AddressDirectory.rms.crmDetail}/${row?.id}`, { state: { ...row.toObject() } })
        // })
    }
    return (
        <Box sx={{ width: "100%", overflow: "hidden" }}>

            <TableContainer sx={{
                maxHeight: "calc(100vh - 140px)", "&::-webkit-scrollbar": {
                    width: "4px"
                },
                "&::-webkit-scrollbar-track": {
                    backgroundColor: "#ebebeb",
                    webkitBorderRadius: "0px",
                    borderRadius: "0px"
                },
                "&::-webkit-scrollbar-thumb": {
                    webkitBorderRadius: "0px",
                    borderRadius: "0px",
                    background: grey[400]
                }
            }}>
                <Table stickyHeader aria-label="sticky table" sx={{ position: "relative", minHeight: "auto" }}>
                    <TableHead sx={{ height: "20px" }}>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data?.map((row: IAccount) => {
                            return (
                                <TableRowStyled hover role="checkbox" tabIndex={-1} key={row.accountId} onClick={() => goto(row)}>

                                    {/* Timestamp */}
                                    {/* <TableCell >
                                        <Row sx={{ display: "flex" }}>
                                           <ClouDateTimeExhibit time={row?.createdAt}/>
                                        </Row>
                                    </TableCell> */}

                                    {/* Unit Number */}
                                    <TableCell >
                                        <Row sx={{ display: "flex" }}>

                                            <Tooltip title={<ClouStatusConverterExhibit value={row?.unitType} />}>
                                                <HouseIcon htmlColor={grey[500]} sx={{ width: "24px", height: "24px", marginRight: "5px" }} />
                                            </Tooltip>
                                            <UnitNumber sx={{ marginTop: "4px" }}>{row?.unitNumber ?? ""}</UnitNumber>
                                        </Row>
                                    </TableCell>


                                    {/* Account Number */}
                                    <TableCell >
                                        <Row sx={{ display: "flex" }}>
                                            <ClouAccountNumberExhibit sx={{ marginTop: "4px" }} value={row?.accountNumber ?? ""} />
                                        </Row>
                                    </TableCell>





                                    {/* Proprietor */}
                                    <TableCell >
                                        <Box>
                                            {/* <Row sx={{ fontSize: ".95em", fontWeight: 100, marginBottom: '-3px', minWidth: "200px" }}> */}
                                            <NameExhibit name={row?.accountName} avatarSize={30} avatar={false} sx={{ fontSize: "1em", marginBottom: '-3px', minWidth: "200px" }} />
                                            {/* <ProprietorContact contact={{phone: "987-8262-272", email: "example@email.com"}} fontSize={".87em"} /> */}
                                        </Box>
                                    </TableCell>

                                    {/* Address */}
                                    {/* <TableCell align="left">
                                            <CivicAddress>
                                                <AccountDetailAddress address={row?.address} />
                                            </CivicAddress>
                                        </TableCell> */}

                                    {/* Floor Plan */}
                                    {/* <TableCell align="left">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", background: "0" }}>
                                                        <Stack spacing={0}>
                                                            <Box sx={{ display: "flex", }}>
                                                                <Box sx={{ transform: "translateY(1px)", paddingRight: '2px' }}>
                                                                    <BedOutlinedIcon sx={{ width: "18px", height: "18px", color: grey[700] }} />
                                                                </Box>
                                                                <ClouStatusConverterExhibit value={`${row?.bedroomCount}_bed`} fontSize={".92em"} />
                                                            </Box>
                                                            <Box sx={{ display: "flex" }}>
                                                                <Box sx={{ transform: "translateY(1px)", paddingRight: '2px' }}>
                                                                    <BathtubOutlinedIcon sx={{ width: "18px", height: "18px", color: grey[700] }} />
                                                                </Box>
                                                                <ClouStatusConverterExhibit value={`${row?.bathroomCount}_bath`} fontSize={".92em"} />
                                                            </Box>
                                                        </Stack>
                                                    </Box>
                                                </TableCell> */}

                                    {/* Entitlement */}
                                    <TableCell >
                                        <Box>
                                            {/* <Row sx={{ fontSize: ".95em", fontWeight: 100, marginBottom: '-3px', minWidth: "200px" }}> */}
                                            <ClouMoneyExhibit value={row?.fee} sx={{ fontSize: "1em" }} />

                                            {/* <ProprietorContact contact={row?.contact} fontSize={".87em"} /> */}
                                        </Box>
                                    </TableCell>
                                    {/* <TableCell >
                                        <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                            <Stack direction={"column-reverse"}>
                                                <Box sx={{ display: "flex", fontSize: ".97em", color: grey[600] }}>
                                                    <Box sx={{ transform: "translateY(1px)", paddingRight: '2px' }}>
                                                        <SquareFootOutlinedIcon sx={{ width: "18px", height: "18px", color: grey[700] }} />
                                                    </Box>
                                                    <ClouSquareFootageExhibit footage={row?.unitSize ?? 0} />
                                                </Box>

                                                <Box width={20} />

                                                <Box sx={{ display: "flex" }}>
                                                    <Box sx={{ transform: "translateY(1px)", paddingRight: '2px' }}>
                                                        <HomeOutlinedIcon sx={{ width: "18px", height: "18px", color: grey[700] }} />
                                                    </Box>
                                                    <ClouStatusConverterExhibit value={row?.unitType} fontSize={"1em"} />

                                                </Box>

                                                <Box sx={{ display: "flex" }}>
                                                        <Box sx={{ transform: "translateY(1px)", paddingRight: '2px' }}>
                                                            <AttachMoneyIcon sx={{ width: "18px", height: "18px", color: grey[700] }} />
                                                        </Box>
                                                        <ClouMoneyExhibit value={row?.fee} sx={{ fontSize: "1em" }} />
                                                    </Box>
                                            </Stack>
                                        </Box>
                                    </TableCell> */}

                                    {/* Status */}
                                    <TableCell align="right">
                                        <ClouStatusConverterExhibit value={row?.status} fontSize={"1em"} />
                                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                            <ClouOutstandingInvoiceExhibit months={row?.monthsOutstanding} fontSize={"0.96em"} />
                                        </Box>
                                    </TableCell>

                                    {/* Maintenance */}
                                    {/* <TableCell align="right">
                                                    
                                                </TableCell> */}

                                    {/* Invoice */}
                                    {/* <TableCell >
                                    <ClouMoneyExhibit value={row?.balance} sx={{ fontSize: "1em" }} />
                                    </TableCell> */}

                                    {/* Action */}
                                    {/* <TableCell align="right">
                                            <CardButton variant={'contained'} color={'primary'} onClick={() => { handlePayment(row) }}>Pay</CardButton>
                                        </TableCell> */}

                                    {/* Menu */}
                                    <TableCell >
                                        {/* <PropertyCardMenu data={row} /> */}
                                    </TableCell>
                                </TableRowStyled>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}
export default DirectoryRowItem