import React, { useEffect, useState } from 'react';
import { green, grey, orange, red } from '@mui/material/colors';
import { Box } from '@mui/material';

import useAppSync from 'hooks/useAppSync';
import { AppsyncRequestMethod } from 'hooks/interface';
import { IAccount } from 'Straclou/commons/';
import Row from './Row';

interface IProps {
    /**
     * This args will be removed soon as we are no nolnger calling the server for this information. 
     * @deprecated
     */
    id?: string;
    months?: number;
    fontSize?: string
}
const ClouOutstandongInvoiceExhibit: React.FC<IProps> = ({ id, months, fontSize }) => {

    const [icolor, setIColor] = useState<any>(grey[600])
    const [total, setTotal] = useState(0)
    const [IfontSize, setIFontSize] = useState("1em")

    const [account, isLoading, dispatch] = useAppSync<IAccount>()

    /**
     * we are no longer call this function to get outstanding invoices 
     * @deprecated
     */
    // useEffect(() => {
    //     if (id !== undefined) {
    //         dispatch({ method: AppsyncRequestMethod.getAccount, args: id })
    //     }
    // }, [id])

    // useEffect(() => {

    //     if (!isLoading && account !== undefined) {
    //         let t = account.monthsOutstanding
    //         console.log("Account ",id)


    //         if (t > 0) {
    //             setColor(red[400])
    //         } else {
    //             setColor(grey[600])
    //         }

    //         setTotal(t)
    //     }
    // }, [isLoading])

    useEffect(() => {
        if (months !== undefined) {

            if(months > 3 ){
                setIColor(red[700])
            }

            if (months <3 && months > 0){
                setIColor(orange[500])
            }

            if(months === 0){
                setIColor(green[400])
            }
        }
    }, [months])

    useEffect(() => {
        if (fontSize !== undefined) {
            setIFontSize(fontSize)
        }
    }, [fontSize])

    const displayText = () => {
        let m = ""
        if (months !== undefined && months > 0) {
            m = "s"
        }
        return `${months} invoice${m}`
    }
    return (
        <Row sx={{ fontSize: IfontSize, color: icolor }}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ width: "auto" }}>{displayText()}</Box>
            </Box>
        </Row>
    )
}

export default ClouOutstandongInvoiceExhibit