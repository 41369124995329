
import ClouInvoiceBlankExperience from 'Features/TransactionServices/BalanceExperience/ClouInvoiceBlankExperience';
import { IInvoice } from 'Straclou/commons/IInvoice';
import { styled, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import { grey } from '@mui/material/colors';


import React, { useEffect, useState } from 'react';
import TransactionInvoiceCard from './TransactionInvoiceCard';
import { TransactionManager } from 'Straclou/handler/TransactionManager';



const TableContainerDetail = styled(TableContainer)(({
    maxHeight: "calc(100vh - 144px)", "&::-webkit-scrollbar": {
        width: "4px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#ebebeb",
        webkitBorderRadius: "0px",
        borderRadius: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
        webkitBorderRadius: "0px",
        borderRadius: "0px",
        background: grey[400]
    }
}))

const TableHeaderDetail = styled(TableCell)(({
    textAlign: 'left',
    fontSize: ".8em",
    color: grey[700],
    fontWeight: 700
}))


interface IProps {
    accountNumber?: string
}
const TransactionInvoiceTableList: React.FC<IProps> = ({ accountNumber }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [rows, setRows] = useState<IInvoice[]>([])

    useEffect(() => {
        setIsLoading(true)
        console.log("We are trying to get list of invoices: ", accountNumber)

        if (accountNumber !== undefined) {
            TransactionManager.listInvoice({ accountId: accountNumber || "" }).then((res: any) => {
                console.log("Transaction list array: ", res)
                setRows(res)
                setIsLoading(false)
            })
        }
    }, [accountNumber])



    return (
        <>
            {

                rows.length > 0 ?
                    <TableContainerDetail>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow sx={{ height: '35px', fontWeight: "700" }}>
                                    <TableHeaderDetail>Date</TableHeaderDetail>
                                    <TableHeaderDetail>Invoice No.</TableHeaderDetail>
                                    <TableHeaderDetail>Description</TableHeaderDetail>
                                    <TableHeaderDetail>Amount</TableHeaderDetail>
                                    <TableHeaderDetail>% Paid</TableHeaderDetail>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows?.map(el => {
                                        return (<TransactionInvoiceCard key={el.id} data={el} />);
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainerDetail>

                    :

                    <ClouInvoiceBlankExperience />
            }
        </>
    )
}

export default TransactionInvoiceTableList