import { Box, IconButton, styled } from "@mui/material"
import { green, purple } from "@mui/material/colors"
import ClouItemTitle from "components/ClouItemTitle"
import ClouItemInfo from "components/Display/ClouItemInfo"
import AppButton from "components/widget/AppButton"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from "react-router-dom"
import useWorkspaceStore from "hooks/useWorkspaceStore"


const Wrapper = styled(Box)({
    display: "grid",
    gridTemplateColumns: `60px 1fr 1fr 1fr 30px`,
    gridTemplateRows: "1fr",
    gap: "0 10px"
})


const Avatar = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "60px",
    height: "60px",
    background: purple[500],
    color: "#fff",
    borderRadius: "50%"
}))

const AppCard: React.FC<any> = ({ }) => {

    const navigate = useNavigate()
    const { workspace } = useWorkspaceStore()

    const handleLaunch = () => {
        navigate(`/rms/${workspace.workspaceId}`)
    }
    return (
        <Wrapper>
            <Box sx={{
                borderRadius: "8px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <Avatar >RM</Avatar>
            </Box>
            <Box sx={{ lineHeight: "20px" }}>
                <ClouItemInfo>Resident Manager</ClouItemInfo>
                <ClouItemTitle sx={{ fontSize: "10pt", color: green[500] }}>30 days left</ClouItemTitle>
                <ClouItemTitle sx={{ fontWeight: "bold", color: green[500] }}>Active</ClouItemTitle>
            </Box>
            <Box></Box>
            <Box sx={{
                // background: "green",
                borderRadius: "8px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
            }}>
                <AppButton value="Launch" variant="outlined" onClick={handleLaunch} />
            </Box>
            <Box sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center"
            }}>
                <IconButton>
                    <MoreHorizIcon />
                </IconButton>
            </Box>
        </Wrapper>
    )
}

export default AppCard