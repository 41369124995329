
import { AccountManagerQueryErrorResult, AccountManagerQueryResult, AccountQueryInput, API, CreateAccountInput, GetAccountInput, ListAccountInput, ServiceName } from "Straclou/API"
import { IAccount } from "Straclou/commons"




export module AccountManager {
    export const create = async (createAccountInput: CreateAccountInput): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await API.straclouql("createAccount", 
                {
                    method: "init",
                    input: createAccountInput
                }
                ,
                ServiceName.account)

            console.log(">>>>> data from createAccount >>>>> ", data)
            if (data !== null) resolve(data)

            else reject(data)
            } catch (error) {
                reject(error)
            }
            
        })
    }

    export const list = async (listAccountInput: ListAccountInput): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await API.straclouql("listAccounts", listAccountInput) as Array<any>
                console.log(">>>>> data from listAccounts >>>>> ", data)
                resolve(data)
            } catch (error) {
                reject(null)
            }

        })
    }

    /**
     * Get an account from the community pool 
     */
    export const get = async (getAccountInput: GetAccountInput) => {
        return new Promise(async (resolve, reject) => {
            try {
                const data = await API.straclouql("getAccount", getAccountInput) as any

                console.log("Get Account Response: ", data)
                resolve(new IAccount(data.Item))
            } catch (error) {
                console.log("something went wrong when try to getAccount: ", JSON.stringify(error, null, 6));
                reject(null)
            }
        })
    }

    export const query = async(queryInput: AccountQueryInput): Promise<AccountManagerQueryResult | AccountManagerQueryErrorResult>=>{
        return new Promise(async(resolve, reject)=>{
           try {
               const data = await API.straclouql("query", queryInput) as AccountManagerQueryResult
               resolve(data)
            
           } catch (error) {
            console.log("error on call see error: >>>> ", error);
            reject({
                error: true,
                message: error
            })
            
           }
        })
    }
}