import { Box, styled, alpha, CircularProgress } from "@mui/material"
import { grey } from "@mui/material/colors"
import StraclouLogo from "../../../components/StraclouLogo"
import UserInfo from "../UserInfo";
import LandingRoute from "./LandingRoute";


const PageWrapper = styled(Box)(({
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: alpha(grey[300], 0.8),
    overflow: "auto"

}))

const Wrapper = styled(Box)(({
    width: "70vw",
    height: "90vh"
}))


const Row = styled(Box)(({
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
}))

const Content = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "300px 1fr",
    gap: "0 20px",
    margin: "30px 0"

}))


const LandingPageWrapper = (props: any) => {

    const {children} = props
    
   return (
        <PageWrapper>
            <Wrapper>
                <Row>
                    <StraclouLogo />
                    {/* User Info Control */}
                    {/* <UserInfo /> */}
                </Row>

                <Row sx={{overflow: "auto"}}>
                    {
                        children
                    }
                </Row>

            </Wrapper>
        </PageWrapper>
    )
}

export default LandingPageWrapper