import { Box  } from "@mui/material"
import SignInForm from "./SignInForm"
import { useNavigate } from "react-router-dom"
import { NavigationLink } from "Pages/Onboarding/Widgets/NavigationLink"
import Enfold from "./Enfold"


const SignInActivity = () => {

    const navigate = useNavigate()
    return (
        <Enfold title={"Sign in to Straclou"} subtitle={"Welcome back to awesome."}>
            <SignInForm enfoldItem="form" />
            <Box sx={{ fontSize: "10pt", marginRight: "4px" }}>Forgot your password?</Box> <NavigationLink onClick={() => { navigate('/signin-process/forget_Password') }} fontSize={"10pt"}>Get help with fixing that</NavigationLink>
        </Enfold>
    )
}

export default SignInActivity