import { create } from "zustand"


interface CommentaryStore {
    accountId:  null | string,
    updateAccountId: (id: string)=>void,
    notes: Array<any>,
    updateNotes: (item: any) => void,
    clearNotes: ()=>void
}

let commentaryStore: any = (set: any) => ({
    accountId: null,
    updateAccountId: (id: string)=>set((s: CommentaryStore)=>{
        if(id !== undefined && s.accountId !== id){
            return {
                accountId: id,
                notes: []
            }
        }

        return {
            ...s
        }
    }),
    notes: [],
    updateNotes: (item: any) => set((s: CommentaryStore) => {
        let myList = s.notes || []
        let isIndex = myList.findIndex((i: any) => (i.id === item.id ))
                
        // We did not fnd a key therefore this object is not part of the list
        if (isIndex === -1) {
            //The item is new 
            myList.push(item)
        } else {
            // The item is old but we are going to update it 
            myList[isIndex] = item
        }

        myList.sort((a, b) => {
            const at = new Date(a.timestamp).getTime()
            const bt = new Date(b.timestamp).getTime()
            return bt - at
        })

        return {
            notes: [...myList]
        }
    }),
    clearNotes: ()=>set((s:CommentaryStore)=>({
        notes: []
    })),
    
})

// userAccess"" = persist(userAccess"", { name: userHandler.userAccessTag })

const useCommentaryStore = create<CommentaryStore>(commentaryStore)

export default useCommentaryStore