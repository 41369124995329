
export const PhoneFormatter = (value: string) => {
    let newText = ''
    let replacedText = ('' + value).replace(/\D/g, '')

    for (let i = 0; i < replacedText.length; i++) {
        if (i === 0) {
            newText = '(';
        } else if (i === 3) {
            newText += ') ';

        } else if (i === 6) {
            newText += '-'
        }

        newText += replacedText[i]

    }
    return newText
}

export const Initials = (word: string) => {
    return word.split(" ").reduce((p, w) => {
        return p.substring(1, 0).toUpperCase() + w.substring(1, 0).toUpperCase()
    })
}
/**
 * This function will generate a set of numbers base on the input from the user.
 * @param start Indicate the starting the value for the range
 * @param stop Indeicate the stop value of the range
 * @param step Indicate the incremental values 
 * @returns an array of numbers starting from the start and stop values 
 */
export const range = (start?: number, stop?: number, step: number = 1)=>{
    // init the array to return
    let arr: number[] = []
    
    // create the looper object that will store the counting value init at 0
    let looper = 0

    // var to hold the terminating value init to 0
    let terminator = 0

    // stepper is the incremental value 
    let stepper = step

    // ensure we wet stop if stop and no start
    if(start===undefined && stop){
        terminator = stop
    }
    
// Check if stop is undefined if yes then set terminator to start value
    if(start && stop === undefined){
        terminator = start
    }

    // assign when start and stop
    if(start && stop){
        looper = start;
        terminator = stop
    }

    // handle the input from the user
    while(looper <= terminator){
      arr.push(looper)
      looper += stepper
    }
    return arr
  }


  export class Helper{

    /**
     * This  is a custom function that will test the object to find if you have 
     * a certain property or not.
     * @param source Your object you wish to test 
     * @param prop The property you are looking to find
     * @returns boolean
     */
    static hasOwnProperty(source: any, prop: string ){

        if('hasOwnProperty' in source){
            return source.hasOwnProperty(prop)
        }

        return source.prototype.hasOwnProperty.call(source, prop)
    }



    /**
     * Check if an object is empty 
     * if the object is undefned then error is throw to legt the caller knows that the object is out of bound
     * @param arg default data type type object Array, String, Number, Object
     * @returns boolean
     */
    static isEmpty(arg: any){
        try {
            if(arg === undefined) throw new Error("object undefined, cannot perform checks on an undefinded object")
            
            // not undefined then we need to get its data type 
            let typeOf = typeof arg

            // console.log("arg data type ", typeOf)

            // if string type then perform operation
            if(typeOf === "string"){
                return arg.length > 0 ? false : true
            }
        } catch (error) {
            
        }
        return true
    }
  }


