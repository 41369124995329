import { Box, styled } from "@mui/material";
import { isMobile } from "react-device-detect";

export const FooterPlaceholder = styled(Box)(({
    width: "100%", 
    height: "120px",
    // position: isMobile ? "fixed" : "absolute", 
    bottom: 0, 
    display: "flex", 
    justifyContent: "center", 
    alignItems: "center" ,
    
}))