import React from "react"
import { Box, TextareaAutosize, Typography } from "@mui/material"
import { theme } from "globals/theme"
import { grey } from "@mui/material/colors"

interface IProps {
  placeholder?: string
  minRows?: number
  maxRows?: number
  label?: string
  onChange?: Function
}
export default function ClouTextArea(props: any) {
  const { placeholder, minRows, maxRows, label, onChange } = props
  return (
    <Box sx={{width: "100%", position: "relative"}}>
      <Typography sx={{ fontSize: ".9em" }}>{label}</Typography>
      <TextareaAutosize
        aria-label="minimum height"
        minRows={minRows}
        maxRows={maxRows}
        placeholder={placeholder}
        onChange={onChange}
        style={{
          width: "96%",
          borderRadius: 5,
          padding: "2%",
          border: `1px solid ${grey[300]}`,
          fontFamily: 'font-family: "Roboto", sans-serif',
          fontSize: "1.1em",
          outline: 'none',
        }}
      />
    </Box>
  )
}