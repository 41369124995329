import styled from "@emotion/styled"
import { useWidgetBuilderNavigation, useWidgetBuilderParams } from "@knymbus/react-widget-builder"
import { Box, Button } from "@mui/material"
import { green } from "@mui/material/colors"
import ClouAccountNumberExhibit from "components/ClouAccountNumberExhibit"
import LoadingActivity from "Pages/Onboarding/Landing/LoadingActivity"
import { useEffect } from "react"





const Wrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "400px",
    fontSize: "16pt",
    flexDirection: "column",
    // background: 'red'


})


export default function CompleteFrame(props:any) {
    const { onClose, onNext } = props
    const { navigate } = useWidgetBuilderNavigation()

    const params = useWidgetBuilderParams()

    const handleClose = () => {
        navigate({
            path: "financial",
            params: params.data
        })
    }



    useEffect(()=>{
        console.log(">>>>Params>>>>>",params)
    },[params])

    useEffect(()=>{
        setTimeout(() => {
            // onNext({
            //     next: "general",
            //     data: {...NEWACCOUNTRESPONSESTUB.data}
            // })
                // setIsLoading(false)
            }, 3000);
    },[])

    return (
        <Wrapper>
            <Box sx={{
                border: `1px solid ${green[200]}`,
                padding: "8px 16px",
                background: green[50]
            }}>
            <ClouAccountNumberExhibit value={params?.data?.account?.accountNumber} />

            </Box>
            {/* <LoadingActivity /> */}
            <p>Your account has been created successfully</p>
            <Button sx={{width: "160px"}} variant="contained" onClick={handleClose} color="primary">Ok</Button>
            </Wrapper>
    )
}