import { Box, Tooltip, styled, InputBase, InputAdornment, IconButton } from "@mui/material"
import { IconButtonSmall, IconButtonStyle } from "./CustomStyles"
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useState } from "react";
import { grey } from "@mui/material/colors";


interface IPropsWrapper {
    state?: string
    
}
const Wrapper = styled(Box)<IPropsWrapper>(({ theme, state, onChange }) => ({
    transition: "500ms",
    position: state === "true" ? "absolute" : "relative",
    width: state === "true" ? "calc(100% - 16px)" : "auto",
    display: state === "true" ? "flex" : "block",
    background: theme.palette.primary.main,
    zIndex: "999999999999"
}))
const Search = (props: any) => {

    const [state, setState] = useState(false)

    const handleOnChange = (e:any)=>{
        props.onChange?.(e.target.value)
    }

    return (
        <Wrapper state={state.toString()}>
            <Tooltip title="Search">
                <IconButtonStyle size="small" onClick={() => { setState(true) }}>
                    <SearchIcon sx={{ color: "#fff",width: "20px", height: "20px" }} />
                </IconButtonStyle>
            </Tooltip>
            <Box width={"100%"} sx={{ background: "0", display: state ? "block" : "none" }}>
                <InputBase
                    sx={{ ml: 1, flex: 1, color: "#fff", background: 0, width: "97%", fontSize: "11pt" }}
                    placeholder="Search comments"
                    inputProps={{
                        'aria-label': 'search google maps'
                    }}
                    onChange={handleOnChange}
                    endAdornment={
                        <InputAdornment position="end" >
                            <Tooltip title="Close search">
                                <IconButtonSmall size="small" onClick={() => { setState(false);  props.onChange?.("")}}>
                                    <CloseIcon sx={{ color: "#fff", width: "16px", height: "16px", "&:hover":{
                                        transform: "rotate(-90deg)"
                                    } }} />
                                </IconButtonSmall>
                            </Tooltip>
                        </InputAdornment>
                    }
                />
            </Box>
        </Wrapper >
    )
}

export default Search