

import { styled, Box } from "@mui/system";

import ActivePaymentProvider from "./Context/PaymentProvider";
import PaymentFormControl from "./PaymentFormControl";



const Wrapper = styled(Box, { name: "wrapper-payment" })(({
    // background: "red",
    width: "100%",
    height: "100%",
    padding: "10px",
    overflow: 'hidden',
}))

const PaymentMonitor = () => {

    // const { setActivePayment } = useContext(ActivePaymentContext)
    // const { setFragmentHeader } = useContext(WidgetBuilderContext)

    
    return (

        <Wrapper>
            {/* <ClouNavigationHeader 
                    title="Payment Detail"
                >
                    <Box sx={{ position: 'absolute', right: "32px", top: "8px", fontSize: ".8em", color: grey[600] }}>
                        <ClouDateTimeExhibit />
                    </Box>
                </ClouNavigationHeader> */}
            <ActivePaymentProvider>
                <PaymentFormControl />
            </ActivePaymentProvider>
        </Wrapper>

    )
}

export default PaymentMonitor