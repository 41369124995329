import {v4 as uuidV4} from 'uuid'

export interface IIMember{
    key?: string;
    name?: string;
    uid?: string;
    email?: string;
    priviledge?: string;
}
export class IMember{
    private _key: string = uuidV4()
    private _name: string
    private _uid: string
    private _email: string
    private _priviledge: string

    constructor(args?: IIMember){
        if(args !== undefined && args !==null){
            Object.keys(args).forEach((key: any)=>{
                this._key = args?.key || this._key
                this._uid = args?.uid || ""
                this._email = args?.email || ""
                this._priviledge = args?.priviledge || ""
                this._name = args?.name || ""



            })
        }
    }


    //Getters
    get key(){return this._key}
    get name(){return this._name}
    get uid(){return this._uid}
    get email(){return this._email}
    get priviledge(){return this._priviledge}

    //Getters
    set key(value: string ){ this._key = value}
    set name(value: string ){this._name = value}
    set uid(value: string ){this._uid = value}
    set email(value: string ){this._email = value}
    set priviledge(value: string ){this._priviledge = value}
}