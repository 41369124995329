import React, { createContext, ReactNode, useState } from "react";

interface SummaryContextIntf {
    setOpenSummary: Function
    openSummary: boolean
}
export const PaymentSummaryContext = createContext<SummaryContextIntf>({
    setOpenSummary: (e: any) => { },
    openSummary: false
})


interface IProps {
    children: ReactNode
}
const PaymentSummaryProvider = ({ children }: IProps) => {
    const [openSummary, setOpenSummary] = useState<boolean>(false)
    return (
        <PaymentSummaryContext.Provider value={{ openSummary, setOpenSummary }}>
            {children}
        </PaymentSummaryContext.Provider>
    )
}

export default PaymentSummaryProvider