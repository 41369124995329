import { AnyNsRecord } from "dns"


export const BASEURL = "https://pdmsgeqt14.execute-api.us-east-1.amazonaws.com/dev"
export const BASEURL_COMMENTARY = "https://jdtl7ltwxj.execute-api.us-east-1.amazonaws.com/dev"
export const BASEURL_TRANSACTION = "https://klkaa4k2el.execute-api.us-east-1.amazonaws.com/dev"
export const BASEURL_ADDRESS = "https://pdmsgeqt14.execute-api.us-east-1.amazonaws.com/dev/address"
export const BASEURL_ACCOUNT = "https://l0bxpl56zc.execute-api.us-east-1.amazonaws.com/dev"
export const BASEURL_COMMUNITY = "https://xyvppqooed.execute-api.us-east-1.amazonaws.com/dev/community/"

export type APIROUTESPATHNAME = "listAccounts" | "getAccount" | "createAccount"

export type CommentaryMethod = "create" | "add" | "update" | "list"

export type TransactionMethod = "listInvoices" | "listPayments" | "query"

export type AccountMethod = "listAccounts" | "getAccount" | "query" | 'createAccount'

export type AddressMethod = "getParishList"

export type APIMethods = 
"listAccounts" | 
"getAccount" | 
"createAccount" | 
"create" | 
"add" | 
"update" | 
"list" | 
"listInvoices" | 
"listPayments" | 
"query" | 
"getParishList" |
"listFeeByCategory" |
"getFeeCountByCategory" |
"addFee" |
"updateFeeById" |
"deleteFeeById" |
"GetCommunityIdentity" | 
"gci"


export const AccountConnectionString = (accountMethod: APIMethods, params?: any): { method: "POST" | "PUT" | "GET", url: Function } => {
    const list: any = {
        createAccount: {
            method: "POST",
            url: () => `${BASEURL_ACCOUNT}/oncreate`
        }
    }

    return list[accountMethod]
}

export const CommentaryConnectionString = (commentaryMethod: APIMethods, params?: any): { method: "POST" | "PUT" | "GET", url: Function } => {
    const list: any = {
        create: {
            method: "POST",
            url: () => `${BASEURL_COMMENTARY}/comment`
        },
        list: {
            method: "GET",
            url: () => `${BASEURL_COMMENTARY}/comment/all/${params}`

        }

    }

    return list[commentaryMethod]
}


export const TransactionConnectionString = (commentaryMethod: APIMethods, params?: any): { method: "POST" | "PUT" | "GET", url: Function } => {
    const list: any = {
        query: {
            method: "POST",
            url: () => `${BASEURL_TRANSACTION}`
        },
        listInvoices: {
            method: "GET",
            url: () => `${BASEURL_TRANSACTION}/invoice/all/${params}`

        },
        listPayments: {
            method: "GET",
            url: () => `${BASEURL_TRANSACTION}/payment/all/${params}`

        }

    }

    return list[commentaryMethod]
}


export const CommunityConnectionString = (commentaryMethod: APIMethods, params?: any): { method: "POST" | "PUT" | "GET", url: Function } => {
   return {
        method: 'POST',
        url: ()=>`${BASEURL_COMMUNITY}`
    }
}


export const AddressConnectionString = () => {
    return {
        method: "POST",
        url: BASEURL_ADDRESS
    }
}