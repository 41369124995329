import { styled, Box, Stack } from "@mui/material"
import SignInForm from "./SignInForm"
import { useNavigate } from "react-router-dom"
import Enfold from "./Enfold"


const SignupLink = styled(Box)<{ fontSize?: string }>(({ theme, fontSize }) => ({
    fontSize: fontSize || "9pt",
    margin: "0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
        textDecoration: "underline",
    }
}))


const ForgetPasswordActivity = () => {

    const navigate = useNavigate()
    return (
        <Enfold title="Forget Password helper" subtitle="Enter the email address to which you need help with.">
            <SignInForm enfoldItem="form"/>
            <SignupLink fontSize={"10pt"} onClick={() => navigate("/signin-process/signin")}>Back to signin</SignupLink>
        </Enfold>
    )
}

export default ForgetPasswordActivity