import React from 'react'
import {NumericFormat} from 'react-number-format'

interface IProps{
    footage: number | string
}
const ClouSquareFootageExhibit: React.FC<IProps> = ({footage})=>{
    return <><NumericFormat value={footage} displayType="text" thousandSeparator />{'\u00a0ft\u00b2'}</>
}

export default ClouSquareFootageExhibit