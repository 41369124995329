import { Box } from "@mui/material";
import ClouItemTitle from "components/ClouItemTitle";

export default function WhoAreYou(){
    return (
        <>
        <ClouItemTitle>Who are you</ClouItemTitle>

        <Box>
            I'm a Landlord/Agent who manages individual properties
        </Box>
        <Box>
            I'm a Home Owner Association that manages a community
        </Box>
        </>
    )
}