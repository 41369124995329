import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { grey } from "@mui/material/colors";
import ClouItemTitle from "components/ClouItemTitle";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import ClouDisplayItem from "components/Display/ClouDisplayItem";
import ClouItemInfo from "components/Display/ClouItemInfo";
import Row from "components/Row";
import useCommunity from "hooks/useCommunity";
import { useNavigate } from "react-router-dom";

export default function SubscriptionPurchase() {
    const navigate = useNavigate()
    const {activeCommunity}= useCommunity()

    const handleOrder = () => {
        navigate("/subscription/list")
    }

    return (
        <>
            <ClouItemTitle sx={{ fontWeight: "bold" }}>
                Renewal & Billing
            </ClouItemTitle>

            <Row sx={{ marginTop: "16px", display: "flex", justifyContent: "space-between" }}>
                <ClouDisplayItem>
                    <ClouItemTitle>Package</ClouItemTitle>
                    <ClouItemInfo>Straclou Lite</ClouItemInfo>
                </ClouDisplayItem>
                <ClouDisplayItem>
                    <ClouItemTitle>Type</ClouItemTitle>
                    <ClouItemInfo>Residential</ClouItemInfo>
                </ClouDisplayItem>

                <Box>
                    <ClouDisplayItem>
                        <ClouItemTitle>Account Number</ClouItemTitle>
                        <ClouItemInfo>653-474-9222</ClouItemInfo>
                    </ClouDisplayItem>
                </Box>
            </Row>

            <Row sx={{ margin: "16px 0 32px 0", display: "flex", justifyContent: "space-between" }}>
                <ClouDisplayItem>
                    <ClouItemTitle>Workspace</ClouItemTitle>
                    <ClouItemInfo>
                        <ul>
                            <li>The Richmond - 180 units</li>
                            
                        </ul>
                    </ClouItemInfo>
                </ClouDisplayItem>

            </Row>

            <Row sx={{ marginTop: "16px", display: "flex", justifyContent: "flex-start" }}>
                <FormControl sx={{ width: "200px" }}>
                    <FormLabel id="demo-radio-buttons-group-label">
                        <ClouItemTitle>Duration</ClouItemTitle>
                    </FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="monthly"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="monthly" control={<Radio />} label="Monthly" />
                        <FormControlLabel value="bi-annually" control={<Radio />} label="Bi-Annually" />
                        <FormControlLabel value="annually" control={<Radio />} label="Annually" />
                    </RadioGroup>
                </FormControl>

                <Box sx={{ background: grey[50], padding: "8px", minWidth: "400px" }}>
                    <ClouItemTitle>Total Units 380</ClouItemTitle>
                    <ClouItemTitle>Price</ClouItemTitle>
                    <Box sx={{ fontSize: "2.4rem" }}>
                        <ClouMoneyExhibit value={(65000 + (100 * 80))} />
                    </Box>
                </Box>
            </Row>

            <Row sx={{ margin: "16px 0" }}>
                <ClouItemTitle>
                    Some information about the payment control
                </ClouItemTitle>
            </Row>

            <Row sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box sx={{ marginRight: "8px" }}>
                    <Button>Cancel</Button>
                </Box>
                <Box>
                    <Button variant="contained" onClick={handleOrder}>Order Subscription</Button>
                </Box>
            </Row>
        </>
    )
}