import ClouFormInputNumber from "components/Form/ClouFormInputNumber"
import ClouFormInputSelect from "components/Form/ClouFormInputSelect"
import ClouFormWrapper from "components/Form/ClouFormWrapper"
import { SelectItem } from "components/Form/interface"
import { theme } from "globals/theme"


import { styled, Box, Button, Input, alpha } from "@mui/material"
import React, { useContext } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { ActivePaymentContext, PaymentMethod } from "./Context/PaymentProvider"
import ClouButtonGroup from "components/ClouButtonGroup"



const Wrapper = styled(Box, { name: "Wrapper-Payment-Type-Selection" })(({
    display: "flex",
    alignItems: "center",
    borderRadius: "0px",
    background: alpha(theme.palette.secondary.main, 0.1),
    height: "80px",
    paddingLeft: "16px"
}))

const ActionButton = styled(Button, { name: "Clou-Action-Button" })(({
    height: "30px",
    width: "auto",
    padding: "0",
    margin: "0",
    fontSize: ".8em",
    borderRadius: "5px"
}))


const ActionInput = styled(Input, { name: "Clou-Action-Input" })(({
    width: "100%",
    border: "none"

}))


interface IProps {

}


interface IFormInput {
    option: string
    amount: string
}

const defaultValues = {
    option: "cash",
    amount: ''

}

const PaymentMethodOption: SelectItem[] = [
    {
        text: "Cash",
        value: "cash"
    },
    {
        text: "Debit card",
        value: "debit card"
    },
    {
        text: "Credit Card",
        value: "credit card"
    },
    {
        text: "Online",
        value: "online"
    }
]


const PaymentTypeSelection: React.FC<IProps> = ({ }) => {

    const { setPaymentMethod } = useContext(ActivePaymentContext)

    const methods = useForm<IFormInput>({ defaultValues: defaultValues });
    const { handleSubmit, reset } = methods

    const handleSelection = (e: any) => {
        if (e.amount > 0) {
            setPaymentMethod((prev: PaymentMethod[]) => {
                const indexOfOption = prev.findIndex(pl => pl.option === e.option)
                if (indexOfOption === -1) {
                    let arr: PaymentMethod[] = []
                    arr.push(e)
                    return [...prev, e]
                }

                prev[indexOfOption] = e
                return [...prev]


            })
            reset()

        }

    }

    const handleCancel = () => {
        console.log("We have selected cancel: ")
        reset()
    }
    return (
        <Wrapper>
            <ClouFormWrapper color="primary">
                <FormProvider {...methods}>
                    <ClouFormInputSelect items={PaymentMethodOption} name={"option"} label="Payment Method" />
                    <Box sx={{ width: "24px" }} />
                    <ClouFormInputNumber name={"amount"} placeholder="$0.00" required={true} label="Amount" />

                    <ClouButtonGroup align="right" sx={{ height: "auto", padding: "0 30px 0 0", margin: "12px 0 0 0" }}>
                        <ActionButton variant="text" onClick={() => { handleCancel() }} >Cancel</ActionButton>
                        <ActionButton variant="contained" onClick={handleSubmit(handleSelection)}>Ok</ActionButton>
                    </ClouButtonGroup>
                </FormProvider>
            </ClouFormWrapper>

        </Wrapper>
    )
}

export default PaymentTypeSelection