import { Box, TextField } from "@mui/material"
import { theme } from "globals/theme"
import { styled, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { Controller, useForm } from "react-hook-form"
import { useEffect } from "react"
import { ErrorMessage } from "@hookform/error-message"
import useAuth from "hooks/useAuth"
import { ProgressStep, useOnboardingProgressStore } from "hooks/useOnboardingProgress"
import { useTranslation } from "react-i18next"



const Wrapper = styled(Box)(({
    width: "60vw",
    height: "50vh"
}))

const InputBox = styled(TextField)(({
    width: "100%",
    textAlign: "center",

    "&:outlined-basic": {
        fontSize: "1.1em !important",
        textAlign: "center !important",

    }

}))

const CommunitySetupNamePage = () => {
    const navigate = useNavigate()
    const params = useParams()
    const { user } = useAuth()

    const { t } = useTranslation()


    const { updateProgress, progress } = useOnboardingProgressStore()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();



    const onSubmit = (e: any) => {
        // console.log(e)
        console.log(params)  
        const option: any = {
            id: params.clientId || "",
            uid: user.uid,
            step: ProgressStep.Community_Name,
            data: { CommunityName: e.name }
        }      

        console.log("Option: ", option);
        
        updateProgress(option).then(res => {
            console.log(res)
            navigate(`/client/${params.clientId}/community_strata_number`)
        })


        // console.log("payload: ", workspaceUpdateInput)

    }

    useEffect(()=>{
        console.log("This user: ",user)
    },[user])

    useEffect(() => {
        reset({
            name: progress?.CommunityName
        })
    }, [progress])

    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ textAlign: "center", fontSize: '2.3em', fontWeight: "600", margin: "30px 0 30px 0", color: theme.palette.primary.main }}>
                    {t("community_name.title")}
                </Box>

                <Box sx={{ textAlign: "center", margin: "0 0 30px 0" }}>
                    <Controller
                        name={"name"}
                        control={control}
                        defaultValue={progress?.CommunityName || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("name", {
                                    required: "Community name is required", minLength: {
                                        value: 3,
                                        message: "Length of community name have to be greater than 3 characters"
                                    }
                                })}
                                onChange={onChange}
                                value={value}
                                placeholder={t('community_name.input_placeholder') || ""}
                                inputProps={{ style: { textAlign: "center", fontSize: "18pt" } }}
                                sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                        )}
                    />
                    <Box sx={{
                        margin: "8px",
                        height: "40px"
                    }}>
                        <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                        />
                    </Box>

                </Box>

                <Box sx={{ textAlign: "center", margin: "0 0 30px 0", lineHeight: "2em" }}>{t("community_name.description")}</Box>

                <Box sx={{ width: "300px", margin: "0 auto" }}>
                    <Button type="submit" variant="contained" fullWidth sx={{ height: "55 px", fontSize: "1.6em" }}>{t(('button.next'))}</Button>
                </Box>
            </form>
        </Wrapper>
    )
}

export default CommunitySetupNamePage