import * as React from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { deepOrange, grey } from '@mui/material/colors';
import Row from 'components/Row';
import { theme } from 'globals/theme';


const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const iconColor = deepOrange[300]

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${grey[500]};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: transparent;
  
//   padding: 12px 16px;
  margin-right: 12px;
  border: none;
//   border-radius: 5px;
//   display: flex;
//   justify-content: flex-start;
  transition: 250ms ease-in-out 20ms;
  border-bottom: 2px solid transparent;
  text-align: left;

  &:hover {
    color: ${theme.palette.primary.light};
  }

  &:focus {
    color: #fff;
    // border-radius: 3px;
    // outline-offset: 2px;
   
  }

  &.${tabUnstyledClasses.selected} {
    color: ${theme.palette.primary.main};
    border-bottom: 2px solid ${theme.palette.primary.main};
    text-align: left;

  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
//   font-size: 0.875rem;
  padding: 0px;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 120px;
  background-color: 0;
  border-radius: 0px;
  margin-bottom: 8px;
//   border-bottom: 1px solid ${grey[200]};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
`;


interface IProps {
    tabs?: Array<string>
    children?: React.ReactNode
}

const TransactionTabs: React.FC<IProps> = ({ tabs, children }) => {

    return (
        <TabsUnstyled defaultValue={0}>
            <TabsList>
                {
                    tabs?.map(el => {
                        return (
                            <Tab key={el}>
                                <Row>{el}</Row>
                            </Tab>
                        )
                    })
                }
            </TabsList>
            {
                children
            }
        </TabsUnstyled>
    );
}


export default TransactionTabs