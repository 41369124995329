import { Box, Button, styled, Tooltip, TableContainer, TableCell } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import TransactionTabs, { TabPanel } from "./TransactionTabs";
import TransactionInvoiceTableList from "./TransactionInvoiceTableList";
import TransactionPaymentTableList from "./TransactionPaymentTableList";
import { useWidgetBuilderParams } from "@knymbus/react-widget-builder";


const PaymentButton = styled(Button)(({
    height: "24px",
    borderRadius: "5px",
    fontSize: ".8em"
}))

const TableHeaderDetail = styled(TableCell)(({
    textAlign: 'left',
    fontSize: ".8em",
    color: grey[700],
    fontWeight: 700
}))

const TableContainerDetail = styled(TableContainer)(({
    maxHeight: "calc(100vh - 200px)", "&::-webkit-scrollbar": {
        width: "4px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#ebebeb",
        webkitBorderRadius: "0px",
        borderRadius: "0px"
    },
    "&::-webkit-scrollbar-thumb": {
        webkitBorderRadius: "0px",
        borderRadius: "0px",
        background: grey[400]
    }
}))


const Wrapper = styled(Box)`
padding: 10px;
`;

interface StateType {
    name: string
    address: any
    unitNumber: string


}

const TransactionCardViewDetail: React.FC = () => {
    const navigation = useNavigate();

    const params = useWidgetBuilderParams()

    const location = useLocation()
    const state = location.state as StateType

    useEffect(()=>{
        console.log("AccountId: ", params)
    },[params])

    return (
        <Wrapper>

            {/* <Box sx={{ position: "relatve", marginBottom: "25px" }}>
                <Typography sx={{ fontSize: ".9em", color: "InfoText" }}>Transactions</Typography>
                <Typography sx={{ fontSize: ".776em", color: "InactiveCaptionText" }}>{0} records found</Typography>
                <Box sx={{ position: "absolute", right: '24px', top: '87px', background: "0" }}>
                    <Tooltip title="Make Payment">
                        <PaymentButton
                            variant="contained"
                            color="secondary"
                            onClick={() => { navigation(AddressDirectory.rms.transactionManager.payment + accountId, { state: { name: state?.name } }) }}
                        >
                            Pay
                        </PaymentButton>
                    </Tooltip>
                </Box>
            </Box> */}


            {/* Header */}
            {/* <Box sx={{ position: "relatve", background: "0", marginBottom: "4px" }}>
                <Typography sx={{ fontSize: ".9em", color: "InfoText" }}>Transactions</Typography>
                <Typography sx={{ fontSize: ".776em", color: "InactiveCaptionText" }}>{0} records found</Typography>
                <Box sx={{ position: "absolute", right: '24px', top: '107px', display:"flex", justifyContent: "flex-end" }}>
                    <SearchTransaction />
                </Box>
            </Box> */}

            <TransactionTabs tabs={[`Invoice`, `Payment`]}>
                <TabPanel value={0}>
                    <TransactionInvoiceTableList accountNumber={''} />
                </TabPanel>
                <TabPanel value={1}>
                    <TransactionPaymentTableList accountNumber={''} accountName={state?.name} />
                </TabPanel>
            </TransactionTabs>
        </Wrapper>
    )
}

export default TransactionCardViewDetail