import { v4 as uuidV4 } from 'uuid'
import CommunityGeneral from "./CommunityGeneral";
import CommunityStrataNumber from "./CommunityStrataNumber";
import CommunityAddress from "./CommunityAddress";
import CommunityStructure from "./CommunityStructure";
import CommunityUnit from "./CommunityUnit";
import CommunityContact from "./CommunityContact";



// Icons 
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import DomainIcon from '@mui/icons-material/Domain';
import CommunityIdentity from './CommunityIdentity';


export 
type CommunityPreferenceDialogNavigationType = "Identity" | "Strata Number" | "Address" | "Structure" | "Units" | "Contacts" | "Notification" | "Subsciption" | "Security"
export enum CommunityPreferenceDialogNavigation {
    Identity = "Identity",
    StrataNumber = "Strata Number",
    Address = "Address",
    Structure = "Structure",
    Units = "Units",
    Contacts = "Contacts",
    Notification = "Notification",
    Subsciption = "Subsciption",
    Security = "Security"
}
export enum CommunityPreferenceDialogStructure {
    apartment = "Apartments",
    singleFamilyHome = "Single Family Homes",
    townhouses = "Townhouses",
    condominiums = "Condominiums",
    villas = "Villas"
}
// Data
export const SETTINGS_DATA = [
    {

        key: uuidV4(),
        name: CommunityPreferenceDialogNavigation.Identity,
        isActive: false,
        button: {
            icon: <DomainIcon />,
            text: CommunityPreferenceDialogNavigation.Identity
        },
        component: <CommunityIdentity />

    },
    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.StrataNumber,
    //     isActive: false,
    //     button: {
    //         icon: <NotificationsActiveIcon />,
    //         text: CommunityPreferenceDialogNavigation.StrataNumber
    //     },
    //     component: <CommunityStrataNumber />

    // },
    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.Address,
    //     isActive: false,
    //     button: {
    //         icon: <MyLocationIcon />,
    //         text: CommunityPreferenceDialogNavigation.Address
    //     },
    //     component: <CommunityAddress />

    // },
    {
        key: uuidV4(),
        name: CommunityPreferenceDialogNavigation.Structure,
        isActive: false,
        button: {
            icon: <Grid3x3Icon />,
            text: CommunityPreferenceDialogNavigation.Structure
        },
        component: <CommunityStructure />

    },
    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.Units,
    //     isActive: false,
    //     button: {
    //         icon: <NotificationsActiveIcon />,
    //         text: CommunityPreferenceDialogNavigation.Units
    //     },
    //     component: <CommunityUnit />

    // },
    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.Contacts,
    //     isActive: false,
    //     button: {
    //         icon: <PermContactCalendarIcon />,
    //         text: CommunityPreferenceDialogNavigation.Contacts
    //     },
    //     component: <CommunityContact />


    // },


    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.Notification,
    //     isActive: false,
    //     button: {
    //         icon: <NotificationsActiveIcon />,
    //         text: CommunityPreferenceDialogNavigation.Notification
    //     },
    //     component: <>Notification Settings</>
    // },
    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.Security,
    //     isActive: false,
    //     button: {
    //         icon: <NotificationsActiveIcon />,
    //         text: CommunityPreferenceDialogNavigation.Security
    //     },
    //     component: <>Security Settings</>
    // },

    // {
    //     key: uuidV4(),
    //     name: CommunityPreferenceDialogNavigation.Subsciption,
    //     isActive: false,
    //     button: {
    //         icon: <NotificationsActiveIcon />,
    //         text: CommunityPreferenceDialogNavigation.Subsciption
    //     },
    //     component: <>Subscription Detail</>
    // },



]