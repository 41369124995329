import { styled, SxProps, Divider } from "@mui/material"
import React, { Children, useEffect, useState } from "react"

const TitleStyle = styled('div')((props: {size: string})=>(({
    width: "100%",
    lineHeight: "1.5em",
    fontSize: props.size,
    fontWeight: "100",
    textTransform: "capitalize"

})))

interface TitleIProps{
    text?: string, 
    children?: React.ReactNode, 
    variant?: "1" | "2" | "3" | "4" | "5" | "6", 
    divider?: boolean 
    sx?: SxProps
}

export const Title = (props: TitleIProps) => {
    const typeVariant = {
        "1":"1.9em",
        "2":"1.7em",
        "3":"1.5em",
        "4":"1.3em",
        "5":"1.1em",
        "6":"0.9em",
    }

    const {text, children, variant, divider, sx} = props
    const [size, setSize] = useState("1.9em")
    const [dividerOn, setDividerOn] = useState(false)

    useEffect(()=>{
        if(variant) setSize(typeVariant[variant])
        if(divider) setDividerOn(true); else setDividerOn(false);
    },[])
    return (
        <div >
            <TitleStyle sx={sx} size={size}>{props.text || props.children}</TitleStyle>
            {dividerOn?<Divider />:""}
        </div>
    )
}

const SubTitleStyle = styled("div")((props: {size: string})=>(({
    margin: "0 0 3px 0",
    fontSize: props.size
})))

interface SubTitleIProps{
    text?: string, 
    children?: React.ReactNode, 
    variant?: "1" | "2" | "3" | "4" | "5" | "6", 
    divider?: boolean 
    sx?: SxProps
}

export const SubTitle = (props: SubTitleIProps) => {
    const typeVariant = {
        "1":"1.1em",
        "2":"1em",
        "3":".9em",
        "4":".8em",
        "5":".78em",
        "6":"0.7em",
    }
    const [size, setSize] = useState(typeVariant["2"])
    const [dividerOn, setDividerOn] = useState(false)
    useEffect(()=>{
        if(variant) setSize(typeVariant[variant])
        if(divider) setDividerOn(true); else setDividerOn(false);
    },[])
    const {text, children, variant, divider, sx} = props
    return (
        <div>
            <SubTitleStyle sx={sx} size={size}>{text || children}</SubTitleStyle>
            {dividerOn?<Divider />:""}
        </div>
    )
}



const TitleWrapperStyle = styled("div")(({
    width: "100%",
    margin: "0"
}))


interface TitleWrapperProps {
    title?: React.ReactNode
    subTitle?: React.ReactNode
    sx?: SxProps,
    children?: React.ReactNode
}
export const TitleWrapper = (props: TitleWrapperProps) => {
    const { title, subTitle, sx, children} = props
    return (<TitleWrapperStyle sx={sx}>
        <div className="item">{title}</div>
        <div className="item">{subTitle}</div>
        {children}
        
    </TitleWrapperStyle>)
}