import { styled, Box, Stack } from "@mui/material"
import logo from "../../logo.svg"
import SignInForm from "./SignInForm"
import { useNavigate } from "react-router-dom"
import FloatingText from "components/FloatingText"
import SignUpForm from "./SignUpForm"
import Enfold from "./Enfold"


const CenterItem = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}

const Wrapper = styled(Box)(({
    width: "100vw",
    height: "100vh",
    overflowY: 'auto'
    // ...CenterItem,

}))

const Content = styled(Box)(({
    width: "60%",
    ...CenterItem,
    margin: "0 auto",
    flexDirection: "column"
}))

const Logo = styled(Box)(({
    ...CenterItem,
    height: "90px",
    cursor: "pointer",
    marginBottom: "16px"
}))

const Instruction = styled(Box)(({
    ...CenterItem,
    fontSize: "2.7em",
    fontWeight: 900
}))

const LogoName = styled('span')(({
    fontFamily: `'Fira Sans Condensed', sans-serif`,
    letterSpacing: "2px",
    fontSize: "2.3em",
    fontWeight: 600,
    marginLeft: "8px"
}))


const SignupLink = styled(Box)<{ fontSize?: string }>(({ theme, fontSize }) => ({
    fontSize: fontSize || "9pt",
    margin: "0",
    color: theme.palette.primary.main,
    cursor: "pointer",
    fontWeight: 600,
    "&:hover": {
        textDecoration: "underline",
    }
}))


export default function SignupActivity() {
    const navigate = useNavigate()


    return (
        <Enfold
            title="Create a profile to get started"
            subtitle="We suggest using the community email address."
        >
            <SignUpForm enfoldItem="form" />
            <Box sx={{ fontSize: "10pt", marginBottom: "0px", marginRight: "4px" }}>Already using Straclou?</Box> <SignupLink onClick={() => navigate('/')} fontSize={"10pt"}>Sign in with my credentials</SignupLink>
        </Enfold >
    )
}

