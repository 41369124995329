import { createContext, useContext } from "react";
import { ThemeColor } from "../Table/GlobalTableProps";


export type ClouFormContent = {
    activeColor: ThemeColor,
    
}

export const ClouFromContext = createContext<ClouFormContent>({
    activeColor: "primary",
    
})

export const useClouFormContext = () => useContext(ClouFromContext)