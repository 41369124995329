export class IIdentity{

    // key vars
    private _communityId: string
    private _itemId: string
    private _name: string
    private _isDeletable: Boolean
    private _activeState: string
    private _createdAt: string
    private _sologon: string
    private _type: string
    private _shortName: string


    // Getters
    get communityId(){return this._communityId}
    get itemId(){return this._itemId}
    get name(){return this._name}
    get isDeletable(){return this._isDeletable}
    get activeState(){return this._activeState}
    get createdAt(){return this._createdAt}
    get sologon(){return this._sologon}
    get type(){return this._type}
    get shortName(){return this._shortName}
    


    // Setters

    constructor(args?: any){
        console.log(args)
        if(args !== undefined){
            this._communityId = args.communityId || ""
            this._itemId = args.itemId || ""
            this._name = args.name || ""
            this._isDeletable = args.isDeletable || false
            this._activeState = args.activeState || "in-active"
            this._createdAt = args.createdAt || ""
            this._sologon = args.sologon || ""
            this._type = args.type || ""
            this._shortName = args?.shortName || ""
        }
    }


    public isset(): boolean{
        return this._communityId === undefined || null ? false : true
    }
}