import { styled, Box} from "@mui/material"
import { grey } from "@mui/material/colors"
import { Container } from "@mui/system"
import { ProgressStep, useOnboardingProgressStore } from "hooks/useOnboardingProgress"
import useOnboardingProgressRouter from "hooks/useOnboardingProgressRouter"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

const Wrapper = styled(Box)(({
    width: "100%",
    // padding: "24px",
    transition: "all 500ms",
    color: grey[400], fontWeight: 100,
    cursor: "pointer",
    "&:hover": {
        color: grey[500]
    }
}))

interface IPropsShadow {
    vPosition?: any
}
const Shadow = styled(Box)(({ vPosition }: IPropsShadow) => ({
    width: "100%",
    height: "60px",
    background: "inherit",
    position: "absolute",
    ...vPosition,
    opacity: 0.7


}))

const ShadowItem = styled(Box)(({
    "span": {
        display: "block",
        fontSize: ".8em"
    },
    "span:first-of-type": {
        fontSize: ".6em",
    },
    margin: ".75em",
    "&:hover": {
        color: grey[700]
    }
}))


const OnboardingProgressPane = () => {

    const params = useParams()
    const { progress, load } = useOnboardingProgressStore()

    const {goto} = useOnboardingProgressRouter(params?.clientId)
    

    const handleClicked = (e: ProgressStep) => {
        goto(e)
    }

    useEffect(()=>{
        if(params.clientId !== undefined) load(params.clientId)
    },[])

    return (
        <Wrapper>

            <Container sx={{ margin: "30px 0", fontSize: "1.4em", }}>

                {
                    progress?.CommunityName && (
                        <ShadowItem onClick={() => handleClicked(ProgressStep.Community_Name)}>
                            <span>Community</span>
                            <span>{progress?.CommunityName}</span>
                        </ShadowItem>
                    )
                }

                {
                    progress?.CommunityStrataNumber && (
                        <ShadowItem onClick={() => handleClicked(ProgressStep.Community_Strata_Number)}>
                            <span>Strata Number</span>
                            <span>{progress?.CommunityStrataNumber}</span>
                        </ShadowItem>
                    )
                }


                {
                    progress?.CommunitySize && (
                        <ShadowItem onClick={() => handleClicked(ProgressStep.Community_Size)}>
                            <span>Community Size</span>
                            <span>{progress?.CommunitySize}</span>
                        </ShadowItem>
                    )
                }

                {
                    progress?.CommunityStructure && (
                        <ShadowItem onClick={() => handleClicked(ProgressStep.Community_Structure)}>
                            <span>Community Structure</span>
                            {
                                progress?.CommunityStructure?.map(el => {
                                    return (
                                        <span key={el}>{el}</span>)
                                })
                            }
                        </ShadowItem>
                    )
                }

                {
                    progress.CommunityAddress && (
                        <ShadowItem onClick={() => handleClicked(ProgressStep.Community_Address)}>
                            <span>Community Address</span>
                            <span title="Street">{progress.CommunityAddress?.street}</span>
                            <span title="Town">{progress.CommunityAddress?.town}</span>
                            <span title="City">{progress.CommunityAddress?.city}</span>
                            <span title="Parish">{progress.CommunityAddress?.parish}</span>
                        </ShadowItem>
                    )
                }


            </Container>
            <Shadow vPosition={{ 'bottom': 0 }} />
        </Wrapper>
    )
}

export default OnboardingProgressPane