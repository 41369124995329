import { CommunityPreferenceDialogStructure } from "Features/UserProfileService/CommunityPreferences/SettingsData"
import { IFeeCategory } from "./IFeeCategory"

export class IFee {
    private _apartment: IFeeCategory
    private _villa: IFeeCategory
    private _townHouse: IFeeCategory
    private _singleFamilyHome: IFeeCategory
    private _condominium: IFeeCategory

    constructor(args?: any) {
        // console.log("Arguments: ",args)
        if (args !== undefined) {
            args.forEach((el: any) => {
                if (el.category === CommunityPreferenceDialogStructure.apartment) {
                    this._apartment = new IFeeCategory(el)
                }
            })
        }
        this._condominium = new IFeeCategory()
        this._apartment = new IFeeCategory()
        this._townHouse = new IFeeCategory()
        this._singleFamilyHome = new IFeeCategory()
        this._villa = new IFeeCategory()
    }


    get apartment() { return this._apartment }
    get townHouse() { return this._townHouse }
    get singleFamilyHome() { return this._singleFamilyHome }
    get condominium() { return this._condominium }
    get villa() { return this._villa }


    getFeeByCategory(category: CommunityPreferenceDialogStructure){
        switch(category){
            case CommunityPreferenceDialogStructure.apartment: 
            return this.apartment

            case CommunityPreferenceDialogStructure.condominiums: 
            return this.condominium

            case CommunityPreferenceDialogStructure.townhouses: 
            return this.townHouse

            case CommunityPreferenceDialogStructure.singleFamilyHome: 
            return this.singleFamilyHome

            case CommunityPreferenceDialogStructure.villas: 
            return this.villa

        }
    }
}