
import { useWidgetBuilderNavigation, useWidgetBuilderParams, WidgetBuilderContext } from "@knymbus/react-widget-builder"
import { Box, Button, Divider, Stack, styled, Tooltip } from "@mui/material"
import { Title } from "components/Title"
import { useContext, useEffect, useLayoutEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import FeesTable from './FeesTable'
import useWorkspace from "hooks/useWorkspace"
import useWorkspaceStore from "hooks/useWorkspaceStore"
import StructureOutletDeleteDialog from "./StructureOutletDeleteDialog"
import FeesTableCreateDialog from "./FeesTableCreateDialog"
import useCategoryFeeStore from "hooks/useCategoryFeeStore"
import StructureOutletMenu from "./StructureOutletMenu"

const Wrapper = styled(Box, { name: "Structure-Outlet-Wrapper" })({
    position: "relative",
    width: "100%",
    height: "100%",
    // background: "#a1a1a126",
    padding: '0px',
    margin: '0',
    transition: "500ms",
    borderRadius: '5px',

})

const Content = styled(Box, { name: "Structure-Outlet-Content" })({
    padding: '16px'
})

const Header = styled(Box, { name: "Structure-Outlet-Header" })({
    padding: '8px 24px',
    display: 'flex'
})

const Icon = styled(Box, { name: "Structure-Outlet-Header-Icon" })(({ theme }) => ({
    padding: '8px',
    background: theme.palette.secondary.light,
    borderRadius: "6px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 8px 0 0"
}))

export default function (props: any) {
    const params = useWidgetBuilderParams()
    const { t } = useTranslation()



    const { getFeeCountByCategory, updateWorkspaceById } = useWorkspace()
    const { workspace, updateWorkspace } = useWorkspaceStore()

    const [open, setOpen] = useState(false)
    const [menuShow, setMenu] = useState<boolean>(false)
    const [deleteMenuOpen, setDeleteMenuOpen] = useState<boolean>(false)

    const [feeState, setFeeState] = useState(useCategoryFeeStore.initialState)

    const handleOpen = () => {
        if (!open) {
            setOpen(true)
        }
    }


    useLayoutEffect(() => {
        let isRunning = false

        if (!isRunning) {
            useCategoryFeeStore.subscribe(setFeeState)
            useCategoryFeeStore.init(workspace.workspaceId, params.type)
            isRunning = true
        }

        console.log("is running....", isRunning)
        return () => {
            // when the component unmount we unsubscribe to the subscription also we clear the data
            useCategoryFeeStore.subscribe(useCategoryFeeStore.initialState).unsubscribe()
            console.log("Function run when component is no longer mounted", isRunning)
        }
    }, [params])



    return (
        <Wrapper>
            <Header>
                <Icon>
                    {params?.icon}
                </Icon>

                <Title variant="3" sx={{ mb: 1 }}>
                    {params?.type}
                </Title>
            </Header>

            <Box sx={{ padding: "0 24px" }}>
                <Divider />
            </Box>

            <Box sx={{ position: "absolute", right: '32px', top: '12px' }}>
                <Stack direction={"row"} gap={1}>
                    <FeesTableCreateDialog type={params?.type} />
                    <StructureOutletMenu type={{
                        communityId: workspace.workspaceId,
                        category: params?.type
                    }} />
                </Stack>
            </Box>
            <Content>
                <Box sx={{ mb: 1 }}>
                    {t('CommunitySettings.structure.editor.content.c1')}
                </Box>

                <Box sx={{ height: "auto" }}>
                    <FeesTable type={params?.type} open={open} data={feeState} />
                </Box>
            </Content>

        </Wrapper>
    )

}