import { Box, IconButton, styled, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import Row from "components/Row";
import AppCarousel from "./AppCarousel";
import AppsIcon from '@mui/icons-material/Apps';


const Wrapper = styled(Box)({
    background: grey[50],
    margin: "16px 0",
    borderRadius: "20px",
    padding: "24px 16px",
    boxShadow: `1px 2px 1px ${grey[300]}`
})



export default function ApplicationViewer() {
    return (
        <Wrapper>
            <Row>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"

                }}>
                    <Box sx={{ fontSize: "10.4pt" }}>Connected Applications</Box>
                    <Box sx={{ fontSize: "10.4pt" }}>
                        <Tooltip title="More apps">
                            <IconButton>
                                <AppsIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
                <AppCarousel />
            </Row>
        </Wrapper>
    )
}