import { WidgetBuilder, IWidgetBuilderRoute, WidgetBuilderOutlet, IWidgetBuilderNavigate } from "@knymbus/react-widget-builder"
import { Box, styled } from "@mui/material"
import { grey } from "@mui/material/colors"
import SubscriptionList from "Features/SubscriptionService/SubscriptionList"
import SubscriptionMain from "Features/SubscriptionService/SubscriptionMain"
import WorkSpacesActivity from "Pages/Onboarding/Landing/WorkSpacesActivity"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { v4 } from "uuid"
import AdminHeader from "./header/AdminHeader"
import AdminSideMenuHeader from "./header/AdminSideMenuHeader"
import AdminSideMenuContainer from "./Menus/AdminSideMenuContainer"


const Wrapper = styled(Box, { name: "Admin-Dashboard-wrapper" })(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "250px calc(100vw - 250px)",
    gridTemplateRows: "100vh",
    background: grey[50],

    "& .side_menu": {
        background: grey[100],
        // borderRight: `1px solid ${grey[100]}`
    },
    "& .content": {
        background: grey[100],
        border: theme.palette.grey[500],
        overflow: "hidden"
    },
}))

interface IProps {

}

export default function AdminDashboard(props: IProps) {

    const location = useLocation();

    const [onNavigate, setOnNavigate] = useState<IWidgetBuilderNavigate>({
        path: "#dashboard"
    })


    const routes: IWidgetBuilderRoute[] = [
        {
            index: true,
            window: <WorkSpacesActivity key={v4()} />,
            path: '#dashboard'
        },
        {
            window: <SubscriptionList key={v4()} />,
            path: "#subscription"
        }
    ]
    const adminDashboardWidget = new WidgetBuilder({
        routes
    })

    useEffect(() => {
        setOnNavigate({
            path: location.hash
        })
    }, [location])


    return (
        <Wrapper>
            <Box className="side_menu">
                <AdminSideMenuHeader />
                <AdminSideMenuContainer />
            </Box>
            <Box className="content">
                <AdminHeader />
                <Box sx={{ padding: "8px" }}>
                    <WidgetBuilderOutlet builder={adminDashboardWidget} onNavigate={onNavigate} />
                </Box>
            </Box>

        </Wrapper>
    )
}