import { useWidgetBuilderNavigation, WidgetBuilderContext } from "@knymbus/react-widget-builder";
import { Box, styled } from "@mui/material"
import { grey } from "@mui/material/colors"
import { ReactNode, useContext, useEffect, useState } from "react";
import { CommunityPreferenceDialogNavigation, SETTINGS_DATA } from "./SettingsData";
const Wrapper = styled(Box)(({
    width: "100%",
    height: "496px",
    // background: "purple"
}))

const Container = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "250px auto",
    gap: "0 20px"
}))


const List = styled(Box)(({
    background: "0",
    height: "480px",
    overflowY: "auto",
    padding: "8px"
}))

const Content = styled(Box)(({
    height: "100%",
    background: "0",
    overflowY: "auto",
    padding: "8px"
}))

const Button = styled(Box)<{ isactive: string }>(({ theme, isactive }) => ({
    background: isactive === "true" ? theme.palette.primary.light : 0,
    color: isactive === "true" ? "#fff" : "#000",
    fontSize: "10pt",
    height: "30px",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: 'center',
    flexDirection: "row",
    cursor: "pointer",
    padding: "0 8px",
    margin: "5px 0",
    "&:hover": {
        background: isactive === "true" ? theme.palette.primary.light : grey[100],
    },
    "svg": {
        width: "18px",
        height: "18px",
        marginRight: "8px"
    }


}))



// const List = styled(Box)(({
// }))



export default function DialogFrame(props: any) {

    const { onClose, open } = props

    // const { setFragmentHeader } = useContext(WidgetBuilderContext)

    const [element, setElement] = useState<ReactNode | null>(null)

    const goto = (name: CommunityPreferenceDialogNavigation) => {

        SETTINGS_DATA.map(el => {
            el.isActive = false
            if (el.name === name) {
                el.isActive = true
                setElement(el.component)
            }
            return el
        })
    }

    useEffect(() => {
        goto(CommunityPreferenceDialogNavigation.Identity)
        // setFragmentHeader("Community Profile")
    }, [])
    return (
        <Wrapper>
            <Container>

                <List>
                    {
                        SETTINGS_DATA.map(link => {
                            return (
                                <Button key={link.key} onClick={() => { goto(link.name) }} isactive={link.isActive.toString()}>
                                    {link.button.icon}
                                    {link.button.text}
                                </Button>

                            )
                        })
                    }
                </List>
                <Content>
                    {
                        element
                    }
                </Content>
            </Container>
        </Wrapper>
    )
}