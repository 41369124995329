import { Avatar, Box, styled } from "@mui/material"
import { grey } from "@mui/material/colors"
import DateTimeExhibit from "components/DateTimeExhibit"


const Wrapper = styled(Box)((theme) => ({
    minHeight: "120px",
    border: `1px solid ${grey[100]}`,
    background: grey[50],
    padding: "12px",
    margin: "16px 0",
    borderRadius: "25px"
}))

const Time = styled(Box)({
    fontSize: "9pt",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    color: grey[600]

})
const Description = styled(Box)({
    fontSize: "10pt",
    padding: "8px",
    lineHeight: "15pt"
})
interface IProps {
    comment: any
}

const Item: React.FC<IProps> = ({ comment }) => {
    return (
        <Wrapper>
            <Box sx={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                }}>
                    <Avatar sx={{ width: 24, height: 24 }} />

                    <Box sx={{ display: "flex", flexDirection: "column", paddingLeft: "8px" }}>
                        <span style={{fontSize: "10pt", fontWeight: "500" }}>{comment?.author}</span>
                        <DateTimeExhibit sx={{fontSize: "9pt"}} time={comment?.timestamp}/>
                    </Box>
                </Box>


            </Box>
            <Description dangerouslySetInnerHTML={{ __html: comment?.htmlText }} />


        </Wrapper>
    )
}

export default Item