
interface Author{
    id: string
    name: string
}

export class IInvoice {
    private _id: string
    private _accountId: string
    private _amount: number;
    private _author: Author
    private _communityId: string
    private _createdAt: string
    private _description: string
    private _discount: number
    private _interest: number
    private _isPaid: boolean
    private _month: string
    private _percentagePaid: number
    private _total: number
    private _totalPayment: number
    private _trackingNumber: string;

    constructor(args: any) {
        if (args !== undefined) {
            this._id = args.id
            this._accountId = args.accountId
            this._amount = args.amount
            this._author = args.author
            this._communityId = args.communityId
            this._createdAt = args.createdAt
            this._description = args.description
            this._discount = args.discount
            this._interest = args.interest
            this._isPaid = args.isPaid
            this._month = args.month
            this._percentagePaid = args.percentagePaid
            this._total = args.total
            this._totalPayment = args.totalPayment
            this._trackingNumber = args.trackingNumber

        }
    }

    get id() { return this._id }
    get accountId() { return this._accountId }
    get amount() { return this._amount }
    get author() { return this._author }
    get communityId() { return this._communityId }
    get createdAt() { return this._createdAt }
    get description() { return this._description }
    get discount() { return this._discount }
    get interest() { return this._interest }
    get isPaid() { return this._isPaid }
    get month() { return this._month }
    get percentagePaid() { return this._percentagePaid }
    get total() { return this._total }
    get totalPayment() { return this._totalPayment }
    get trackingNumber() { return this._trackingNumber }

}