import { createContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IProps {
    children: React.ReactNode
}

interface MessageDialogIntf {
    drawer: boolean;
    setDrawer: (e: boolean)=>void,
    accountNumber: string
}
export const MessageDialogContext = createContext<MessageDialogIntf>({
    drawer: false,
    setDrawer: () => {},
    accountNumber: ""
})

const MessageDialogProvider = ({ children }: IProps) => {
    const [drawer, setDrawer] = useState(false)
    const { accountId } = useParams<{ accountId?: string }>()
    const [accountNumber, setAccountNumber] = useState('')

    useEffect(()=>{
        setAccountNumber(accountId ?? "")
    },[accountId])

    return <MessageDialogContext.Provider value={{drawer, setDrawer, accountNumber}} >
        {children}
    </MessageDialogContext.Provider>
}
export default MessageDialogProvider