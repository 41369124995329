import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, TextField, styled, Snackbar, Alert, Divider, Tooltip } from "@mui/material";
import ClouItemTitle from "components/ClouItemTitle";
import ClouHeader from "components/headers/ClouHeader";


import { theme } from "globals/theme";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form"
import { useParams } from "react-router-dom";
import { ChangeDetectionManager } from "Straclou";
import { ButtonController, IMessageControl } from "./component/ButtonController";
import { Identity } from "./interface";
import HomeIcon from '@mui/icons-material/Home';
import { grey } from "@mui/material/colors";
import UsageCapacity from "./component/UsageCapacity";
import Row from "components/Row";
import UpgradeButton from "./component/UpgradeButton";
import { useTranslation } from "react-i18next";
import ClouDisplayItem from "components/Display/ClouDisplayItem";
import ClouItemInfo from "components/Display/ClouItemInfo";
import { Stack } from "@mui/system";
import IdentityMainMenu from "./component/IdentityMainMenu/IdentityMainMenu";


const Container = styled(Box, { name: "Container" })({
    display: "grid",
    alignItems: "center",

    gridTemplateColumns: `90px 1fr 1fr 1fr`,
    gridTemplateRows: `20px 60px 20px 1fr 20px 1fr`,
    gap: "10px 10px",
    gridTemplateAreas: `
    "title1 title1 title1 title1"
    "sh_name c_name c_name c_unit"
    "title2 title2 title2 title2"
    "c_loc c_loc c_loc c_loc"
    "title3 title3 title3 title3"
    "c_contact_ph c_contact_ph c_contact_em c_contact_em"`,

    "& .sh_name": { gridArea: "sh_name" },
    "& .c_name": { gridArea: "c_name" },
    "& .c_unit": { gridArea: "c_unit" },
    "& .c_loc": { gridArea: "c_loc" },
    "& .c_contact_ph": { gridArea: "c_contact_ph" },
    "& .c_contact_em": { gridArea: "c_contact_em" },
    "& .title1": { gridArea: "title1" },
    "& .title2": { gridArea: "title2" },
    "& .title3": { gridArea: "title3" }

})

const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))



export default function CommunityIdentity() {

    const { workspace, updateWorkspace } = useWorkspaceStore()
    const { navigateBack } = useWidgetBuilderNavigation()
    const { queryWorkspaceById, updateWorkspaceById } = useWorkspace()
    const [identity, setIdentity] = useState<Identity | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const params = useParams()
    const { t } = useTranslation()

    const [message, setMessage] = useState<IMessageControl>({
        open: false,
        message: "",
        type: "info"
    });


    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();


    const onSubmit = (e: any) => {
        setIsLoading(true)
        ChangeDetectionManager.check(identity, {
            CommunityName: e.name
        }).then(res => {
            if (res.isChange) {
                updateWorkspaceById(workspace.workspaceId || "", res.change).then((updateRes: any) => {
                    updateWorkspace(updateRes?.Attributes)
                    setIdentity({
                        CommunityName: updateRes?.Attributes["CommunityName"],
                        CommunityShortName: workspace.shortName
                    })
                    setIsLoading(false)


                    setMessage({
                        open: true,
                        message: "data saved .... ",
                        type: "success"
                    })
                })
            } else {
                setMessage({
                    open: true,
                    message: "No change detected, nothing to save!",
                    type: "info"
                })

                setIsLoading(false)
            }




        })
    }



    useEffect(() => {
        // let id = workspace?.workspaceId || ""
        // queryWorkspaceById(id,["CommunityName","CommunityShortName"]).then((res: any)=>{

        // })
        setIdentity({
            CommunityName: workspace.name,
            CommunityShortName: workspace.shortName,
            CommunityStrataNumber: workspace.strataNumber
        })

        reset({
            name: workspace?.name,
            number: workspace.strataNumber
        })

    }, [workspace])






    return (
        <Box sx={{ pr: 3, width: "80%" }}>
            <Box sx={{position: "relative"}}>
                <ClouItemTitle sx={{
                    mb: 3
                }}>
                    {t('CommunitySettings.identity.title')}
                </ClouItemTitle>

                <Box sx={{
                    position: "absolute",
                    right: 0,
                    top: 0
                }}>
                    <IdentityMainMenu/>
                </Box>
            </Box>
            <UpgradeButton />

            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <Box >
                {/* {t('CommunitySettings.identity.titleDetail')} */}
                {/* Add a name to represent your company or organization.
                    This name will also be shown to other organizations that you work with using Straclou. */}
            </Box>

            <Container sx={{ mt: 5 }}>

                {/* Title 1  */}
                <Box className="title1" sx={{ mb: 5 }}>
                    <Divider />
                    {/* <ClouItemTitle>Basic Information</ClouItemTitle> */}
                </Box>

                {/* Row 2 item 1 */}
                {/* Short Name */}
                <Box className="sh_name" sx={{
                    background: theme.palette.primary.main,
                    color: "#fff",
                    width: "90px",
                    height: "60px",
                    display: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "5px",
                    mb: 4
                    // mr: 1,


                }}>
                    {identity?.CommunityShortName}
                </Box>


                {/* Row 2 item 2 */}
                {/* Community Name & Type*/}

                <Box className="c_name" sx={{
                    display: "flex",
                    width: "auto",
                    position: "relative",
                    mb: 3
                    // mt: 3
                }}>
                    <Stack>
                        <Box sx={{ fontWeight: 600, position: "relative", display: "flex", flexDirection: "row" }}>
                            <Tooltip title="Community Name">
                                <Box>{workspace.name}</Box>
                            </Tooltip>
                            <ClouItemTitle sx={{
                                borderRadius: "11px",
                                background: "#9b64001a",
                                fontSize: "9pt",
                                width: "auto",
                                height: "20px",
                                padding: "3px 6px",
                                color: "#736c64",
                                fontWeight: 600,
                                mt: "-10px",
                                ml: 1

                                // right: "-10px",
                                // top: 0
                            }}>

                                {workspace.type}

                            </ClouItemTitle>
                        </Box>
                        <Tooltip title="Strata Number">
                            <ClouItemTitle>
                                {workspace.strataNumber.length === 0 ? <ClouItemTitle fontStyle={"italic"}>strata number here</ClouItemTitle> : workspace.strataNumber}
                            </ClouItemTitle>
                        </Tooltip>
                    </Stack>

                </Box>

                {/* Row 2 item 3 */}
                {/* Total Units */}
                <Box className="c_unit" sx={{ mb: 3 }}>
                    <Stack direction={"row"} gap={2}>
                        <ClouItemInfo>{workspace.communitySize}</ClouItemInfo>
                        <ClouItemTitle>units</ClouItemTitle>
                    </Stack>

                </Box>

                <Box className="title2" sx={{ mb: 2 }}>
                    <Divider />
                    <ClouItemTitle>Location</ClouItemTitle>
                </Box>

                <Box className="c_loc" sx={{ mb: 3 }}>
                    <Box>{workspace.address.street}</Box>
                    <Box>{workspace.address.town}</Box>
                    <Box>{workspace.address.city}</Box>
                    <Box>{workspace.address.parish}</Box>
                </Box>

                <Box className="title3">
                    <Divider />
                    <ClouItemTitle>Contact</ClouItemTitle>
                </Box>

                <Box className="c_contact_ph">
                    <Stack>
                        <ClouItemTitle>Phone</ClouItemTitle>
                        {workspace.phone.length === 0 ? <ClouItemTitle fontStyle={"italic"}>000-000-0000</ClouItemTitle> : workspace.phone}
                    </Stack>
                </Box>

                <Box className="c_contact_em">
                    <ClouItemTitle>Email</ClouItemTitle>
                    {workspace.email.length === 0 ? <ClouItemTitle fontStyle={"italic"}>example@email.com</ClouItemTitle> : workspace.email}
                </Box>

                {/* <Box className="s_number" sx={{
                    display: "flex",
                    mt: 2
                }}>


                    <Box sx={{ mb: 0, width: "100%" }}>



                        <InputLabel>Community Strata Number</InputLabel>
                        <Controller
                            name={"number"}
                            control={control}
                            defaultValue={identity?.CommunityStrataNumber || ""}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    {...register("number")}
                                    onChange={onChange}
                                    value={value}
                                    size="small"
                                    placeholder="Official Community Strata Number"
                                    inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                    sx={{ width: "87%" }} />
                            )}
                        />
                    </Box>
                </Box> */}



                {/* <Box className="c_capacity" sx={{ mt: 6 }}>
                    <Divider />
                    <ClouItemTitle>Contact</ClouItemTitle>
                    <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
                        <Stack>
                            <ClouItemTitle>Email</ClouItemTitle>
                            <ClouItemInfo>{workspace.phone}</ClouItemInfo>
                        </Stack>
                    </Box>
                </Box> */}


            </Container>
            {/* <ButtonController onCancel={navigateBack} isLoading={isLoading} message={message} setMessage={setMessage} /> */}

            {/* </form> */}
        </Box>
    )
}