import { ReactNode } from "react";

export class IAccountInput {
    private _key: string;
    private _value: any;
    private _text: string | ReactNode;
    private _fieldName: string;
    private _title: string;
    private _path: string;
    /**
     * Community Id or Code
     */
    private _cid: string; 

    constructor(args?: IIAccountInput) {
        if(args !==undefined){
            this._key = args.key
            this._value = args.value
            this._text = args.text
            this._fieldName = args.fieldName
            this._title = args.title
            this._path = args.path
            this._cid = args.cid
        }
    }

    // getters
    get key() { return this._key }
    get value() { return this._value }
    get text() { return this._text }
    get fieldName() { return this._fieldName }
    get title() { return this._title }
    get path() { return this._path }
    get cid(){return this._cid}


    // Setters
    set key(value: string) { this._key = value }
    set value(value: string) { this._value = value }
    set text(value: string | ReactNode) { this._text = value }
    set fieldName(value: string) { this._fieldName = value }
    set title(value: string) { this._title = value }
    set path(value: string) { this._path = value }
    set cid(value: string){this._path = value}


}

export interface IIAccountInput {
    key: string;
    value: any;
    text: string | ReactNode;
    fieldName: string;
    title: string;
    path: string;
    cid: string


}