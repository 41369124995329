import { useState } from "react"
import { IContact, IPremises } from "Straclou"



export interface GetPremisesInput{
    documentId: string
    communityId: string
}

export interface UpdatePremisesInput{

}

interface IContactList{
    phone: IContact[]
    email: IContact[]
}

export default function usePropertyInterface(){
    const [premises] = useState<IPremises | null>(null)
    const [contactList] = useState<IContactList | null>(null)


    const getPremises = async(getPremisesInput: GetPremisesInput)=>{
        // const docRef = doc(firestore,getPremisesInput.communityId, getPremisesInput.documentId)
        // const docSnap = await getDoc(docRef);

        // if(docSnap.exists()){
        //     console.log(docSnap.data())
        //     setPremises(new IPremises({
        //         docId: docSnap.id,
        //         ...docSnap.data()
        //     }))
        // }
    }

    const updateProperty = async(updateObject: UpdatePremisesInput, route: GetPremisesInput)=>{
        try {
            // const docRef = doc(firestore,route.communityId,route.documentId)
            // setDoc(docRef,updateObject,{merge: true}).then(res=>{
            //     console.log("result from updated Doc: ",res)
            // })
        } catch (error) {
            console.log("unable to update due to error: ",error)
        }
    }

    const getPremisesContactList = async(input: GetPremisesInput)=>{
        try {
            // const collectionRef = collection(firestore,`${input.communityId}/${input.documentId}/contacts`)
            // onSnapshot((collectionRef),(snapShot: any)=>{
            //     let earr: IContact[] = []
            //     let parr: IContact[] = []
            //     snapShot.forEach((doc: any) => {
            //         let IC = new IContact({
            //             documentId: doc.id,
            //             ...doc.data()
            //         })
            //       console.log("IC ",IC)
            //         if(IC.category === "PHONE"){ parr.push(IC)}
            //         if(IC.category === "EMAIL") {earr.push(IC)}
                    
            //     });

            //     setContactList({...contactList, email: earr, phone: parr})
            //     console.log("contact list email:  ",earr)
            //     console.log("contact list phone:  ",parr)

            // })
        } catch (error) {
            console.log("unable to get contact list due to error: ",error)
        }
    }

    return {premises, getPremises, updateProperty, getPremisesContactList, contactList}
}