import { Avatar, Box, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import Row from "components/Row";
import MemberButton from "./MemberButton";

const Wrapper = styled(Row)({
    display: "flex",
    justifyContent: "space-between",

})

const Info = styled(Box)({
    display: "flex",
    justifyContent: "space-between"
})

interface IProps{
    data?: any
}
export default function MemberItem({data}: IProps) {
    return (
        <Wrapper >
            <Info>
                <Avatar  sx={{width: "30px", height: "30px"}}/>
                <Box sx={{fontSize: "10pt", ml: 1}}>
                    <Row>{data?.name}</Row>
                    <Box sx={{color: grey[500]}}>{data.email}</Box>
                </Box>
            </Info>

            <MemberButton type={data.type}/>
        </Wrapper>
    )
}