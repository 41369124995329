import {create} from "zustand"
import { persist } from "zustand/middleware"
import {
    userHandler,
} from "Straclou/handler"


interface UserAccessStore {
    expired: boolean | undefined | null,
    updateAccess: (isExpired: boolean) => void
}

let userAccessStore: any = (set: any) => ({
    expired: undefined,
    updateAccess: (isExpired: boolean) => set(() => ({ expired: isExpired }))
})

userAccessStore = persist(userAccessStore, { name: userHandler.userAccessTag })

const userAccess = create<UserAccessStore>(userAccessStore)

export default userAccess
