import { Box, Input, TextField } from "@mui/material";
import AppButton from "components/widget/AppButton";

export default function InvitationWidget({ }) {
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "flex-start",
            height: "64px",
            margin: "16px 0 8px 0"
            
        }}>

            <TextField type="text" size="small" sx={{ height: "30px", width: "70%" }} id="outlined-basic" placeholder="charles.xavier@exampleemail.com" label="Emails" variant="outlined" />

            <Box sx={{
                display: "flex",
                alignItems:"center",
                justifyContent: "flex-end",
                margin: "-5px 0 0 15px",
                width: "146px",
                height: "100%"

            }}>
                <AppButton variant="outlined" value="invite member" />
            </Box>


        </Box>
    )
}