import { styled, Box } from "@mui/system";
const ClouItemTitle = styled(Box)(
    ({        
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        color: "InactiveCaptionText"
    }),
);

export default ClouItemTitle