import { Box, TextField } from "@mui/material"
import { theme } from "globals/theme"
import { styled, Button } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import bgld from "../../../images/small_town.svg"
import { ProgressStep, useOnboardingProgressStore } from "hooks/useOnboardingProgress"
import { Controller, useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import useAuth from "hooks/useAuth"
import useOnboardingProgressRouter from "hooks/useOnboardingProgressRouter"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const Wrapper = styled(Box)(({
    width: "60vw",
    height: "90vh"
}))

const InputBox = styled(TextField)(({
    width: "100%",

}))

const Content = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "16px 0",
    gap: "0 50px",



}))


const CommunitySetupAddressPage = () => {

    const navigate = useNavigate()
    const params = useParams()
    const { user } = useAuth()

    const { t } = useTranslation()

    const { updateProgress, progress } = useOnboardingProgressStore()
    const { goto } = useOnboardingProgressRouter(params?.clientId)

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();


    const onSubmit = (e: any) => {
        console.log(e)
        // updateProgress({
        //     CommunityAddress: e
        // })
        // navigate('/client/setup_complete')

        updateProgress({
            id: params.clientId || "",
            uid: user.uid,
            step: ProgressStep.Community_Address,
            data: { CommunityAddress: e },
        }).then(res => {
            console.log(res)
            // navigate(`/client/${params.clientId}/community_strata_number`)
            navigate('/client/setup_complete', { state: { name: progress.CommunityName, clientId: params.clientId } })
            // goto(ProgressStep.Complete)
        })

    }

    useEffect(() => {
        console.log("Progress Changed. ", progress)
        reset({
            street: progress.CommunityAddress?.street,
            town: progress.CommunityAddress?.town,
            city: progress.CommunityAddress?.city,
            parish: progress.CommunityAddress?.parish
        })
    }, [progress])

    return (
        <Wrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ textAlign: "center", fontSize: '2.3em', fontWeight: "600", margin: "30px 0", color: theme.palette.primary.main }}>
                    {t("community_address.title")}
                </Box>

                <Content>
                    <Box sx={{
                        // overflow: "hidden",
                        position: "relative",
                        "img": {
                            position: "absolute",
                            top: "-70px"
                        }
                    }}>
                        <img src={bgld} alt="landing Page Image" />
                    </Box>
                    <Box>

                        <Box sx={{ textAlign: "center", margin: "0 0 10px 0" }}>

                            <Controller
                                name={"street"}
                                control={control}
                                defaultValue={progress?.CommunityAddress?.street || ""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("street", {
                                            required: "Street name is required", minLength: {
                                                value: 3,
                                                message: "Length of community name have to be greater than 3 characters"
                                            }
                                        })}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Street name and number"
                                        inputProps={{ style: { textAlign: "left", fontSize: "16pt" } }}
                                        sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                                )}
                            />
                            <Box sx={{
                                margin: "2px",
                                height: "auto"
                            }}>
                                <ErrorMessage
                                    errors={errors}
                                    name="street"
                                    render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                                />
                            </Box>

                        </Box>
                        <Box sx={{ textAlign: "center", margin: "0 0 10px 0" }}>

                            <Controller
                                name={"town"}
                                control={control}
                                defaultValue={progress?.CommunityAddress?.town || ""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("town", {
                                            required: "Town is required", minLength: {
                                                value: 4,
                                                message: "Length of community name have to be greater than 4 characters"
                                            }
                                        })}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Town"
                                        inputProps={{ style: { textAlign: "left", fontSize: "16pt" } }}
                                        sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                                )}
                            />
                            <Box sx={{
                                margin: "2px",
                                height: "auto"
                            }}>
                                <ErrorMessage
                                    errors={errors}
                                    name="town"
                                    render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                                />
                            </Box>

                        </Box>
                        <Box sx={{ textAlign: "center", margin: "0 0 10px 0" }}>

                            <Controller
                                name={"city"}
                                control={control}
                                defaultValue={progress?.CommunityAddress?.city || ""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("city", {
                                            required: "City is required", minLength: {
                                                value: 3,
                                                message: "Length of community name have to be greater than 3 characters"
                                            }
                                        })}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="City"
                                        inputProps={{ style: { textAlign: "left", fontSize: "16pt" } }}
                                        sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                                )}
                            />
                            <Box sx={{
                                margin: "2px",
                                height: "auto"
                            }}>
                                <ErrorMessage
                                    errors={errors}
                                    name="city"
                                    render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                                />
                            </Box>

                        </Box>
                        <Box sx={{ textAlign: "center", margin: "0 0 10px 0" }}>

                            <Controller
                                name={"parish"}
                                control={control}
                                defaultValue={progress?.CommunityAddress?.parish || ""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("parish", {
                                            required: "Parish is required", minLength: {
                                                value: 3,
                                                message: "Length of community name have to be greater than 3 characters"
                                            }
                                        })}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Parish"
                                        inputProps={{ style: { textAlign: "left", fontSize: "16pt" } }}
                                        sx={{ width: "100%", display: "flex", justifyContent: "center" }} />
                                )}
                            />
                            <Box sx={{
                                margin: "2px",
                                height: "auto"
                            }}>
                                <ErrorMessage
                                    errors={errors}
                                    name="parish"
                                    render={({ message }: any) => <span style={{ margin: "8px", color: "red" }}>{message}</span>}
                                />
                            </Box>

                        </Box>

                        <Box sx={{ textAlign: "center", margin: "0 0 10px 0", lineHeight: "2em" }}>
                        {t("community_address.description")}
                        </Box>

                        <Box sx={{ width: "300px", margin: "0 auto" }}>
                            <Button type="submit" variant="contained" fullWidth sx={{ height: "45 px", fontSize: "1.5em" }}>Finish</Button>
                        </Box>
                    </Box>
                </Content>
            </form>
        </Wrapper>
    )
}

export default CommunitySetupAddressPage