import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Divider, InputLabel, MenuItem, TextField, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ChangeDetectionManager } from 'Straclou';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButtonSmall } from 'Features/Commentary/CustomStyles';
import { LoadingButton } from '@mui/lab';
import { WidgetBuilderContext } from '@knymbus/react-widget-builder';
import useWorkspaceStore from 'hooks/useWorkspaceStore';
import useWorkspace from 'hooks/useWorkspace';
import useCategoryFeeStore from 'hooks/useCategoryFeeStore';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function StructureOutletDeleteDialog(props: any) {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    
    const { goBack } = React.useContext(WidgetBuilderContext)

    const { type, disabled } = props

    const { workspace, updateWorkspace } = useWorkspaceStore()
    const { updateWorkspaceById } = useWorkspace()


    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemoval = () => {
        let m = workspace.structure

        const ma: any = []
        m.forEach(el => {
            if (el !== type.category) ma.push(el)
        })

        updateWorkspaceById(workspace.workspaceId, { CommunityStructure: ma }).then((res: any) => {
            console.log("update response: ", res)
            workspace.update(res.Attributes)
            updateWorkspace(workspace)
            goBack()
        })

    }

    const onSubmit = (e: any) => {
        console.log(e)
        setIsLoading(true)

        ChangeDetectionManager.check({ category: type.category }, e).then(res => {
            console.log(res)
            if (!res.isChange) {
                // then we have the data we need from the user no change in the input text and the category text
                // now we can continue our operation 
                handleRemoval()
            }
        })
    }

   
    return (
        <Box>
            <MenuItem onClick={handleClickOpen} disabled={disabled}>
                <DeleteIcon />
                <Box sx={{ color: 'red', fontWeight: 100, fontSize: "10.7pt" }}>
                    Remove category from structure
                </Box>
            </MenuItem>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <DialogTitle>{`Remove community structure`}</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            You are about to remove <b>{props.type?.category}</b> from your community structure.
                            If this was an error you can cancel and nothing will happen. However if this is your intention
                            then type the name of the category in the box below and hit remove.

                            <Box sx={{ mt: 1 }}>Please note that you can add this category at anytime after it has been removed</Box>
                        </DialogContentText>

                        <Box sx={{ mt: 2, mb: 3 }}>

                            <InputLabel>Removing {props?.type?.category}</InputLabel>
                            <Controller
                                name={"category"}
                                control={control}
                                defaultValue={""}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        {...register("category")}
                                        onChange={onChange}
                                        value={value}
                                        size="small"
                                        required
                                        placeholder={`Type ${props?.type?.category} to remove`}
                                        inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                        sx={{ width: "97%" }} />
                                )}
                            />
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{ mb: 2, mr: 3 }}>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton loading={isLoading} variant="contained" type="submit">Remove</LoadingButton>
                    </DialogActions>
                </form>

            </Dialog>
        </Box>
    );
}