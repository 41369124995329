export enum AppsyncRequestMethod {
    propertyList = "ListProperties",
    createProperty = "CreateProperty",
    getPropertyDetail = "GetPropertyDetail",
    listOwners = "listOwners",
    createPerson = "CreatePerson",
    listPerson = "ListPerson",
    createPersonContact = "CreatePersonContact",
    createPersonWork = "CreatePersonWork",
    oisPropertyList = "OISPropertyList",
    makePayment = "MakePayment",
    listContacts = "ListContacts",
    getOwnerDetail = "GetOwnerDetail",
    updateContact = "updateContact",
    updatePerson = "updatePerson",
    updateOwnerCategory = "updateOwnerCategory",
    updateWork = "updateWork",
    getCommunityPhase = "getCommunityPhase",
    createFinancialAccount = "createFinancialAccount",
    listProprietors = "listProprietors",
    getProprietor = "getProprietor",
    createAccount = "createAccount",
    accountList = "accountList",
    getAccount = "getAccount",
    createAdditionalAddress = "createAdditionalAddress",
    listAdditionalAddress = "listAdditionalAddress",
    updateOccupancy = "updateOccupancy",
    updatePropertyType = "updatePropertyType",
    createFloorPlan = "createFloorPlan",
    listFloorPlans = "listFloorPlans",
    transactionList = "transactionList",
    propertyDashboardList = "propertyDashboardList",
    listInvoices = "listInvoices",
    RecentRMSDashboardList = "RecentRMSDashboardList",
    FullRMSDashboardList = "FullRMSDashboardList",
    RMSListing = "RMSListing",
    getInvoiceList = "getInvoiceList"
}


type StatusType = "ON_DATA_FETCH" | "ON_SUCCESS" | "ON_ERROR"

export interface AppsyncRequest{
    method: AppsyncRequestMethod
    args?: any
}


export enum CommunityRequestMehod{
    create = "Create",
    get = 'Get',
    update = "Update"
}

export interface CommunityDispatchActionRequest{
    method: CommunityRequestMehod
    args?: any

}


/**
 * Interface for the useReducer state
 */
export interface StateReducer<T>{
    data: T | undefined
    isLoading: boolean
    isRequestError: {message: string} | null
}
/**
 * Reducer Action 
 */
export interface ReducerAction<T>{
    type: StatusType
    payload: T 
}

export interface IUseAppSync<T>{
    state: StateReducer<T>, 
    dispatch: (request : AppsyncRequest) => void
}

export type SortingOrder = "ASC" | "DES"

