import ContactDetail from "core-ui/Contacts/ContactDetail"
import { IContact } from "Straclou"



interface IProps{
    emailList?: IContact[], 
    phoneList?: IContact[]
}

const ContactDetailHolder: React.FC<IProps> = ({emailList, phoneList}) => {
    
    return (
        <>
            <ContactDetail name="Email" contacts={emailList} />
            <ContactDetail name="Phone" contacts={phoneList} />
        </>
    )
}

export default ContactDetailHolder