
import { IconButton, Divider, styled, Box } from "@mui/material"
import { grey } from "@mui/material/colors"
import ClouItemTitle from "components/ClouItemTitle"
import ClouItemInfo from "components/Display/ClouItemInfo"
import Row from "components/Row"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InvitationWidget from "./InvitationWidget"
import Members from "./Members"

const Wrapper = styled(Box)(({ theme }) => ({
    background: grey[50],
    borderRadius: "20px",
    padding: "16px",
    boxShadow: `1px 2px 1px ${grey[300]}`
}))

const TeamMembers: React.FC<any> = () => {
    return (
        <Wrapper>
            <Row>
                <Row sx={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <Box>
                        <ClouItemInfo>
                            Team Members
                        </ClouItemInfo>
                        <ClouItemTitle sx={{ fontSize: "10pt" }}>Every one invited can acces this service</ClouItemTitle>
                    </Box>
                    <Box>
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                </Row>
                <Row height={'8px'} />
                <Divider />
            </Row>

            {/* Invitation Widget */}
            <Row>
                <InvitationWidget />
            </Row>

            <Row>
                <Members />
            </Row>
        </Wrapper>
    )
}

export default TeamMembers