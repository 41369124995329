import { useWidgetBuilderNavigation } from "@knymbus/react-widget-builder";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, MenuItem, Radio, RadioGroup, Select, Stack, styled, TextField } from "@mui/material";
import ClouMoneyExhibit from "components/ClouMoneyExhibit";
import ClouSquareFootageExhibit from "components/ClouSquareFootageExhibit";
import Row from "components/Row";
import { Title } from "components/Title";
import useCategoryFeeStore from "hooks/useCategoryFeeStore";
import useWorkspace from "hooks/useWorkspace";
import useWorkspaceStore from "hooks/useWorkspaceStore";
import { useState, useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { v4 } from "uuid";

import { IAccountInput } from "./classes/IAccountInput";
import { AccountServiceContext } from "./Context/AccountServiceContext";
import { UIContent, UIFormWrapper, UIWrapper } from "./Helpers/UIWrapper";




const InputLabel = styled(Box)(({
    fontWeight: "bold",
    margin: "0 0 4px 0",
    fontSize: "10pt"
}))

export default function (props: any) {

    
    const { onClose } = props
    const [identity, setIdentity] = useState<any>({})
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [value, setValue] = useState<{name: string, value: string}>({name: "", value: ""})
    const [feeState, setFeeState] = useState(useCategoryFeeStore.initialState)
    const { handleNextView } = useContext(AccountServiceContext)

    const { updateStructureList, structureList, workspace } = useWorkspaceStore()

    const { queryWorkspaceById } = useWorkspace()

    const params = useParams()
    const { navigate } = useWidgetBuilderNavigation()

    const { handleSubmit, reset, control, register, formState: { errors } } = useForm();



    useEffect(() => {

        console.log("Params: ", params)

        if (params !== undefined) {

            let wid = params?.workspaceId || ""
            queryWorkspaceById(wid, ["CommunityStructure",]).then((res: any) => {
                console.log("Identity: ", res)
                res["CommunityStructure"].forEach((s: string) => {
                    updateStructureList(s)
                })
            })
        }

    }, [params])


    const handleClose = () => {
        onClose?.()
        reset()
    }

    const onSubmit = (e: any) => {
        setIsLoading(true)
        console.log("form data: ", e)
        console.log(params)
        const createNewAccountInput: any = {
            meta: {
                cid: params.workspaceId
            },
            data: [
                new IAccountInput({
                    key: v4(),
                    value: e.property_type,
                    text: e.property_type,
                    fieldName: "property_type",
                    title: "Property Type",
                    path: "property_type",
                    cid: params.workspaceId || ""
                }),
                new IAccountInput({
                    key: v4(),
                    value: e.fee,
                    text: <ClouMoneyExhibit value={0} />,
                    fieldName: "fee",
                    title: "Maintenance Fee",
                    path: "fee",
                    cid: params.workspaceId || ""
                })
            ]
        }

        console.log("Input: ", createNewAccountInput)

        // TODO remove stud with real data from server.


        setIsLoading(false)
        navigate({
            path: "account_summary",
            params: createNewAccountInput
        })

    }


    const onSelectionChange = (e: any) => {
        setValue(e.target)
        useCategoryFeeStore.subscribe(setFeeState)
        useCategoryFeeStore.init(workspace.workspaceId, e.target.value)

        
    }

    return (
        <UIWrapper>
            <UIContent>

                <Title variant="1" sx={{ fontWeight: 400 }}>What is this unit type, size and fee?</Title>

                <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>



                    <UIFormWrapper sx={{ mb: 5, mt: 5 }}>
                        <InputLabel>Property Type</InputLabel>
                        <Controller
                            name={"property_type"}
                            control={control}
                            defaultValue={structureList[0] || ""}
                           
                            render={({ field }) => (
                                <Select
                                    labelId="demo-simple-select-disabled-label"
                                    id="demo-simple-select-disabled"
                                    placeholder="Enter property type"                                    
                                    {...field}
                                    value={value.value}
                                    onChange={(e)=>{onSelectionChange(e)}}
                                    sx={{ width: "100%", textAlign: "center" }}
                                    size="small"
                                >

                                    {
                                        structureList.map(el => {
                                            return (<MenuItem key={el} value={el}>{el}</MenuItem>)
                                        })
                                    }


                                </Select>
                            )}
                        />
                    </UIFormWrapper>

                    <UIFormWrapper sx={{ mb: 3 }}>

                        {/* <InputLabel>Association/Maintenace Fee</InputLabel>
                    <Controller
                        name={"fee"}
                        control={control}
                        defaultValue={identity?.CommunityAddress?.street || ""}
                        render={({ field: { onChange, value } }) => (
                            <TextField
                                {...register("fee")}
                                onChange={onChange}
                                value={value}
                                type={"number"}
                                size="small"
                                required
                                placeholder="The base monthly association or maintenance fee that the property must pay."
                                inputProps={{ style: { textAlign: "left", fontSize: "12pt" } }}
                                sx={{ width: "100%" }} />
                        )}
                    /> */}


                        <InputLabel>Association/Maintenace Fee</InputLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                        >
                            <Stack direction={"column"} gap={2}>
                                {
                                    feeState.feeDirectory.collection.map(el=>{
                                        return <FormControlLabel key={el.key} value={el.size} control={<Radio />} label={<Box sx={{ display: "flex" }}><ClouSquareFootageExhibit footage={el.size} /> - <ClouMoneyExhibit value={el.fee} /></Box>} />
                                    })
                                }
                                
                                
                            </Stack>

                        </RadioGroup>


                    </UIFormWrapper>

                    <Row center height={"40px"} sx={{ margin: "50px 0 0 0", display: "flex" }}>

                        <Box sx={{ position: "fixed", right: '32px', bottom: '32px' }}>
                            <Stack direction={"row"} gap={2}>
                                <Button onClick={handleClose}>Cancel</Button>
                                <LoadingButton
                                    loading={isLoading}
                                    variant="contained"
                                    type="submit"
                                    sx={{ width: "120px" }}
                                >Next</LoadingButton>
                            </Stack>
                        </Box>
                    </Row>

                </form>
            </UIContent>
        </UIWrapper>
    )
}


