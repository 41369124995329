import React from 'react'
import { Controller, useFormContext } from "react-hook-form"
import { SelectIProps } from './interface'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useClouFormContext } from './ClouFormContext'
import { v4 as uuidV4 } from 'uuid'
import ClouItemTitle from 'components/ClouItemTitle'





const ClouFormInputSelect: React.FC<SelectIProps> = ({ name, label, hint, items, placeholder, disabled, onSelectionChange }) => {
    const { control } = useFormContext()
    const { activeColor } = useClouFormContext();


    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
            }) => (
                <FormControl fullWidth>
                    {/* <InputLabel id="select-label" color={activeColor} sx={{top: "-8px"}}>{label}</InputLabel> */}
                    <ClouItemTitle fontSize={'.8em'} paddingLeft="8px">{label}</ClouItemTitle>
                    <Select
                        labelId="select-label"
                        value={value}
                        placeholder={placeholder}
                        size="small"
                        onChange={(value)=>{onSelectionChange?.(value); onChange(value);}}
                        color={activeColor}
                        fullWidth
                        disabled={disabled}
                    >

                        {
                            items?.map(el => {
                                return <MenuItem key={uuidV4()} value={el.value}>
                                    {el.text}
                                </MenuItem>
                            })
                        }
                    </Select>
                    <FormHelperText>{hint}</FormHelperText>
                </FormControl>                
            )}



        />

    )
}

export default ClouFormInputSelect