import { Box, styled, CircularProgress } from "@mui/material"
import { grey } from "@mui/material/colors"
import { theme } from "globals/theme"



import { useLocation, useNavigate } from "react-router-dom";
import StraclouLogo from "../../components/StraclouLogo"
import { useEffect, useState } from "react";
import useCommunity from "hooks/useCommunity";
import { useOnboardingProgressStore } from "hooks/useOnboardingProgress";

const Wrapper = styled(Box)(({
    width: "60vw",
    height: "90vh"
}))


const Row = styled(Box)(({
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
}))

const Content = styled(Box)(({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    margin: "30px 0"

}))

const SetupComplete = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const state = location.state as {name: string, clientId: string}
    

    const [loader, setLoader] = useState("")

    

    const handleClick = () => {
        navigate(`/rms/${state?.clientId}`)
    }

    const moduleLoader = [
        `Initializing Community ${state?.name}`,
        "Loading community structrue",
        "Almost there",
        "Finish"
    ]

    useEffect(()=>{
        let c = 0
        setInterval(() => {
            if(c < 4){
                setLoader(moduleLoader[c])
            }
            if(c === 4){
                handleClick()
            }
            c++
        }, 2000);


    },[])

    return (
        <Wrapper>
            <Row>
                <StraclouLogo />
            </Row>

            <Row>

                <Box>
                    <Box sx={{
                        fontSize: '2.3em',
                        fontWeight: "600", margin: "30px 0 90px 0",
                        color: theme.palette.primary.main
                    }}>Creating community workspace</Box>
                    <Box sx={{ margin: "30px auto", display: "flex", justifyContent: "center"}}>
                        <CircularProgress color="primary" size={90} />

                    </Box>



                    <Box sx={{ margin: "20px 0 0 0", 
                    fontSize: "1em", fontWeight: "500", 
                    color: grey[600], lineHeight: "1.3em",
                    display: "flex", justifyContent: "center",
                    "span": {display: "block"}
                    }}>
                        
                        
                        <span>{loader}</span>
                    </Box>
                </Box>

            </Row>

        </Wrapper>
    )
}

export default SetupComplete