import * as React from 'react';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { deepOrange, grey } from '@mui/material/colors';
import { Box, Divider } from '@mui/material';
import Row from 'components/Row';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ApartmentIcon from '@mui/icons-material/Apartment';
import WorkIcon from '@mui/icons-material/Work';
import PropertyDetail from '../PropertyDetail';

import { useParams } from 'react-router';
import { AppsyncRequestMethod } from 'hooks/interface';
import useAppSync from 'hooks/useAppSync';
import { theme } from 'globals/theme';
import WorkDetail from './WorkDetail';
import ContactDetailHolder from './ContactDetailHolder';
import AccountDetail from '../AccountDetail';
import { useLocation } from 'react-router-dom';
import usePropertyInterface from 'hooks/usePropertyInterface';
import { IAccount } from 'Straclou';
import { useState } from 'react';
import { useAccountInterface } from 'hooks/useAccountInterface';

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const iconColor = deepOrange[300]

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${grey[500]};
  cursor: pointer;
  font-size: 0.775rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 12px 16px;
  margin: 6px 6px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;

  &:hover {
    color: ${theme.palette.primary.light};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline-offset: 2px;
  }

  &.${tabUnstyledClasses.selected} {
    color: ${theme.palette.primary.main};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
//   font-size: 0.875rem;
  padding: 0px;
`;

const TabsList = styled(TabsListUnstyled)`
  min-width: 220px;
  background-color: transparent;
  border-radius: 0px;
  margin-bottom: 8px;
  border-bottom: 1px solid ${grey[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;

const IISIZE = 20

const ICONSIZE = { width: `${IISIZE}px`, height: `${IISIZE}px` }


interface StateObject{
    documentId: string
    communityId: string
    id: string

}

const CustomTab: React.FC = () => {

    // const { accountId } = useParams<{ accountId: string }>()

    const location = useLocation()
    
    const {premises, getPremises, getPremisesContactList, contactList} = usePropertyInterface()

    // const {account, getAccount} = useAccountInterface()

    React.useEffect(() => {
        console.log("State: ",location.state)
        let paramsData: StateObject = location.state as StateObject
        if (paramsData !== undefined && paramsData !== null) {
            getPremises(paramsData)
            getPremisesContactList(paramsData)
            // getAccount(paramsData.id)
        }
    }, [location])

   

    return (
        <TabsUnstyled defaultValue={0}>
            <TabsList>
                {/* Account Information */}
                <Tab>
                    <Box>
                        <Row center>
                            <ApartmentIcon sx={ICONSIZE} />
                        </Row>
                        <Row center sx={{ fontSize: ".745em" }}>Account</Row>
                    </Box>
                </Tab>
                {/* Property Information */}
                <Tab>
                    <Box>
                        <Row center>
                            <ApartmentIcon sx={ICONSIZE} />
                        </Row>
                        <Row center sx={{ fontSize: ".745em" }}>Property</Row>
                    </Box>
                </Tab>
                {/* Email Table */}
                <Tab>
                    <Box>
                        <Row center>
                            <EmailIcon sx={ICONSIZE} />
                        </Row>
                        <Row center sx={{ fontSize: ".745em" }}>Contacts</Row>
                    </Box>
                </Tab>


                {/* Work Information Tab */}
                <Tab>
                    <Box>
                        <Row>
                            <WorkIcon sx={ICONSIZE} />
                        </Row>
                        <Row center sx={{ fontSize: ".745em" }}>
                            Work
                        </Row>
                    </Box>
                </Tab>
            </TabsList>

            <TabPanel value={0}>
                <AccountDetail />
            </TabPanel>
            
            <TabPanel value={1}>
                <PropertyDetail premises={premises}/>
            </TabPanel>

            <TabPanel value={2}>
               <ContactDetailHolder emailList={contactList?.email} phoneList={contactList?.phone}/>
            </TabPanel>

            <TabPanel value={3}>
                <WorkDetail work={premises?.workDetail} />
                
                </TabPanel>
        </TabsUnstyled>
    );
}


export default CustomTab