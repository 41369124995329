// import { Address } from "models";

import { Address } from "./IShareInterface";

export class IWork{
    
    private _id: string;

    private _main: boolean;

    private _isAssigned: boolean = false

    private _companyName: string = ""

    private _occupation:  string = ""
    
    private _createdAt: string

    private _updatedAt: string

    private __deleted: any

    private __version: number 

    private _address: Address

    constructor(args? : any){

        if(args !== undefined){
            
            this._isAssigned = true

            this._id = args.id

            this._main = args.main

            this._companyName = args?.company

            this._createdAt = args?.createdAt

            this._occupation = args?.position

            this._updatedAt = args?.updatedAt

            this.__deleted = args._deleted
            
            this.__version = args._version

            this._address = args?.address
        }
    }

    get id(){return this._id}

    get main(){return this._main}

    get isAssigned(){return this._isAssigned}

    get companyName(){return this._companyName}

    get occupation(){return this._occupation}

    get createdAt(){return this._createdAt}

    get updatedAt(){return this._updatedAt}

    get deleted(){return this.__deleted}

    get version(){return this.__version}

    get address(){return this._address}

    get completed(){
        let count = 0

        if(this._companyName.length > 0) count++; 
        if(this._occupation.length > 0) count++; 
        if(this._address?.street) count++; 
        if(this._address?.town) count++; 
        if(this._address?.city) count++; 
        if(this._address?.parish) count++; 
        
        return (count/6)*100
    }
}