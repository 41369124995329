import Row from "components/Row"
import { IContact } from "Straclou/commons"
import { PhoneFormatter } from "globals/helper"
import { Box, Divider, SxProps } from "@mui/material"
import React from "react"

interface IProps {
    contacts?: IContact[]
    name?: string
    buttons?: React.ReactChild
    sx?: SxProps
}

interface IPropsContact {
    item: IContact
}

const Contact: React.FC<IPropsContact> = ({ item }) => {
    return (
        <Row sx={{ padding: '8px 16px', }}>
            <Row sx={{ color: "GrayText", fontSize: ".8em" }}>{item.type}</Row>
            <Row sx={{fontSize: ".8em"}}>{item.category === 'phone' ? PhoneFormatter(item.address) : item.address}</Row>
            <Row width={"90%"} sx={{pt:1}}>
                <Divider />
            </Row>
        </Row>
    )
}
const ContactDetail: React.FC<IProps> = ({ contacts, name, buttons, sx }) => {
    return (
        <Box sx={sx}> 
            <Row sx={{ 
                color: "GrayText", 
                fontSize: ".8em",
                display: "grid",
                gridTemplateColumns: "1fr 1fr"
                }}>
                  <Box sx={{padding: "0 16px", display: "flex", alignItems: "center"}}>{name} List</Box>  
                  <Box sx={{display: "flex", justifyContent: "flex-end", padding: " 0 8px 0 0"}}>
                      {buttons}
                  </Box>
                    </Row>
            {
                contacts?.map(el => {
                    return <Contact key={el.id} item={el} />
                })
            }
        </Box>
    )
}

export default ContactDetail


